<template>
  <div class="zoined-snippet">
    <spinner v-show="loading"></spinner>
    <p class="chart-error" v-if="error">{{ error }}</p>
    <dashbox v-if="dashData" v-bind="dashData" :component="component" :series-type="filters.series_type"></dashbox>
    <pagination
      v-if="paginationEnabled"
      :page="pagingInfo.page"
      :totalPages="pagingInfo.totalPages"
      @pageChange="switchPage"
    ></pagination>
  </div>
</template>

<script>
import pagination from "../../components/pagination";
import spinner from "../../components/spinner";
import dashbox from "./dashbox.vue";
import cloneDeep from "clone";
import SnippetFetcher from "../../lib/data/snippet";
import PortalContext from "../../lib/data/portal_context";

function toInt(i) {
  if ("number" == typeof i) {
    return i;
  } else if ("string" == typeof i) {
    try {
      return parseInt(i);
    } catch (err) {
      return 0;
    }
  } else {
    return 0;
  }
}

export default {
  components: {
    pagination,
    spinner,
    dashbox,
  },
  props: {
    pagination: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    component: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      currentFilters: this.filters,
      options: null,
      loading: false,
      fetchIndex: 0,
      error: null,
      snippetHtml: null,
      dashData: null,
    };
  },
  methods: {
    fetchData: function(filters, chartOptions = null, context = null) {
      this.fetchIndex++;
      this.loading = true;
      const ongoingIndex = this.fetchIndex;
      chartOptions =
        chartOptions ||
        Object.assign({}, this.chartOptions, {
          static: this.component ? !!this.component.static : false,
        });
      context = context || PortalContext.global();
      return new SnippetFetcher(context)
        .fetchJson(this.type, filters, false, chartOptions)
        .then((json) => {
          // Only process results if this is latest query we have started
          // This discards multiclicks
          if (ongoingIndex == this.fetchIndex) {
            this.loading = false;

            this.error = null;
            json.max_value = json.max_value.toString();
            this.dashData = json;
            this.$emit("data", json);
            return true;
          } else {
            return false;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.error = err.message;
          this.options = null;
          this.dashData = null;
          return false;
        });
    },
    switchPage: function(page) {
      const f = cloneDeep(this.currentFilters);
      f.offset = (page - 1) * (this.options.paging.limit || 10);

      this.fetchData(f).then((success) => {
        if (success) {
          this.currentFilters = f;
        }
      });
    },
  },
  computed: {
    paginationEnabled: function() {
      return this.pagination;
    },
    pagingInfo: function() {
      if (!this.options) {
        return { page: 1, totalPages: 1 };
      } else {
        const paging = this.options.paging;
        const offset = toInt(paging.offset) || 0;
        const limit = toInt(paging.limit) || 10;
        return {
          page: Math.floor(offset / limit) + 1,
          totalPages: Math.ceil(paging.total_items / limit),
        };
      }
    },
  },
  watch: {
    filters: function(filters, old) {
      if (!_.isEqual(filters, old)) {
        this.fetchData(filters).then((success) => {
          if (success) {
            this.drilldownStack = [];
          }
        });
      }
    },
    pagingInfo: function(info) {
      this.$emit("pageChanged", cloneDeep(info, true));
    },
    type: function(type) {
      this.fetchData(this.filters).then((success) => {
        if (success) {
          this.drilldownStack = [];
        }
      });
    },
  },
  mounted: function() {
    if (this.component?.request_params) {
      // predefined params and chart_token with params digest for public pages
      const { filter, chart_options } = this.component.request_params;
      const context = new PortalContext(this.component.chart_token, I18n.locale);
      this.fetchData(filter, chart_options, context);
    } else if (this.filters) {
      this.fetchData(this.filters);
    }
  },
};
</script>

<style lang="scss" scoped>
.zoined-snippet {
  overflow: hidden;
  min-height: 70px;
}
</style>
