import _ from "lodash";
import UserCompanyRole from "../model/user-company-role";
import Team from "@/model/team";
import Permission from "@/model/permission";
import Permissions from "@/model/permissions";

export function defaultPermissions(): Permissions {
  return {
    dimensions: {
      store: { allow: "all" },
    },
    metrics: { allow: "all" },
    groupings: { allow: "all" },
  };
}
export function hasAccessToDimension(permissions: Permissions, dimension, id) {
  const permission = _.get(permissions, `dimensions.${dimension}`) || {
    allow: "all",
  };
  if (permission.allow === "all") {
    return true;
  } else if (permission.allow === "except") {
    return permission.ids.indexOf(id) < 0;
  } else if (permission.allow === "only") {
    return permission.ids.indexOf(id) >= 0;
  } else {
    return false;
  }
}

export function calculateEffectivePermissions(role: UserCompanyRole, teams: Team[]): Permissions {
  // Get array of all permissions objects
  const allPermissions = _.compact([role.permissions].concat(teams.map(({ permissions }) => permissions)));

  // Build array of dimensions permissions: [ ['store', {...}], ['store', {...}]]
  const dimensionPermissionsArr = _.flatten(allPermissions.map((permissions) => _.toPairs(permissions.dimensions)));

  // Reduce and merge dimensions permissions to object { store: {...}, ... }
  const dimensions = dimensionPermissionsArr.reduce(
    (result, [dimension, permission]) => ({
      ...result,
      [dimension]: mergePermissions([result[dimension], permission]),
    }),
    {}
  );

  // Merge metrics permissions
  const metrics = mergePermissions(allPermissions.map(({ metrics }) => metrics));

  // Merge groupings permissions
  const groupings = mergePermissions(allPermissions.map(({ groupings }) => groupings));

  return { dimensions, metrics, groupings };
}

export function mergePermissions(permissions: Permission[]): Permission {
  let exceptIds, onlyIds, ids;

  // Remove possible nulls
  permissions = _.compact(permissions);

  const allowAll = permissions.filter((permission) => permission.allow === "all");
  const allowOnly = permissions.filter((permission) => permission.allow === "only");
  const allowExcept = permissions.filter((permission) => permission.allow === "except");

  if (allowAll.length > 0) {
    return { allow: "all" };
  } else if (allowExcept.length > 0) {
    exceptIds = _.intersection(...allowExcept.map(({ ids }) => ids));
    onlyIds = _.union(...allowOnly.map(({ ids }) => ids));
    ids = _.without(exceptIds, ...onlyIds);
    if (ids.length === 0) {
      return { allow: "all" };
    } else {
      return { allow: "except", ids };
    }
  } else if (allowOnly.length > 0) {
    ids = _.union(...allowOnly.map(({ ids }) => ids));
    if (ids.length === 0) {
      return { allow: "none" };
    } else {
      return { allow: "only", ids };
    }
  } else {
    return { allow: "none" };
  }
}
