import { makeApiInstance } from "@/api/instance";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { Ref } from "vue";

export type UseEmailDeliveriesParams = {
  search: string;
  sort: string;
  page: number;
  per_page: number;
};

export const useEmailDeliveries = (params: Ref<UseEmailDeliveriesParams>) => {
  return useQuery({
    queryKey: ["emailDeliveries", params],
    queryFn: () =>
      makeApiInstance()
        .get("/api/zoined_admin/email_deliveries", { params: params.value })
        .then((res) => {
          return {
            data: res.data,
            total: Number.parseInt(res.headers["total"]),
          };
        }),
  });
};

export const useClearEmailDeliveryMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: number) => makeApiInstance().delete(`/api/zoined_admin/email_deliveries/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["emailDeliveries"] });
    },
  });
};
