import _ from "lodash";
import PortalContext from "./data/portal_context";

export const postForm = (action: string, attrs = {}) => {
  // add CSRF token
  const portalContext = new PortalContext();
  attrs[portalContext.csrfParam] = portalContext.csrfToken;

  // Submit as form
  const form = window.$("<form></form>");
  form.attr("method", "POST");
  form.attr("action", action);
  _.each(attrs, (value, key) => {
    const field = window.$(`<input type="hidden" name="${key}"></input >`);
    field.attr("value", value);
    form.append(field);
  });
  window.$(document.body).append(form);
  form.submit();
};
