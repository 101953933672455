<template lang="pug">
.insight-chart
  zoined-chart(type='insight', :title="chartTitle", :component='component', :clean-update="true", :filters='chartFilters', :chart-options="chartOptions", :chartData="overriddenChartData", :pagination="true", :drilldown="false", @select="onSelect", @data="onData", @updateLimit="limit = $event", @loading="selectedItem = null")
  .detail-chart(v-if="selectedItem")
    zoined-chart(type="insight", :title="detailChartTitle", :filters='detailChartFilters', :chart-options="chartOptions", :chartData="overriddenDetailChartData", :limit="true", :drilldown="false", @data="onDetailData", @updateLimit="detailChartLimit = $event")
</template>
<script>
import zoinedChart from "../zoined-chart/zoined-chart.vue";
import Vue from "vue";
import Component from "vue-class-component";

const insightsSelectedColor = "#81E4FF";

@Component({
  components: {
    zoinedChart
  },
  props: {
    component: Object,
    filters: Object,
    chartOptions: Object
  }
})
export default class InsightChart extends Vue {
  selectedItem = null;
  chartData = null;
  detailChartData = null;

  get limit() {
    return this.filters.limit || 5;
  }

  set limit(limit) {
    this.$emit("update", { limit });
  }

  get detailChartLimit() {
    return this.filters.detail_chart_limit || 5;
  }

  set detailChartLimit(detail_chart_limit) {
    this.$emit("update", { detail_chart_limit });
  }

  get chartTitle() {
    return this.firstChartItem && this.insightItemTitle(this.firstChartItem);
  }

  insightItemTitle(item) {
    const dimensionName = I18n.t(`filter.config.${item.dimension}`);
    return `${dimensionName} / ${item.name}`;
  }

  get showDetailChart() {
    return !!this.detailChartType;
  }

  get detailChartTitle() {
    const item = _.get(this.detailChartData, "series.0.data.0");
    return item && this.insightItemTitle(item);
  }

  get chartFilters() {
    const filter = { ...this.filters, limit: this.limit };

    delete filter.detail_chart_limit;

    return filter;
  }

  get detailChartFilters() {
    const filter = { ...this.filters, limit: this.detailChartLimit };

    if (this.firstChartItem && this.selectedItem) {
      const { id, dimension } = this.selectedItem;
      const dimensions = this.filters.dimension;

      // Set grouping and dimensions
      filter.grouping = [dimension];
      filter.dimensions = _.without(filter.dimensions, dimension);

      // Set filters
      filter[dimension] = [id];
      if (this.firstChartItem != this.selectedItem) {
        filter[this.firstChartItem.dimension] = [this.firstChartItem.id];
      }
    }

    return filter;
  }

  get overriddenChartData() {
    if (this.chartData) {
      return {
        ...this.chartData,
        series: this.chartData.series.map(series => ({
          ...series,
          data: series.data.map(item => ({
            ...item,
            color:
              this.selectedItem &&
              this.selectedItem.id === item.id &&
              this.selectedItem.dimension === item.dimension
                ? insightsSelectedColor
                : item.color
          }))
        }))
      };
    }
  }

  get overriddenDetailChartData() {
    if (this.detailChartData) {
      return {
        ...this.detailChartData,
        series: this.detailChartData.series.map(series => ({
          ...series,
          data: series.data.map((item, i) => ({
            ...item,
            color: i === 0 ? insightsSelectedColor : item.color
          }))
        })),
        // Same yAxis configuration (min, max) as with main chart
        yAxis: this.chartData.yAxis
      };
    }
  }

  get firstChartItem() {
    return _.get(this.chartData, "series.0.data.0");
  }

  onSelect(item) {
    this.selectedItem = item;
  }

  onData(data) {
    this.chartData = data;
    this.onSelect(_.get(this.chartData, "series.0.data.1"));
  }

  onDetailData(data) {
    this.detailChartData = data;
  }
}
</script>

<style lang="scss" scoped>
.insight-chart {
  margin-top: 5px;
}
.chart-title {
  color: #4d4d4d;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}
.paging {
  margin-right: 10px;
}
</style>
