<template lang="pug">
div
  spinner(v-if="!users")
  table.table.table-hover
    thead
      tr
        th {{ "activerecord.attributes.user.first_name" | i18n }}
        th {{ "activerecord.attributes.user.last_name" | i18n }}
        th {{ "activerecord.attributes.user.email" | i18n }}
        th {{ "activerecord.attributes.user.role" | i18n }}
        th {{ "activerecord.attributes.user.status" | i18n }}
        th
        th
    tbody(v-if="users")
      tr(v-if="users.length == 0")
        td(colspan=6) {{ "table.no_results" | i18n }}
      tr(v-for="user in users", :key="user.id")
        td {{ user.first_name }}
        td {{ user.last_name }}
        td {{ user.email }}
        td {{ ("roles." + user.role) | i18n }}
        td(v-if="user.user_status.type == 'active'") {{ "company_admin.user_row.active" | i18n }}
        td(v-else-if="user.user_status.type == 'invited'")
          | {{  "company_admin.user_row.invited" | i18n('', { ago: ago(user.created_at) })  }} &nbsp;
          a(@click="resend(user)") {{ "company_admin.user_row.resend" | i18n }}
        td(v-else-if="user.user_status.type == 'bad_mailbox'")
          span.bg-danger
            i.fa.fa-exclamation-circle.text-danger &nbsp;
            | {{  "company_admin.user_row.bad_mailbox" | i18n('', { ago: ago(user.updated_at) })  }}
        td(v-else)
          span.bg-warning
            i.fa.fa-exclamation-circle.text-warning &nbsp;
            | {{  "company_admin.user_row." + user.user_status.type | i18n('', { ago: ago(user.updated_at) })  }}
        td(v-if="isAdmin")
          form.button_to(method="post", :action="impersonatePath(user)")
            input(type="hidden", name="authenticity_token", :value="csrfToken()")
            input(value="Impersonate", type="submit")
        td.text-right
          a(@click="edit(user)") {{ "actions.edit" | i18n }}
  user-dialog(
    ref="userDialog",
    :teams="teams",
    :definitions="newsletterDefinitions",
    @delete="$emit('delete', $event)"
    @updated="$emit('updated', $event)"
  )
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import moment from "moment";
import UserCompanyRole from "@/model/user-company-role";
import Spinner from "@/components/spinner.vue";
import Team from "@/model/team";
import adminTeamsApi from "@/api/admin-teams-api";
import adminNewsletterDefinitions from "@/api/admin-newsletter-definitions-api";
import adminUsersApi from "@/api/admin-users-api";
import NewsletterDefinition from "@/model/newsletter-definition";
import UserDialog from "./user-dialog.vue";

@Component({ components: { Spinner, UserDialog } })
export default class UserTable extends Vue {
  @Ref("userDialog")
  userDialog: UserDialog;

  @Prop()
  users: UserCompanyRole[];

  teams: Team[] = null;

  newsletterDefinitions: NewsletterDefinition[] = null;

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  ago(time) {
    return moment(time).fromNow(true);
  }

  async edit(user: UserCompanyRole) {
    // fetch full user details
    user = await adminUsersApi.get({ company_id: window.zoinedContext.companyId, id: user.id });

    this.userDialog.show(user);
  }

  resend(user: UserCompanyRole) {
    this.$emit("resend", user);
  }

  impersonatePath(role) {
    return `/users/${role.user.id}/impersonate`;
  }

  csrfToken() {
    return document.querySelector("meta[name='csrf-token']")?.getAttribute("content") || "";
  }

  created() {
    this.fetchTeams();
    this.fetchNewsletterDefinitions();
  }

  async fetchTeams() {
    this.teams = await adminTeamsApi.get({ company_id: window.zoinedContext.companyId });
  }

  async fetchNewsletterDefinitions() {
    this.newsletterDefinitions = await adminNewsletterDefinitions.get({ company_id: window.zoinedContext.companyId });
  }
}
</script>
