<template>
  <zoined-chart
    type="trend_insights"
    :filters="props.filters"
    :chart-options="props.chartOptions"
    :chart-data="updatedData"
    v-bind="$attrs"
    v-on="$listeners"
    @data="data = $event"
  ></zoined-chart>
</template>

<script setup lang="ts">
import ChartOptions from "@/model/chart-options";
import ZoinedChart from "@/zoined-chart/zoined-chart.vue";
import _ from "lodash";
import { computed, ref } from "vue";

const props = defineProps<{
  filters: Record<string, any>;
  chartOptions: ChartOptions;
}>();

const data = ref(null);

const updatedData = computed(() => {
  if (!data.value) {
    return null;
  }

  if (props.chartOptions.chart_variant === "change") {
    return updateChangeChartData(data.value);
  } else {
    return updateValueChartData(data.value);
  }
});

const updateValueChartData = (opts: any) => {
  opts = _.cloneDeep(opts);

  // Calculate max values for each x-axis point
  const visibleSeries = opts.series.filter((serie) => serie.visible !== false);
  const seriesByStack = _.groupBy(visibleSeries, "stack");
  const stackTotals = _.map(seriesByStack, (series) => {
    return series.reduce((acc, serie) => {
      serie.data.forEach((point, index) => {
        acc[index] = (acc[index] || 0) + (point.y || 0);
      });
      return acc;
    }, []);
  });
  const maxValues = stackTotals.reduce((acc, stack) => {
    return stack.map((value, index) => {
      return Math.max(acc[index] || 0, value);
    });
  }, []);

  // Update annotation y positions based on max values
  if (opts.annotations) {
    opts.annotations = opts.annotations.map(({ labels, ...annotation }) => {
      return {
        ...annotation,
        labels: labels.map((label, x) => {
          const y = maxValues[x] || 0;
          return {
            ...label,
            point: {
              xAxis: 0,
              yAxis: 0,
              x,
              y,
            },
            verticalAlign: y >= 0 ? "bottom" : "top",
            y: y >= 0 ? -5 : 5,
          };
        }),
      };
    });
  }
  return opts;
};

const updateChangeChartData = (opts: any) => {
  opts = _.cloneDeep(opts);

  // Total serie is always the first serie
  const totalSerie = opts.series[0];

  // Show annotations only if total serie is visible
  opts.annotations = opts.annotations.map(({ labels, ...annotation }) => {
    return {
      ...annotation,
      labels: labels.map((label, x) => {
        const serieItem = totalSerie.data[x];
        return {
          ...label,
          point: {
            xAxis: 0,
            yAxis: 0,
            x,
            y: totalSerie.visible !== false ? serieItem.y : Number.MAX_SAFE_INTEGER,
          },
          verticalAlign: serieItem.y >= 0 ? "bottom" : "top",
          y: serieItem.y >= 0 ? -5 : 5,
        };
      }),
    };
  });
  return opts;
};
</script>
