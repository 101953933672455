<template lang="pug">
.flex-row.align-items-center.gap-sm(v-if="items.length > 0")
  .title.mr-md {{ 'filters.filterset.saved_filter' | i18n }}
  pill-button(
    v-for="item in items",
    pill-style="pill-outline"
    :key="item.id",
    :label="item.label",
    :enabled="item.enabled",
    :removable="false",
    @toggle="onUpdateItems([{ id: item.id, enabled: !item.enabled }])"
  )
  
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import _ from "lodash";
import FiltersConfiguration from "../model/filters-configuration";
import FiltersetItem from "../model/filterset-item";
import Actions from "../store/actions";
import pillButton from "./pill-button.vue";

@Component({
  components: {
    pillButton,
  },
})
export default class SavedFilterSelector extends Vue {
  @Prop({
    default: (): FiltersConfiguration => ({
      v: 2,
      sets: [],
      filters: {},
    }),
  })
  config: FiltersConfiguration;

  readonly isAdmin = !!window.zoinedContext?.isAdmin;

  get effectiveConfig(): FiltersConfiguration {
    if (this.config) {
      const sets = (this.config.sets || []).filter(({ id }) => _.find(this.filtersets, (set) => set.id == id));
      return {
        ...this.config,
        sets,
      };
    } else {
      return null;
    }
  }

  get items() {
    return _.map(this.filtersets, (set) => ({
      label: set.name,
      id: set.id,
      enabled: this.effectiveConfig.sets.some(({ id, enabled }) => id == set.id && enabled),
    }));
  }

  get filtersets() {
    return this.$store.state.filtersets.all;
  }

  onUpdateItems(items) {
    const sets: FiltersetItem[] = items.map(({ id, enabled }) => ({ id, enabled }));
    this.onFiltersetsUpdated(sets);
  }

  onFiltersetsUpdated(sets: FiltersetItem[]) {
    const enabledSetId = sets.find(({ enabled }) => enabled)?.id;
    const enabledSet = enabledSetId && _.find(this.filtersets, (set) => set.id == enabledSetId);
    const filters = enabledSet ? _.cloneDeep(enabledSet.config.filters) : this.config.filters;
    const config: FiltersConfiguration = {
      v: 2,
      sets,
      filters,
    };
    this.$emit("update", config);
  }

  @Watch("config.filters")
  watchFilters(filters, old) {
    if (!_.isEqual(filters, old)) {
      // Set up enabled flag for filtersets based on current filters state
      const sets = _.map(this.effectiveConfig.sets, ({ id, enabled }) => {
        if (enabled) {
          const set = _.find(this.filtersets, (set) => set.id == id);
          if (!_.isEqual(filters, set.config.filters)) {
            enabled = false;
          }
        }
        return { id, enabled };
      });

      if (!_.isEqual(this.config.sets, sets)) {
        const config = {
          ...this.config,
          sets,
        };
        this.$emit("update", config);
      }
    }
  }

  @Watch("filtersets")
  watchFiltersets() {
    this.onFiltersetsUpdated(this.config.sets || []);
  }

  created() {
    if (!this.filtersets) {
      this.$store.dispatch(Actions.fetchFiltersets);
    }
  }
}
</script>

<style lang="scss" scoped>
.filterset-selector {
  display: inline-block;

  &:not(:last-child) {
    margin-right: 20px;
  }
}
</style>
