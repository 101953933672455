import { defaultTimePeriodIds } from "./time-period";

export const ZoinedTimeRangeService = function() {
  this.label = {
    sale_date: I18n.t("filters.selection_sale_time"),
    date: I18n.t("filters.selection_time"),
    compare_date: I18n.t("filters.compare_to"),
    no_of_past_weeks: I18n.t("filters.no_of_past_weeks"),
  };

  this.choices = {
    sale_date: [
      "whole_history",
      "last_7_days",
      "last_30_days",
      "last_90_days",
      "last_180_days",
      "last_365_days",
      "last_2_years",
    ],
    date: ["4_weeks", "yesterday", "12_months", "custom"],
    compare_date: [
      "prev_year_corresponding",
      "2_years_back_corresponding",
      "previous_corresponding_period",
      "previous_year_lfl",
      "2_years_back_lfl",
      "custom",
      "no_comparison",
    ],
    no_of_past_weeks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  };

  this.moreChoices = {
    date: [
      "today",
      "last_7_days",
      "last_14_days",
      "last_30_days",
      "last_90_days",
      "last_365_days",
      "rolling_12_weeks",
      "rolling_3_months",
      "current_year",
      "current_month",
      "current_week",
      "current_full_year",
      "current_full_month",
      "current_full_week",
      "last_year",
      "last_month",
      "last_week",
    ],
  };

  this.default = {
    sale_date: "whole_history",
    date: "4_weeks",
    compare_date: "prev_year_corresponding",
    no_of_past_weeks: "8",
  };

  this.selectionLabel = (p, model) => this.choiceLabel(this.selectionValue(p, model));
  this.selectionValue = (p, model) => {
    switch (p) {
      case "date":
      case "sale_date":
        return model.selection.type || this.default[p];
      case "compare_date":
        return model.comparison ? model.comparison.type || this.default[p] : "no_comparison";
      case "no_of_past_weeks":
        return model.comparison ? model.comparison.text || this.default[p] : this.default[p];
      default:
        throw "unknown date filter parameter";
    }
  };

  this.selectionDates = (p, model) => {
    switch (p) {
      case "date":
      case "sale_date":
        return {
          start_time: model.selection.start_time,
          end_time: model.selection.end_time,
        };
      case "compare_date":
      case "no_of_past_weeks":
        return model.comparison
          ? {
              start_time: model.comparison && model.comparison.start_time,
              end_time: model.comparison && model.comparison.end_time,
            }
          : {};
      default:
        throw "unsupported date filter parameter";
    }
  };

  const choiceLabelKeys = {
    "4_weeks": "filters.date_selector.rolling_4_weeks",
    "12_months": "filters.date_selector.rolling_12_months",
    prev_year_corresponding: "filters.benchmark.prev_year_corresponding",
    "2_years_back_corresponding": "filters.benchmark.2_years_back_corresponding",
    prev_year_corresponding_same_weekday_ending: "filters.benchmark.prev_year_corresponding_same_weekday_ending",
    previous_corresponding_period: "filters.benchmark.previous_corresponding_period",
    previous_year_lfl: "filters.benchmark.previous_year_lfl",
    "2_years_back_lfl": "filters.benchmark.2_years_back_lfl",
    no_comparison: "filters.benchmark.no_comparison",
    avg_chain: "filters.benchmark.avg_chain",
    best_store: "filters.benchmark.best_store",
    avg_salesperson: "filters.benchmark.avg_salesperson",
    best_salesperson: "filters.benchmark.best_salesperson",
    budget: "filters.benchmark.budget",
  };

  this.choiceLabel = (l) => {
    if (choiceLabelKeys[l] !== undefined) {
      return I18n.t(choiceLabelKeys[l]);
    } else if (Number.isInteger(parseInt(l))) {
      return l; // Number of weeks.
    } else {
      return I18n.t("filters.date_selector." + l);
    }
  };

  this.choicesWithLabels = (a) => (a ? a.map((x) => ({ id: x, name: this.choiceLabel(x) })) : []);

  // Time periods that are enabled by default
  this.defaultTimePeriodIds = defaultTimePeriodIds;

  // Optional time periods, must be enabled from settings
  this.optionalTimePeriodIds = [
    "last_28_days",
    "last_180_days",
    "last_7_days_until_today",
    "last_14_days_until_today",
    "last_30_days_until_today",
    "last_90_days_until_today",
    "last_365_days_until_today",
    "rolling_3_weeks",
    "last_52_weeks",
    "rolling_6_months",
    "last_tertile",
    "rolling_2_tertiles",
    "rolling_3_tertiles",
    "last_quarter",
    "rolling_2_quarters",
    "rolling_3_quarters",
    "rolling_4_quarters",
    "to_end_of_year",
    "to_end_of_month",
    "to_end_of_week",
    "to_end_of_year_from_today",
    "to_end_of_month_from_today",
    "to_end_of_week_from_today",
    "current_week_until_today",
    "current_month_until_today",
    "current_tertile",
    "current_quarter",
    "current_half_year",
    "current_year_until_today",
    "current_year_until_end_of_last_month",
    "next_week",
    "next_month",
    "next_year",
    "tomorrow",
    "next_7_days",
    "next_7_days_from_today",
    "today_until_last_full_hour",
    "last_full_hour",
    "next_546_days",
    "next_546_days_from_today",
    "last_year_4_weeks",
    "last_year_7_days",
    "last_year_14_days",
    "last_year_30_days",
    "last_year_today",
    "last_year_tomorrow",
    "last_year_current_week",
    "last_year_next_week",
    "last_year_next_month",
  ];

  this.timePeriodIds = [...this.defaultTimePeriodIds, ...this.optionalTimePeriodIds];

  this.timePeriods = (timePeriodIds) => this.choicesWithLabels(timePeriodIds || this.timePeriodIds);
  this.customPeriods = (customPeriods) => this.choicesWithLabels(customPeriods);
};

export default [ZoinedTimeRangeService];
