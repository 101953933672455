'use strict';

import i18n from 'i18n-js';

const html = document.getElementsByTagName("html");
const htmlLangAttr = (html.length>0 && html[0].attributes["lang"]);
const lang = (htmlLangAttr && htmlLangAttr.value) || 'en';

i18n.defaultLocale = "en";
i18n.locale = lang;
i18n.fallbacks = true;

// satisfy ts compiler
i18n.t = i18n.t;

window.getUrlParams = function(k) {
    var p={};
    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v});
    return k?p[k]:p;
};

/*
var vUrlParams = getUrlParams("locale");
if (vUrlParams != null) {
    i18n.defaultLocale = vUrlParams;
    i18n.locale = vUrlParams;
};
*/

// Register i18n to global scope for legacy components
global.I18n = i18n;

// load the translations after I18n has been set to root context
require('../../assets/javascripts/i18n/translations.js');

const moment = require('moment');
moment.locale(lang.toLowerCase());

export default i18n;
