import $ from "jquery";

const overlayButton = {
  css: {
    border: "none",
    padding: "15px",
    backgroundColor: "#000",
    "-webkit-border-radius": "10px",
    "-moz-border-radius": "10px",
    opacity: 0.5,
    color: "#fff",
  },
};

let overlay;

const showOverlay = (msg = "") => {
  hideOverlay();
  overlay = $("<div>");
  overlay.css({
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000000,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });
  document.body.appendChild(overlay[0]);
  if (msg) {
    const overlayText = $("<div>");
    overlayText.text(msg);
    overlayText.css({
      color: "#333",
      fontSize: "20px",
      textAlign: "center",
      padding: "15px",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      borderRadius: "10px",
    });
    overlay.append(overlayText);
  }
};

const hideOverlay = () => {
  if (overlay) {
    overlay.remove();
    overlay = null;
  }
};

export { showOverlay, hideOverlay };
