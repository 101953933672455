<template>
  <div id="menubar">
    <div class="sidenav-placeholder" />
    <div class="flex-1 max-w-full">
      <div class="container">
        <div class="flex-row justify-content-center align-items-center">
          <report-actions></report-actions>
          <div class="flex-1 flex-row justify-content-flex-end align-items-center gap-md">
            <flyover-filters-handle v-if="showFlyoverFilters" />
            <save-report-button v-if="showSaveButton" />
            <ai-assistant-button v-if="showAiAssistant" />
          </div>
        </div>
      </div>
    </div>
    <ai-assistant-placeholder v-if="showAiAssistant" />
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed } from "vue";
import ReportActions from "@/components/report-actions.vue";
import aiAssistantButton from "@/ai-assistant/ai-assistant-button.vue";
import FlyoverFiltersHandle from "@/flyover-filters/flyover-filters-handle.vue";
import aiAssistantPlaceholder from "@/ai-assistant/ai-assistant-placeholder.vue";
import saveReportButton from "@/save-report/save-report-button.vue";

const store = useStore();

const showFlyoverFilters = computed(() => {
  const reportContext = store.getters.getReportContext;
  return reportContext?.active_filters?.length > 0;
});

const showAiAssistant = window.zoinedContext?.aiAssistantEnabled;

const showSaveButton = computed(() => {
  const reportContext = store.getters.getReportContext;
  return reportContext?.my_report?.editable;
});
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

#menubar {
  position: relative;
  display: none;
  position: fixed;
  top: var(--header-height);
  left: 0;
  width: 100%;
  z-index: var(--z-index-menubar);
  transform: translateY(-50%);

  @media screen and (min-width: $grid-float-breakpoint) {
    display: flex;
  }
}
</style>
