import "babel-polyfill"

// IE11 doesn't support element.remove() which is used by ngVue
if (!('remove' in Element.prototype)) {
  Element.prototype.remove = function() {
    if (this.parentNode) {
      this.parentNode.removeChild(this);
    }
  };
}

// IE11 doesn't support Number.parseInt
if (Number.parseInt === undefined)
  Number.parseInt = window.parseInt;
