<!-- Non modal pill select component (all items are rendered and enabled by toggling) -->
<template lang="pug">
.selectors
	ul.selector.list-inline()
		li.pill.btn(:class="{inactive: !item.enabled}", @click="toggleItem(item)" v-for="item in items")
			| {{ item.label }}
</template>
<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    radio: Boolean
  },
  methods: {
    toggleItem(item) {
      const items = this.items.map(each =>
        this.radio
          ? { ...each, enabled: each === item }
          : {
              ...each,
              enabled: each === item ? !each.enabled : each.enabled
            }
      );
      this.$emit("change", items);
    }
  }
};
</script>
