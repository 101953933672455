"use strict";

import moment from "moment";

export default function() {
  return (range) => {
    if (!range) {
      return "";
    }

    const format = (window.zoinedContext && window.zoinedContext.dateFormat) || "L";

    return moment(range.start_time).format(format) + " - " + moment(range.end_time).format(format);
  };
}
