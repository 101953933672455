<template>
  <ui-toggle-switch
    :value="value"
    :title="t('newsletter.definition.sections.common.chart_type.show_datalabels')"
    @input="toggle"
  />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiToggleSwitch from "@/ui/ui-toggle-switch.vue";

const { t } = useI18n();

const props = defineProps<{
  value?: boolean;
}>();

const emit = defineEmits(["input"]);

const toggle = () => {
  emit("input", !props.value);
};
</script>
