import { makeApiInstance } from "@/api/instance";
import MyReport from "@/model/my-report";
import Report from "@/model/report";
import { useMutation } from "@tanstack/vue-query";

export type UseCreateReportMutationParams = {
  report: Report;
  myReport: MyReport;
};

export const useCreateReportMutation = () => {
  return useMutation({
    mutationFn: (params: UseCreateReportMutationParams) => {
      return makeApiInstance().post("/api/v1/reports", { report: params.report, my_report: params.myReport });
    },
  });
};
