<template lang="pug">
.flyover-filters-display-tag(v-if="text && text.length > 0")
  svg-icon.icon(v-if="icon" :name="icon" @click.native.stop="$emit('click')")
  .text {{ text }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SvgIcon from "../components/svg-icon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class FlyoverFiltersDisplayTag extends Vue {
  @Prop()
  icon: string;

  @Prop()
  text: string;
}
</script>

<style lang="scss" scoped>
.flyover-filters-display-tag {
  color: white;
  background-color: var(--color-black-80);
  font-size: 11px;
  font-weight: 600;
  height: 30px;
  padding: 0 7px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  border-radius: 9999px;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 35px;
  }

  .icon {
    margin-right: 5px;
    cursor: pointer;
    height: 16px;
  }

  .text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 5px;
  }
}
</style>
