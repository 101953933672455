<template lang="pug">
.time-series-section
  .option
    label {{ 'filter.config.metrics' | i18n }}
    select(:value="section.metric", @input="updateMetric")
      option(v-for="option in metricsOptions" :value="option.id") {{ option.name }}
  .option
    label
    .checkbox
      label
        input(type='checkbox' :checked='section.cumulative' :disabled='!!section.top_grouping' @input="updateCumulative")
        | {{ 'cumulative' | i18n }}
  .option
    label {{ 'newsletter.definition.sections.common.time_period' | i18n }}
    timePeriodDropdown(:show-title="false", :custom="false", :available-items="timePeriods", :available-series-types="seriesTypes", :value="selection", @update="updateSelection")
  .option(v-if="openingHoursDefined")
    label {{ 'filter.config.limit_to_business_hours' | i18n }}
    businessHoursSelector(:title="false" :value="section.limit_to_business_hours" @update="updateLimitToBusinessHours")
  .option
    label {{ 'newsletter.definition.sections.common.top_grouping' | i18n }}
    select(:value="section.top_grouping", @input="updateTopGrouping")
      option(v-for="option in groupingOptions" :value="option.id") {{ option.name }}
  .option(v-if='section.top_grouping')
    label {{ 'filter.config.sort' | i18n }}
    select(:value="section.sort", @input="updateSort")
      option(v-for="option in sortOptions" :value="option.id") {{ option.name }}
  .option(v-if='section.top_grouping')
    label {{ 'newsletter.definition.sections.common.entries_to_show' | i18n }}
    select(:value="section.limit", @input="updateLimit")
      option(v-for="option in limitOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.chart_type.description' | i18n }}
    select(:value="section.displayType", @input="updateDisplayType")
      option(v-for="option in displayTypes" :value="option.id") {{ option.name }}
  .option
    label
    .checkbox
      label
        input(type='checkbox' :checked='section.showDatalabels' @input="updateShowDatalabels")
        | {{ 'newsletter.definition.sections.common.chart_type.show_datalabels' | i18n }}
  .option
    label {{ 'newsletter.definition.sections.common.comparison' | i18n }}
    comparisonSelector(:value="section.comparison", :choices="comparisons" @input="updateComparison")
  .option(v-if="lflEnabled && section.comparison && isTimeComparison(section.comparison.type)")
    label {{ 'filter.config.like_for_like' | i18n }}
    likeForLikeSelector(:config="section.like_for_like", :title="null", @update="updateLikeForLike")
  .option(v-if='section.comparison && ["prev_year_corresponding", "budget"].indexOf(section.comparison.type) >= 0')
    label
    .checkbox
      label
        input(type='checkbox' :checked='section.benchmarkForward' @input="updateBenchmarkForward")
        | {{ 'newsletter.definition.sections.common.comparison_forward' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import MenuItem from "../../interfaces/menu-item";
import { timeComparisonTypes } from "../../filters/time-period";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import comparisonSelector from "../../components/comparison-selector.vue";
import _ from "lodash";
import i18n from "../../i18n";
import { displayTypes, sortOptions } from "../constants";

@Component({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
    comparisonSelector,
  },
})
export default class TimeSeriesSection extends Vue {
  @Prop()
  section: any;

  @Prop()
  newsletterType: string;

  @Prop()
  timePeriods: MenuItem[];

  @Prop()
  seriesTypes: string[];

  @Prop()
  openingHoursDefined: boolean;

  @Prop()
  lflEnabled: boolean;

  @Prop()
  insightsVisible: boolean;

  get selection() {
    return (
      this.section.time_period && {
        type: this.section.time_period,
        series: this.section.time_series_type,
      }
    );
  }

  get metricsOptions(): MenuItem[] {
    return this.$store.state.parameters.metrics.all;
  }

  get groupingOptions(): MenuItem[] {
    const options = this.$store.state.parameters.grouping.all;
    return options && [{ id: "", name: i18n.t("newsletter.definition.sections.common.no_grouping") }, ...options];
  }

  get comparisons() {
    const comparisons = this.$store.state.parameters.comparisons.all;
    return comparisons && [{ type: "no_comparison", name: i18n.t("filters.benchmark.no_comparison") }, ...comparisons];
  }

  get limitOptions() {
    return [1, 2, 3, 4, 5, 10].map(function(v) {
      return { id: v, name: v };
    });
  }

  get sortOptions() {
    return sortOptions;
  }

  get displayTypes() {
    return displayTypes(["bar", "line"]);
  }

  updateTopGrouping(event) {
    const top_grouping = event.target.value;
    this.updateSection({ ...this.section, top_grouping });
  }

  updateMetric(event) {
    const metric = event.target.value;
    this.updateSection({ ...this.section, metric, insights: null });
  }

  updateSort(event) {
    const sort = event.target.value;
    this.updateSection({ ...this.section, sort });
  }

  updateLimit(event) {
    const limit = Number.parseInt(event.target.value);
    this.updateSection({ ...this.section, limit });
  }

  updateDisplayType(event) {
    const displayType = event.target.value;
    this.updateSection({ ...this.section, displayType });
  }

  updateCumulative(event) {
    const cumulative = event.target.checked;
    this.updateSection({ ...this.section, cumulative });
  }

  updateShowDatalabels(event) {
    const showDatalabels = event.target.checked;
    this.updateSection({ ...this.section, showDatalabels });
  }

  updateBenchmarkForward(event) {
    const showDatalabels = event.target.checked;
    this.updateSection({ ...this.section, showDatalabels });
  }

  updateSelection({ type, series }) {
    this.updateSection({ ...this.section, time_period: type, time_series_type: series });
  }

  updateLimitToBusinessHours(limit_to_business_hours) {
    this.updateSection({ ...this.section, limit_to_business_hours });
  }

  updateComparison(comparison) {
    this.updateSection({ ...this.section, comparison });
  }

  updateLikeForLike(like_for_like) {
    this.updateSection({ ...this.section, like_for_like });
  }

  updateSection(section) {
    this.$emit("update-section", section);
  }

  isTimeComparison(type) {
    return _.includes(timeComparisonTypes, type);
  }
}
</script>
