<template lang="pug">
.selectors
  draggable.flex-row.align-items-center.flex-wrap.gap-sm(v-model="model" @update="onOrderUpdated")
    pill-button(:enabled="item.enabled", :removable="false", @toggle="clickItem(item)" v-for="item in model" :key="item.key")
      | {{ item.name || item.label }}
      span(v-if="item.translatable") &nbsp;&nbsp;
      i.fa.fa-edit(v-if="item.translatable")
  metric-dialog(ref="metricDialog" :type="type" @save="onSave")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import _ from "lodash";
import MetricDialog from "./metric-dialog.vue";
import draggable from "vuedraggable";
import pillButton from "@/components/pill-button.vue";

@Component({
  components: {
    MetricDialog,
    draggable,
    pillButton,
  },
})
export default class PillList extends Vue {
  @Ref("metricDialog")
  metricDialog: MetricDialog;

  @Prop()
  items: any[];

  @Prop()
  type: string;

  model = null;

  @Watch("items", { immediate: true })
  onItemsUpdated() {
    this.model = _.cloneDeep(this.items);
  }

  onOrderUpdated() {
    const model = this.model.map((item, index) => {
      return { ...item, order: index + 1 };
    });
    this.$emit("update", model);
  }

  clickItem(item) {
    if (item.translatable === true) {
      this.editTranslations(item);
    } else {
      item.enabled = !item.enabled;
      this.$emit("update", this.model);
    }
  }

  editTranslations(item) {
    const config = {
      invisible: !item.enabled,
      label: item.custom_labels,
      original_label: item.originalLabels,
    };
    this.metricDialog.show({ key: item.key, config });
  }

  onSave({ key, config: { invisible, label } }) {
    const model = this.model.map((item) => {
      return item.key === key ? { ...item, enabled: !invisible, custom_labels: label } : item;
    });
    this.$emit("update", model);
  }
}
</script>
