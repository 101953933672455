<template lang="pug">
dropdown.dropdown-select(ref="dropdown")
  template(v-slot:toggle="toggleProps")
    slot(name="toggle" v-bind:menuOpen="toggleProps.menuOpen" v-bind:toggleMenu="toggleProps.toggleDropdown")
      dropdown-select-button(:class="btnClasses" :button-style="buttonStyle" :menu-open="toggleProps.menuOpen" @click="toggleProps.toggleDropdown") {{ label }}
  template(slot="menu")
    .simple-spinner(v-if="!availableItems")
      .spinner
    .dropdown-items
      .dropdown-item.flex-row(@click="addItem(item)", v-for="item in enabledItems")
        .flex-row
          i.fa.mr-sm(v-if="item.icon" :class="item.icon")
          | {{ item.name }}

      .dropdown-item.flex-row(v-for="item in disabledItems")
        .flex-row.disabled
          i.fa.mr-sm(v-if="item.icon" :class="item.icon")
          | {{ item.name }}
        pro-text.ml-sm
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import Dropdown from "./dropdown.vue";
import DropdownSelectButton from "./dropdown-select-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import proText from "./pro-text.vue";

@Component({
  components: {
    Dropdown,
    DropdownSelectButton,
    proText,
  },
})
export default class DropdownSelect extends Vue {
  @Ref("dropdown")
  dropdown: Dropdown;

  @Prop()
  availableItems: MenuItem[];

  @Prop()
  default: any;

  @Prop()
  value: any;

  @Prop()
  selectLabel: string;

  @Prop({ default: "primary-inverted" })
  buttonStyle: string;

  readonly isLimited = window.zoinedContext.limited;

  get btnClasses() {
    return {
      default: this.default && !this.value,
    };
  }

  get label() {
    if (this.value !== null && this.value !== undefined) {
      return this.getLabel(this.value);
    } else if (this.default) {
      return this.getLabel(this.default);
    } else if (this.selectLabel) {
      return this.selectLabel;
    } else {
      return "Select";
    }
  }

  get enabledItems() {
    return _.filter(this.availableItems, (item) => !this.isDisabled(item));
  }

  get disabledItems() {
    return _.filter(this.availableItems, (item) => this.isDisabled(item));
  }

  addItem(item: MenuItem) {
    this.$emit("update", menuItemKey(item));
    this.dropdown.toggle();
  }

  getLabel(key) {
    const item = _.find(this.availableItems, (item) => menuItemKey(item) === key);
    if (item) {
      return item.name;
    } else {
      return key;
    }
  }

  isDisabled(item: MenuItem) {
    return this.isLimited && item.enabled_for_limited === false;
  }
}
</script>

<style lang="scss" scoped>
.dropdown-select {
  .btn.default {
    opacity: 0.5;
  }
  .dropdown-items {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .dropdown-item {
      padding: 7px 10px;
      border-radius: 5px;
      color: #333;
      white-space: nowrap;
      margin: 4px;

      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-top: 0;
      }

      &:hover {
        background-color: #cceeff;
        cursor: pointer;
      }

      .disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
