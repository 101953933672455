<template lang="pug">
uiv-modal(v-model="open", :title="'company_admin.import_users.title' | i18n", size="lg")
  div(v-if="error")
    .alert.alert-danger {{ error }}
  div(v-if="sending")
    spinner
  div(v-else-if="users")
    table.table
      thead
        tr
          th {{ "activerecord.attributes.user.first_name" | i18n }}
          th {{ "activerecord.attributes.user.last_name" | i18n }}
          th {{ "activerecord.attributes.user.email" | i18n }}
          th {{ "activerecord.attributes.user.role" | i18n }}
          th {{ "filter.config.store" | i18n }}
      tbody
        tr(v-for="user in users", :key="user.email")
          td {{ user.first_name }}
          td {{ user.last_name }}
          td {{ user.email }}
          td {{ ("roles." + user.role) | i18n }}
          td {{ userStores(user) }}
    .mt-md
      .checkbox
        label
          input(type="checkbox", v-model="sendEmails")
          | {{ "company_admin.import_users.send_invitation_emails" | i18n }}
  div(v-else-if="processing")
    spinner
  div(v-else)
    .droparea
      input(type="file", @change="onFileChange", ref="fileInput")
      | {{ "company_admin.import_users.drop_file_here" | i18n }}
    .mt-md
      a(href="/templates/user-import.xlsx" download) {{ "company_admin.import_users.download_template" | i18n }}
  div(slot="footer")
    button.btn(@click="cancel()") {{ "actions.cancel" | i18n }}
    button.btn.btn-primary(type="submit", @click="invite()" :disabled="!users || error || sending") {{ "company_admin.import_users.invite_all" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import Spinner from "../components/spinner.vue";
import readXlsxFile from 'read-excel-file'
import i18n from "../i18n";
import adminUsersApi from "../api/admin-users-api";
import _ from "lodash";

@Component({
  components: {
    Spinner,
  },
})
export default class UserInviteDialog extends Vue {
  @Ref("fileInput")
  fileInput: HTMLInputElement;

  open = false;

  processing = false;

  users = null;

  error = null;

  sendEmails = true;

  sending = false;

  show() {
    this.error = null;
    this.users = null;
    this.processing = false;
    this.sending = false;
    this.sendEmails = true;

    this.open = true;
  }

  userStores(user) {
    const { allow, ids } = user.permissions.dimensions.store;
    if (allow === "all") {
      return i18n.t("permissions.all")
    }
    else if (allow === "none") {
      return i18n.t("permissions.none")
    }
    else if (allow === "only") {
      return ids.join(", ");
    }
    else if (allow === "except") {
      return ids.map((id) => "-" + id).join(", ");
    }
  }

  async onFileChange() {
    const files = this.fileInput.files;
    if (files.length > 0) {
      try {
        this.error = null;
        this.users = null;
        this.processing = true;

        this.parseExcel(await readXlsxFile(files[0]));
      }
      catch {
        this.error = i18n.t("company_admin.import_users.invalid_file_format")
      }
      finally {
        this.processing = false;
      }
    }
  }

  parseExcel(rows) {
    const headers = rows.shift().map((header) => header.toString().toLowerCase().trim());

    let firstNameIndex = headers.indexOf("first name")
    if (firstNameIndex < 0) {
      firstNameIndex = headers.indexOf(i18n.t("activerecord.attributes.user.first_name").toString().toLowerCase());
    }
    let lastNameIndex = headers.indexOf("last name");
    if (lastNameIndex < 0) {
      lastNameIndex = headers.indexOf(i18n.t("activerecord.attributes.user.last_name").toString().toLowerCase());
    }
    let emailIndex = headers.indexOf("email");
    if (emailIndex < 0) {
      emailIndex = headers.indexOf(i18n.t("activerecord.attributes.user.email").toString().toLowerCase());
    }
    let storeIndex = headers.indexOf("store");
    if (storeIndex < 0) {
      storeIndex = headers.indexOf(i18n.t("filter.config.store").toString().toLowerCase());
    }
    let roleIndex = headers.indexOf("role");
    if (roleIndex < 0) {
      roleIndex = headers.indexOf(i18n.t("activerecord.attributes.user.role").toString().toLowerCase());
    }

    if (firstNameIndex < 0 || lastNameIndex < 0 || emailIndex < 0) {
      throw new Error("Invalid file format");
    }

    const requiredFields = ["first_name", "last_name", "email"];

    const users = rows.map((row) => ({
      first_name: row[firstNameIndex] || "",
      last_name: row[lastNameIndex] || "",
      email: row[emailIndex] || "",
      role: this.userRole(row[roleIndex]),
      permissions: {
        dimensions: { store: this.storePermissions(row[storeIndex]) },
        metrics: { allow: "all" },
        groupings: { allow: "all" },
      }
    })).filter((user) => _.every(requiredFields, (field) => user[field].trim().length > 0));

    if (users.length > 0) {
      this.users = users;
    }
    else {
      this.error = i18n.t("company_admin.import_users.invalid_file_format");
    }
  }

  storePermissions(store) {
    store = (store || "").trim();
    if (store.toLowerCase() === "all") {
      return { allow: "all" };
    }
    else if (store.toLowerCase() === "none") {
      return { allow: "none" };
    }
    else {
      const ids = store.split(",").map((s) => s.trim());
      const onlyIds = ids.filter((id) => !id.startsWith("-")).filter((id) => id.length > 0);
      const exceptIds = ids.filter((id) => id.startsWith("-")).map((id) => id.substring(1)).filter((id) => id.length > 0);

      if (exceptIds.length > 0) {
        return { allow: "except", ids: exceptIds };
      }
      else if (onlyIds.length > 0) {
        return { allow: "only", ids: onlyIds };
      }
      else {
        return { allow: "all" };
      }
    }
  }

  userRole(role) {
    role = (role || "").toLowerCase().trim();
    if (role === "admin" || role === "email") {
      return role;
    }
    else {
      return "user";
    }
  }

  cancel() {
    this.open = false;
  }

  async invite() {
    this.sending = true;

    try {
      const companyId = window.zoinedContext.companyId;
      for (const user of this.users) {
        await adminUsersApi.invite({ company_id: companyId, user, send_email: this.sendEmails });
      }
      this.open = false;
      this.$emit("add-users", this.users);
    }
    catch (err) {
      this.error = err.response.data.error;
    }
    finally {
      this.sending = false;
    }
  }
}
</script>


<style lang="scss" scoped>
.droparea {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  position: relative;

  input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>