import FilterConfigurationItem from "./filter-configuration-item";
import PillItem from "../interfaces/pill-item";
import _ from "lodash";

type FilterConfigurationItemMap = Record<string, FilterConfigurationItem>;

const filterConfigurationItemMapFromPillItemList = (items: PillItem[]): FilterConfigurationItemMap => {
  return items.reduce((config, item, index) => {
    return { ...config, [item.value]: { ...item, order: index + 1 } };
  }, {});
};

const filterConfigurationItemMapToFilters = (itemMap: FilterConfigurationItemMap): string[] => {
  return _.chain(itemMap)
    .toPairs()
    .sortBy((entry) => entry[1].order || 999999)
    .filter((entry) => entry[1].enabled)
    .map((entry) => {
      let filter = entry[0];
      if (entry[1].exclude) {
        filter = `exclude:${filter}`;
      }
      if (entry[1].wildcard) {
        filter = `wildcard:${filter}`;
      }
      return filter;
    })
    .value();
};

export { filterConfigurationItemMapFromPillItemList, filterConfigurationItemMapToFilters };

export default FilterConfigurationItemMap;
