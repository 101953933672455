<template lang="pug">
modal(v-model="open", :title="title")
  h2 {{ 'chart_settings.color_scheme' | i18n }}
  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="default" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.default' | i18n }}
    .col-md-5.gradient.gradient--default
  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="traffic_lights" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.traffic_lights' | i18n }}
    .col-md-5.gradient.gradient--traffic-lights

  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="reverse_traffic_lights" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.reverse_traffic_lights' | i18n }}
    .col-md-5.gradient.gradient--reverse-traffic-lights

  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="violet" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.violet' | i18n }}
    .col-md-5.gradient.gradient--violet

  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="neutral" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.neutral' | i18n }}
    .col-md-5.gradient.gradient--neutral

  label.row
    .col-md-6
      input(type="radio" name="color_scheme" value="none" v-model="colorScheme")
      .scheme-label {{ 'chart_settings.color_schemes.none' | i18n }}
 
  div(slot="footer")
    button.btn.btn-default(@click="open = false") {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(@click="update") {{ 'actions.save' | i18n }}
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import i18n from "../i18n";
import { Emit, Watch } from "vue-property-decorator";

@Component({
  props: {
    component: Object,
    filterConfiguration: Object
  },
  components: { Modal }
})
export default class HeatmapSettingsModal extends Vue {
  open = false;

  colorScheme = "default";

  get title() {
    return i18n.t("chart_settings.title");
  }

  show() {
    this.open = true;
  }

  @Emit("update")
  update() {
    this.open = false;
    return {
      ...this.component.chartOptions,
      color_scheme: this.colorScheme
    };
  }

  created() {
    this.onComponentUpdated();
  }

  @Watch("component")
  onComponentUpdated() {
    const { chartOptions = {} } = this.component;

    this.colorScheme = chartOptions.color_scheme || "default";
  }
}
</script>

<style lang="scss" scoped>
label {
  display: block;
}
.scheme-label {
  display: inline;
  margin-left: 5px;
  font-weight: normal;
}
.gradient {
  height: 20px;
  background-color: grey;

  &--default {
    background: linear-gradient(to right, #ffffff, #4bcdf0, #ffbe0f, #fa644a);
  }
  &--traffic-lights {
    background: linear-gradient(to right, green, yellow, red);
  }

  &--reverse-traffic-lights {
    background: linear-gradient(to right, red, yellow, green);
  }

  &--violet {
    background: linear-gradient(to right, #ffffff, #5555ff);
  }

  &--neutral {
    background: linear-gradient(to right, #f2f2f2, #67c9e9);
  }
}
</style>
