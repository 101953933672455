<template lang="pug">
.comparison-pill-list.flex-row.align-items-center.flex-wrap.gap-sm
  pill-list(
    :title="title",
    :searchable="false",
    :items="items",
    :defaults="defaultItems",
    :pill-style="pillStyle",
    :draggable="true",
    :radio="radio",
    :togglable="togglable",
    :addable="false",
    @update="onUpdate($event)"
  )
  dropdown(right)
    template(v-slot:toggle="toggleProps")
      plus-button(@click="toggleProps.toggleDropdown")
    template(v-slot:menu)
      dropdown-item(
        v-for="item in menuItems",
        :key="item.key",
        @click="addItem(item)"
      ) {{ item.name }}
      .divider(v-if="isLimited")
      more-with-pro-menu
        .divider(v-if="proVersionMenuItems.length > 0")
        dropdown-item(
          v-for="item in proVersionMenuItems",
          :key="item.key",
          @click="addItem(item)"
        ) {{ item.name }}
        .divider(v-if="custom")
        dropdown-submenu(v-if="custom" :title="'filters.date_selector.custom' | i18n")
          dropdown-calendar(mode="range" :max-date="new Date()" @select-custom-range="selectCustomRange")
        .divider(v-if="customScenario")
        dropdown-submenu(v-if="customScenario", :title="'filters.custom_scenario.title' | i18n")
          comparison-scenario-form(:available-comparisons="availableComparisons" @add="addCustomScenario")
</template>

<script lang="ts">
import pillList from "./pill-list.vue";
import Component from "vue-class-component";
import Vue from "vue";
import Calendar from "v-calendar/lib/components/calendar.umd";

import moment from "moment";
import TranslationService from "../core/translation.service";
import { Prop } from "vue-property-decorator";
import i18n from "../i18n";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import _ from "lodash";
import ComparisonSelector from "./comparison-selector.vue";
import dropdown from "./dropdown.vue";
import DropdownItem from "./dropdown-item.vue";
import DropdownSubmenu from "./dropdown-submenu.vue";
import DropdownCalendar from "./dropdown-calendar.vue";
import PillButton from "./pill-button.vue";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import Comparison from "main/model/comparison";
import ComparisonScenarioForm from "./comparison-scenario-form.vue";
import MoreWithProMenu from "./more-with-pro-menu.vue";
import PlusButton from "@/components/buttons/plus-button.vue";

const translationService = new TranslationService();

@Component({
  components: {
    pillList,
    Calendar,
    ComparisonSelector,
    dropdown,
    DropdownItem,
    DropdownSubmenu,
    DropdownCalendar,
    PillButton,
    ComparisonScenarioForm,
    MoreWithProMenu,
    PlusButton,
  },
})
export default class ComparisonPillList extends Vue {
  @Prop({ default: i18n.t("filters.compare_to") })
  title: string;

  @Prop()
  comparisons: Comparison[];

  @Prop()
  choices: Comparison[];

  @Prop()
  defaults: Comparison[];

  @Prop({ default: "pill" })
  pillStyle: string;

  @Prop({ default: false })
  radio: boolean;

  @Prop({ default: true })
  togglable: boolean;

  @Prop({ default: true })
  custom: boolean;

  @Prop({ default: true })
  customScenario: boolean;

  readonly isLimited = window.zoinedContext.limited;

  get availableComparisons(): Comparison[] {
    return this.choices || this.$store.getters.getParameters("comparisons");
  }

  get items() {
    return (
      this.comparisons &&
      this.comparisons.map((comparison) => ({
        value: this.menuKey(comparison),
        name: translationService.comparisonTitle(comparison),
        enabled: !!comparison.enabled,
      }))
    );
  }

  get defaultItems() {
    return (
      this.defaults &&
      this.defaults.map((comparison) => ({
        value: this.menuKey(comparison),
        name: translationService.comparisonTitle(comparison),
        enabled: comparison.enabled,
      }))
    );
  }

  get menuItems() {
    if (!this.availableComparisons) {
      return [];
    } else {
      return this.availableComparisons
        .filter(
          (comparison) =>
            (!this.isLimited || comparison.enabled_for_limited) &&
            !_.some(this.items, (item) => item.value == this.menuKey(comparison))
        )
        .map((comparison) => ({
          key: this.menuKey(comparison),
          name: comparison.name,
        }));
    }
  }

  get proVersionMenuItems() {
    if (!this.isLimited) {
      return [];
    }
    return (this.availableComparisons || [])
      .filter((comparison) => !comparison.enabled_for_limited)
      .map((comparison) => ({
        key: this.menuKey(comparison),
        name: comparison.name,
      }));
  }

  menuKey(comparison) {
    return comparisonToMenuKey(comparison);
  }

  addCustomScenario(scenario: Comparison) {
    let comparisons = this.comparisons || [];
    if (this.radio) {
      comparisons = comparisons.map((c) => ({ ...c, enabled: false }));
    }

    comparisons = [...comparisons, scenario];

    this.$emit("update", comparisons);
  }

  addItem(item: MenuItem) {
    const comparison = menuKeyToComparison(menuItemKey(item), true);
    if (comparison) {
      this.$emit("update", [...(this.comparisons || []), comparison]);
    }
  }

  onUpdate({ items }) {
    const comparisons = items.map(({ value, enabled }) => {
      return menuKeyToComparison(value, enabled);
    });
    this.$emit("update", comparisons);
  }

  selectCustomRange([d1, d2]) {
    let start = moment(d1);
    let end = moment(d2);
    [start, end] = start.isAfter(end) ? [end, start] : [start, end];

    const comparison = {
      type: "custom",
      start_time: start.format("YYYY-MM-DD"),
      end_time: end.format("YYYY-MM-DD"),
      enabled: true,
    };
    if (!this.comparisonExists(comparison)) {
      this.$emit("update", [...(this.comparisons || []), comparison]);
    }
  }

  comparisonExists(comparison) {
    return _.some(this.comparisons, (c) => this.menuKey(c) == this.menuKey(comparison));
  }
}
</script>

<style lang="scss" scoped>
.custom {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}
.custom-scenario {
  border-top: 1px solid #ccc;
  padding: 14px;
  cursor: pointer;
}

.custom-calendar {
  margin-top: 10px;
}

.custom-scenario-form {
  margin-top: 15px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 10px;
  }
}
</style>
