<template lang="pug">
.flex-row.align-items-center.gap-lg
  .title {{ 'filter.config.zerofill' | i18n }}
  btn-group.btn-multiple
    btn.btn-sm(type="primary" input-type="radio", :input-value="true", v-model="model", @click="update(true)") {{ 'options.yes' | i18n }}
    btn.btn-sm(type="primary" input-type="radio", :input-value="false", v-model="model", @click="update(false)") {{ 'options.no' | i18n }}
</template>

<script>
import Vue from "vue";
import { Btn, BtnGroup } from "uiv";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    Btn,
    BtnGroup,
  },
})
export default class ZerofillSelector extends Vue {
  @Prop()
  value;

  model = true;

  update(value) {
    this.$emit("input", value ? "true" : "false");
  }

  created() {
    this.setModel();
  }

  @Watch("value")
  setModel() {
    this.model = this.value !== "false";
  }
}
</script>
