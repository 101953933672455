"use strict";

export const periodsForHourly = ["today"];
export const periodsForDaily = [
  "today",
  "yesterday",
  "last_week",
  "last_7_days",
  "last_14_days",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "last_and_next_7_days",
  "last_year_4_weeks",
  "last_year_7_days",
  "last_year_14_days",
  "last_year",
  "last_month",
  "current_week",
  "current_month",
  "current_year",
  "current_full_year",
  "current_full_month",
  "current_full_week",
  "next_30_days",
  "next_3_months",
  "next_6_months",
];
export const periodsForWeekly = [
  "last_week",
  "last_7_days",
  "last_14_days",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "last_and_next_7_days",
  "last_4_weeks",
  "last_12_weeks",
  "last_year_4_weeks",
  "last_year",
  "last_year_7_days",
  "last_year_14_days",
  "last_month",
  "current_week",
  "current_month",
  "current_year",
  "current_full_year",
  "current_full_month",
  "current_full_week",
  "next_30_days",
  "next_3_months",
  "next_6_months",
];
export const periodsForMonthly = [
  "last_month",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "last_3_months",
  "last_12_months",
  "last_year_4_weeks",
  "last_year_7_days",
  "last_year",
  "current_month",
  "current_year",
  "current_full_year",
  "current_full_month",
  "next_30_days",
  "next_3_months",
  "next_6_months",
];
export const periodsForYearly = [
  "last_month",
  "last_30_days",
  "last_90_days",
  "last_365_days",
  "last_3_months",
  "last_12_months",
  "last_year_4_weeks",
  "last_year_7_days",
  "last_year",
  "current_month",
  "current_year",
  "current_full_year",
  "current_full_month",
  "next_30_days",
  "next_3_months",
  "next_6_months",
];

const periodToOption = (p) => {
  return {
    id: p,
    name: I18n.t("filters.date_selector." + p),
  };
};

export const periodDefaults = {
  metric_comparison: {
    daily: "yesterday",
    weekly: "last_week",
    monthly: "last_month",
    yearly: "last_year",
    at_most_once: "yesterday",
    at_most_hourly: "today",
    at_most_daily: "yesterday",
    at_most_weekly: "last_week",
    at_most_monthly: "last_month",
  },
  time_series: {
    daily: "last_7_days",
    weekly: "last_12_weeks",
    monthly: "current_year",
    yearly: "last_year",
  },
  top: {
    daily: "yesterday",
    weekly: "last_week",
    monthly: "last_month",
    yearly: "last_year",
    at_most_once: "yesterday",
    at_most_hourly: "today",
    at_most_daily: "yesterday",
    at_most_weekly: "last_week",
    at_most_monthly: "last_month",
  },
};

let unltd = I18n.t("filters.limit_selector.unlimited");
unltd = unltd[0].toUpperCase() + unltd.slice(1).toLowerCase();

export const limitOptions = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100]
  .map(function(l) {
    return {
      id: l,
      name: l,
    };
  })
  .concat([{ id: 1000, name: unltd }]);

export const sortOptions = ["-metric", "metric", "grouping"].map(function(s) {
  return {
    id: s,
    name: I18n.t("filter.sort." + s),
  };
});

export function displayTypes(types) {
  return types.map((p) => {
    return { id: p, name: I18n.t("newsletter.definition.sections.common.chart_type." + p) };
  });
}
