<template lang="pug">
.metric-comparison-section
  .option
    label {{ 'filter.config.metrics' | i18n }}
    select(:value="section.metric", @input="updateMetric")
      option(v-for="option in metricsOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.time_period' | i18n }}
    timePeriodDropdown(:show-title="false", :custom="false", :available-items="timePeriods", :available-series-types="seriesTypes", :value="selection", @update="updateSelection")
  .option(v-if="openingHoursDefined")
    label {{ 'filter.config.limit_to_business_hours' | i18n }}
    businessHoursSelector(:title="false" :value="section.limit_to_business_hours" @update="updateLimitToBusinessHours")
  .option
    label {{  'newsletter.definition.sections.common.comparison' | i18n }}
    comparisonPillList(:choices="comparisons", :custom="false", :title="null", :comparisons="section.comparisons", @update="updateComparisons")
  .option(v-if="lflEnabled && section.comparisons.length == 1 && isTimeComparison(section.comparisons[0].type)")
    label {{ 'filter.config.like_for_like' | i18n }}
    likeForLikeSelector(:config="section.like_for_like", :title="null", @update="updateLikeForLike")
  .option
    label {{ 'newsletter.definition.sections.metric_comparison.comparison_text' | i18n }}
    comparisonSelector(:value="section.comparison", :choices="comparisonTexts" @input="updateComparison")
  .option(v-if="insightsVisible && section.metric")
    label {{ 'newsletter.definition.insights.title' | i18n }}
    newsletterSectionInsights(:metric="section.metric", :configuration="section.insights", @change="updateInsights")
  .option(v-if="newsletterType == 'alert'")
    label {{  'newsletter.definition.alert.alert_conditions' | i18n }}
    div(style="margin-left: 200px;")
      newsletterSectionAlertConditions(:config="section.alert", :default-metric="section.metric" @update="updateAlert")
</template>

<script lang="ts">
import _ from "lodash";
import { timeComparisonTypes } from "../../filters/time-period";
import MenuItem from "../../interfaces/menu-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import comparisonSelector from "../../components/comparison-selector.vue";
import i18n from "../../i18n";

@Component({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
    comparisonSelector,
  },
})
export default class MetricComparisonSection extends Vue {
  @Prop()
  section: any;

  @Prop()
  newsletterType: string;

  @Prop()
  timePeriods: MenuItem[];

  @Prop()
  seriesTypes: string[];

  @Prop()
  openingHoursDefined: boolean;

  @Prop()
  lflEnabled: boolean;

  @Prop()
  insightsVisible: boolean;

  get selection() {
    return (
      this.section.time_period && {
        type: this.section.time_period,
        series: this.section.time_series_type,
      }
    );
  }

  get metricsOptions(): MenuItem[] {
    return this.$store.state.parameters.metrics.all;
  }

  get comparisons() {
    const allowedComparisonTypes = [
      "prev_year_corresponding",
      "2_years_back_corresponding",
      "3_years_back_corresponding",
      "prev_year_corresponding_same_weekday_ending",
      "prev_year_same_days",
      "prev_year_full_month",
      "previous_corresponding_period",
      "previous_year_lfl",
      "2_years_back_lfl",
      "3_years_back_lfl",
      "budget",
    ];

    return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
  }

  get comparisonTexts() {
    return (
      this.comparisons && [
        { type: "no_comparison", name: i18n.t("filters.benchmark.no_comparison") },
        ...this.comparisons,
      ]
    );
  }

  updateMetric(event) {
    const metric = event.target.value;
    this.updateSection({ ...this.section, metric, insights: null });
  }

  updateSelection({ type, series }) {
    this.updateSection({ ...this.section, time_period: type, time_series_type: series });
  }

  updateLimitToBusinessHours(limit_to_business_hours) {
    this.updateSection({ ...this.section, limit_to_business_hours });
  }

  updateComparisons(comparisons) {
    this.updateSection({ ...this.section, comparisons });
  }

  updateComparison(comparison) {
    this.updateSection({ ...this.section, comparison });
  }

  updateLikeForLike(like_for_like) {
    this.updateSection({ ...this.section, like_for_like });
  }

  updateInsights(insights) {
    this.updateSection({ ...this.section, insights });
  }

  updateAlert(alert) {
    this.updateSection({ ...this.section, alert });
  }

  updateSection(section) {
    this.$emit("update-section", section);
  }

  isTimeComparison(type) {
    return _.includes(timeComparisonTypes, type);
  }
}
</script>
