import _, { head } from "lodash";
import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id }) => {
  return `/api/admin/companies/${company_id}/audits`;
};

const apiInstance = makeApiInstance();

const api = {
  get: ({ company_id, ...params }) =>
    apiInstance
      .get(buildUrl({ company_id }), { params })
      .then(({ data, headers }) => Object.assign(data, headers.total ? { total_count: headers.total } : {})),
};

export default api;
