<template lang="pug">
.row-wrapper
  .component-row(:class="rowClasses")
    zoined-report-component.component(
      v-for='(component, index) in components',
      :key="index",
      :class="{half: component.span == 6, third: component.span == 4, quarter: component.span == 3}",
      :component="effectiveComponentConfig(component)",
      :filter-configuration="filterConfiguration",
      :chart-options="chartOptions",
      :table-config="tableConfig",
      :highchart-options="highchartOptions",
      :is-details-open="component == detailComponent",
      :showFilterConfiguration="dashboard",
      :custom="custom",
      :dashboard="dashboard",
      :preview="preview",
      :public="public",
      :saveable="saveable",
      @data="$set(datas, index, $event)",
      @drilldown="handleDrilldown(component, $event)",
      @pageChanged="handlePageChange(component, $event)",
      @toggleDetails="$emit('toggle-details', component)",
      @filter-configuration-updated="$emit('filter-configuration-updated', $event)",
      @chart-options-updated="$emit('chart-options-updated', $event)",
      @table-config-updated="$emit('table-config-updated', $event)",
      @highchart-options-updated="$emit('highchart-options-updated', $event)",
      @componentUpdated="$emit('component-updated', { component, updatedComponent: $event})"
      @excel-export-component="$emit('excel-export-component', $event)"
      @save="$emit('save', component)"
    )
  .detail-row(v-if="detailComponent")
    div(v-for="(component, index) in components")
      component-details(v-if="component == detailComponent" 
        :filters="componentDetailFilters(component)",
        :component="component", 
        :row-components="components",
        :data="datas[index]",
        @open-report="openReport(component, $event)"
      )
</template>

<script lang="ts">
import _ from "lodash";
import { componentFilters, filterToFlyover, refreshFilterTimes } from "../lib/filter-util";
import { openReport } from "../lib/report";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import zoinedReportComponent from "./zoined-report-component.vue";
import componentDetails from "./component-details.vue";

@Component({
  components: {
    zoinedReportComponent,
    componentDetails,
  },
})
export default class ZoinedReportRow extends Vue {
  @Prop()
  components: any[];

  @Prop()
  detailComponent: any;

  @Prop()
  filterConfiguration: any;

  @Prop()
  chartOptions: any;

  @Prop()
  tableConfig: any;

  @Prop()
  highchartOptions: any;

  @Prop({ default: false })
  preview: boolean;

  @Prop({ default: false })
  custom: boolean;

  @Prop({ default: false })
  dashboard: boolean;

  @Prop({ default: false })
  public: boolean;

  @Prop({ default: false })
  saveable: boolean;

  @Prop({ default: () => ({}) })
  overrides: any;

  detailChartOverrides = {};
  datas = {};

  get rowClasses() {
    const hasChart = !!this.components.find((c) => c.type === "chart");
    const hasTable = !!this.components.find((c) => c.name == "cross_table");
    return {
      "has-chart": hasChart,
      "has-table": hasTable,
    };
  }

  get company() {
    return window.zoinedContext.company;
  }

  effectiveComponentConfig(component) {
    return _.merge({}, component, this.overrides[component.id || component.name]);
  }

  handleDrilldown(component, filters) {
    const index = this.components.indexOf(component);

    if (component.drilldown_remote_for) {
      let overrides = this.overrides;
      component.drilldown_remote_for.forEach((name) => {
        overrides = _.assign({}, overrides, {
          [name]: { drilldownFilters: filters || {} },
        });
      });
      this.$emit("update-overrides", overrides);
    } else if (index >= 0) {
      Vue.set(this.detailChartOverrides, index, filters);
    }
  }

  handlePageChange(component, paging) {
    if (component.pagination_remote_for) {
      let overrides = this.overrides;
      component.pagination_remote_for.forEach((name) => {
        overrides = _.assign({}, overrides, {
          [name]: {
            drilldownFilters: {
              ...(this.overrides?.[name]?.drilldownFilters || {}),
              limit: paging.pageSize,
              offset: (paging.page - 1) * paging.pageSize,
            },
          },
        });
      });
      this.$emit("update-overrides", overrides);
    }
  }

  componentDetailFilters(component) {
    const index = this.components.indexOf(component);
    return {
      ...componentFilters(this.filterConfiguration, component),
      ...(index >= 0 ? this.detailChartOverrides[index] : {}),
    };
  }

  openReport(component, params) {
    const filterConfiguration = filterToFlyover(
      refreshFilterTimes({
        ...this.filterConfiguration,
        ...component.filterConfiguration,
      })
    );
    openReport(filterConfiguration, params, this.company);
  }
}
</script>

<style lang="scss" scoped>
.row-wrapper {
  margin-bottom: 30px;

  .component-row {
    page-break-inside: avoid;

    @media print {
      margin-bottom: 45px;
    }

    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-column-gap: 30px;

    .component {
      grid-column-end: span 12;

      @media (min-width: 768px) {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }

      @media print {
        &.half {
          grid-column-end: span 6;
        }

        &.third {
          grid-column-end: span 4;
        }

        &.quarter {
          grid-column-end: span 3;
        }
      }
    }
  }
}
</style>
