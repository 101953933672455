import _ from 'lodash';

export const WEATHER_SYMBOLS = {
  '113': '&#xf00d', // wi-day-sunny      # Clear/Sunny
  '116': '&#xf002', // wi-day-cloudy     # Partly Cloudy
  '119': '&#xf013', // wi-cloudy         # Cloudy
  '122': '&#xf013', // wi-cloud          # Overcast
  '143': '&#xf01a', // wi-raindrops      # Mist
  '176': '&#xf019', // wi-rain           # Patchy rain nearby
  '179': '&#xf01b', // wi-snow           # Patchy snow nearby
  '182': '&#xf0b5', // wi-sleet          # Patchy sleet nearby
  '185': '&#xf019', // wi-raindrops      # Patchy freezing drizzle nearby
  '200': '&#xf01e', // wi-thunderstorm   # Thundery outbreaks in nearby
  '227': '&#xf01b', // wi-snow-wind      # Blowing snow
  '230': '&#xf01b', // wi-snow-wind      # Blizzard
  '248': '&#xf014', // wi-fog            # Fog
  '260': '&#xf014', // wi-fog            # Freezing fog
  '263': '&#xf019', // wi-raindrops      # Patchy light drizzle
  '266': '&#xf019', // wi-raindrops      # Light drizzle
  '281': '&#xf019', // wi-raindrops      # Freezing drizzle
  '284': '&#xf019', // wi-raindrops      # Heavy freezing drizzle
  '293': '&#xf019', // wi-rain           # Patchy light rain
  '296': '&#xf019', // wi-rain           # Light rain
  '299': '&#xf019', // wi-rain           # Moderate rain at times
  '302': '&#xf019', // wi-rain           # Moderate rain
  '305': '&#xf019', // wi-rain           # Heavy rain at times
  '308': '&#xf019', // wi-rain           # Heavy rain
  '311': '&#xf019', // wi-rain-mix       # Light freezing rain
  '314': '&#xf019', // wi-rain-mix       # Moderate or Heavy freezing rain
  '317': '&#xf0b5', // wi-sleet          # Light sleet
  '320': '&#xf0b5', // wi-sleet          # Moderate or heavy sleet
  '323': '&#xf01b', // wi-snow           # Patchy light snow
  '326': '&#xf01b', // wi-snow           # Light snow
  '329': '&#xf01b', // wi-snow           # Patchy moderate snow
  '332': '&#xf01b', // wi-snow           # Moderate snow
  '335': '&#xf01b', // wi-snow           # Patchy heavy snow
  '338': '&#xf01b', // wi-snow           # Heavy snow
  '350': '&#xf015', // wi-hail           # Ice pellets
  '353': '&#xf019', // wi-rain           # Light rain shower
  '356': '&#xf019', // wi-rain           # Moderate or heavy rain shower
  '359': '&#xf019', // wi-rain           # Torrential rain shower
  '362': '&#xf0b5', // wi-sleet          # Light sleet showers
  '365': '&#xf0b5', // wi-sleet          # Moderate or heavy sleet showers
  '368': '&#xf01b', // wi-snow           # Light snow showers
  '371': '&#xf01b', // wi-snow           # Moderate or heavy snow showers
  '374': '&#xf015', // wi-hail           # Light showers of ice pellets
  '377': '&#xf015', // wi-hail           # Moderate or heavy showers of ice pellets
  '386': '&#xf01e', // wi-thunderstorm   # Patchy light rain in area with thunder
  '389': '&#xf01e', // wi-thunderstorm   # Moderate or heavy rain in area with thunder
  '392': '&#xf01e', // wi-thunderstorm   # Patchy light snow in area with thunder
  '395': '&#xf01e', // wi-thunderstorm   # Moderate or heavy snow in area with thunder
};

const COLORS = ["#109ec5", "#666"]

export const weatherSeriesHeight = 48;
export const weatherSeriesBotttomSpacing = 20;

export function drawWeatherSymbols(chartObj) {
  const weather = chartObj.options.weather;
  if (!weather) return;

  // Remove possible old weather icons (during redraw).
  let color, data_series, series_group, unit;
  let font_size = 12;

  // Make new group for weather icons.
  let weather_group = chartObj.renderer.g('weather')
    .attr({
      translateX: chartObj.plotLeft,
      translateY: (chartObj.plotTop + chartObj.plotHeight + chartObj.marginBottom) - (weather.length * weatherSeriesHeight) - weatherSeriesBotttomSpacing,
      zIndex: 5,
      "font-size": font_size,
      "text-anchor": "middle"}).add();

  return Array.from(weather).map((weather_series, i) =>
    ((data_series = chartObj.series[i]),
    ({ color } = data_series),
    (color = COLORS[i] || color),
    (unit = weather_series.unit || 'C'),

    // Group for a line of weather icons and texts.
    (series_group = chartObj.renderer.g()
      .attr({
        translateY: i * weatherSeriesHeight,
        fill: color}).add(weather_group)),

    // Draw a "legend" for each weather series, just to the left of the main weather box.
    chartObj.renderer
      .rect({
        x: (- chartObj.plotLeft / 2) - 8,
        y: font_size,
        width: 16,
        height: font_size}).add(series_group),

    chartObj.renderer.text(`°${unit}`, - chartObj.plotLeft / 2, weatherSeriesHeight - 3)
      .add(series_group),

    (() => {
      let result = [];
      for (let j = 0; j < weather_series.data.length; j++) {
        let weather_point = weather_series.data[j];
        let data_point = data_series.data[j];
        if (data_point == null) { break; }

        let group = chartObj.renderer.g()
          .attr({translateX: data_point.plotX, translateY: 0})
          .clip(chartObj.renderer.clipRect(-30, 0, 60, weatherSeriesHeight))
          .add(series_group);

        let not_available_symbol = '&#xf07b';

        let x = chartObj.renderer.text('', 0, 2 * font_size)
          .attr({
            "font-family": "weather icons",
            "font-size": "150%"}).add(group);
        x.element.innerHTML = WEATHER_SYMBOLS[weather_point.weather_code] || not_available_symbol;

        result.push(chartObj.renderer.text(weather_point.temperature, 0, weatherSeriesHeight - 3)
          .add(group));
      }
      return result;
    })()));
}

export function weatherTooltipDesc(element, data) {
  let s = '';
  let temperatures = '';
  let ix = element.points[0].point.index;
  let weather_codes = (temperatures = "");
  _.each(data.weather, function(weather_series, i) {
    let unit = weather_series.unit || 'C';
    if (weather_series.data != null) {
      let weather_point = weather_series.data[ix];
      if (weather_point != null) {
        let comp_text = "<br/>";
        if (i > 0) { comp_text += I18n.t("comparison") + ": "; }
        weather_codes += comp_text + I18n.t("weather.weather_code") + ": " + I18n.t(`weather.description.${weather_point.weather_code}`);
        let temp = (weather_point.temperature != null) ? `${weather_point.temperature}° ${unit}` : "N/A";
        return temperatures += comp_text + I18n.t("weather.temperature") + `: ${temp}`;
      }
    }
  });
  s += weather_codes + temperatures;
  return s;
}
