import RootState from "../root-state";
import { Module } from "vuex";
import ReportContext from "../../model/report-context";
import { filterToFlyover, refreshFilterTimes } from "../../lib/filter-util";
import FilterConfiguration from "../../model/filter-configuration";
import ChartOptions from "../../model/chart-options";
import TableConfig from "../../model/table-config";
import { makeApiInstance } from "../../api/instance";
import axios from "axios";

export interface ReportContextState {
  state: "initial" | "clear" | "loading" | "loaded" | "failed";
  data: ReportContext;
}

let abortController: AbortController = null;

const reportContext: Module<ReportContextState, RootState> = {
  namespaced: true,
  state: () => ({
    state: "initial",
    data: null,
  }),
  actions: {
    fetchSystemDashboard({ dispatch }) {
      const url = `/api/v1/dashboard/report_context`;
      return dispatch("fetchReportContext", { url });
    },
    fetchCustomDashboard({ dispatch }, report_id: string) {
      const url = `/api/v1/reports/report_context`;
      const params = { report_id };
      return dispatch("fetchReportContext", { url, params });
    },
    fetchAnalyticsReport({ dispatch }, { report_name, my_report_id = null }) {
      const url = `/api/v1/reports/report_context`;
      const params = { report_name, my_report_id };
      return dispatch("fetchReportContext", { url, params });
    },
    fetchReportContext({ commit }, { url, params = null }) {
      abortController?.abort();
      abortController = new AbortController();
      commit("fetchRequest");
      return makeApiInstance()
        .get(url, { params, signal: abortController.signal })
        .then((response) => commit("fetchSuccess", response.data))
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }
          commit("fetchFailure");
          throw error;
        });
    },
  },
  mutations: {
    fetchRequest: (state) => {
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loading", data: null });
    },
    fetchSuccess: (state, data: ReportContext) => {
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
    fetchFailure: (state) => {
      Object.assign<ReportContextState, ReportContextState>(state, { state: "failed", data: null });
    },
    clear: (state) => {
      Object.assign<ReportContextState, ReportContextState>(state, { state: "clear", data: null });
    },
    setHasChanges(state, hasChanges: boolean) {
      const data = { ...state.data, has_changes: !!hasChanges };
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
    setFilterConfiguration: (state, filterConfiguration: FilterConfiguration) => {
      // Ensure we have newest configuration format
      filterConfiguration = filterToFlyover(filterConfiguration);

      // Refresh time selection and comparisons
      filterConfiguration.time = refreshFilterTimes(filterConfiguration.time);

      const data = { ...state.data, filter_configuration: filterConfiguration };
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
    setChartOptions: (state, chartOptions: ChartOptions) => {
      const data = { ...state.data, chart_options: chartOptions };
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
    setHighchartOptions: (state, highchartOptions: any) => {
      const data = { ...state.data, highchart_options: highchartOptions };
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
    setTableConfig: (state, tableConfig: TableConfig) => {
      const data = { ...state.data, table_config: tableConfig };
      Object.assign<ReportContextState, ReportContextState>(state, { state: "loaded", data });
    },
  },
};

export default reportContext;
