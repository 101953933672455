<template>
  <div class="edit-report-page" v-if="loaded">
    <div class="mt-md"></div>
    <report-editor ref="editor" :default-report="report" :default-my-report="myReport"></report-editor>
  </div>
  <spinner v-else></spinner>
</template>

<script lang="ts">
import Spinner from "@/components/spinner.vue";
import ReportEditor from "@/analytics/report-editor.vue";
import { ref, computed, watchEffect } from "vue";
import { useI18n } from "@/composables/i18n";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { makeApiInstance } from "@/api/instance";

export default {
  props: {
    myReportId: {
      type: Number,
      required: false,
    },
    duplicateId: {
      type: Number,
      required: false,
    },
    name: {
      type: String,
      required: true,
    },
    config: {
      type: Object,
      required: false,
    },
  },
  name: "EditReportPage",
  components: { Spinner, ReportEditor },
  beforeRouteUpdate(to, from, next) {
    this.handleRouteChange(next);
  },
  beforeRouteLeave(to, from, next) {
    this.handleRouteChange(next);
  },
  setup(props) {
    const { t } = useI18n();
    const store = useStore();

    const editor = ref<ReportEditor>(null);
    const report = ref(null);
    const myReport = ref(null);

    const title = computed(() => {
      if (props.myReportId) {
        return myReport.value.name;
      } else {
        return t("custom_report.new_report");
      }
    });

    const loaded = computed(() => {
      return report.value && myReport.value;
    });

    const handleRouteChange = (next) => {
      let confirmed = true;
      if (editor.value!?.hasChanges) {
        confirmed = confirm(t("confirm.unsaved_changes_confirmation"));
      }
      if (confirmed) {
        store.commit(Mutations.clearTitle);
        next();
      } else {
        next(false);
      }
    };

    watchEffect(async () => {
      if (props.myReportId) {
        const reportContext = await makeApiInstance()
          .get("/api/v1/reports/report_context", {
            params: {
              report_name: props.name,
              my_report_id: props.myReportId,
            },
          })
          .then((response) => response.data);
        report.value = reportContext.report;
        myReport.value = reportContext.my_report;
      } else if (props.duplicateId) {
        const reportContext = await makeApiInstance()
          .get("/api/v1/reports/report_context", {
            params: {
              report_name: props.name,
              my_report_id: props.duplicateId,
            },
          })
          .then((response) => response.data);
        report.value = reportContext.report;
        myReport.value = reportContext.my_report;
        myReport.value.name = `Copy of ${myReport.value.name}`;
        delete myReport.value.id;
      } else {
        const reportContext = await makeApiInstance()
          .get("/api/v1/reports/report_context", {
            params: {
              report_name: props.name,
            },
          })
          .then((response) => response.data);
        report.value = reportContext.report;
        myReport.value = { ...reportContext.my_report, filters: reportContext.filter_configuration };
      }

      // Overrides
      if (props.config?.filter_configuration) {
        myReport.value.filters = props.config.filter_configuration;
      }
      if (props.config?.chart_options) {
        myReport.value.control_state = props.config.chart_options;
      }
      if (props.config?.table_config) {
        myReport.value.table_config = props.config.table_config;
      }

      store.commit(Mutations.setTitle, title.value);
    });

    return { editor, report, myReport, title, loaded, handleRouteChange };
  },
};
</script>
