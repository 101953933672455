<template lang="pug">
.drilldown-provider
  slot
</template>

<script lang="ts">
import Vue from "vue";
import { Component, Provide } from "vue-property-decorator";

@Component({})
export default class DrilldownProvider extends Vue {
  @Provide()
  drilldownData = {
    selected: null,
    metric: null,
    currentGrouping: null,
    userChangedDrilldown: false,
  };
}
</script>
