import EventBus from "@/events/event-bus";
import { onMounted, onUnmounted } from "vue";

export const useEvent = (event, listener) => {
  onMounted(() => {
    EventBus.subscribe(event, listener);
  });

  onUnmounted(() => {
    EventBus.unsubscribe(event, listener);
  });
};
