import { render, staticRenderFns } from "./newsletter-definitions-page.vue?vue&type=template&id=6419996e&scoped=true&lang=pug"
import script from "./newsletter-definitions-page.vue?vue&type=script&lang=ts"
export * from "./newsletter-definitions-page.vue?vue&type=script&lang=ts"
import style0 from "./newsletter-definitions-page.vue?vue&type=style&index=0&id=6419996e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6419996e",
  null
  
)

export default component.exports