<template lang="pug">
form.navbar-form
  .form-group
    select.form-control(v-model="companyId" @change="onChange")
      option(:value="company.id" v-for="company in companies") {{ companyTitle(company) }}
</template>

<script lang="ts">
import i18n from "../i18n";
import Company from "../model/company";
import Vue from "vue";
import Component from "vue-class-component";
import { postForm } from "../lib/form";
import { showOverlay } from "@/ui/overlay";

@Component({})
export default class CompanySelector extends Vue {
  companyId = window.zoinedContext.companyId;

  companyTitle(company: Company) {
    let title = company.name;
    if (window.zoinedContext.isZoinedAdmin && company.partner) {
      title += ` [${company.partner.name}]`;
    }
    return title;
  }

  get companies(): Company[] {
    return window.zoinedContext.available_companies || [];
  }

  onChange() {
    window.$(".dropdown.open").removeClass("open");
    showOverlay(i18n.t("please_wait"));
    postForm(`/companies/${this.companyId}/switch`);
  }
}
</script>
