import { render, staticRenderFns } from "./drilldown-provider.vue?vue&type=template&id=4fbef8de&lang=pug"
import script from "./drilldown-provider.vue?vue&type=script&lang=ts"
export * from "./drilldown-provider.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports