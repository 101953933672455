export default class ErrorReportingService {

  error(msg, payload) {
    (window.Rollbar || console).error(msg, payload)
  }

  warning(msg, payload) {
    window.Rollbar ? window.Rollbar.warning(msg, payload) : console.warn(msg, payload)
  }

  info(msg, payload) {
    (window.Rollbar || console).info(msg, payload)
    
  }
}