<template lang="pug">
.sidenav-category-title.uppercase.font-size-md.letter-spacing-wide.color-muted
  .caret-icon
    i.fa(:class="open ? 'fa-caret-right' : 'fa-caret-down'")
  .flex-1 {{ title }}
  a.manage-reports-btn(
    v-if="manageFoldersEnabled",
    @click.stop="$emit('manage-folders')",
    v-uiv-tooltip.hover="manageFoldersTooltip"
  )
    svg-icon(name="folder")
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import SvgIcon from "../components/svg-icon.vue";

export default Vue.extend({
  components: {
    SvgIcon,
  },
  props: {
    title: String,
    manageFoldersEnabled: Boolean,
    open: Boolean
  },
  computed: {
    manageFoldersTooltip() {
      return i18n.t("sidenav.manage_folders");
    },
  },
});
</script>

<style lang="scss" scoped>
.sidenav-category-title {
  margin: 20px 15px 0 15px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--color-sidenav-category-title);

  .caret-icon {
    display: flex;
    justify-content: center;
    width: 10px;
    margin-right: 5px;
  }

  .manage-reports-btn {
    display: flex;
    visibility: hidden;
    color: var(--color-primary);
    border-radius: 9999px;
    margin-top: 2px;

    svg {
      width: 14px;
    }
  }

  &:hover .manage-reports-btn {
    visibility: visible;
  }
}
</style>
