<template lang="pug">
.data-management
  .row(v-if="successText")
    .col-md-12
      .alert.alert-success
        | {{ successText }}
  .row(v-if="errorText")
    .col-md-12
      .alert.alert-danger
        | {{ errorText }}
  .row
    .col-md-12
      h4 {{ "company_admin.data_management.data_type" | i18n }}
      p {{ "company_admin.data_management.data_type_description" | i18n }}
    .col-md-12
      select(v-model="dataType")
        option(v-for="dataType in sortedDataTypes", v-bind:value="dataType.type") {{ dataType.title }}
        .row
    .col-md-12
      h4 {{ "company_admin.data_management.download_data" | i18n }}
      p {{ "company_admin.data_management.download_data_description" | i18n }}
  .row.mb-md
    .col-md-4
      h5 {{ 'attributes.start_date' | i18n }}
      date-picker(v-model="startDate")
    .col-md-4
      h5 {{ 'attributes.end_date' | i18n }}
      date-picker(v-model="endDate")
  .row
    .col-md-12.mb-md(v-if="dataType == 'flat_hierarchies'")
      h5 {{ 'metadata.category.FlatHierarchies' | i18n }}
      select(v-model="hierarchyGroup")
        option(:value="null") {{ 'filter.config.all' | i18n }}
        option(v-for="group in hierarchyGroups", v-bind:value="group.group") {{ group.title }}
  .row
    .col-md-12
      a.btn.btn-primary(@click="downloadData") {{ "company_admin.data_management.download_data" | i18n }}
  .row
    .col-md-12
      h4 {{ "company_admin.data_management.upload_data_title" | i18n }}
      p {{ "company_admin.data_management.upload_data_description" | i18n }}
    .col-md-12
      vue-dropzone#dropzone(
        ref="dropzone",
        :options="dropzoneOptions",
        v-on:vdropzone-complete="complete",
        v-on:vdropzone-success="success",
        v-on:vdropzone-error="error",
        v-on:vdropzone-file-added="fileAdded"
      )
  .row(v-if="events.length > 0")
    .col-md-12
      h4 {{ "company_admin.data_management.uploaded_data_title" | i18n }}
      p {{ "company_admin.data_management.uploaded_data_desc" | i18n }}
  .row(v-if="events.length > 0")
    .col-md-12
      table.table
        thead
          tr
            th {{ "company_admin.data_management.data_type" | i18n }}
            th {{ "company_admin.data_management.upload_time" | i18n }}
            th {{ "company_admin.data_management.uploader" | i18n }}
            th {{ "company_admin.data_management.upload_status" | i18n }}
        tbody
          tr(v-for="event in events")
            td {{ dataTypesHash[event.data.type].title }}
            td {{ event.timestamp }}
            td {{ event.user && event.user.name }}
            td
              span.error(v-if="event.data.status == 'validation_error'")
                | {{ "company_admin.data_management.validation_error" | i18n }}
                span(v-if="event.data.message") : {{ event.data.message }}
              span.error(v-if="event.data.status == 'failed'") {{ "company_admin.data_management.upload_failed" | i18n }}
              span(v-if="!event.data.status || event.data.status == 'success'") {{ "company_admin.data_management.data_uploaded" | i18n }}
</template>

<script lang="ts">
import I18n from "../i18n";
import Vue from "vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import EventsApiService from "../api/events-api-service";
import { getDownloadUrl } from "../channel/file-download-channel";
import { v4 as uuidv4 } from "uuid";
import { downloadFile } from "../lib/download";
import Component from "vue-class-component";
import _ from "lodash";
import { makeApiInstance } from "../api/instance";
import { showOverlay, hideOverlay } from "../ui/overlay";
import DatePicker from "@/components/date-picker.vue";
import moment from "moment";

const DATA_TYPES = [
  {
    type: "sales",
    title: I18n.t("data_maintenance.types.sales_transactions"),
  },
  {
    type: "sales_summaries",
    title: I18n.t("metadata.category.SalesSummaries"),
  },
  {
    type: "employee_hours",
    title: I18n.t("metadata.category.WorkHours"),
  },
  {
    type: "workshifts",
    title: I18n.t("metadata.category.WorkShift"),
  },
  {
    type: "purchase_orders",
    title: I18n.t("metadata.category.PurchaseOrders"),
  },
  {
    type: "sales_offers",
    title: I18n.t("metadata.category.SalesOffers"),
  },
  {
    type: "sales_orders",
    title: I18n.t("metadata.category.SalesOrders"),
  },
  {
    type: "visitors",
    title: I18n.t("metadata.category.Visitors"),
  },
  {
    type: "calendar",
    title: I18n.t("metadata.category.Calendar"),
  },
  {
    type: "products",
    title: I18n.t("metadata.category.Products"),
  },
  {
    type: "customers",
    title: I18n.t("metadata.category.Customers"),
  },
  {
    type: "customer_feedbacks",
    title: I18n.t("metadata.category.CustomerFeedback"),
  },
  {
    type: "organisations",
    title: I18n.t("metadata.category.Organisations"),
  },
  {
    type: "departments",
    title: I18n.t("metadata.category.Departments"),
  },
  {
    type: "sales_persons",
    title: I18n.t("metadata.category.Salespersons"),
  },
  {
    type: "campaigns",
    title: I18n.t("metadata.category.Campaigns"),
  },
  {
    type: "currency_rates",
    title: I18n.t("metadata.category.Currencyrates"),
  },
  {
    type: "suppliers",
    title: I18n.t("metadata.category.Suppliers"),
  },
  {
    type: "flat_hierarchies",
    title: I18n.t("metadata.category.FlatHierarchies"),
  },
  {
    type: "inventory_transactions",
    title: I18n.t("data_maintenance.types.inventory_transactions"),
  },
  {
    type: "inventory_snapshots",
    title: I18n.t("data_maintenance.types.inventory_snapshots"),
  },
  {
    type: "payment_types",
    title: I18n.t("metadata.parameters.PaymentType"),
  },
  {
    type: "accounts",
    title: I18n.t("metadata.category.Accounts"),
  },
  {
    type: "accounting",
    title: I18n.t("metadata.category.Accounting"),
  },
  {
    type: "staff_costs",
    title: I18n.t("data_maintenance.types.staff_costs"),
  },
];

@Component({
  components: {
    vueDropzone: vue2Dropzone,
    DatePicker,
  },
})
export default class DataManagement extends Vue {
  dataType = null;
  successText = null;
  errorText = null;
  events = [];
  fetchEventsIntervalId = null;
  hierarchyGroup = null;
  startDate: Date = null;
  endDate: Date = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get dataTypes() {
    return DATA_TYPES;
  }

  get sortedDataTypes() {
    return _.sortBy(this.dataTypes, "title");
  }

  get hierarchyGroups() {
    return ["account", "customer", "organisation", "product"].map((group) => ({
      group,
      title: I18n.t(`flat_hierarchies.hierarchy_groups.${group}`),
    }));
  }

  get dropzoneOptions() {
    return {
      acceptedFiles: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      dictDefaultMessage: I18n.t("company_admin.data_management.file_upload_message"),
      method: "post",
      url: (files) => {
        return `/api/admin/companies/${this.companyId}/datasets/${this.dataType}/upload`;
      },
    };
  }

  get selectedDataType() {
    return this.dataTypes.find((dataType) => dataType.type == this.dataType);
  }

  get dataUrl() {
    if (!this.dataType) {
      return null;
    }
    return `/api/admin/companies/${this.companyId}/datasets/${this.dataType}`;
  }

  get dataTypesHash() {
    return _.fromPairs(this.dataTypes.map((dataType) => [dataType.type, dataType]));
  }

  fileAdded(file) {
    this.successText = this.errorText = null;
  }

  success(file) {
    this.fetchEvents();
    this.successText = I18n.t("company_admin.data_management.file_upload_success_message");
  }

  error(file, message, xhr) {
    console.log(message);
    if (xhr.status === 400) {
      this.errorText = I18n.t("company_admin.data_management.errors.bad_request");
    } else if (xhr.status === 422) {
      this.errorText = message.error;
    } else {
      this.errorText = I18n.t("company_admin.data_management.file_upload_error_message");
    }
  }

  complete(file) {
    setTimeout(() => {
      this.$refs.dropzone.removeFile(file);
    }, 2000);
  }

  fetchEvents() {
    new EventsApiService().getEvents({ eventType: "data_upload" }).then((events) => (this.events = events));
  }

  async downloadData() {
    if (!this.dataType) {
      return;
    }
    const download_id = uuidv4();
    const data: any = {
      download_id,
    };
    if (this.dataType == "flat_hierarchies" && this.hierarchyGroup) {
      data.hierarchy_group = this.hierarchyGroup;
    }
    if (this.startDate) {
      data.start = moment(this.startDate).format("YYYY-MM-DD");
    }
    if (this.endDate) {
      data.end = moment(this.endDate).format("YYYY-MM-DD");
    }

    showOverlay(I18n.t("processing_export_file"));
    try {
      // Listen for export finish
      const url = await getDownloadUrl(download_id, {
        connected: () => {
          // Start download
          makeApiInstance()
            .post(`/api/admin/companies/${this.companyId}/datasets/${this.dataType}/download`, data)
            .catch(() => {
              (window.Rollbar || console).error("Data download failed. Download id: " + download_id);
              this.errorText = "Downloading failed.";
              hideOverlay();
            });
        },
      });

      // Wait until export is finished, and then download it
      await downloadFile(url);
    } catch (error) {
      (window.Rollbar || console).error("Data download failed. Download id: " + download_id);
      this.errorText = "Downloading failed.";
    } finally {
      hideOverlay();
    }
  }

  created() {
    this.dataType = this.sortedDataTypes[0].type;

    this.fetchEvents();
    this.fetchEventsIntervalId = setInterval(() => {
      this.fetchEvents();
    }, 10000);
  }

  destroyed() {
    clearInterval(this.fetchEventsIntervalId);
  }
}
</script>

<style lang="scss" scoped>
.row.margin-bottom-fix-50 {
  margin-bottom: 50px;
}

table td .error {
  color: red;
}
</style>
