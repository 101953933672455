import SectionType from "../section-type";
import { avg_store_validator, top_legacy_validator } from "../validators";

class Top extends SectionType {
  constructor() {
    const opts = {
      defaults: {
        grouping: "store",
        metric: "sales",
        sort: "-metric",
        limit: 10,
        displayType: "bar",
        pairwiseDiff: false,
        comparisons: [],
      },
    };

    super("top", opts);

    this.validators = [top_legacy_validator, avg_store_validator];
  }
  exportHook(section) {
    delete section.comparison;
    delete section.compare_to;
    if (section.displayType !== "bar") {
      delete section.comparisons;
    }
    if (!section.comparisons || !_.some(section.comparisons, ({ type }) => type === "avg_store")) {
      delete section.comparison_target;
    }
    if (!(section.displayType === "bar" && _.size(section.comparisons) === 1)) {
      delete section.pairwiseDiff;
    }
    return section;
  }
}

export default Top;
