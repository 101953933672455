<template lang="pug">
.input-group
  span.input-group-addon
    .fa.fa-search
  input#search.form-control(type="text", :value="value", :placeholder="'actions.search' | i18n", @input="onInput")
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class SearchBar extends Vue {
  @Prop()
  value: string;

  onInput = _.debounce((event) => {
    this.$emit("input", event.target.value);
  }, 400);
}
</script>