import RootState from "../root-state";
import { Module } from "vuex";

export interface FlyoverFiltersState {
  open: boolean;
}

const flyoverFilters: Module<FlyoverFiltersState, RootState> = {
  namespaced: true,
  state: () => ({
    open: false,
  }),
  mutations: {
    open: (state) => Object.assign(state, { open: true }),
    close: (state) => Object.assign(state, { open: false }),
  },
};

export default flyoverFilters;
