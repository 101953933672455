<template lang="pug">
.metric-range-selector.flex-row.align-items-center.flex-wrap.gap-sm
  .title {{ 'filter.config.metric_range' | i18n }}
  pill-button(
    :enabled="item.enabled", 
    :label="itemLabel(item)",
    @toggle="toggleItem(item)",
    @remove="removeItem(item)",
    v-for="item in items", :key="item.value"
    )
  dropdown(ref="dropdown", tag="span", class="inline-block", v-model="open", :not-close-elements="notCloseElements")
    plus-button
    div.metric-range-dropdown(slot="dropdown")
      div(v-if="!filterForm")
        item-menu(:items="metrics", :search-query="search", @select="onSelectMetric($event)", @search="search = $event")
      div(v-else)
        form.filter-form(name="filterForm", novalidate, @submit.prevent="addItem()")
          .filter-form-title {{ filterForm.item.name }}
          .form-horizontal
            .form-group
              label.control-label.col-sm-5 {{ 'filters.metric_range.lower_limit' | i18n }}
              .col-sm-7
                input.form-control.lower_limit(type="number", autofocus, v-model="filterForm.lower_limit")
            .form-group
              label.control-label.col-sm-5 {{ 'filters.metric_range.upper_limit' | i18n }}
              .col-sm-7
                input.form-control.upper_limit(type="number", autofocus, v-model="filterForm.upper_limit")
            .form-group
              label.control-label.col-sm-5 {{ 'filters.metric_range.include_limits' | i18n }}
              .col-sm-7
                btn-group.btn-multiple
                  btn.btn-sm(type="primary" input-type="radio", :input-value="true", v-model="filterForm.inclusive", 
                    @click="filterForm.inclusive = true") {{ 'options.yes' | i18n }}
                  btn.btn-sm(type="primary" input-type="radio", :input-value="false", v-model="filterForm.inclusive"
                    @click="filterForm.inclusive = false") {{ 'options.no' | i18n }}
          .filter-form-buttons
            button.btn(type="button", @click="open = false") {{ 'actions.cancel' | i18n }}
            button.btn.btn-primary(type="submit", :disabled="!isFormValid") {{ 'actions.add' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Dropdown, BtnGroup, Btn } from "uiv";
import pillButton from "./pill-button.vue";
import itemMenu from "./item-menu.vue";
import { Prop, Ref, Watch } from "vue-property-decorator";
import TranslationService from "../core/translation.service";
import MetricRangeItem from "../model/metric-range-item";
import Actions from "../store/actions";
import PlusButton from "@/components/buttons/plus-button.vue";

@Component({
  components: {
    Dropdown,
    BtnGroup,
    Btn,
    pillButton,
    itemMenu,
    PlusButton,
  },
})
export default class MetricRangerSelector extends Vue {
  @Ref()
  dropdown: Dropdown;

  @Prop({ default: () => [] })
  items: MetricRangeItem[];

  open = false;
  filterForm = null;
  notCloseElements = [];
  search = "";

  get isFormValid() {
    return !!(this.filterForm && (this.filterForm.lower_limit || this.filterForm.upper_limit));
  }

  get metrics() {
    return this.$store.state.parameters.metrics.all;
  }

  onSelectMetric(metric) {
    Vue.set(this, "filterForm", {
      item: metric,
      lower_limit: null,
      upper_limit: null,
      inclusive: true,
    });
  }

  addItem() {
    if (!this.isFormValid) {
      return;
    }
    const {
      item: { id },
      lower_limit,
      upper_limit,
      inclusive,
    } = this.filterForm;

    const items = [
      ...this.items.map((item) => (item.id === id ? { ...item, enabled: false } : item)),
      { id, lower_limit, upper_limit, inclusive, enabled: true },
    ];
    this.$emit("update", items);

    this.open = false;
  }

  removeItem(item) {
    const items = this.items.filter((each) => each !== item);
    this.$emit("update", items);
  }

  toggleItem(item) {
    const enabled = !item.enabled;
    const items = this.items.map((each) =>
      each === item
        ? { ...item, enabled }
        : each.id === item.id
        ? { ...each, enabled: enabled ? false : each.enabled }
        : each
    );
    this.$emit("update", items);
  }

  itemLabel(metricRange) {
    return new TranslationService({ metrics: this.metrics }).metricRangeTitle(metricRange);
  }

  mounted() {
    this.notCloseElements.push(this.dropdown.$el);
  }

  @Watch("open")
  onChangeOpen() {
    if (this.open) {
      this.search = "";
      this.filterForm = null;
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  display: inline-block;
  margin-right: 10px;
}

.metric-range-dropdown {
  min-width: 340px;
}

.filter-form {
  padding: 6px;

  .filter-form-title {
    padding-bottom: 10px;
  }

  .filter-form-buttons {
    padding-top: 10px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
</style>
