<template lang="pug">
inline-svg(:src="iconSrc")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import InlineSvg from "vue-inline-svg";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    InlineSvg,
  },
})
export default class SvgIcon extends Vue {
  @Prop()
  name: string;

  get iconSrc() {
    return require(`@assets/images/icons/${this.name}.svg`);
  }
}
</script>
