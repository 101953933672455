import TimeSeries from "./section-types/time-series";
import Top from "./section-types/top";
import MetricExtremes from "./section-types/metric-extremes";
import MetricComparison from "./section-types/metric-comparison";
import ReportAttachment from "./section-types/report-attachment";
import PdfAttachment from "./section-types/pdf-attachment";
import SokProvision from "./section-types/sok-provision";

const ALERT_SECTIONS = ["metric_comparison", "top"];

const SectionTypes = {
  all: () => {
    const typeNames = [
      "metric_comparison",
      "top",
      "metric_extremes",
      "time_series",
      "report_attachment",
      "pdf_attachment",
    ];
    if (window.zoinedContext.features.sok_provision) {
      typeNames.push("sok_provision");
    }
    return typeNames;
  },
  alert: () => ALERT_SECTIONS,
  get: (id) => {
    switch (id) {
      case "metric_comparison":
        return new MetricComparison();
      case "top":
        return new Top();
      case "metric_extremes":
        return new MetricExtremes();
      case "time_series":
        return new TimeSeries();
      case "report_attachment":
        return new ReportAttachment();
      case "pdf_attachment":
        return new PdfAttachment();
      case "sok_provision":
        return new SokProvision();
      default:
        return null;
    }
  },
};

export default SectionTypes;
