<template lang="pug">
modal.dashboard-from-template-modal(v-model="open", :append-to-body="true", :title="'dashboard_custom.select_dashboard_template' | i18n", :footer="false")
  .loading-indicator(v-if="!dashboardTemplates")
    spinner
  .content(v-else)
    .item(v-for="template in dashboardTemplates" @click="createFromTemplate(template)") {{ template.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import spinner from "../components/spinner.vue";
import AnalyticsTemplatesApiService from "../api/analytics-templates-api-service";
import PortalContext from "../lib/data/portal_context";
import _ from "lodash";
import { showOverlay } from "@/ui/overlay";

@Component({
  components: {
    Modal,
    spinner,
  },
})
export default class DashboardFromTemplateModal extends Vue {
  open = false;

  dashboardTemplates = null;

  show() {
    this.open = true;
    if (!this.dashboardTemplates) {
      this.fetchDashboardTemplates();
    }
  }

  createFromTemplate(template) {
    this.open = false;
    showOverlay();
    const { name, description, config } = template;
    const portalContext = new PortalContext();
    const attrs = {
      "report[config]": JSON.stringify(config),
      "report[title]": name,
      "report[description]": description,
      [portalContext.csrfParam]: portalContext.csrfToken,
    };

    // Submit as form
    const form = $("<form></form>");
    form.attr("method", "POST");
    form.attr("action", "/dashboards/new");
    _.each(attrs, (value, key) => {
      const field = $(`<input type="hidden" name="${key}"></input >`);
      field.attr("value", value);
      form.append(field);
    });
    $(document.body).append(form);
    form.trigger("submit");
  }

  fetchDashboardTemplates() {
    new AnalyticsTemplatesApiService().getDashboardTemplates().then(
      (dashboardTemplates) =>
        (this.dashboardTemplates = dashboardTemplates.map((template) => {
          const localizedTitle = _.get(template, `translations.title.${window.zoinedContext.locale}`);
          if (localizedTitle) {
            template = { ...template, name: localizedTitle };
          }
          return template;
        }))
    );
  }
}
</script>

<style lang="scss" scoped>
.dashboard-from-template-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
