<template lang="pug">
.filters-display
  .filterset(v-if="filtersetName")
    b {{ 'filters.filterset.saved_filter' | i18n }}: 
    | {{ filtersetName }}
  .filters
    .filter(v-for="(value, label) in filters")
      b {{ label }}: 
      span(v-html="value")
</template>

<script lang="ts">
import FiltersConfiguration from "../model/filters-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import Filter from "../model/filter";

@Component({})
export default class FiltersDisplay extends Vue {
  @Prop({
    default: (): FiltersConfiguration => ({
      v: 2,
      sets: [],
      filters: {},
    }),
  })
  config: FiltersConfiguration;

  get availableFilters(): Filter[] {
    return this.$store.state.parameters.filters.all;
  }

  get filtersetName(): string {
    return _.first(
      this.config.sets &&
        this.config.sets
          .filter(({ enabled }) => enabled)
          .map(
            ({ id }) =>
              _.get(
                _.find(this.filtersets, (set) => set.id == id),
                "name"
              ) || String(id)
          )
    );
  }

  get filters(): Record<string, string> {
    return _.reduce(
      this.availableFilters,
      (result, filter) => {
        const enabledFilters = _.values(this.config.filters[filter.id])
          .filter(({ enabled }) => enabled)
          .map(({ name, exclude }) => (exclude ? `<strike>${name}</strike>` : name));
        if (enabledFilters.length > 0) {
          result = { ...result, [filter.name]: enabledFilters.join(", ") };
        }
        return result;
      },
      {}
    );
  }

  get filtersets() {
    return this.$store.state.filtersets.all;
  }
}
</script>

<style lang="scss" scoped>
.filters-display {
  margin-bottom: 10px;
}
</style>
