<template>
  <div class="ai-assistant-workspace-page report-body">
    <save-report-dialog ref="saveReportDialog" :config="saveReportConfig"></save-report-dialog>
    <ai-assistant-component-view
      v-for="(component, index) in components"
      :component="component"
      :key="index"
    ></ai-assistant-component-view>
  </div>
</template>

<script lang="ts">
import { useStore } from "@/composables/store";
import { computed, ref, watch } from "vue";
import AiAssistantComponentView from "@/ai-assistant/ai-assistant-component-view.vue";
import Mutations from "@/store/mutations";
import { useEvent } from "@/composables/events";
import Events from "@/events/events";
import SaveReportDialog from "@/save-report/save-report-dialog.vue";
import ReportConfig from "@/model/report-config";

export default {
  beforeRouteLeave(to, from, next) {
    this.handleRouteLeave(next);
  },
  components: {
    AiAssistantComponentView,
    SaveReportDialog,
  },
  setup() {
    const saveReportDialog = ref<typeof SaveReportDialog>(null);

    const store = useStore();

    useEvent(Events.saveReport, () => {
      saveReportDialog.value?.show();
    });

    const components = computed(() => store.state.aiAssistant.components);

    const reportContext = computed(() => {
      return {
        my_report: { editable: true },
        has_changes: components.value.length > 0,
      };
    });

    const saveReportConfig = computed<ReportConfig>(() => {
      return {
        components: components.value,
      } as ReportConfig;
    });

    watch(
      reportContext,
      (ctx) => {
        store.commit(Mutations.fetchReportContextSuccess, ctx);
      },
      { immediate: true }
    );

    const handleRouteLeave = (next) => {
      store.commit(Mutations.clearReportContext);
      next();
    };

    return {
      components,
      handleRouteLeave,
      saveReportDialog,
      saveReportConfig,
    };
  },
};
</script>
