<template lang="pug">
modal.dashboard-template-from-dashboard-modal(
  v-model="open",
  :append-to-body="true",
  :title="'dashboard_templates.select_dashboard' | i18n",
  :footer="false"
)
  .loading-indicator(v-if="!dashboards")
    spinner
  .content(v-else)
    .item(v-for="dashboard in dashboards", @click="createFromDashboard(dashboard)") {{ dashboard.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import { makeApiInstance } from "../../api/instance";

@Component({
  components: {
    Modal,
    spinner,
  },
})
export default class DashboardTemplateFromDashboardModal extends Vue {
  open = false;

  dashboards = null;

  get reportsApi() {
    return makeApiInstance({
      baseURL: "/api/v1/reports",
    });
  }

  show() {
    this.open = true;
    if (!this.dashboards) {
      this.fetchDashboards();
    }
  }

  createFromDashboard(dashboard) {
    this.open = false;
    this.$emit("select", dashboard.id);
  }

  fetchDashboards() {
    this.reportsApi.get("company_dashboards").then((result) => (this.dashboards = result.data));
  }
}
</script>

<style lang="scss" scoped>
.dashboard-template-from-dashboard-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
