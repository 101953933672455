<template lang="pug">
pill-list(:items="items", :available-items="availableItems", @update="onUpdate")
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import pillList from "./pill-list.vue";

@Component({
  props: {
    value: Array,
    onlyIds: Array,
  },
  components: {
    pillList,
  },
})
export default class MetricsSelector extends Vue {
  get allMetrics() {
    return this.$store.getters.getParameters("metrics");
  }

  get items() {
    return (
      this.value &&
      this.value.map(({ id, enabled }) => ({
        value: id,
        enabled: !!enabled,
      }))
    );
  }
  get availableItems() {
    let metrics = this.allMetrics;
    if (metrics && this.onlyIds) {
      metrics = metrics.filter(({ id }) => this.onlyIds.includes(id));
    }
    return metrics;
  }

  onUpdate({ items }) {
    this.$emit(
      "input",
      items.map(({ value, enabled }) => ({ id: value, enabled }))
    );
  }
}
</script>
