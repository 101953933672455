<template lang="pug">
.dropdown-item(@click="onClick")
  slot
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Inject } from "vue-property-decorator";
import Dropdown from "./dropdown.vue";

@Component({})
export default class DropdownItem extends Vue {
  @Inject("dropdown")
  dropdown: Dropdown;

  onClick() {
    this.dropdown?.close();
    this.$emit("click");
  }
}
</script>

<style lang="scss" scoped>
.dropdown-item {
  padding: 5px 10px;
  margin: 0 5px;
  white-space: nowrap;
  cursor: pointer;
  border-radius: 2px;
  &:hover {
    background-color: #f5f5f5;
  }
}
</style>
