<template lang="pug">
pill-list(
  :title="title",
  :searchable="false",
  :items="items",
  :availableItems="menuItems",
  :disabled="disabled",
  :excludable="true",
  @update="onUpdate"
  )
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import pillList from "./pill-list.vue";
import { Emit, Prop } from "vue-property-decorator";
import i18n from "../i18n";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import _ from "lodash";
import MenuItem from "../interfaces/menu-item";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";

@Component({
  components: {
    pillList,
  },
})
export default class LikeForLikeSelector extends Vue {
  @Prop()
  config: FilterConfigurationItemMap;

  @Prop()
  disabled: boolean;

  @Prop({ default: i18n.t("filter.config.like_for_like") })
  title: string;

  get items(): PillItem[] {
    return pillItemListFromFilterConfigurationItemMap(this.config).map((item) => ({
      ...item,
      name: i18n.t("filters.like_for_like.day"),
    }));
  }

  get menuItems(): MenuItem[] {
    return [{ key: "day", name: i18n.t("filters.like_for_like.day") }];
  }

  @Emit("update")
  onUpdate({ items }) {
    return filterConfigurationItemMapFromPillItemList(items);
  }
}
</script>
