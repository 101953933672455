<template lang="pug">
.newsletter-subscriptions
  .table-controls
    search-bar(v-model="search")

  div
    spinner(v-if='!subscriptions')
    table.table.table-hover
      thead
        tr
          th {{'newsletter.subscription.user' | i18n}}
          th {{'newsletter.title' | i18n}}
          th {{'newsletter.definition.frequency' | i18n}}
          th {{'newsletter.last_sent_at' | i18n}}
          th

      tbody(v-if="subscriptions")
        tr(v-if="subscriptions.length==0")
          td(colspan=6) {{ "table.no_results" | i18n }}
        tr(v-for="subscription in subscriptions")
          td(:title="subscription.user.email") {{subscription.user.first_name}} {{subscription.user.last_name}}
          td {{subscription.title}}
          td {{'newsletter.frequency.'+subscription.frequency | i18n }}
          td {{subscription.last_sent_at | dateTime}}
          td.text-right
            a.mr-sm(@click="unsubscribe(subscription)") {{ "newsletter.subscription.unsubscribe"|i18n }}
            a(v-if="permissions.send", @click="deliver(subscription)") {{ "newsletter_subscription.send_latest"|i18n }}
    .centered(v-if="total > perPage")
      uiv-pagination(v-model="page", :total-page="Math.ceil(total / perPage)")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Watch } from "vue-property-decorator";
import Spinner from "../components/spinner.vue";
import SearchBar from "../components/search-bar.vue";
import adminNewsletterSubscriptionsApi from "../api/admin-newsletter-subscriptions-api";
import toaster from "toastr";
import i18n from "../i18n";

@Component({
  components: {
    Spinner,
    SearchBar,
  },
})
export default class NewsletterSubscriptions extends Vue {
  page = 1;
  perPage = 15;
  search = "";
  total = 0;
  subscriptions = null;

  get permissions() {
    return window.zoinedContext.permissions;
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get params() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      company_id: this.companyId,
    };

    if (this.search.length > 0) {
      params["q"] = this.search;
    }

    return params;
  }

  @Watch("params", { immediate: true })
  fetchSubscriptions() {
    return adminNewsletterSubscriptionsApi.get(this.params).then((subscriptions) => {
      this.subscriptions = subscriptions;
      this.total = subscriptions.total_count;
    });
  }

  unsubscribe(subscription) {
    return adminNewsletterSubscriptionsApi.delete({ company_id: this.companyId, id: subscription.id }).then(() => {
      this.fetchSubscriptions();
    });
  }

  deliver(subscription) {
    return adminNewsletterSubscriptionsApi.deliver({ company_id: this.companyId, id: subscription.id }).then(() => {
      toaster.success(i18n.t("newsletter.subscription.send_latest_success"));
      this.subscriptions = this.subscriptions.map((s) => {
        if (s.id === subscription.id) {
          s.last_sent_at = new Date().toISOString();
        }
        return s;
      });
    });
  }

  @Watch("search")
  onSearchChange() {
    this.page = 1;
  }
}
</script>
