import PortalContext from "./portal_context";
import { makeApiInstance } from "../../api/instance";

export default class SnippetFetcher {
  context = null;
  static global() {
    return new SnippetFetcher(PortalContext.global());
  }
  constructor(context) {
    this.context = context;
  }
  fetchJson(name, filters, include_html = false, chartOptions, tableConfig = {}) {
    let query = "?locale=" + this.context.locale;
    if (include_html) {
      query += "&html=1";
    }

    const url = (this.context.baseUrl || "") + "/api/v1/snippets/" + name + ".json" + query;
    return makeApiInstance({}, { chart_token: this.context.token })
      .post(url, {
        filter: filters || {},
        chart_options: chartOptions || {},
        table_config: tableConfig,
      })
      .then((response) => response.data)
      .catch((error) => {
        const message = error?.response?.data?.error || error.message;
        throw { message };
      });
  }
}
