<template>
  <uiv-modal v-if="role" v-model="open" :title="title" :backdrop="false">
    <div class="row mb-md">
      <div class="col-md-6">
        <label class="control-label">{{ t("activerecord.attributes.user.first_name") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="role.first_name"
          required
          :placeholder="t('activerecord.attributes.user.first_name')"
        />
      </div>
      <div class="col-md-6">
        <label class="control-label">{{ t("activerecord.attributes.user.last_name") }}</label>
        <input
          type="text"
          class="form-control"
          v-model="role.last_name"
          required
          :placeholder="t('activerecord.attributes.user.last_name')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <label class="control-label">{{ t("activerecord.attributes.user.email") }}</label>
        <input
          autofocus
          type="email"
          class="form-control"
          v-model="role.email"
          required
          :placeholder="t('activerecord.attributes.user.email')"
        />
      </div>
    </div>
    <template v-slot:footer>
      <button type="button" class="btn btn-default" :disabled="saving" @click="open = false">
        {{ t("actions.cancel") }}
      </button>
      <button type="button" class="btn btn-primary" :disabled="saving" @click="save">{{ t("actions.invite") }}</button>
    </template>
  </uiv-modal>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";
import UserPartnerRole from "@/model/user-partner-role";
import { useInviteUserPartnerRoleMutation } from "@/partner-admin/admin-users/user-partner-roles-api";
import { computed, ref } from "vue";

const { t } = useI18n();

const role = ref(null);

const open = ref(false);

const saving = ref(false);

const title = computed(() => t("partner_admin.invite_admin_user"));

const show = (r: UserPartnerRole = null) => {
  role.value = r || {};
  open.value = true;
};

const inviteUserMutation = useInviteUserPartnerRoleMutation();

const save = () => {
  if (role.value) {
    saving.value = true;
    inviteUserMutation.mutate(role.value, {
      onSuccess: () => {
        open.value = false;
        saving.value = false;
      },
      onError: () => {
        saving.value = false;
      },
    });
  }
};

defineExpose({ show });
</script>

<style scoped></style>
