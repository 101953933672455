"use strict";

import _ from "lodash";
import Vue from "vue";

export default class SectionType {
  constructor(id, options = {}) {
    this.id = id;
    this.title = I18n.t(`newsletter.definition.sections.${id}.title`);
    this.description = I18n.t(`newsletter.definition.sections.${id}.select_desc`);
    this.validators = [];

    this.defaults = options.defaults || {};
    this.exporter = null;
  }

  valid(section, definition) {
    let errors = {};
    this.validators.map((v) => v(section, errors, definition));

    if (_.isEmpty(errors)) {
      errors = null;
    }

    Vue.set(section, "errors", errors);

    return section.errors == null;
  }

  // Hook for making any type specific conversions for JSON.
  // Received JSON is allocated by section type, so it can be modified in place
  exportHook(json) {
    return json;
  }

  // Convert section to JSON
  toJson(section) {
    const json = _.cloneDeep(section);
    json.type = section.type.id;
    delete json.errors;
    return this.exportHook(json);
  }
}
