export function downloadFile(fileUrl) {
  let filename;
  return fetch(fileUrl)
    .then((resp) => {
      const disposition = resp.headers.get("Content-Disposition");
      filename = disposition ? disposition.split(/;(.+)/)[1].split(/=([^;]+)/)[1] : filenameFromUrl(fileUrl);
      if (filename.toLowerCase().startsWith("utf-8''")) {
        filename = decodeURIComponent(filename.replace(/utf-8''/i, ""));
      } else {
        filename = filename.replace(/['"]/g, "");
      }
      return resp.blob();
    })
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    });
}

const filenameFromUrl = (urlStr) => {
  const url = new URL(urlStr);
  const path = url.pathname;
  return path.substring(path.lastIndexOf("/") + 1);
};
