<template>
  <component :is="component" v-bind="componentAttrs" @confirm="onConfirm">
    <report-action-button
      v-uiv-tooltip.hover="t('actions.edit')"
      icon="fluent:edit-16-filled"
      :text="t('actions.edit')"
      @click="onClick"
    />
  </component>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import ConfirmButton from "./confirm-button.vue";
import EventBus from "../events/event-bus";
import Events from "../events/events";
import reportActionButton from "@/components/report-action-button.vue";
import { useI18n } from "@/composables/i18n";
import { useStore } from "@/composables/store";

const { t } = useI18n();

const store = useStore();

const hasChanges = computed(() => {
  return store.getters.hasChanges;
});

const component = computed(() => {
  return hasChanges ? ConfirmButton : "div";
});

const componentAttrs = computed(() => {
  return hasChanges
    ? {
        popoverMessage: t("dashboard_custom.edit_confirm_message"),
      }
    : {};
});

const onConfirm = () => {
  EventBus.emit(Events.editReport);
};

const onClick = () => {
  if (!hasChanges.value) {
    EventBus.emit(Events.editReport);
  }
};
</script>
