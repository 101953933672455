<template lang="pug">
calendar(
  is-expanded
  :class="{'hide-year-nav': hideYearNav}"
  :attributes="customCalendarAttributes", 
  :first-day-of-week="firstDayOfWeek + 1",
  :locale="locale",
  :from-date="fromDate"
  :min-date="minDate",
  :max-date="maxDate",
  @dayclick="onDayClick"
)
  template(v-slot:header-title="headerProps")
    slot(name="title", v-bind="headerProps")
      | {{ headerProps.title }}
</template>

<script lang="ts">
import Calendar from "v-calendar/lib/components/calendar.umd";
import Vue from "vue";
import Component from "vue-class-component";
import i18n from "../i18n";
import { Inject, Prop } from "vue-property-decorator";
import Dropdown from "./dropdown.vue";
import moment from "moment";

@Component({
  components: {
    Calendar,
  },
})
export default class DropdownCalendar extends Vue {
  @Prop({ default: null })
  minDate: Date;

  @Prop({ default: null })
  maxDate: Date;

  @Prop({ default: "single" })
  mode: "single" | "range";

  @Inject("dropdown")
  dropdown: Dropdown;

  rangeStartDate = null;

  readonly firstDayOfWeek = window.zoinedContext.firstDayOfWeek;
  readonly locale = i18n.locale;

  get customCalendarAttributes() {
    return [
      {
        highlight: true,
        dates: this.rangeStartDate,
      },
    ];
  }

  get fromDate() {
    if (this.maxDate && moment().isAfter(this.maxDate)) {
      return this.minDate || this.maxDate;
    }
  }

  get hideYearNav() {
    return this.minDate && this.maxDate && this.minDate?.getFullYear() === this.maxDate?.getFullYear();
  }

  mounted() {
    this.rangeStartDate = null;
  }

  onDayClick({ date }) {
    if (this.mode === "range") {
      if (this.rangeStartDate) {
        this.$emit("select-custom-range", [this.rangeStartDate, date]);
        this.rangeStartDate = null;
        this.dropdown?.close();
      } else {
        this.rangeStartDate = date;
      }
    } else {
      this.$emit("select-date", date);
      this.dropdown?.close();
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-year-nav::v-deep {
  .vc-nav-container > .vc-nav-header {
    display: none;
  }
}
</style>
