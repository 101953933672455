<template lang="pug">
.permissions-editor(v-if="model")
  .permissions-row
    dimension-permissions(
      dimension="store",
      :config="model.dimensions.store",
      :effective-config="effectivePermissions && effectivePermissions.dimensions.store",
      @update="updateDimensionConfig('store', $event)"
    )
  .permissions-row
    dimension-permissions(
      dimension="metrics",
      :config="model.metrics",
      :effective-config="effectivePermissions && effectivePermissions.metrics",
      @update="updateMetricsConfig($event)"
    )
  .permissions-row
    dimension-permissions(
      dimension="groupings",
      :config="model.groupings",
      :effective-config="effectivePermissions && effectivePermissions.groupings",
      @update="updateGroupingsConfig($event)"
    )
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import DimensionPermissions from "@/permissions/dimension-permissions.vue";
import Permissions from "@/model/permissions";

@Component({ components: { DimensionPermissions } })
export default class PermissionsEditor extends Vue {
  @Prop()
  permissions: Permissions;

  @Prop()
  effectivePermissions: Permissions;

  @Prop({ default: "all" })
  defaultPermission: string;

  model: Permissions = null;

  @Watch("permissions", { deep: true, immediate: true })
  onPermissionsChange() {
    this.model = this.sanitizePermissions(this.permissions || {});
  }

  sanitizePermissions(permissions) {
    return {
      dimensions: {
        store: { allow: this.defaultPermission },
        ...(permissions.dimensions || {}),
      },
      metrics: {
        allow: this.defaultPermission,
        ...(permissions.metrics || {}),
      },
      groupings: {
        allow: this.defaultPermission,
        ...(permissions.groupings || {}),
      },
    };
  }

  updateDimensionConfig(dimension, config) {
    const permissions = {
      ...this.model,
      dimensions: {
        ...this.model.dimensions,
        [dimension]: config,
      },
    };
    this.$emit("update", permissions);
  }

  updateMetricsConfig(metrics) {
    const permissions = {
      ...this.model,
      metrics,
    };
    this.$emit("update", permissions);
  }

  updateGroupingsConfig(groupings) {
    const permissions = {
      ...this.model,
      groupings,
    };
    this.$emit("update", permissions);
  }
}
</script>

<style lang="scss" scoped>
.permissions-row {
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
}
</style>
