import Preferences from "../model/preferences";
import { makeApiInstance } from "./instance";

const apiInstance = makeApiInstance({ baseURL: "/api/v1/preferences" });

const api = {
  get: () => apiInstance.get("").then((response) => response.data),
  update: (preferences: Preferences) => apiInstance.put("", { preferences }).then((response) => response.data),
};

export default api;
