export const asArray = (obj) => {
  if (obj) {
    if (Array.isArray(obj)) {
      return obj;
    } else {
      return [obj];
    }
  } else {
    return [];
  }
};
