<template lang="pug">
uiv-modal(:title="'public_share.generate_filtered_links' | i18n", v-model="open", :append-to-body="true")
  div(v-if="links")
    textarea.form-control.links-area(rows="5", readonly="readonly") {{ linksText }}
    .mt-md
      a.cursor-pointer(@click="copyLinks()") {{ "public_share.copy_links" | i18n }}
  spinner(v-else-if="generating")
  div(v-else)
    label {{ "public_share.select_filter" | i18n }}
    select.form-control(v-model="filter")
      option(v-for="filter in filterItems", :value="filter.id") {{ filter.name }}
    .mt-md(v-if="selectedFilter")
      filter-selector(:config="config", :filter="selectedFilter", :excludable="false", @update="onUpdateFilterConfig")
  div(slot="footer")
    div(v-if="links")
      button.btn.btn-default(@click="open = false") {{ "actions.close" | i18n }}
    div(v-else-if="!generating")
      button.btn.btn-default(@click="open = false") {{ "actions.cancel" | i18n }}
      button.btn.btn-primary(@click="generateLinks()", :disabled="!valid") {{ "public_share.generate_links" | i18n }}
</template>

<script lang="ts">
import _ from "lodash";
import { makeApiInstance } from "@/api/instance";
import Filter from "@/model/filter";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import MyReport from "@/model/my-report";
import Report from "@/model/report";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import FilterSelector from "../filter-selector.vue";
import Spinner from "../spinner.vue";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import i18n from "@/i18n";
import toastr from "toastr";

@Component({
  components: {
    FilterSelector,
    Spinner,
  },
})
export default class FilteredLinksModal extends Vue {
  @Prop()
  report: Report;

  @Prop()
  myReport: MyReport;

  open: boolean = false;

  generating: boolean = false;

  filter: string = null;

  config: FilterConfigurationItemMap = null;

  links: Record<string, string> = null;

  get filterItems(): Filter[] {
    return this.$store.getters.getParameters("filters");
  }

  get selectedFilter(): Filter {
    return this.filterItems?.find((f) => f.id === this.filter);
  }

  get valid() {
    return this.config && !_.isEmpty(this.config);
  }

  get linksText() {
    return _.map(this.links, (link, name) => `${name}: ${link}`).join("\n");
  }

  show() {
    this.filter = this.filterItems?.find((f) => f.id === "store") ? "store" : this.filterItems[0]?.id;
    this.config = {};
    this.links = null;
    this.generating = false;
    this.open = true;
  }

  @Watch("filter")
  onFilterChanged() {
    this.config = {};
  }

  onUpdateFilterConfig(config: FilterConfigurationItemMap) {
    console.log(config);
    this.config = config;
  }

  async generateLinks() {
    try {
      this.generating = true;

      const links = await Promise.all(
        _.map(this.config, async (item) => {
          const name = item.name || item.value;
          const filterConfiguration: FlatFilterConfiguration = {
            [this.filter]: { [item.value]: item },
          };
          const config = { filter_configuration: filterConfiguration };
          const link = await this.fetchLink(config);
          return [name, link];
        })
      );
      this.links = _.fromPairs(links);
    } finally {
      this.generating = false;
    }
  }

  fetchLink(config) {
    const url = `/api/v1/reports/${this.report.id}/filtered_link`;
    const params: any = {};
    if (this.myReport?.id) {
      params.my_report_id = this.myReport.id;
    }
    const data: any = { config };
    return makeApiInstance()
      .post(url, data, { params })
      .then((response) => response.data)
      .then(({ link }) => link);
  }

  copyLinks() {
    navigator.clipboard.writeText(this.linksText);
    toastr.success(i18n.t("public_share.links_copied_to_clipboard"));
  }
}
</script>

<style lang="scss" scoped>
.links-area {
  width: 100%;
  max-width: none;
  resize: none;
}
</style>
