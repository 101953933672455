<template lang="pug">
spinner(v-if="loading")
.subscriptions-list-page(v-else)
  .top-actions.pull-right
    button.btn.btn-primary(v-if="isAdmin || isTeamAdmin" @click="gotoManageReports()") {{ 'newsletter.subscription.manage_email_reports' | i18n }} 
  .subscriptions(v-if="!!sortedSubscriptions.length")
    h2 {{ 'newsletter.subscription.my_email_reports' | i18n }}
    table.table.table-hover
      thead
        tr
          th {{"newsletter.title" | i18n }}
          th.frequency {{"newsletter.definition.frequency" | i18n }}
          th.default-parameter 
            | {{"newsletter.definition.default_parameter" | i18n }} 
            i.fa.fa-lg.fa-info-circle(v-uiv-tooltip.bottom="defaultParameterHelpText")
          th.actions
      tbody
        tr(v-for="subscription in sortedSubscriptions" :key="subscription.id")
          td
            | {{ subscription.newsletter_definition.title }}
            span(v-if="subscription.team")  ({{ 'newsletter.definition.team' | i18n}}: {{ subscription.team.name }})
          td.frequency {{ 'newsletter.frequency.' + subscription.newsletter_definition.frequency | i18n }}
          td.default-parameter {{ defaultParameter(subscription.newsletter_definition) }}
          td.actions
            a(@click="preview(subscription.newsletter_definition)") {{ "actions.preview" | i18n}}
            a(v-if="!subscription.team_id" @click="unsubscribe(subscription)") {{ "newsletter.subscription.unsubscribe" | i18n}}

  .definitions(v-if="!!sortedDefinitions.length")
    h2 {{ 'newsletter.subscription.available_email_reports' | i18n }}
    table.table.table-hover
      thead
        tr
          th {{"newsletter.title" | i18n }}
          th.frequency {{"newsletter.definition.frequency" | i18n }}
          th.default-parameter 
            | {{"newsletter.definition.default_parameter" | i18n }} 
            i.fa.fa-lg.fa-info-circle(v-uiv-tooltip.bottom="defaultParameterHelpText")
          th.actions
      tbody
        tr(v-for="definition in sortedDefinitions" :key="definition.id")
          td {{ definition.title }}
          td.frequency {{ 'newsletter.frequency.' + definition.frequency | i18n }}
          td.default-parameter {{ defaultParameter(definition) }}
          td.actions
            a(@click="preview(definition)") {{ "actions.preview" | i18n}}
            a(@click="subscribe(definition)") {{ "newsletter.subscription.subscribe" | i18n}}

  newsletter-preview-modal(ref="newsletterPreviewModal")
</template>

<script lang="ts">
import spinner from "../components/spinner.vue";
import Mutations from "../store/mutations";
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import newsletterSubscriptionsApi from "../api/newsletter-subscriptions-api";
import teamNewsletterSubscriptionsApi from "../api/team-newsletter-subscriptions-api";
import newsletterDefinitionsApi from "../api/newsletter-definitions-api";
import _ from "lodash";
import NewsletterPreviewModal from "../newsletter-definition-editor/newsletter-preview-modal.vue";
import { Ref } from "vue-property-decorator";
import { menuItemKey } from "@/interfaces/menu-item";

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.$store.commit(Mutations.clearTitle);
    next();
  },
  components: {
    spinner,
    NewsletterPreviewModal,
  },
})
export default class SubscriptionsListPage extends Vue {
  @Ref("newsletterPreviewModal")
  newsletterPreviewModal: NewsletterPreviewModal;

  subscriptions = [];
  teamSubscriptions = [];
  definitions = [];
  loading = false;

  get allSubscriptions() {
    return [...this.subscriptions, ...this.teamSubscriptions];
  }

  get sortedSubscriptions() {
    return _.sortBy(this.allSubscriptions, ({ newsletter_definition: { title } }) => title?.toLowerCase());
  }

  get subscribedDefinitionsIds() {
    return this.subscriptions.map(({ newsletter_definition: { id } }) => id);
  }

  get filteredDefinitions() {
    return this.definitions.filter(({ id }) => !this.subscribedDefinitionsIds.includes(id));
  }

  get sortedDefinitions() {
    return _.sortBy(this.filteredDefinitions, ({ title }) => title);
  }

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  get teamsMembershipsAsAdmin(): any[] {
    return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
  }

  get isTeamAdmin() {
    return this.teamsMembershipsAsAdmin.length > 0;
  }

  get groupings() {
    return this.$store.getters.getParameters("grouping");
  }

  get defaultParameterHelpText() {
    return i18n.t("newsletter.definition.default_parameter_help_text");
  }

  defaultParameter({ use_default_parameters, default_parameter }) {
    if (use_default_parameters) {
      const grouping = this.groupings.find((grouping) => menuItemKey(grouping) == default_parameter);
      return grouping?.name || "-";
    } else {
      return i18n.t("newsletter.definition.default_parameter_not_set");
    }
  }

  gotoManageReports() {
    if (this.isAdmin) {
      this.$router.push("/company_admin/newsletter_definitions");
    } else if (this.isTeamAdmin) {
      this.$router.push("/team_admin/newsletter_definitions");
    }
  }

  created() {
    this.$store.commit(Mutations.setTitle, i18n.t("newsletter.subscription.email_reports"));
    this.fetchData();
  }

  async fetchData() {
    this.loading = true;
    [this.subscriptions, this.teamSubscriptions, this.definitions] = await Promise.all([
      newsletterSubscriptionsApi.getAll(),
      teamNewsletterSubscriptionsApi.getAll(),
      newsletterDefinitionsApi.getAll(),
    ]);
    this.loading = false;
  }

  async subscribe(definition) {
    const subscription = await newsletterSubscriptionsApi.create({ newsletter_definition_id: definition.id });
    this.subscriptions = [...this.subscriptions, subscription];
  }

  async unsubscribe(subscription) {
    await newsletterSubscriptionsApi.destroy(subscription);
    this.subscriptions = this.subscriptions.filter((it) => it !== subscription);
  }

  preview(definition) {
    this.newsletterPreviewModal.show(definition);
  }
}
</script>

<style lang="scss" scoped>
td,
th {
  &.frequency {
    width: 20%;
  }
  &.default-parameter {
    width: 20%;
  }
  &.actions {
    width: 20%;
    a {
      margin-right: 10px;
      cursor: pointer;
    }
  }
}
</style>
