import { makeApiInstance } from "./instance";

export default class MessagesApiService {
  getActiveMessages() {
    return makeApiInstance()
      .get("/api/v1/messages/active")
      .then((response) => response.data);
  }

  markMessageViewed(id) {
    return makeApiInstance().delete(`/api/v1/messages/${id}`);
  }
}
