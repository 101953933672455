<template lang="pug">
uiv-modal.newsletter-preview-modal(v-model="open" :title="'newsletter.definition.preview' | i18n")
  spinner(v-if="!errors && !previewUrl")
  .errors(v-if='errors')
    h3 {{ 'newsletter.definition.messages.preview_errors' | i18n }}
    | {{errors}}
  .preview-in-progress(v-if='!errors && !previewUrl') {{ 'newsletter.definition.messages.preview_generating' | i18n }}
  .preview-content(:class="{loaded: previewUrl }")
    .send-preview(v-if='emailSent') {{ 'newsletter.definition.messages.email_sent' | i18n }}
    iframe(ref="iframe" :src='previewUrl', seamless: true)

  div(slot="footer")
    button.btn.btn-primary(@click='sendPreview()', v-if='!errors && previewUrl && !emailSent', :disabled='sendingEmail')
      | {{ 'newsletter.definition.actions.send_preview' | i18n }}
    button.btn.btn-warning(@click="cancel()") {{ 'actions.cancel' | i18n }}
</template>

<script lang="ts">
import axios from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import spinner from "../components/spinner.vue";

@Component({
  components: {
    spinner,
  },
})
export default class NewsletterPreviewModal extends Vue {
  @Ref()
  iframe: HTMLIFrameElement;

  open = false;
  sendingEmail = false;
  emailSent = false;
  token = null;
  previewUrl = null;
  errors = null;

  show(definition) {
    this.open = true;
    this.sendingEmail = false;
    this.emailSent = false;
    this.token = null;
    this.previewUrl = null;
    this.errors = null;

    const resultCheck = () => {
      return axios
        .get("/newsletter_previews/" + this.token + ".json")
        .then((result) => {
          if (result.data.status === "ok") {
            this.previewUrl = result.data.preview_url;
            this.iframe.contentWindow.focus();
          } else if (this.open) {
            return window.setTimeout(resultCheck, 1000);
          }
        })
        .catch((error) => {
          if (error.response) {
            this.errors = error.response.data.error;
          } else {
            throw error;
          }
        });
    };

    const company_id = window.zoinedContext.companyId;

    newsletterDefinitionsApi
      .preview({ company_id }, definition)
      .then((data) => {
        this.token = data.token;
        resultCheck();
      })
      .catch((error) => {
        if (error.response) {
          this.errors = error.response.data.error;
        } else {
          throw error;
        }
      });
  }

  sendPreview() {
    this.sendingEmail = true;
    return axios.post("/newsletter_previews/" + this.token + "/send.json").then(() => {
      this.sendingEmail = false;
      this.emailSent = true;
    });
  }

  cancel() {
    this.open = false;
  }
}
</script>

<style lang="scss" scoped>
.preview-content {
  &.loaded {
    height: 75vh;
  }
}
</style>
