<template lang="pug">
spinner(v-if="!teams")
table.table.table-hover(v-else)
  thead
    tr
      th {{ 'activerecord.attributes.team.name' | i18n }}
      th {{ 'company_admin.teams.members_count' | i18n }}
      th {{ 'company_admin.teams.newsletter_subscriptions_count' | i18n }}
      th
  tbody
    tr(v-if="teams.length === 0")
      td(colspan="4") {{ 'table.no_results' | i18n }}
    tr(v-for="team in teams" :key="team.id")
      td {{ team.name }}
      td {{ team.members_count }}
      td {{ team.newsletter_subscriptions_count }}
      td.text-right
        a.mr-sm(@click="$emit('edit', team)") {{ 'actions.edit' | i18n }}
        a(@click="$emit('goto-members', team)") {{ 'company_admin.teams.members' | i18n }}
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Team from '../model/team';
import Spinner from "../components/spinner.vue"

@Component({
  components: {
    Spinner
  }
})
export default class TeamTable extends Vue {
  @Prop()
  teams: Team[];
}
</script>
