<template lang="pug">
.dashboard(v-if="loaded")
  dashboard-view(:key="$route.path")
spinner(v-else)
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import spinner from "../components/spinner.vue";
import Actions from "../store/actions";
import Mutations from "../store/mutations";
import DashboardView from "./dashboard-view.vue";
import toaster from "toastr";
import i18n from "../i18n";
import { sendMixpanelEvent } from "@/mixpanel";

@Component({
  components: {
    DashboardView,
    spinner,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearReportContext);
    next();
  },
})
export default class ReportPage extends Vue {
  @Prop()
  name: string;

  @Prop()
  myReportId: string;

  get reportContextState() {
    return this.$store.state.reportContext.state;
  }

  get loaded() {
    return this.reportContextState === "loaded";
  }

  created() {
    // Do we have reportContext passed from page request
    if (this.reportContextState === "initial" && window.reportContext) {
      this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
    }
    // If not then fetch context
    else {
      return this.$store.dispatch(Actions.fetchSystemDashboard).catch((error) => {
        if (error?.response?.status === 404) {
          this.$router.push("/");
        } else {
          toaster.error(i18n.t("errors.loading_failed"));
          throw error;
        }
      });
    }

    sendMixpanelEvent("Opened Dashboard");
  }
}
</script>
