import { makeApiInstance } from "@/api/instance";
import UserPartnerRole from "@/model/user-partner-role";
import { useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { Ref } from "vue";

export type UseUserPartnerRolesParams = {
  search: string;
  sort: string;
  page: number;
  per_page: number;
};

export const useUserPartnerRoles = (params: Ref<UseUserPartnerRolesParams>) => {
  return useQuery<{ data: UserPartnerRole[]; total: number }>({
    queryKey: ["userPartnerRoles", params],
    queryFn: () =>
      makeApiInstance()
        .get("/api/partner_admin/user_partner_roles", { params: params.value })
        .then((res) => {
          return {
            data: res.data,
            total: Number.parseInt(res.headers["total"]),
          };
        }),
  });
};

export const useInviteUserPartnerRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (attrs: UserPartnerRole) =>
      makeApiInstance().post("/api/partner_admin/user_partner_roles", { user_partner_role: attrs }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userPartnerRoles"] });
    },
  });
};

export const useDeleteUserPartnerRoleMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: number) => makeApiInstance().delete(`/api/partner_admin/user_partner_roles/${id}`),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["userPartnerRoles"] });
    },
  });
};
