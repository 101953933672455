<template lang="pug">
span
  dropdown(:open="open")
    template(slot="toggle")
      .btn-group
        button.btn.btn-primary(type="button" @click="newDashboard()") {{ 'dashboard_custom.new_dashboard' | i18n }}
        button.btn.btn-primary(type="button" @click="open=!open" @close="open=false" v-if="enableTemplates")
          span.caret
    template(slot="menu")
      li
        a(@click="newDashboard()") {{ 'dashboard_custom.create_empty_dashboard' | i18n }}
        a(@click="newDashboardFromTemplate()") {{ 'dashboard_custom.create_dashboard_from_template' | i18n }}
  dashboard-from-template-modal(ref="dashboardFromTemplateModal")
</template>

<script lang="ts">
import Vue from "vue";
import dropdown from "./dropdown.vue";
import DashboardFromTemplateModal from "../custom-dashboard-editor/dashboard-from-template-modal.vue";

export default Vue.extend({
  props: {
    enableTemplates: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    dropdown,
    DashboardFromTemplateModal,
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    newDashboard() {
      this.open = false;
      location.href = "/dashboards/new";
    },
    newDashboardFromTemplate() {
      this.open = false;
      this.toggleDashboardsDropdown();
      this.$refs.dashboardFromTemplateModal.show();
    },
    toggleDashboardsDropdown() {
      const dropdown = document.querySelector("#dashboards-dropdown");
      dropdown.classList.remove("open");
    },
  },
});
</script>
