import Vue from "vue";

const vue = new Vue();

const EventBus = {
  emit(event, ...args) {
    console.debug(`[EventBus] emit ${event}`);
    vue.$emit(event, args);
  },

  subscribe(event, handler) {
    console.debug(`[EventBus] subscribe ${event}`);
    vue.$on(event, handler);
  },

  unsubscribe(event, handler = null) {
    console.debug(`[EventBus] unsubscribe ${event}`);
    vue.$off(event, handler);
  },
};

export default EventBus;
