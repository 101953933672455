import { reportTypes } from "main/custom-report/report-types";
import ReportConfig from "./report-config";
import _ from "lodash";

export interface ReportTypeFilterDefinition {
  default?: any;
  options?: any;
  enabled?: any;
}

export default interface ReportType {
  config: ReportConfig;
  defaults?: Record<string, any>;
  filters?: Record<string, ReportTypeFilterDefinition>;
  // eslint-disable-next-line no-unused-vars
  validate?: (flatFilterConfiguration: any) => boolean;
}

export const reportTypeActiveFilters = (reportType: ReportType, flatFilterConfiguration) => {
  return reportType.config.filters.filter((filter) => {
    const enabled = _.get(reportType, `filters.${filter}.enabled`, () => true);
    return enabled(flatFilterConfiguration);
  });
};

export const reportTypeFromReportConfig = (reportConfig: ReportConfig): ReportType => {
  if (reportConfig.report_type) {
    return reportTypes.find((reportType) => reportType.config.report_type === reportConfig.report_type);
  } else {
    return {
      config: reportConfig,
    };
  }
};
