import { useWindowSize } from "@vueuse/core";
import { computed } from "vue";

const BREAKPOINT = 992;

export const useIsMobile = () => {
  const { width } = useWindowSize();

  return computed(() => {
    return width.value < BREAKPOINT;
  });
};
