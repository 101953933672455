<template lang="pug">
report-view(v-if="loaded" :key="$route.path")
</template>

<script lang="ts">
import ReportView from "./report-view.vue";
import Vue from "vue";
import Component from "vue-class-component";
import spinner from "../components/spinner.vue";
import Mutations from "@/store/mutations";

@Component({
  components: {
    ReportView,
    spinner,
  },
})
export default class ReportPdfPage extends Vue {
  get reportContextState() {
    return this.$store.state.reportContext.state;
  }

  get loaded() {
    return this.reportContextState === "loaded";
  }

  created() {
    this.$store.commit(Mutations.fetchReportContextSuccess, window.reportContext);
  }
}
</script>
