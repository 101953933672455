import RootState from "../root-state";
import { Module } from "vuex";
import Preferences, { DetailChartMetricPreferences } from "../../model/preferences";
import preferencesApi from "../../api/preferences-api";

export interface PreferencesState {
  data: Preferences;
}

const preferences: Module<PreferencesState, RootState> = {
  namespaced: true,
  state: () => ({
    data: {},
  }),
  actions: {
    updateDetailChartMetricPreferences: (
      { commit, state },
      detailChartMetricPreferences: DetailChartMetricPreferences
    ) => {
      commit("setDetailChartMetricPreferences", detailChartMetricPreferences);
      preferencesApi.update(state.data);
    },
  },

  mutations: {
    setPreferences: (state, data: Preferences) => {
      Object.assign(state, { data });
    },
    setDetailChartMetricPreferences: (state, detail_chart_metric_preferences: DetailChartMetricPreferences) => {
      const data = { ...state.data, detail_chart_metric_preferences };
      Object.assign(state, { data });
    },
  },
};

export default preferences;
