<template>
  <div class="highcharts" ref="container"></div>
</template>

<script lang="ts" setup>
import { useResizeObserver } from "@vueuse/core";
import Highcharts from "highcharts";
import { onMounted, onBeforeUnmount, ref, watch } from "vue";

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  Highcharts: {
    type: Object,
    required: false,
  },
  cleanUpdate: {
    type: Boolean,
    default: false,
  },
});

const container = ref(null);

const chart = ref(null);

const getChart = () => chart.value;

useResizeObserver(container, () => {
  chart.value?.reflow();
});

const init = () => {
  if (!getChart() && props.options) {
    let _Highcharts = props.Highcharts || Highcharts;
    if (_Highcharts.product == "Highstock") {
      chart.value = new _Highcharts.stockChart(container.value, props.options);
    } else {
      chart.value = new _Highcharts.Chart(container.value, props.options);
    }
  }
};

onMounted(() => {
  if (!getChart() && props.options) {
    init();
  }
});

onBeforeUnmount(() => {
  if (getChart()) {
    getChart().destroy();
  }
});

watch(
  () => props.options,
  (options) => {
    if (props.cleanUpdate && getChart()) {
      getChart().destroy();
      chart.value = null;
    }
    if (!getChart() && options) {
      init();
    } else {
      getChart().update(props.options, true, true);
    }
  }
);
</script>
