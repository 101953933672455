<template lang="pug">
div
  #layout-container.report-editor
    .row
      .col-md-4
        .form-group
          label(for="name") {{ "dashboard_custom.report_editor.title" | i18n }}
          input#name(type="text", v-model="myReport.name")
    .row(v-if="isAdmin || isTeamAdmin")
      .col-md-12
        report-owner-selector(:my-report="myReport", @update-my-report="updateMyReport")
    .row(v-if="companyReport")
      .col-md-12
        .form-group
          div
            label(for="restricted") {{ "activerecord.attributes.my_report.restrict_access" | i18n }}
          .btn-group.btn-multiple
            .btn.btn-primary(:class="{ active: myReport.restrict_access }", @click="setRestricted(true)") Yes
            .btn.btn-primary(:class="{ active: !myReport.restrict_access }", @click="setRestricted(false)") No
    .row(v-if="companyReport && myReport.restrict_access")
      .col-md-12 
        user-role-pill-list(:roles="permittedRoles", @update="updatePermittedRoles($event)")
        team-pill-list(:teams="permittedTeams", @update="updatePermittedTeams($event)")
    .row(v-if="partnerReport")
      .col-md-12
        visible-to-customers-selector(v-model="myReport.visible_to_customers")
    .row
      .col-md-12
        h4 {{ "dashboard_custom.report_editor.filters" | i18n }}

    .row
      .col-md-12
        .time-filters.flex-row.align-items-center.flex-wrap.gap-md.column-gap-xxl.mb-md
          time-period-dropdown(
            v-if="isFilterEnabled('date')",
            :available-items="options.time_ranges",
            :available-series-types="options.seriesTypes",
            :value="filterConfiguration.time.selection",
            @update="updateSelection($event)"
          )
          comparison-pill-list(
            v-if="isFilterEnabled('comparisons')",
            :comparisons="filterConfiguration.time.comparisons",
            @update="updateComparisons"
          )
          business-hours-selector(
            v-if="isFilterEnabled('limit_to_business_hours')",
            :value="filterConfiguration.time.limit_to_business_hours",
            @update="updateBusinessHours($event)"
          )
        widget-filters-section(
          :filter-configuration="filterConfiguration",
          :report-config="report.config",
          @update="updateFilterConfiguration"
        )
        hr.mt-0
        div(v-if="isFilterEnabled('basket_filters')")
          basket-filters-section(:value="filterConfiguration.basket_filters" :items="options.filters" @input="updateBasketFilters")
          hr.mt-0
        .flex-row.align-items-center.flex-wrap.gap-md.column-gap-xxl.mb-md
          filterset-selector(:config="filterConfiguration.filters", @update="updateFiltersConfig")
          show-filterset-name-toggle(v-model="myReport.control_state.show_filterset_name", v-if="hasEnabledFilterSets")
    .row
      .col-md-12
        .flex-row.align-items-center.flex-wrap.gap-lg.column-gap-xxl.mb-md
          filter-selector(
            :config="filters[filter.id]",
            :filter="filter",
            @update="updateFilter(filter.id, $event)",
            v-for="filter in options.filters",
            :key="filter.id"
          )
    .row
      .col-md-12
        h4 {{ "actions.preview" | i18n }}

    .row.mb-md(v-for="component in report.config.components")
      div(:class="'col-md-' + (component.span || 12)")
        zoined-report-component(
          :component="component",
          :filter-configuration="flatFilterConfiguration",
          :chart-options="chartOptions",
          :table-config="tableConfig",
          :preview="true",
          @filter-configuration-updated="updateFilterConfiguration"
          @chart-options-updated="updateChartOptions"
          @table-config-updated="updateTableConfig"
        )

    .row.buttons
      .col-md-12
        .pull-right
          button.pull-right.btn.btn-primary(@click="save", :disabled="submitDisabled") {{ "actions.save" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import _ from "lodash";
import { refreshFilterTimes, convertToNewFilterConfiguration, flatFilterConfiguration } from "../lib/filter-util";
import confirmButton from "../components/confirm-button.vue";
import userRolePillList from "../components/user-role-pill-list.vue";
import teamPillList from "../components/team-pill-list.vue";
import comparisonPillList from "../components/comparison-pill-list.vue";
import filterSelector from "../components/filter-selector.vue";
import timePeriodDropdown from "../components/time-period-dropdown.vue";
import businessHoursSelector from "../components/business-hours-selector.vue";
import filtersetSelector from "../components/filterset-selector.vue";
import showFiltersetNameToggle from "../components/show-filterset-name-toggle.vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import WidgetFiltersSection from "../flyover-filters/widget-filters-section.vue";
import FilterConfiguration from "../model/filter-configuration";
import i18n from "../i18n";
import ReportOwnerSelector from "../components/report-owner-selector.vue";
import MyReport from "../model/my-report";
import AnalyticsApiService from "../api/analytics-api-service";
import Actions from "../store/actions";
import ChartOptions from "../model/chart-options";
import TableConfig from "../model/table-config";
import { showOverlay, hideOverlay } from "../ui/overlay";
import BasketFiltersSection from "@/flyover-filters/basket-filters-section.vue";
import VisibleToCustomersSelector from "@/components/visible-to-customers-selector.vue";

@Component({
  components: {
    confirmButton,
    userRolePillList,
    teamPillList,
    comparisonPillList,
    filterSelector,
    timePeriodDropdown,
    businessHoursSelector,
    filtersetSelector,
    showFiltersetNameToggle,
    zoinedReportComponent,
    WidgetFiltersSection,
    ReportOwnerSelector,
    BasketFiltersSection,
    VisibleToCustomersSelector,
  },
})
export default class CustomDashboardEditor extends Vue {
  @Prop()
  defaultReport: any;

  @Prop()
  defaultMyReport: any;

  report: any = null;
  myReport: any = null;
  permittedRoles: any = null;
  permittedTeams: any = null;
  isAdmin: boolean = null;
  selectedReportType = null;
  initialData = null;

  get analyticsApi() {
    return new AnalyticsApiService();
  }

  get companyReport() {
    return !this.myReport.user_id && !this.myReport.team_id && !this.myReport.partner_id;
  }

  get partnerReport() {
    return !!this.myReport.partner_id;
  }

  get teamsAsAdmin() {
    return window.zoinedContext.current_role.team_memberships.filter(({ role }) => role === "admin");
  }

  get isTeamAdmin() {
    return this.teamsAsAdmin.length > 0;
  }

  get submitDisabled() {
    return this.myReport.name.length == 0;
  }

  get flatFilterConfiguration() {
    return flatFilterConfiguration(this.filterConfiguration);
  }

  get filterConfiguration() {
    return _.cloneDeep(this.myReport.filters);
  }

  get chartOptions() {
    return _.cloneDeep(this.myReport.control_state);
  }

  get tableConfig() {
    return _.cloneDeep(this.myReport.table_config);
  }

  get options() {
    const sort = this.$store.getters.getParameters("sort") || [];
    const seriesTypes = window.zoinedContext?.budgets && ["actual", ...Object.keys(window.zoinedContext.budgets)];
    const groupings = _.clone(this.$store.getters.getParameters("grouping"));
    const metrics = this.$store.state.parameters.metrics.all;
    const snippets = this.$store.state.parameters.metrics.all?.map((item) => ({ ...item, snippet: true }));
    const filters = this.$store.state.parameters.filters.all;
    const time_ranges = this.$store.state.parameters.timePeriods.all;

    return {
      metrics: metrics || [],
      snippets: snippets || [],
      time_ranges: time_ranges || [],
      filters: filters || [],
      sort,
      seriesTypes,
      groupings,
    };
  }

  get filters() {
    return _.reduce(
      this.filterConfiguration.filters.filters,
      (result, config, filter) => {
        return {
          ...result,
          [filter]: _.fromPairs(
            _.map(config, ({ enabled, exclude, value, name }, key) => [
              key,
              {
                value: key,
                enabled,
                exclude: !!exclude,
                name: name || value || key,
              },
            ])
          ),
        };
      },
      {}
    );
  }

  get hasEnabledFilterSets() {
    let { sets } = this.filterConfiguration.filters;
    sets = sets || [];
    return sets.filter(({ enabled }) => enabled).length > 0;
  }

  get saveData() {
    return _.cloneDeep({
      name: this.myReport.name,
      report_name: this.report.name,
      filters: this.myReport.filters,
      restrict_access: this.myReport.restrict_access,
      visible_to_customers: this.myReport.visible_to_customers,
      control_state: this.myReport.control_state,
      table_config: this.myReport.table_config,
      permitted_role_ids: this.myReport.restrict_access ? this.permittedRoles.map(({ id }) => id) : [],
      permitted_team_ids: this.myReport.restrict_access ? this.permittedTeams.map(({ id }) => id) : [],
      user_id: this.myReport.user_id,
      team_id: this.myReport.team_id,
      partner_id: this.myReport.partner_id,
      company_id: this.myReport.company_id,
    }) as MyReport;
  }

  get hasChanges() {
    console.log(this.initialData, this.saveData);
    return this.initialData && !_.isEqual(this.initialData, this.saveData);
  }

  setRestricted(restricted) {
    Vue.set(this.myReport, "restrict_access", restricted);
  }

  isFilterEnabled(filter) {
    return this.report.config.filters?.includes(filter);
  }

  updateSelection(selection) {
    Vue.set(this.myReport.filters.time, "selection", selection);
  }

  updateComparisons(comparisons) {
    Vue.set(this.myReport.filters.time, "comparisons", comparisons);
  }

  updateBusinessHours(limitToBusinessHours) {
    Vue.set(this.myReport.filters.time, "limit_to_business_hours", limitToBusinessHours);
  }

  updateFiltersConfig(config) {
    Vue.set(this.myReport.filters, "filters", config);
  }

  updateBasketFilters(config) {
    Vue.set(this.myReport.filters, "basket_filters", config);
  }

  updateMyReport(myReport: MyReport) {
    Vue.set(this, "myReport", myReport);
  }

  updateFilter(filter, filterConfig: FilterConfigurationItemMap) {
    if (!_.isEmpty(filterConfig)) {
      const config = _.reduce(
        _.values(filterConfig),
        (result, { enabled, exclude, value, name, wildcard }) => ({
          ...result,
          [value]: { enabled, exclude: !!exclude, value, wildcard, name: name || value },
        }),
        {}
      );
      Vue.set(this.myReport.filters.filters.filters, filter, config);
    } else {
      Vue.delete(this.myReport.filters.filters.filters, filter);
    }
  }

  updateFilterConfiguration(filterConfiguration: FilterConfiguration) {
    filterConfiguration = convertToNewFilterConfiguration(filterConfiguration);
    Vue.set(this.myReport, "filters", filterConfiguration);
  }

  updateChartOptions(chartOptions: ChartOptions) {
    Vue.set(this.myReport, "control_state", chartOptions);
  }

  updateTableConfig(tableConfig: TableConfig) {
    Vue.set(this.myReport, "table_config", tableConfig);
  }

  updatePermittedRoles(roles) {
    this.permittedRoles = roles;
  }

  updatePermittedTeams(teams) {
    this.permittedTeams = teams;
  }

  save() {
    showOverlay(i18n.t("please_wait"));

    const data = (this.initialData = this.saveData);

    const action = this.myReport.id ? this.analyticsApi.update(this.myReport.id, data) : this.analyticsApi.create(data);

    return action
      .then(({ report_name, id }) => {
        this.$router.push({ name: "report", params: { name: report_name, myReportId: id.toString() } });
        this.$store.dispatch(Actions.fetchNavigation);
      })
      .finally(() => {
        hideOverlay();
      });
  }

  created() {
    const myReport = _.cloneDeep(this.defaultMyReport);
    const permittedRoles = myReport.permitted_roles || [];
    const permittedTeams = myReport.permitted_teams || [];

    myReport.restrict_access = !!myReport.restrict_access;
    myReport.control_state = myReport.control_state || {};

    myReport.filters = myReport.filters || {};
    myReport.filters = convertToNewFilterConfiguration(myReport.filters);
    myReport.filters.time = refreshFilterTimes(myReport.filters.time);
    myReport.filters.raw_filters = myReport.filters.raw_filters || {};

    this.report = this.defaultReport;
    this.myReport = myReport;
    this.isAdmin = !!window.zoinedContext?.isAdmin;

    this.permittedRoles = permittedRoles;
    this.permittedTeams = permittedTeams;

    this.initialData = this.saveData;
  }
}
</script>

<style scoped lang="scss">
#layout-container {
  margin-bottom: 8rem; // need to have space for the Olark-tab in bottom of the page
}

.buttons {
  margin-top: 30px;
}

.show {
  text-align: center;

  a:hover {
    cursor: pointer;
  }
}

.row h4 {
  margin: 20px 0;
}
</style>
