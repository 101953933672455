<template lang="pug">
span.filter-selector
  pill-list(
    :title="title",
    :searchable="searchable",
    :typeahead="typeahead",
    :multi-select="true",
    :excludable="excludable",
    :wildcard="wildcard",
    :items="items",
    :defaults="defaultItems",
    :availableItems="availableItems",
    :disabled="disabled",
    @update="onUpdate($event.items)",
    @search="onSearch($event)",
    @toggleMenu="onToggleMenu()"
  )
</template>

<script lang="ts">
import _ from "lodash";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";
import Filter from "../model/filter";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import Vue from "vue";
import Component from "vue-class-component";
import { Emit, Prop } from "vue-property-decorator";
import I18n from "../i18n";
import pillList from "./pill-list.vue";
import { makeApiInstance } from "../api/instance";

@Component({
  components: {
    pillList,
  },
})
export default class FilterSelector extends Vue {
  @Prop()
  config: FilterConfigurationItemMap;

  @Prop()
  defaultConfig: FilterConfigurationItemMap;

  @Prop()
  filter: Filter;

  @Prop({ default: true })
  excludable: boolean;

  @Prop({ default: false })
  disabled: boolean;

  availableFilters = [];

  get title() {
    return this.filter.name || I18n.t(`filter.config.${this.filter.id}`);
  }

  get searchable() {
    return this.filter.filter_count > 10;
  }

  get typeahead() {
    return this.filter.filter_count > 100;
  }

  get wildcard() {
    return this.searchable;
  }

  get showId() {
    return this.filter.show_id;
  }

  get availableItems(): MenuItem[] {
    if (this.availableFilters) {
      if (this.showId) {
        return this.availableFilters.map((f) => ({
          ...f,
          name: `${f.name} (${f.id})`,
        }));
      } else {
        return this.availableFilters;
      }
    } else {
      return null;
    }
  }

  get items(): PillItem[] {
    let items =
      this.config &&
      _.map(this.config, ({ name, value, ...rest }, key) => {
        const item = this.findAvailableItem(value);
        return {
          ...rest,
          value: key,
          name: item?.name || name || value,
        };
      });

    if (this.filter.id == "hour") {
      items = _.sortBy(items, "value");
    }
    return items;
  }

  get defaultItems(): PillItem[] {
    return (
      this.defaultConfig &&
      _.map(this.defaultConfig, ({ name, value, ...rest }, key) => ({
        ...rest,
        value: key,
        name: name || value,
      }))
    );
  }

  @Emit("update")
  onUpdate(items: PillItem[]): FilterConfigurationItemMap {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item.value]: item,
      }),
      {}
    );
  }

  onSearch(q) {
    if (this.typeahead) {
      this.availableFilters = [];
      if (q && q.length > 0) {
        this.fetchItems(q);
      }
    }
  }

  onToggleMenu() {
    if (!this.typeahead) {
      this.fetchItems();
    }
  }

  fetchItems(q = "") {
    this.availableFilters = null;
    const url = `/api/v1/parameters/${this.filter.id}?q=${q}`;

    return makeApiInstance()
      .get(url)
      .then((response) => {
        this.availableFilters = response.data;
      });
  }

  findAvailableItem(id) {
    return this.availableItems?.find((item) => menuItemKey(item) == id);
  }
}
</script>
