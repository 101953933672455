window.jqDebug = function(msg) {
    var jqDebugP1 = getUrlParams("jqDebug");
    if (jqDebugP1 == 1) {
        console.log(msg);
        $("body").addClass( "jqDebug" );
    }
};

window.getUrlParams = function(k) {
    var p={};
    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){p[k]=v});
    return k?p[k]:p;
};

window.getCookie = function(name) {
    var vParams = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (vParams) return vParams[2];
    jqDebug("getCookie: " + vParams);
};

window.setCookie = function(name, value) {
    var expiration_date = new Date();
    var cookie_string = '';
    expiration_date.setFullYear(expiration_date.getFullYear() + 1);
    cookie_string = name + "=" + value + "; path=/; expires=" + expiration_date.toUTCString();
    document.cookie = cookie_string;
    jqDebug("setCookie: " + cookie_string);
};

window.setSessionCookie = function(name, value, expiration) {
    var cookie_string = name + "=" + value + "; path=/; expires=" + expiration;
    document.cookie = cookie_string;
    jqDebug("setSessionCookie: " + cookie_string);
};