<template>
  <confirm-button v-if="shouldConfirm" :disabled="!hasChanges" :popover-message="popoverMessage" @confirm="saveReport">
    <button class="btn btn-emphasis" :disabled="!hasChanges">{{ t("actions.save") }}</button>
  </confirm-button>
  <button v-else class="btn btn-emphasis" :disabled="!hasChanges" @click="saveReport">{{ t("actions.save") }}</button>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import ConfirmButton from "@/components/confirm-button.vue";
import EventBus from "@/events/event-bus";
import Events from "@/events/events";
import { useStore } from "@/composables/store";
import { useI18n } from "@/composables/i18n";

const store = useStore();

const { t } = useI18n();

const reportContext = computed(() => store.getters.getReportContext);

const myReport = computed(() => reportContext.value?.my_report);

const popoverMessage = computed(() => {
  if (myReport.value?.dashboard) {
    return t("dashboard_custom.save_confirm_message");
  } else {
    return t("custom_report.save_confirm_message");
  }
});

const shouldConfirm = computed(() => {
  return myReport.value?.id;
});

const hasChanges = computed(() => store.getters.hasChanges);

const saveReport = () => {
  EventBus.emit(Events.saveReport);
};
</script>
