import { hideOverlay, showOverlay } from "@/ui/overlay";

export const useOverlay = (message: string) => {
  const show = () => {
    showOverlay(message);
  };
  const hide = () => {
    hideOverlay();
  };
  return {
    show,
    hide,
  };
};
