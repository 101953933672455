<template lang="pug">
.team-membership-table(v-if="teamMemberships")
  table(v-if="teamMemberships.length > 0")
    thead
      tr
        th {{ "attributes.name" | i18n }}
    tbody
      tr(v-for="item in teamMemberships")
        td {{ teamName(item.team_id) }}
        td
          .checkbox
            label
              input(type="checkbox", :checked="item.role == 'admin'", @change="toggleItemAdmin(item)")
              | {{ "roles.admin" | i18n }}
        td
          a(@click="removeItem(item)")
            i.fa.fa-trash
  div(v-if="availableTeams.length > 0")
    uiv-dropdown
      button.btn.btn-primary.dropdown-toggle(type="button") {{ "actions.add" | i18n }}
      template(slot="dropdown")
        li(v-for="team in availableTeams")
          a(href="#", @click="addTeam(team)") {{ team.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TeamMembershipTable extends Vue {
  @Prop()
  teamMemberships: any[];

  @Prop()
  teams: any[];

  get availableTeams() {
    const memberTeamIds = this.teamMemberships.map(({ team_id }) => team_id);

    return this.teams.filter(({ id }) => !memberTeamIds.includes(id));
  }

  teamName(teamId) {
    return this.teams.find(({ id }) => id === teamId)?.name;
  }

  toggleItemAdmin(item) {
    const items = this.teamMemberships.map((it) =>
      it === item ? { ...it, role: it.role === "admin" ? "member" : "admin" } : it
    );
    this.$emit("update", items);
  }

  removeItem(item) {
    const items = this.teamMemberships.filter((it) => it !== item);
    this.$emit("update", items);
  }

  addTeam(team) {
    const items = [...this.teamMemberships, { team_id: team.id, role: "member" }];
    this.$emit("update", items);
  }
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;

  td,
  th {
    &:first-child {
      width: 50%;
    }

    &:not(:first-child) {
      width: 25%;
    }
  }
}

.dropdown-content {
  margin: 0 10px;
}
</style>
