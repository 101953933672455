<template lang="pug">
filter-dropdown(:title="'filters.no_of_past_weeks' | i18n" :value="value" :available-items="availableItems" @update="onUpdate")
</template>

<script lang="ts">
import MenuItem from "../interfaces/menu-item";
import Comparison from "../model/comparison";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterDropdown from "./filter-dropdown.vue";

@Component({ components: { FilterDropdown } })
export default class NoOfPastWeeksComparisonDropdown extends Vue {
  @Prop()
  comparison: Comparison;

  get value(): string {
    return this.comparison && this.comparison.text?.toString();
  }

  get availableItems(): MenuItem[] {
    return Array.from(Array(12).keys())
      .map((i) => (i + 1).toString())
      .map((key) => ({ key, name: key }));
  }

  onUpdate(weeks: string) {
    const comparison: Comparison = { type: undefined, text: weeks, enabled: true };
    this.$emit("update", comparison);
  }
}
</script>
