<template lang="pug">
uiv-modal(:title="'actions.save_as' | i18n", v-model="open")
  spinner(v-if="saving")
  form.form(:class="{ disabled: saving }")
    .form-group
      input.form-control(
        type="text",
        v-model="report.name",
        :placeholder="'analytics.my_report_save_as_popup.placeholder' | i18n",
        autofocus="true",
        style="max-width: 100%"
      )
      div(v-if="isAdmin")
        .checkbox
          label(for="shared")
            input#shared(type="checkbox", name="shared", v-model="report.shared")
            | {{ "activerecord.attributes.my_report.shared" | i18n }}
        .checkbox
          defaultParameterSelector(v-model="report")
        .checkbox(v-if="report.shared")
          label(for="restrict")
            input#restrict(type="checkbox", name="restrict", v-model="report.restrict_access")
            | {{ "activerecord.attributes.my_report.restrict_access" | i18n }}
        div(v-if="report.shared && report.restrict_access")
          user-role-pill-list(:roles="permittedRoles", @update="updatePermittedRoles")
          team-pill-list(:teams="permittedTeams", @update="updatePermittedTeams")

  div(slot="footer")
    button.btn.btn-default(@click="open = false") {{ "actions.cancel" | i18n }}
    button.btn.btn-primary(@click="save()", :disabled="saving || !report.name") {{ "actions.save" | i18n }}
</template>

<script lang="ts">
import ChartOptions from "../model/chart-options";
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Spinner from "../components/spinner.vue";
import UserRolePillList from "../components/user-role-pill-list.vue";
import TeamPillList from "../components/team-pill-list.vue";
import TableConfig from "../model/table-config";
import DefaultParameterSelector from "../components/default-parameter-selector.vue";
import { makeApiInstance } from "../api/instance";

@Component({
  components: {
    Spinner,
    UserRolePillList,
    TeamPillList,
    DefaultParameterSelector,
  },
})
export default class SaveReportModal extends Vue {
  @Prop()
  myReport: any;

  @Prop()
  filterConfiguration: FilterConfiguration;

  @Prop()
  chartOptions: ChartOptions;

  @Prop()
  tableConfig: TableConfig;

  open = false;
  saving = false;
  report: any = {};
  permittedRoles: any[] = null;
  permittedTeams: any[] = null;

  get isAdmin() {
    return window.zoinedContext.isAdmin;
  }

  show() {
    this.open = true;
    this.saving = false;
    this.report = {
      name: this.myReport.name,
      shared: this.myReport.shared,
      use_default_parameters: this.myReport.use_default_parameters,
      default_parameter: this.myReport.default_parameter,
      restrict_access: this.myReport.restrict_access,
      report_name: this.myReport.report_name,
    };
    this.permittedRoles = this.myReport.permitted_roles;
    this.permittedTeams = this.myReport.permitted_teams;
  }

  updatePermittedRoles(roles) {
    this.permittedRoles = roles;
  }

  updatePermittedTeams(teams) {
    this.permittedTeams = teams;
  }

  save() {
    this.saving = true;
    const my_report = {
      ...this.report,
      report_name: this.myReport.report_name,
      filters_json: JSON.stringify(this.filterConfiguration),
      control_state: this.chartOptions,
      table_config: this.tableConfig,
      id: this.myReport.id,
      permitted_role_ids: this.report.restrict_access ? this.permittedRoles.map(({ id }) => id) : [],
      permitted_team_ids: this.report.restrict_access ? this.permittedTeams.map(({ id }) => id) : [],
    };
    my_report.shared = my_report.shared ? "1" : "0";

    return makeApiInstance()
      .post("/analytics/save_my_report.json", { my_report })
      .then((response) => response.data)
      .then((saved) => {
        window.location.href = saved.url;
      })
      .finally(() => {
        this.open = false;
        this.saving = false;
      });
  }
}
</script>
