import SectionType from "../section-type";

class MetricExtremes extends SectionType {
  constructor() {
    const opts = {
      defaults: {
        metric: "sales",
        grouping: "category",
      },
    };

    super("metric_extremes", opts);
  }
}

export default MetricExtremes;
