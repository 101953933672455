import SectionType from "../section-type";
import { extend } from "lodash";
import { metric_comparison_validator } from "../validators";

class MetricComparison extends SectionType {
  constructor() {
    const opts = {
      defaults: {
        metric: "sales",
        comparisons: [
          { type: "prev_year_corresponding", enabled: true },
          { type: "previous_corresponding_period", enabled: true },
        ],
        comparison: { type: "prev_year_corresponding" },
      },
    };

    super("metric_comparison", opts);

    this.validators = [metric_comparison_validator];
  }
}

export default MetricComparison;
