import { makeApiInstance } from "./instance";

export default abstract class ApiService {
  chartToken: string;

  protected _get(url: string, { params = null } = {}) {
    return this._request({
      url,
      method: "get",
      params,
    });
  }

  protected _post(url: string, { data = null } = {}) {
    return this._request({
      url,
      method: "post",
      data,
    });
  }

  protected _put(url: string, { data = null } = {}) {
    return this._request({
      url,
      method: "put",
      data,
    });
  }

  protected _delete(url: string) {
    return this._request({
      url,
      method: "delete",
    });
  }

  private _request({ url, method, params = null, data = null }): Promise<any> {
    if (params) {
      url += "?" + new URLSearchParams(params).toString();
    }
    return makeApiInstance()
      .request({
        url,
        method,
        data,
      })
      .then((response) => response.data);
  }
}
