import AiMessage from "@/ai-assistant/ai-message";
import Component from "@/model/component";
import RootState from "@/store/root-state";
import { Module } from "vuex";

export type AiAssistantState = {
  open: boolean;
  messages: AiMessage[];
  components: Component[];
};

const aiAssistant: Module<AiAssistantState, RootState> = {
  namespaced: true,
  state: () => ({
    open: false,
    messages: [],
    components: [],
  }),
  mutations: {
    toggle(state) {
      state.open = !state.open;
    },
    setMessages(state, messages: AiMessage[]) {
      state.messages = messages;
    },
    addMessage(state, message: AiMessage) {
      state.messages = [...state.messages, message];
    },
    amendMessage(state, message: AiMessage) {
      const index = state.messages.length - 1;
      if (index >= 0) {
        state.messages = [...state.messages.slice(0, index), message];
      }
    },
    setComponents(state, components: Component[]) {
      state.components = components;
    },
  },
};

export default aiAssistant;
