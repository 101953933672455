<template lang="pug">
.sok-provision-section
  .option
    label {{ 'filter.config.metrics' | i18n }}
    select(:value="section.metric", @input="updateMetric")
      option(v-for="option in metricsOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.entries_to_show' | i18n }}
    select(:value="section.limit", @input="updateLimit")
      option(v-for="option in limitOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.time_period' | i18n }}
    timePeriodDropdown(:show-title="false", :custom="false", :available-items="timePeriods", :available-series-types="seriesTypes", :value="selection", @update="updateSelection")
  .option
    label {{  'newsletter.definition.sections.common.comparison' | i18n }}
    comparisonPillList(:choices="comparisons", :custom="false", :title="null", :radio="true", :comparisons="section.comparisons", @update="updateComparisons")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import MenuItem from "../../interfaces/menu-item";
import { limitOptions } from "../constants";

@Component({
  components: {
    timePeriodDropdown,
    comparisonPillList,
  },
})
export default class PdfAttachmentSection extends Vue {
  @Prop()
  section: any;

  @Prop()
  timePeriods: MenuItem[];

  @Prop()
  seriesTypes: string[];

  get selection() {
    return (
      this.section.time_period && {
        type: this.section.time_period,
        series: this.section.time_series_type,
      }
    );
  }

  get metricsOptions(): MenuItem[] {
    return [
      {
        id: "total_profit",
        name: "Yhteensä myyntikate (Alviton) €",
      },
      {
        id: "total_sales",
        name: "Yhteensä myynti (Alviton) €",
      },
    ];
  }

  get comparisons() {
    const allowedComparisonTypes = [
      "prev_year_corresponding",
      "2_years_back_corresponding",
      "3_years_back_corresponding",
      "prev_year_corresponding_same_weekday_ending",
      "prev_year_same_days",
      "prev_year_full_month",
      "previous_corresponding_period",
      "budget",
    ];

    return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
  }

  get limitOptions() {
    return limitOptions;
  }

  updateSelection({ type, series }) {
    this.$emit("update-section", { ...this.section, time_period: type, time_series_type: series });
  }

  updateMetric(event) {
    const metric = event.target.value;
    this.$emit("update-section", { ...this.section, metric, insights: null });
  }

  updateLimit(event) {
    const limit = Number.parseInt(event.target.value);
    this.$emit("update-section", { ...this.section, limit });
  }

  updateComparisons(comparisons) {
    this.$emit("update-section", { ...this.section, comparisons });
  }
}
</script>
