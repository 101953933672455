<template lang="pug">
uiv-modal(v-model="open" size="sm" :title="'filters.export_pdf' | i18n" @hide="$emit('close-dialog')")
  label {{ 'pdf.choose_layout' | i18n }}
  select(v-model="orientation")
    option(value="portrait") {{ 'pdf.orientation.portrait' | i18n }}
    option(value="landscape") {{ 'pdf.orientation.landscape' | i18n }}
  div(slot="footer")
    button.btn.btn-primary(@click="download()") {{ "filters.export_report" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class PdfExportDialog extends Vue {
  open = true;

  orientation = "portrait";

  download() {
    this.$emit("close-dialog", { orientation: this.orientation })
  }
}
</script>