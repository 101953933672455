<template>
  <date-picker
    :value="value"
    @input="$emit('input', $event)"
    :first-day-of-week="firstDayOfWeek + 1"
    :locale="i18n.locale"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <input class="form-control" type="text" :value="inputValue" v-on="inputEvents" />
    </template>
  </date-picker>
</template>

<script setup lang="ts">
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import i18n from "@/i18n";

defineProps<{
  value?: Date;
}>();

const { firstDayOfWeek } = window.zoinedContext;
</script>
