<template>
  <table class="table table-hover">
    <thead>
      <tr>
        <th v-for="column in columns" :key="column.label">
          <div
            class="flex-row gap-sm align-items-center"
            :class="{ 'cursor-pointer': column.sortable, 'text-underline': column.sortable }"
            @click="updateSort(column)"
          >
            <span>{{ column.label }}</span>
            <span v-if="column.sortable && sortKey == column.key" class="sort-icon">
              <icon :icon="sortIcon" />
            </span>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-if="items.length === 0">
        <td :colspan="columns.length">No results</td>
      </tr>
      <tr v-for="item in items" :key="item.id">
        <td v-for="column in columns" :key="column.key">
          <slot :name="`cell(${column.key})`" :item="item" :value="item[column.key]">
            {{ item[column.key] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue2";
import { computed } from "vue";
export type AdminTableColumn = {
  label: string;
  key: string;
  sortable?: boolean;
};

const props = defineProps<{
  columns: AdminTableColumn[];
  items: any[];
  sort: string;
}>();

const emit = defineEmits(["update-sort"]);

const sortDirection = computed(() => (props.sort.startsWith("-") ? "desc" : "asc"));

const sortKey = computed(() => props.sort.replace(/^-/, ""));

const sortIcon = computed(() => {
  return sortDirection.value === "asc" ? "fluent:arrow-sort-up-16-filled" : "fluent:arrow-sort-down-16-filled";
});

const updateSort = (column: AdminTableColumn) => {
  if (column.sortable) {
    if (column.key === sortKey.value) {
      emit("update-sort", sortDirection.value === "asc" ? `-${column.key}` : column.key);
    } else {
      emit("update-sort", column.key);
    }
  }
};
</script>
