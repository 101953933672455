<template lang="pug">
span.tranlation-modal
  modal(:title="title", v-model="open")
    table.table.table-hover
      thead
        tr
          th {{ "company_admin.configuration.label.locale" | i18n }}
          th {{ "company_admin.configuration.label.current" | i18n }}
      tbody
        tr(v-for="language in languages")
          td {{ language.name }} {{ language.id }}
          td
            input(v-model="model[language.id]")
    div(slot="footer")
      button.btn.btn-default(@click="open = false") {{ "actions.cancel" | i18n }}
      button.btn.btn-primary(@click="update") {{ "actions.save" | i18n }}
  a(@click="openModal()")
    slot
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import { Emit } from "vue-property-decorator";
import { makeApiInstance } from "../api/instance";

@Component({
  components: { Modal },
  props: {
    title: String,
    config: Object,
  },
})
export default class TranslationModal extends Vue {
  open = false;
  languages = null;
  model = {};

  openModal() {
    this.open = true;
    this.model = { ...this.config };
  }

  @Emit("update")
  update() {
    this.open = false;
    console.log(this.model);
    return {
      ...this.model,
    };
  }

  fetchLanguages() {
    makeApiInstance()
      .get(`/api/v1/parameters/available_locales`)
      .then((response) => response.data)
      .then((languages) => (this.languages = languages.filter(({ id }) => id && id.length > 0)));
  }

  created() {
    this.fetchLanguages();
  }
}
</script>
