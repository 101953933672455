<template lang="pug">
.metric-extremes-section
  .description {{ 'newsletter.definition.sections.metric_extremes.description' | i18n }}
  .options
    .option
      label {{ 'filter.config.grouping' | i18n }}
      select(:value="section.grouping", @input="updateGrouping")
        option(v-for="option in groupingOptions" :value="option.id") {{ option.name }}
    .option
      label {{ 'filter.config.metrics' | i18n }}
      select(:value="section.metric", @input="updateMetric")
        option(v-for="option in metricsOptions" :value="option.id") {{ option.name }}
</template>

<script lang="ts">
import MenuItem from "../../interfaces/menu-item";
import Actions from "../../store/actions";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class MetricExtremesSection extends Vue {
  @Prop()
  section: any;

  get metricsOptions(): MenuItem[] {
    return this.$store.state.parameters.metrics.all;
  }

  get groupingOptions(): MenuItem[] {
    return this.$store.state.parameters.grouping.all;
  }

  updateGrouping(event) {
    const grouping = event.target.value;
    this.$emit("update-section", { ...this.section, grouping });
  }

  updateMetric(event) {
    const metric = event.target.value;
    this.$emit("update-section", { ...this.section, metric, insights: null });
  }
}
</script>

<style lang="scss" scoped>
.description {
  width: 200px;
  float: left;
  text-align: right;
  padding: 5px 15px 0 0;
}
</style>
