<template lang="pug">
.printable-filters(v-html="printableFiltersHtml")
</template>

<script lang="ts">
import i18n from "../i18n";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TranslationService from "../core/translation.service";
import _ from "lodash";
const translationService = new TranslationService();

@Component({})
export default class PrintableFilters extends Vue {
  @Prop()
  filterConfiguration: any;

  get printableFiltersHtml() {
    const filterConfiguration = this.filterConfiguration;
    let printableFiltersHtml = "";
    _.each(filterConfiguration, function(pills, key) {
      if (key === "metric_range") {
        const label = i18n.t("filter.config.metric_range");
        const itemLabels = pills
          .filter(({ enabled }) => enabled)
          .map((metricRange) => translationService.metricRangeTitle(metricRange));
        if (itemLabels.length > 0) {
          printableFiltersHtml += `<p><b>${label}:</b> ${itemLabels.join(", ")}`;
        }
      } else if (key === "rangefilter_union" && filterConfiguration.metric_range?.length >= 2) {
        const label = i18n.t("filter.config.rangefilter_union");
        const value = pills ? i18n.t("filter.config.or") : i18n("filter.config.and");
        printableFiltersHtml += `<p><b>${label}:</b> ${value.toUpperCase()}`;
      } else if (key !== "snippets" && key !== "charts" && key !== "comparisons") {
        let metric;
        let included = _.chain(pills)
          .filter((pill) => pill && pill.enabled && !pill.exclude)
          .map((pill) => pill.value)
          .value();
        let excluded = _.chain(pills)
          .filter((pill) => pill && pill.enabled && pill.exclude)
          .map((pill) => pill.value)
          .value();

        let filter_label;
        if (key.search("min_") === 0) {
          metric = key.substring(4);
          filter_label = i18n.t("filter.config.min", { metric: i18n.t(`filter.config.${metric}`) });
        } else {
          filter_label = i18n.t(`filter.config.${key}`);
        }

        if (included.length) {
          printableFiltersHtml += `<p><b>${filter_label}:</b> ${included.join(", ")}`;
        }
        if (excluded.length) {
          return (printableFiltersHtml += `<p><b>${i18n.t("filters.excluded")} ${filter_label}:</b> ${excluded.join(
            ", "
          )}`);
        }
      }
    });

    return printableFiltersHtml;
  }
}
</script>

<style lang="scss" scoped>
.printable-filters {
  display: none;

  @media print {
    display: block;
  }
}
</style>
