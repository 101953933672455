import RootState from "../root-state";
import { Module } from "vuex";
import { makeApiInstance } from "../../api/instance";

export interface NavigationState {
  state: "initial" | "loading" | "loaded" | "failed";
  data: any;
}

const reportContext: Module<NavigationState, RootState> = {
  namespaced: true,
  state: () => ({
    state: "initial",
    data: null,
  }),
  actions: {
    fetch({ commit }) {
      const url = `/api/v1/navigation`;

      commit("fetchRequest");
      return makeApiInstance()
        .get(url)
        .then((response) => commit("fetchSuccess", response.data))
        .catch((error) => {
          commit("fetchFailure");
          throw error;
        });
    },
  },
  mutations: {
    fetchRequest: (state) => {
      Object.assign(state, { state: "loading" });
    },
    fetchSuccess: (state, data) => {
      Object.assign(state, { state: "loaded", data });
    },
    fetchFailure: (state) => {
      Object.assign(state, { state: "failed" });
    },
  },
};

export default reportContext;
