<template lang="pug">
.flyover-filters-display(v-if="config")
  flyover-filters-display-tag(v-if="showFiltersetName" :text="filtersetName")
  .flex-row(v-else)
    flyover-filters-display-tag(v-if="excludeText" icon="exclude-filter" :text="excludeText" @click="$emit('click')")
    flyover-filters-display-tag(v-if="filtersText" icon="include-filter" :text="filtersText" @click="$emit('click')")

</template>

<script lang="ts">
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import ChartOptions from "../model/chart-options";
import _ from "lodash";
import TranslationService from "../core/translation.service";
import Actions from "../store/actions";
import i18n from "../i18n";
import ReportContext from "../model/report-context";
import FlyoverFiltersDisplayTag from "./flyover-filters-display-tag.vue";

const translationService = new TranslationService();

@Component({
  components: {
    FlyoverFiltersDisplayTag,
  },
})
export default class FlyoverFiltersDisplay extends Vue {
  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get config(): FilterConfiguration {
    return this.reportContext?.filter_configuration;
  }

  get chartOptions(): ChartOptions {
    return this.reportContext.chart_options;
  }

  get filtersets() {
    return this.$store.state.filtersets.all;
  }

  get selectedSets() {
    return (this.config.filters.sets || []).filter(({ enabled }) => enabled);
  }

  get showFiltersetName() {
    return this.chartOptions && this.chartOptions.show_filterset_name && this.selectedSets.length > 0;
  }

  get filtersetName() {
    if (!this.filtersets) {
      return "";
    }
    const filterSetNames = this.selectedSets.map((set) => {
      const filterSet = this.filtersets.find(({ id }) => id === set.id);
      return filterSet && filterSet.name;
    });
    return _.compact(filterSetNames)[0];
  }

  filterValues(filter) {
    return _.flatten(
      _.compact(
        _.map(this.config.filters.filters, (v, k) => {
          if (v != null) {
            let filters = Object.values(v);
            filters = filters.filter((v) => v.enabled && filter(v));
            if (k == "hour") {
              filters = _.sortBy(filters, "value");
            }
            let names = filters.map(({ name, value }) => name || value);
            if (names.length > 0) {
              if (names.length > 3) {
                names = _.take(names, 2).concat(_.last(names) + "...");
              }
              return names.join(", ");
            }
          }
        })
      )
    );
  }

  get excludeText() {
    return this.filterValues(({ exclude }) => exclude).join(" | ");
  }

  get filtersText() {
    const fText = this.filterValues(({ exclude }) => !exclude);

    if (!_.isEmpty(this.config.widgets.metric_range)) {
      const metricRangeLabels = Object.values(this.config.widgets.metric_range)
        .filter(({ enabled }) => enabled)
        .map((metricRange) => translationService.metricRangeTitle(metricRange));

      const separator = this.config.raw_filters?.rangefilter_union
        ? ` ${i18n.t("filter.config.or").toUpperCase()} `
        : ", ";

      fText.push(metricRangeLabels.join(separator));
    }

    return fText.join(" | ");
  }

  created() {
    if (!this.filtersets) {
      this.$store.dispatch(Actions.fetchFiltersets);
    }
  }
}
</script>
