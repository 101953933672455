<template lang="pug">
.default-parameter-selector.flex-row.align-items-center.flex-wrap.gap-md.column-gap-xxl
  filter-selector(
    :config="value[filter.id]",
    @update="updateDefaultParameter(filter.id, $event)",
    :excludable="false",
    :filter="filter",
    v-for="filter in availableFilters",
    :key="filter.id"
  )
  .help-block(v-if="showDefaultsWarning")
    i.fa.fa-exclamation-triangle.text-danger
    | {{ "profile.warn_defaults_lack_rights" | i18n }}
</template>

<script lang="ts">
import _ from "lodash";
import { hasAccessToDimension } from "@/permissions/utils";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterSelector from "@/components/filter-selector.vue";
import FilterConfigurationItemMap from "@/model/filter-configuration-item-map";
import Permissions from "@/model/permissions";

@Component({
  components: {
    FilterSelector,
  },
})
export default class DefaultParameterSelector extends Vue {
  @Prop()
  value: Record<string, FilterConfigurationItemMap>;

  @Prop()
  permissions: Permissions;

  get availableFilters() {
    return this.$store.getters.getParameters("filters");
  }

  get defaultParametersStoreList() {
    const storeConfig = (this.value || {})["store"];
    return _.values(storeConfig).map(({ name, value }) => value || name);
  }

  get showDefaultsWarning() {
    return !_.every(this.defaultParametersStoreList, (storeId) =>
      hasAccessToDimension(this.permissions, "store", storeId)
    );
  }

  updateDefaultParameter(id, config) {
    const value = { ...this.value, [id]: config };
    this.$emit("input", value);
  }
}
</script>
