'use strict';

export function metricLabel(key, options = {}) {
  const currency = options.currency || '';
  const def = options.default;
  return I18n.t("metric.label."+key, { currency, defaultValue: def});
}

export default {
  metricLabel
};
