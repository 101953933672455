<template lang="pug">
.sidenav-search-box
  svg-icon(name="search")
  input(
    type="text",
    :placeholder="'actions.search' | i18n",
    :value="value",
    @input="$emit('input', $event.target.value)"
  )
  svg-icon(v-if="value.length > 0", name="search-close", @click.native="$emit('input', '')")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SvgIcon from "../components/svg-icon.vue";

@Component({
  components: {
    SvgIcon,
  },
})
export default class SidenavSearchBox extends Vue {
  @Prop()
  value: string;
}
</script>

<style lang="scss" scoped>
.sidenav-search-box {
  height: 40px;
  background-color: var(--color-sidenav-search-box-background);
  border: 1.5px solid var(--color-sidenav-search-box-border);
  border-radius: var(--border-radius-sm);
  margin: 0 15px;
  padding: 0 10px;
  display: flex;
  align-items: center;

  input[type="text"] {
    color: var(--color-sidenav-search-box-text);
    background-color: transparent;
    border: none;
    outline: none;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}
</style>
