<template lang="pug">
.limit-selector
  .input-group.input-group-sm
    input.form-control(:value="value" @input="updateLimit($event.target.value)")
    .input-group-btn
      dropdown(v-model="dropdownOpen" :append-to-body="appendToBody" :dropup="dropup" :menu-right="menuRight")
        div(slot="default")
          btn.dropdown-toggle(data-role="trigger", size="sm")
            svg-icon(:name="dropdownOpen ? 'chevron-up' : 'chevron-down'")
        template(slot="dropdown")
          li.dropdown-item(v-for="option in options" :key="option") 
            a(role="button" @click="updateLimit(option)") {{ option }}
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Btn, Dropdown } from "uiv";
import SvgIcon from "../components/svg-icon.vue";

const defaultOptions = [5, 10, 20, 50, 100];

@Component({
  components: { Btn, Dropdown, SvgIcon },
  props: {
    limit: Number,
    limitOptions: {
      type: Array,
      required: false,
    },
    appendToBody: {
      type: Boolean,
      default: true,
    },
    dropup: {
      type: Boolean,
      default: false,
    },
    menuRight: {
      type: Boolean,
      default: false,
    },
  },
})
export default class LimitSelector extends Vue {
  dropdownOpen = false;

  get value() {
    return this.limit;
  }

  get options() {
    return this.limitOptions || defaultOptions;
  }

  updateLimit(value) {
    value = Number(value);
    if (value) {
      this.$emit("update", value);
    }
  }

  created() {
    // Debounce limit change for input typing
    this.updateLimit = _.debounce(this.updateLimit, 300);
  }
}
</script>
<style lang="scss" scoped>
.limit-selector {
  display: inline-block;
  width: 80px;

  .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.dropdown-item a {
  &:hover {
    background-color: #cceeff;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.component.full-screen {
  .limit-selector {
    button.btn.dropdown-toggle {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
*:not(.component.full-screen) {
  .limit-selector {
    width: 100px;

    .input-group {
      .form-control {
        box-shadow: none;
        border: none;
        background-color: var(--color-interactive-bg-emphasis-1);
        color: var(--color-text-variant);
        padding-left: 20px;
        border-top-left-radius: var(--border-radius-lg);
        border-bottom-left-radius: var(--border-radius-lg);

        &:hover {
          background-color: var(--color-interactive-bg-emphasis-2);
          color: var(--color-text-primary-inverse);
        }
      }

      .input-group-btn {
        &:last-child {
          .btn {
            background-color: var(--color-interactive-bg-emphasis-1);
            color: var(--color-text-variant);
            padding-right: 20px;

            &:hover {
              background-color: var(--color-interactive-bg-emphasis-2);
              color: var(--color-text-primary-inverse);
            }
          }
        }
      }
    }
  }
}
</style>
