<template lang="pug">
.newsletter-definitions-page
  .newsletter-definitions
    .pull-right
      mass-send-newsletters-button
      new-newsletter-button(:enable-templates="true" @new-newsletter="onNewNewsletter" @new-newsletter-from-template="onNewNewsletterFromTemplate")
    .table-controls
      .input-group
        span.input-group-addon
          .fa.fa-search
        input.form-control#search(type="text", v-model="search", :placeholder="'company_admin.actions.search_for_name'|i18n")
    spinner(v-if="!definitions")
    table.table.table-hover
      thead
        tr
          th {{'newsletter.title' | i18n}}
          th {{'newsletter.description' | i18n}}
          th {{'newsletter.definition.type' | i18n}}
          th {{'newsletter.definition.frequency' | i18n}}
          th

      tbody
        tr(v-if="definitions && definitions.length==0")
          td(colspan=6) {{ "table.no_results" | i18n }}
        tr(v-for="definition in currentPageItems")
          td {{definition.title}}
          td {{definition.description}}
          td {{definition.newsletterType == "alert" ? 'newsletter.type.alert' : 'newsletter.type.custom' | i18n }}
          td {{'newsletter.frequency.' + definition.frequency | i18n}}
          td.actions
            confirm-button.mr-sm(@confirm="deliverDefinition(definition)") 
              a {{ 'actions.deliver' | i18n }}
            router-link(v-if="definition.editable" :to="'newsletter_definitions/' + definition.id + '/edit'") {{ 'actions.edit' | i18n }}
            router-link(:to="'newsletter_definitions/new?duplicateId=' + definition.id") {{ 'actions.duplicate' | i18n }}
            confirm-button(v-if="definition.editable" @confirm="deleteDefinition(definition)")
              a {{ 'actions.delete' | i18n }}
    .centered(v-if="definitions")
      uiv-pagination(v-model="page", :total-page="totalPages" size="sm")

</template>

<script lang="ts">
import ZoinedContext from "../model/zoined-context";
import Vue from "vue";
import Component from "vue-class-component";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import spinner from "../components/spinner.vue";
import newNewsletterButton from "./new-newsletter-button.vue";
import { Watch } from "vue-property-decorator";
import _ from "lodash";
import confirmButton from "../components/confirm-button.vue";
import toaster from "toastr";
import i18n from "../i18n";
import MassSendNewslettersButton from "./mass-send-newsletters-button.vue";

@Component({
  components: {
    spinner,
    newNewsletterButton,
    confirmButton,
    MassSendNewslettersButton,
  },
})
export default class NewsletterDefinitionsPage extends Vue {
  search = "";
  definitions: any[] = null;
  page = 1;

  get zoinedContext(): ZoinedContext {
    return window.zoinedContext;
  }

  get companyId() {
    return this.zoinedContext.companyId;
  }

  get pageSize() {
    return 15;
  }

  get totalPages() {
    return Math.ceil(this.definitions.length / this.pageSize);
  }

  get currentPageItems() {
    const start = (this.page - 1) * this.pageSize;
    return this.definitions?.slice(start, start + this.pageSize);
  }

  created() {
    this.fetchDefinitions();
  }

  onPageChange(page) {
    this.page = page;
  }

  onNewNewsletter() {
    this.$router.push("newsletter_definitions/new");
  }

  onNewNewsletterFromTemplate(id) {
    this.$router.push("newsletter_definitions/new?templateId=" + id);
  }

  deliverDefinition(definition) {
    newsletterDefinitionsApi.deliver({ company_id: this.companyId, id: definition.id }).then(() => {
      toaster.success(i18n.t("newsletter_subscription.scheduled_for_delivery"));
    });
  }

  async deleteDefinition(definition) {
    const params = { company_id: this.companyId, id: definition.id };
    await newsletterDefinitionsApi.delete(params);
    this.fetchDefinitions();
  }

  async fetchDefinitions() {
    this.definitions = null;
    const params: any = { company_id: this.companyId };
    if (this.search?.length > 0) {
      params.q = this.search;
    }
    this.definitions = await newsletterDefinitionsApi.get(params);
  }

  @Watch("search")
  debouncedFetchDefinitions = _.debounce(() => {
    this.fetchDefinitions();
  }, 300);
}
</script>

<style lang="scss" scoped>
h3 {
  margin: 20px 0;
}

.table-controls {
  margin-bottom: 10px;
}

table.table {
  margin-bottom: 0;

  td.actions {
    a:not(:last-child) {
      margin-right: 5px;
    }

    a {
      cursor: pointer;
    }
  }
}
</style>
