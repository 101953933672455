<template>
  <div class="btn-group btn-multiple">
    <button
      class="btn btn-primary btn-xs"
      id="change-percent-button"
      :class="{ active: config.changePercent }"
      v-uiv-tooltip.hover="changePercentTooltip"
      @click="toggleValue('changePercent')"
    >
      <span>%±</span>
    </button>
    <button
      class="btn btn-primary btn-xs"
      id="absolute-change-button"
      :class="{ active: config.absoluteChange }"
      v-uiv-tooltip.hover="changeAbsoluteTooltip"
      @click="toggleValue('absoluteChange')"
    >
      <span>±</span>
    </button>
    <button
      class="btn btn-primary btn-xs"
      id="change-index-button"
      :class="{ active: config.changeIndex }"
      v-uiv-tooltip.hover="changeIndexTooltip"
      @click="toggleValue('changeIndex')"
    >
      Ind
    </button>
    <button
      class="btn btn-primary btn-xs"
      id="proportions-button"
      :class="{ active: config.proportions }"
      v-uiv-tooltip.hover="proportionTooltip"
      @click="toggleValue('proportions')"
    >
      <i class="fa fa-sm fa-percent"></i>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";

export interface TableColumnSelectorConfig {
  changePercent: boolean;
  absoluteChange: boolean;
  changeIndex: boolean;
  proportions: boolean;
}

const props = defineProps<{ config: TableColumnSelectorConfig }>();

const emit = defineEmits(["change"]);

const { t } = useI18n();

const changePercentTooltip = t("report.snippet.table.change_percent");

const changeAbsoluteTooltip = t("report.snippet.table.change_absolute");

const changeIndexTooltip = t("report.snippet.table.change_index");

const proportionTooltip = t("report.snippet.table.proportion");

const toggleValue = (key) => {
  emit("change", {
    ...props.config,
    [key]: !props.config[key],
  });
};
</script>

<style lang="scss" scoped>
.table-column-selector {
  display: inline-block;
}
</style>
