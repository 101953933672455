import { makeApiInstance } from "./instance";

export default class TeamsApiService {
  getTeam(id, { companyId = window.zoinedContext.companyId } = {}) {
    return makeApiInstance()
      .get(`/api/admin/companies/${companyId}/teams/${id}`)
      .then((response) => response.data);
  }

  getTeams({ search = "", companyId = window.zoinedContext.companyId } = {}) {
    return makeApiInstance()
      .get(`/api/admin/companies/${companyId}/teams?search=${search}`)
      .then((response) => response.data);
  }
}
