<template>
  <spinner v-if="loading"></spinner>
  <report-view v-else-if="loaded" :key="$route.path"></report-view>
</template>

<script lang="ts">
import { computed } from "vue";
import { useStore } from "@/composables/store";
import { useRouter } from "@/composables/router";
import { withErrorHandling } from "@/core/error-handling";
import ReportView from "./report-view.vue";
import Spinner from "@/components/spinner.vue";
import Mutations from "@/store/mutations";
import Actions from "@/store/actions";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  name: "CustomDashboardPage",
  components: {
    ReportView,
    Spinner,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearReportContext);
    next();
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const loaded = computed(() => store.state.reportContext.state === "loaded");
    const loading = computed(() => store.state.reportContext.state === "loading");

    withErrorHandling(() =>
      store.dispatch(Actions.fetchCustomDashboard, props.id).catch((error) => {
        if (error?.response?.status === 404) {
          router.push("/");
        } else {
          throw error;
        }
      })
    );

    return { loaded, loading };
  },
};
</script>
