<template lang="pug">
.inline-block
  input#team-member-input.form-control(
    type="text"
    :placeholder="'company_admin.teams.add_member_by_name' | i18n"
    autocomplete="off"
  )
  uiv-typeahead(
    v-model="user"
    force-select
    target="#team-member-input"
    item-key="name"
    :async-function="searchUsers"
    @input="onItemSelected"
  )
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import adminUsersApi from "../api/admin-users-api";

@Component({})
export default class TeamMemberInput extends Vue {
  @Prop()
  teamId: number;

  user = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  async searchUsers(search, done) {
    const users = (await adminUsersApi.get({ company_id: this.companyId, search })).map(user => ({
      id: user.id,
      name: `${user.first_name} ${user.last_name} (${user.email})`
    }));
    done(users);
  }

  onItemSelected() {
    if (this.user) {
      this.$emit("add-member", this.user);
      this.user = null;
    }
  }
}
</script>