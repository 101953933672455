export const repositionDataLabels = chartObj => {
  _.each(chartObj.series, function(s) {
    if (s.type === "bar") {
      _.each(s.data, function(point, i) {
        if (typeof point.dataLabel !== "undefined") {
          // Move labels near the axis for better visibility.
          const barWidth = point.shapeArgs.height;
          if (point.y === 0) {
            // Keep zero labels invisible.
            point.dataLabel.css({ display: "none" });
          } else if (point.dataLabel.width + 20 > barWidth) {
            // When the label is wider than bar width, move it outside the bar and change color for contrast.
            if (point.y < 0) {
              // Negative
              if (point.shapeArgs.y + barWidth > chartObj.plotWidth) {
                // Overflow
                point.dataLabel.attr({ x: 10 }).css({ color: "#26282a" });
              } else {
                const newX = chartObj.plotWidth - point.shapeArgs.y -
                  point.shapeArgs.height - 10;
                point.dataLabel.attr({ x: newX, align: "right" })
                  .css({ color: "#26282a" });
              }
            } else {
              // Positive
              const newX = chartObj.plotWidth - point.shapeArgs.y + 5;
              point.dataLabel
                .attr({
                  x: newX,
                  align: "left"
                })
                .css({
                  color: "#26282a"
                });
            }
          } else if (point.y < 0) {
            // Negative, fit inside the bar
            if (point.shapeArgs.y + barWidth > chartObj.plotWidth) {
              point.dataLabel
                .attr({
                  x: 5
                })
                .css({
                  color: "#26282a"
                });
            } else {
              const newX =
                chartObj.plotWidth - point.shapeArgs.y - 10;
              point.dataLabel.attr({ x: newX, align: "right" });
            }
          } else {
            // Positive, fit inside the bar
            const newX = chartObj.plotWidth - point.shapeArgs.y - barWidth + 10;
            point.dataLabel.attr({x: newX});
          }
        }
      });
    }
  });
};
