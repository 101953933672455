window.initializeRollbar = function(accessToken, environment, gitRevision) {
	const rollbar = require('rollbar');

	const _rollbarConfig = {
		accessToken,
		captureUncaught: true,
		payload: {
			environment,
			client: {
				javascript: {
					source_map_enabled: true,
					code_version: gitRevision,
				}	
			}
		},
		// checkIgnore: function(isUncaught, args, payload) {
		//   // Code here to determine whether or not to send the payload
		//   // to the Rollbar API
		//   // return true to ignore the payload
		// },
		// hostWhiteList: ['zoined.com'],
		// ignoredMessages: ["Can't find Clippy.bmp."]
	};

	const Rollbar = rollbar.init(_rollbarConfig);
	window.Rollbar = Rollbar;

	$(function () {
		var meta_info = $("meta[name='zoined']")
		if (meta_info && meta_info.data("user")) {
			// Log to console in addition to Rollbar for Zoined (admin) users.
			var verbosity = (meta_info.data("company") === 'ZOINED');
			// Set the person data to be sent with all errors for this notifier.
			Rollbar.configure({
				payload: {
					verbose: verbosity,
					person: {
						id: meta_info.data("uid"),
						username: meta_info.data("name"),
						email: meta_info.data("user")
					}
				}
			});
		}
	});
}
