<template>
  <confirm-button
    v-uiv-tooltip.hover="t('actions.set_as_default')"
    :title="t('actions.set_as_default')"
    :popover-message="t('dashboard_custom.set_as_default_confirm_message')"
    :disabled="isDefault"
    @confirm="setAsDefault()"
  >
    <report-action-button :icon="icon" :text="t('actions.set_as_default')" :class="{ selected: isDefault }" />
  </confirm-button>
</template>

<script lang="ts" setup>
import EventBus from "../events/event-bus";
import Events from "../events/events";
import ConfirmButton from "../components/confirm-button.vue";
import { computed } from "vue";
import { useStore } from "@/composables/store";
import { useI18n } from "@/composables/i18n";
import reportActionButton from "@/components/report-action-button.vue";

const store = useStore();

const { t } = useI18n();

const reportContext = computed(() => store.getters.getReportContext);

const defaultDashboardId = computed(() => store.getters.defaultDashboardId);

const isDefault = computed(() => {
  if (reportContext?.value?.report_type === "system_dashboard") {
    return !defaultDashboardId.value;
  } else {
    return reportContext?.value?.report?.id === defaultDashboardId.value;
  }
});

const icon = computed(() => {
  return isDefault.value ? "fluent:star-24-filled" : "fluent:star-24-regular";
});

const setAsDefault = () => {
  EventBus.emit(Events.setAsDefault);
};
</script>

<style lang="scss" scoped>
.selected::v-deep {
  button {
    svg {
      color: var(--color-yellow) !important;
    }
  }
}
</style>
