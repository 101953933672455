import { normalizeComparisonType } from "../filters/time-period";
import Comparison from "../model/comparison";

declare const _: any;

const comparisonToMenuKey = ({ type, label, time_period, start_time, end_time, scaling_modifier }: Comparison) => {
  let menuKey;
  if (_.startsWith(type, "budget")) {
    menuKey = `${type}:${label}`;
  } else if (type === "time_period") {
    menuKey = `time_period:${time_period}`;
  } else if (type == "custom") {
    menuKey = `custom:${start_time}:${end_time}`;
  } else {
    menuKey = normalizeComparisonType(type);
  }

  if (scaling_modifier) {
    menuKey += `:${scaling_modifier}`;
  }

  return menuKey;
};

const menuKeyToComparison = (menuKey, enabled = true): Comparison => {
  if (_.startsWith(menuKey, "budget")) {
    const [type, label, scaling_modifier] = menuKey.split(":");
    return { type, label, enabled, scaling_modifier };
  } else if (_.startsWith(menuKey, "time_period:")) {
    const [type, time_period, scaling_modifier] = menuKey.split(":");
    return { type, time_period, enabled, scaling_modifier };
  } else if (_.startsWith(menuKey, "custom:")) {
    const [type, start_time, end_time, scaling_modifier] = menuKey.split(":");
    return { type, start_time, end_time, enabled, scaling_modifier };
  } else {
    const [type, scaling_modifier] = menuKey.split(":");
    return { type, enabled, scaling_modifier };
  }
};

export { comparisonToMenuKey, menuKeyToComparison };
