<template lang="pug">
.flex-row.align-items-center.gap-lg
  .title {{ 'filter.config.show_filterset_name' | i18n }}
  btn-group.btn-multiple
    btn.btn-sm(type="primary" input-type="radio", :input-value="true", v-model="model", @click="update(true)") {{ 'options.yes' | i18n }}
    btn.btn-sm(type="primary" input-type="radio", :input-value="false", v-model="model", @click="update(false)") {{ 'options.no' | i18n }}
</template>
<script>
import { Btn, BtnGroup } from "uiv";

export default {
  components: {
    Btn,
    BtnGroup,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const model = !!this.value;
    return {
      model,
    };
  },
  methods: {
    update(value) {
      this.$emit("input", value);
    },
  },
};
</script>
