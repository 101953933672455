<template>
  <ul v-if="page" class="pagination pagination-sm">
    <li v-bind:class="{ disabled: page == 1 }">
      <a @click="prev()" class="paginate-previous">
        <svg-icon name="chevron-left" />
      </a>
    </li>
    <li>
      <a class="page-display" @click="$refs.pageInput.focus()">
        <span class="current-page">
          <input ref="pageInput" :style="{ width: pageInputWidth }" v-model="currentPage" />
        </span>
        <span class="slash">/</span>
        <span class="total-pages" :style="{ width: pageInputWidth }">{{ shownTotalPages }}</span>
      </a>
    </li>
    <li v-bind:class="{ disabled: page == totalPages }">
      <a @click="next()" class="paginate-next">
        <svg-icon name="chevron-right" />
      </a>
    </li>
  </ul>
</template>

<script>
import SvgIcon from "../components/svg-icon.vue";

export default {
  components: {
    SvgIcon,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: this.page,
    };
  },
  computed: {
    shownTotalPages: function() {
      if (this.totalPages == 0) {
        return 1;
      } else {
        return this.totalPages;
      }
    },
    pageInputWidth() {
      const charWidth = this.shownTotalPages.toString().length + 1;
      return `${charWidth}ch`;
    },
  },
  methods: {
    next() {
      if (this.totalPages != this.page) {
        this.$emit("pageChange", this.page + 1);
      }
    },
    prev() {
      if (this.page > 1) {
        this.$emit("pageChange", this.page - 1);
      }
    },
    updatePage: _.debounce(function(page) {
      if (this.page !== page && page > 0 && page <= this.shownTotalPages) {
        this.$emit("pageChange", page);
      }
    }, 1000),
  },
  watch: {
    page(page) {
      if (this.currentPage !== page) {
        this.currentPage = page;
      }
    },
    currentPage(page) {
      this.updatePage(page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  max-height: 30px;

  > li > a {
    cursor: pointer;
    max-height: 30px;
  }

  > .disabled > a {
    cursor: not-allowed;
  }

  > .disabled.active > a {
    cursor: default;
    &:hover,
    &:focus {
      cursor: default;
    }
  }

  .page-display {
    display: flex;
    align-items: center;

    .current-page {
      input {
        border: none;
        text-align: right;
        padding: 0 2px;
      }
    }
    .slash {
      width: 1em;
    }
    .total-pages {
      text-align: left;
      padding: 0 2px;
    }
  }
}
</style>
