<template lang="pug">
.basket-filters(v-if="items")
  .title {{ 'filters.basket_filters' | i18n }}
  .edit.flex-row.align-items-center.flex-wrap.gap-lg.column-gap-xxl.pb-lg(v-if="edit")
    filter-selector(:config="value[filter.id]", :filter="filter", @update="updateFilter(filter.id, $event)", v-for="filter in items" :key="filter.id")
  .view(v-else)
    span.title.mr-md(v-if="currentFiltersText") {{ currentFiltersText }}
    button.btn.btn-primary.btn-sm(@click="edit = true") {{ 'actions.edit' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import FilterSelector from "../components/filter-selector.vue";
import _ from "lodash";

@Component({
  components: {
    FilterSelector,
  },
})
export default class BasketFiltersSection extends Vue {
  @Prop({ default: () => ({}) })
  value: Record<string, FilterConfigurationItemMap>;

  @Prop()
  items: MenuItem[];

  edit = false;

  get selectedItems() {
    return _.compact(
      Object.keys(this.value).map((key) => {
        const itemMap = this.value[key];
        let items = _.compact(
          _.map(itemMap, ({ name, value, enabled, exclude }) => {
            if (!enabled) {
              return null;
            } else {
              return (exclude ? "-" : "") + (name || value);
            }
          })
        );
        if (key == "hour") {
          items.sort();
        }
        if (items.length > 0) {
          return `${this.filterName(key)}: ${items.join(", ")}`;
        } else {
          return null;
        }
      })
    );
  }

  get currentFiltersText() {
    if (_.isEmpty(this.selectedItems)) {
      return null;
    } else {
      return this.selectedItems.join(", ");
    }
  }

  filterName(key) {
    return this.items.find((item) => menuItemKey(item) === key)?.name || key;
  }

  updateFilter(key, config) {
    this.$emit("input", { ...this.value, [key]: config });
  }
}
</script>

<style lang="scss" scoped>
.basket-filters {
  .title {
    margin-bottom: 15px;
  }

  .view {
    padding-bottom: 15px;
  }
}
</style>
