import SectionType from "../section-type";

class SokProvision extends SectionType {
  constructor() {
    const opts = {
      defaults: {
        limit: 10,
        metric: "total_profit",
        time_period: "last_week",
        comparisons: [{ type: "budget", label: "budget", enabled: true }],
      },
    };

    super("sok_provision", opts);
  }
}

export default SokProvision;
