<template lang="pug">
.comparison-scenario-form
  .form-group
    label {{ "comparison" | i18n }}
    comparison-selector(v-model="scenario.comparison", :choices="availableComparisons")
  .form-group
    label {{ "filters.custom_scenario.percentage_change" | i18n }}
    .row
      .col-md-12
        .input-group
          input.form-control(type="number", v-model="scenario.percentageChange")
          span.input-group-btn
            button.btn.btn-default(@click="addToScalingModifier(5)") +
          span.input-group-btn
            button.btn.btn-default(@click="addToScalingModifier(-5)") -
  .actions
    button.btn.btn-success(@click="addCustomScenario", :disabled="!validScenario") {{ "actions.add" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import ComparisonSelector from "./comparison-selector.vue";
import { Inject, Prop } from "vue-property-decorator";
import Comparison from "../model/comparison";
import _ from "lodash";

@Component({
  components: {
    ComparisonSelector,
  },
})
export default class ComparisonScenarioForm extends Vue {
  @Inject()
  dropdown: any;

  @Prop()
  availableComparisons: Comparison[];

  scenario = {
    comparison: null,
    percentageChange: 0,
  };

  get validScenario() {
    return this.scenario && this.scenario.comparison && this.scenario.percentageChange !== 0;
  }

  created() {
    const { ...comparison } = _.first(this.availableComparisons);

    this.scenario = {
      comparison,
      percentageChange: 0,
    };
  }

  addToScalingModifier(value) {
    this.scenario.percentageChange += value;
  }

  addCustomScenario() {
    const { comparison, percentageChange } = this.scenario;

    const scaling_modifier = percentageChange / 100 + 1;

    const scenario = { ...comparison, scaling_modifier, enabled: true };

    this.$emit("add", scenario);
    this.dropdown?.close();
  }
}
</script>

<style lang="scss" scoped>
.comparison-scenario-form {
  width: 300px;
  padding: 10px;

  .input-group-btn:not(:last-child) {
    .btn {
      border-radius: 0;
      margin-left: -1px;
    }
  }

  .actions {
    margin-top: 15px;
  }
}
</style>
