<template lang="pug">
pill-list(:title="title", :items="items", :availableItems="menuItems", @update="onUpdate")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Emit } from "vue-property-decorator";
import pillList from "../components/pill-list.vue";
import MenuItem from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";

@Component({
  components: {
    pillList,
  },
})
export default class AllowedReports extends Vue {
  @Prop()
  title: string;

  @Prop()
  restrictedReports: Array<any>;

  @Prop()
  allowedReports: Array<any>;

  get items(): PillItem[] {
    return this.allowedReports.map(({ id, name }) => ({ value: id, name }));
  }

  get menuItems(): MenuItem[] {
    return this.restrictedReports.map(({ id, name }) => ({ key: id, name }));
  }

  @Emit("change")
  onUpdate({ items }) {
    return items.map(({ value, name }) => ({ id: value, name }));
  }
}
</script>
