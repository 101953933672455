import { makeApiInstance } from "./instance";

export default class AnalyticsTemplatesApiService {
  chartToken: string = null;

  constructor(chartToken = window.chart_token) {
    this.chartToken = chartToken;
  }

  getDashboardTemplates() {
    return makeApiInstance()
      .get("/api/v1/analytics_templates?analytics_type=dashboard")
      .then((response) => response.data);
  }
}
