<template>
  <button class="btn btn-tertiary btn-sm flex-row align-items-center" @click="$emit('click')">
    <icon icon="fluent:arrow-download-16-filled" class="text-base" />
    <span class="hidden-xs ml-sm">{{ t("components.excel_button.excel_file") }}</span>
  </button>
</template>

<script setup lang="ts">
import { useI18n } from "@/composables/i18n";
import { Icon } from "@iconify/vue2";

const { t } = useI18n();
</script>
