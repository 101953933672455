'use strict';
import * as constants from './constants';

export const combinePeriods = (cp = []) => {
  const newPeriods = cp.length > 0 ? mapPeriods(cp) : []
  const periods = {
    periodsForHourly: constants.periodsForHourly,
    periodsForDaily: constants.periodsForDaily.concat(newPeriods),
    periodsForWeekly: constants.periodsForWeekly.concat(newPeriods),
    periodsForMonthly: constants.periodsForMonthly.concat(newPeriods),
    periodsForYearly: constants.periodsForYearly.concat(newPeriods)
  }
  return periodOptions(periods);
}

export const mapPeriods = (result) => {
  let custom_data = []

  result.map((x) => {
    switch (x) {
      case "custom_year":
        return custom_data.push('custom_current_year', 'custom_current_full_year', 'custom_last_year')
      case "custom_period":
        return custom_data.push('custom_rolling_12_months', 'custom_rolling_3_months', 'custom_current_month', 'custom_current_full_month', 'custom_last_month')
      case "custom_week":
        return custom_data.push('custom_rolling_4_weeks', 'custom_rolling_12_weeks', 'custom_current_week', 'custom_current_full_week', 'custom_last_week', "custom_current_retail_week_until_today")
      case "custom_season":
        return custom_data.push('custom_current_season', 'custom_last_season', 'custom_current_full_season')
    }
  })

  return custom_data
}

export const periodOptions = (periods) => {

  return {
    daily: periods.periodsForDaily.map(periodToOption),
    weekly: periods.periodsForWeekly.map(periodToOption),
    monthly: periods.periodsForMonthly.map(periodToOption),
    yearly: periods.periodsForYearly.map(periodToOption),
    at_most_once: periods.periodsForDaily.map(periodToOption),
    at_most_hourly: periods.periodsForHourly.map(periodToOption),
    at_most_daily: periods.periodsForDaily.map(periodToOption),
    at_most_weekly: periods.periodsForWeekly.map(periodToOption),
    at_most_monthly: periods.periodsForMonthly.map(periodToOption),
  }
};

const periodToOption = (p) => {
  return {
    id: p,
    name: I18n.t("filters.date_selector." + p)
  };
};
