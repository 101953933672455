import Vue from "vue";
import store from "../store/store";
import router from "./router";

export function showDialog<T>(component, props = {}): Promise<T> {
  return new Promise((resolve, reject) => {
    const vm = new Vue({
      store,
      router,
      methods: {
        destroy() {
          vm.$destroy();
          vm.$el.remove();
        },
      },
      render(createElement) {
        return createElement(component, {
          props,
          on: {
            "close-dialog": (data) => {
              resolve(data);
              this.destroy();
            },
            error: (err) => {
              reject(err);
              this.destroy();
            },
          },
        });
      },
    }).$mount();
    document.body.appendChild(vm.$el);
  });
}
