<template lang="pug">
.important-messages(v-if="messages && messages.length > 0")
  .alert.panel.panel-snippet(v-for="message in messages")
    .panel-heading
      h2 {{ title(message) }}
    .panel-body(v-html="messageHtml(message)")
    .panel-body
      button.btn.btn-default.btn-sm(@click="markAsRead(message)") Do not show this message again
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import MessagesApiService from "../api/messages-api-service";
import sanitizeHtml from "sanitize-html";

@Component({})
export default class ImportantMessage extends Vue {
  messages = null;

  title(message) {
    return message.title;
  }

  messageHtml(message) {
    return sanitizeHtml(message.message);
  }

  fetchMessages() {
    new MessagesApiService().getActiveMessages().then((messages) => (this.messages = messages));
  }

  markAsRead(message) {
    new MessagesApiService().markMessageViewed(message.id);

    this.messages = this.messages.filter((each) => each !== message);
  }

  created() {
    this.fetchMessages();
  }
}
</script>

<style lang="scss" scoped>
.panel-snippet {
  > .panel-heading {
    border-color: transparent;
  }
  > .panel-body {
    button {
      float: right;
    }
  }
}
</style>
