<template lang="pug">
.section
  .thumbnail
    .section-type-image(:class="section.type.id")

  .pull-right
    i.fa.fa-files-o(@click="duplicateSection()")
    | &nbsp;
    i.fa.fa-times(@click='removeSection()')

  .configuration
    h3 {{section.type.title}}

    .alert.alert-danger(v-if="section.errors")
      div(v-for='(errors, key) in section.errors') {{ errors.join(" ") }}

    .fields
      metricComparisonSection(
        v-if="section.type.id == 'metric_comparison'" 
        :section="section"
        :newsletter-type="newsletterType"
        :time-periods="timePeriods"
        :series-types="seriesTypes"
        :opening-hours-defined="openingHoursDefined"
        :lfl-enabled="lflEnabled"
        :insights-visible="insightsVisible"
        @update-section="updateSection"
      )
      topSection(
        v-if="section.type.id == 'top'" 
        :section="section"
        :newsletter-type="newsletterType"
        :time-periods="timePeriods"
        :series-types="seriesTypes"
        :opening-hours-defined="openingHoursDefined"
        :lfl-enabled="lflEnabled"
        :insights-visible="insightsVisible"
        @update-section="updateSection"
      )
      metricExtremesSection(
        v-if="section.type.id == 'metric_extremes'" 
        :section="section"
        @update-section="updateSection"
      )
      timeSeriesSection(
        v-if="section.type.id == 'time_series'" 
        :section="section"
        :newsletter-type="newsletterType"
        :time-periods="timePeriods"
        :series-types="seriesTypes"
        :opening-hours-defined="openingHoursDefined"
        :lfl-enabled="lflEnabled"
        :insights-visible="insightsVisible"
        @update-section="updateSection"
      )
      reportAttachmentSection(
        v-if="section.type.id == 'report_attachment'" 
        :section="section"
        @update-section="updateSection"
      )
      pdfAttachmentSection(
        v-if="section.type.id == 'pdf_attachment'" 
        :section="section"
        @update-section="updateSection"
      )
      sokProvisionSection(
        v-if="section.type.id == 'sok_provision'" 
        :section="section"
        :time-periods="timePeriods"
        :series-types="seriesTypes"
        @update-section="updateSection"
      )

    .section-filters
      filtersDisplay(:config="section.filters")
      button.btn.btn-primary(@click='editFilters()') {{ 'newsletter.definition.actions.edit_filter' | i18n }}

  newsletter-section-filters-modal(ref="newsletterSectionFiltersModal" :filters="section.filters" @update="updateFilters")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";

import metricComparisonSection from "./sections/metric-comparison-section.vue";
import topSection from "./sections/top-section.vue";
import metricExtremesSection from "./sections/metric-extremes-section.vue";
import timeSeriesSection from "./sections/time-series-section.vue";
import reportAttachmentSection from "./sections/report-attachment-section.vue";
import pdfAttachmentSection from "./sections/pdf-attachment-section.vue";
import sokProvisionSection from "./sections/sok-provision-section.vue";
import filtersDisplay from "../components/filters-display.vue";
import MenuItem from "../interfaces/menu-item";
import NewsletterSectionFiltersModal from "./newsletter-section-filters-modal.vue";

@Component({
  components: {
    metricComparisonSection,
    topSection,
    metricExtremesSection,
    timeSeriesSection,
    reportAttachmentSection,
    pdfAttachmentSection,
    sokProvisionSection,
    filtersDisplay,
    NewsletterSectionFiltersModal,
  },
})
export default class NewsletterSection extends Vue {
  @Ref("newsletterSectionFiltersModal")
  newsletterSectionFiltersModal: NewsletterSectionFiltersModal;

  @Prop()
  section: any;

  @Prop()
  newsletterType: string;

  @Prop()
  timePeriods: MenuItem[];

  get zoinedContext() {
    return window.zoinedContext;
  }

  get lflEnabled() {
    return !!this.zoinedContext.features.lfl;
  }

  get insightsVisible() {
    return !!this.zoinedContext.features.insights;
  }

  get openingHoursDefined() {
    return !!this.zoinedContext.openingHoursDefined;
  }

  get seriesTypes() {
    return this.zoinedContext.budgets && ["actual", ...Object.keys(this.zoinedContext.budgets)];
  }

  editFilters() {
    this.newsletterSectionFiltersModal.show();
  }

  removeSection() {
    this.$emit("remove", this.section);
  }

  duplicateSection() {
    this.$emit("duplicate", this.section);
  }

  updateSection(config) {
    this.$emit("update", this.section, config);
  }

  updateFilters(filters) {
    this.$emit("update", this.section, {
      ...this.section,
      filters,
    });
  }
}
</script>
