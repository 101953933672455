<template lang="pug">
modal.report-template-from-report-modal(
  v-model="open",
  :append-to-body="true",
  :title="'report_templates.select_report' | i18n",
  :footer="false"
)
  .loading-indicator(v-if="!reports")
    spinner
  .content(v-else)
    .item(v-for="report in reports", @click="createFromReport(report)") {{ report.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import { makeApiInstance } from "../../api/instance";

@Component({
  components: {
    Modal,
    spinner,
  },
})
export default class ReportTemplateFromReportModal extends Vue {
  open = false;

  reports = null;

  get reportsApi() {
    return makeApiInstance({
      baseURL: "/api/v1/reports",
    });
  }

  show() {
    this.open = true;
    if (!this.reports) {
      this.fetchReports();
    }
  }

  createFromReport(report) {
    this.open = false;
    this.$emit("select", report.id);
  }

  fetchReports() {
    this.reportsApi.get("company_reports").then((result) => (this.reports = result.data));
  }
}
</script>

<style lang="scss" scoped>
.report-template-from-report-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
