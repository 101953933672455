import RootState from "../root-state";
import { Module } from "vuex";

export interface RoleState {
  data: {
    role: string;
    default_dashboard_id?: number;
  };
}

const role: Module<RoleState, RootState> = {
  namespaced: true,
  state: () => ({
    data: null,
  }),
  mutations: {
    setRoleData: (state, data) => {
      Object.assign(state, { data });
    },
    setDefaultDashboardId: (state, default_dashboard_id) => {
      Object.assign(state, { data: { ...(state.data || {}), default_dashboard_id } });
    },
  },
};

export default role;
