<template lang="pug">
span
  dropdown
    template(v-slot:toggle="toggleProps")
      button.btn.btn-primary.mr-md(type="primary" @click="toggleProps.toggleDropdown()") 
        span.mr-md {{ 'newsletter.definition.deliver_all' | i18n }}
        span.caret
    template(v-slot:menu)
      dropdown-item(@click="confirmMassSend(frequency)" v-for="frequency in frequencies" :key="frequency")
        span {{ 'newsletter.definition.send_now' | i18n }}: {{ 'newsletter.frequency.' + frequency | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import dropdown from "../components/dropdown.vue";
import Component from "vue-class-component";
import ConfirmButton from "../components/confirm-button.vue";
import i18n from "../i18n";
import newsletterDefinitionsApi from "../api/admin-newsletter-definitions-api";
import toastr from "toastr";
import DropdownItem from "../components/dropdown-item.vue";
import { MessageBox } from "uiv";

@Component({
  components: {
    dropdown,
    ConfirmButton,
    DropdownItem,
  },
})
export default class MassSendNewslettersButton extends Vue {
  get frequencies() {
    return ["daily", "weekly", "monthly", "quarterly", "yearly"];
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  massSendByFrequency(frequency) {
    return newsletterDefinitionsApi
      .deliver({ company_id: this.companyId, id: frequency })
      .then(({ delivery_count }) => {
        toastr.success(i18n.t("newsletter.definition.delivered_successfully_message", { delivery_count }));
      });
  }

  confirmMassSend(frequencyId) {
    const frequency = i18n.t("newsletter.frequency." + frequencyId).toLowerCase();
    const content = i18n.t("newsletter.definition.deliver_all_confirmation_message", { frequency });

    return MessageBox.confirm({
      content,
      okText: i18n.t("actions.confirm"),
      cancelText: i18n.t("actions.cancel"),
    })
      .then(() => this.massSendByFrequency(frequencyId))
      .catch((err) => {
        if (err !== "cancel") {
          throw err;
        }
      });
  }
}
</script>
