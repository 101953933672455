export const avg_store_validator = function(section, errors) {
  if (
    section.comparison &&
    section.comparison.type === "avg_store" &&
    (!(section.comparison_target != null) || section.comparison_target.store.length === 0)
  ) {
    errors.comparison_target || (errors.comparison_target = []);
    errors.comparison_target.push(I18n.t("newsletter.definition.errors.target_store_missing"));
  }
  if (
    section.comparisons &&
    _.includes(
      section.comparisons.map(({ type }) => type),
      "avg_store"
    ) &&
    (!section.comparison_target || !section.comparison_target.store || section.comparison_target.store.length === 0)
  ) {
    errors.comparison_target || (errors.comparison_target = []);
    return errors.comparison_target.push(I18n.t("newsletter.definition.errors.target_store_missing"));
  }
};
export const cumulative_grouping_validator = function(section, errors) {
  if (section.top_grouping && section.cumulative) {
    errors.cumulative || (errors.cumulative = []);
    return errors.cumulative.push(I18n.t("newsletter.definition.errors.cumulative_grouping_not_supported"));
  }
};
export const metric_comparison_validator = function(section, errors) {
  updateSectionComparison(section);
  updateSectionComparisons(section);
  if (!section.comparisons || section.comparisons.length != 1 || !isTimeComparison(section.comparisons[0].type)) {
    delete section.like_for_like;
  }

  if (section.comparisons.length === 0) section.no_comparisons = true;
  if (section.comparison == null) section.comparison = { type: "no_comparison" };
};
export const top_legacy_validator = function(section, errors) {
  updateSectionComparisons(section);
  if (!section.comparisons || section.comparisons.length != 1 || !isTimeComparison(section.comparisons[0].type)) {
    delete section.like_for_like;
  }
  if (section.displayType == null) section.displayType = "bar";
  if (section.sort == null) section.sort = "-metric";
  return (section.comparison = void 0);
};
export const time_series_legacy_validator = function(section, errors) {
  updateSectionComparison(section);
  if (!section.comparison || !isTimeComparison(section.comparison.type)) {
    delete section.like_for_like;
  }
  if (section.sort == null) section.sort = "-metric";
};

const isTimeComparison = (type) => {
  return _.includes(
    [
      "prev_year_corresponding",
      "2_years_back_corresponding",
      "3_years_back_corresponding",
      "prev_year_corresponding_same_weekday_ending",
      "prev_year_same_days",
      "prev_year_full_month",
      "previous_corresponding_period",
      "previous_year_lfl",
      "2_years_back_lfl",
      "3_years_back_lfl",
    ],
    type
  );
};

const updateSectionComparisons = (section) => {
  if (section.compare_to) {
    section.comparisons = section.compare_to.map((type) => ({ type, enabled: true }));
    delete section.compare_to;
  }
  if (!section.comparisons) {
    section.comparisons = [];
  }
};

const updateSectionComparison = (section) => {
  if (_.isString(section.comparison)) {
    section.comparison = { type: section.comparison };
  }
};
