<template lang="pug">
.team-members(v-if="team")
  div
    .row
      .col-md-8.col-sm-6
        .table-controls
          search-bar(v-model="search")
      .col-md-4.col-sm-6
        .pull-right
          team-member-input(:team-id="id" @add-member="addMember")
    div
      spinner(v-if='!members')
      table.table.table-hover
        thead
          tr
            th {{ 'activerecord.attributes.user.first_name' | i18n }}
            th {{ 'activerecord.attributes.user.last_name' | i18n }}
            th {{ 'activerecord.attributes.user.email' | i18n }}
            th
        tbody
          tr(v-if="members.length==0")
            td(colspan=6) {{ "table.no_results" | i18n }}
          tr(v-for="user in members")
            td {{user.first_name}}
            td {{user.last_name}}
            td {{user.email}}
            td.text-right
              confirm-button(@confirm="removeMember(user)") 
                a {{ 'company_admin.teams.remove_member' | i18n }}
      .centered(v-if="total > perPage")
        uiv-pagination(v-model="page", :total-page="Math.ceil(total / perPage)", @change="onPageChange")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import adminTeamsApi from "../api/admin-teams-api";
import adminTeamMembersApi from "../api/admin-team-members-api";
import SearchBar from "../components/search-bar.vue";
import Spinner from "../components/spinner.vue";
import ConfirmButton from "../components/confirm-button.vue";
import TeamMemberInput from "./team-member-input.vue";
import Mutations from "../store/mutations";
import i18n from "../i18n";

@Component({
  components: {
    SearchBar,
    Spinner,
    ConfirmButton,
    TeamMemberInput,
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit(Mutations.clearTitle);
    next();
  },
})
export default class TeamMembersListing extends Vue {
  @Prop()
  id: number;

  team = null;
  members = [];
  page = 1;
  perPage = 15;
  search = "";
  total = 0;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get params() {
    return {
      company_id: this.companyId,
      team_id: this.id,
      page: this.page,
      per_page: this.perPage,
      search: this.search,
    };
  }

  created() {
    this.fetchTeam();
    this.fetchMembers();
  }

  onPageChange(page) {
    this.page = page;
  }

  fetchTeam() {
    adminTeamsApi.get({ company_id: this.companyId, id: this.id }).then((team) => {
      this.team = team;
      this.$store.commit(Mutations.setTitle, team.name + " - " + i18n.t("company_admin.teams.members"));
    });
  }

  @Watch("params")
  fetchMembers() {
    adminTeamMembersApi.get(this.params).then((members) => {
      this.members = members;
      this.total = members.total;
    });
  }

  addMember(role) {
    return adminTeamMembersApi.post({ company_id: this.companyId, team_id: this.id, role_id: role.id }).then(() => {
      this.fetchMembers();
    });
  }

  removeMember(role) {
    return adminTeamMembersApi.delete({ company_id: this.companyId, team_id: this.id, id: role.id }).then(() => {
      this.fetchMembers();
    });
  }
}
</script>
