import Company from "@/model/company";
import { makeApiInstance } from "./instance";

const buildUrl = ({ id }) => {
  let url = "/api/admin/companies";
  if (id) {
    url += `/${id}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: (params) => apiInstance.get(buildUrl(params)).then((response) => response.data),
  put: ({ id, company }: { id: number; company: Partial<Company> }) =>
    apiInstance.put(buildUrl({ id }), { company }).then((response) => response.data),
  getRestrictedReports: (params) =>
    apiInstance.get(buildUrl(params) + "/restricted_reports").then((response) => response.data),
  getRestrictedDashboards: (params) =>
    apiInstance.get(buildUrl(params) + "/restricted_dashboards").then((response) => response.data),
  getBudgetConfiguration: ({ id, ...params }) =>
    apiInstance.get(buildUrl({ id }) + "/budget_configuration", { params }).then((response) => response.data),
};

export default api;
