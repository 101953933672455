<template lang="pug">
uiv-modal(v-model="open" :title="'newsletter.definition.messages.select_section' | i18n")
  div(v-for='(section, index) in sections')
    .section-thumbnail(@click='ok(section)')
      .image
        .section-type-image(:class="section")
      .caption
        h3 {{ 'newsletter.definition.sections.' + section + '.title' | i18n }}
        p {{ 'newsletter.definition.sections.' + section + '.select_desc' | i18n }}
    hr(v-if='index < sections.length - 1')
  div(slot="footer")
    button.btn.btn-default(@click="cancel") {{ 'actions.cancel' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SectionTypes from "./section-types";

@Component({})
export default class AddSectionModal extends Vue {
  @Prop()
  newsletterType: string;

  @Prop()
  allowedSectionTypes: string[];

  open = false;

  get sections() {
    let sectionTypes = this.newsletterType == "alert" ? SectionTypes.alert() : SectionTypes.all();
    if (this.allowedSectionTypes) {
      sectionTypes = sectionTypes.filter((type) => this.allowedSectionTypes.includes(type));
    }
    return sectionTypes;
  }

  show() {
    this.open = true;
  }

  ok(sectionId) {
    const section = SectionTypes.get(sectionId);
    this.$emit("add", section);
    this.open = false;
  }

  cancel() {
    this.open = false;
  }
}
</script>
