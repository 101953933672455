<template lang="pug">
.sidenav-analytics
  sidenav-search-box(v-model="search")
  sidenav-search-results(v-if="search" :reports="searchResults")
  sidenav-category(v-else :category="category" v-for="category in navigationItems" :key="category.name")
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import SidenavCategory from "./sidenav-category.vue";
import SidenavSearchBox from "./sidenav-search-box.vue";
import SidenavSearchResults from "./sidenav-search-results.vue";

@Component({
  components: { SidenavCategory, SidenavSearchBox, SidenavSearchResults },
})
export default class SidenavAnalytics extends Vue {
  search = "";

  get navigationItems(): any[] {
    return this.$store.state.navigation.data?.analytics || [];
  }

  get allReports() {
    return _.flatten(this.navigationItems.map((category) => _.flatten(category.folders.map(({ reports }) => reports))));
  }

  get searchResults() {
    console.log(this.search);
    const search = this.search.trim().toLowerCase();
    if (search.length === 0) {
      return this.allReports;
    }
    return this.allReports.filter(({ name }) => name?.toLowerCase().includes(search));
  }
}
</script>
