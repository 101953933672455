<template lang="pug">
modal(:title="'activerecord.attributes.company.business_hours' | i18n", v-model="open")
  .row
    .col-md-6
      label {{ 'components.business_hours_editor.store_selection' | i18n }}
      select(v-model="storeId")
        option(:value="null") {{ 'components.business_hours_editor.company_default' | i18n }}
        option(:value="String(store.id)", v-for="store in stores", :key="store.id") {{ store.name }}
  .row
    .col-md-6
      label {{ 'components.business_hours_editor.start_date' | i18n }}
      input(type="date" v-model="startDate")
    .col-md-6
      label {{ 'components.business_hours_editor.end_date' | i18n }}
      input(type="date" v-model="endDate")
  .row(v-if="itemsByDay")
    .col-md-12
      .flex-column.gap-lg
        business-hours-day-selector(:day="day" v-model="itemsByDay[day]" v-for="day in days" :key="day")
  .row.defaults(v-if="!itemsByDay && defaultItemsByDay")
    .col-md-12
      .flex-column.gap-lg
        business-hours-day-selector(:day="day" :value="defaultItemsByDay[day]" :is-default="true" v-for="day in days" :key="day" @input="onDefaultItemUpdated(day, $event)")
  div(slot="footer")
    button.btn.btn-default(@click="open = false") {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(:disabled="!isValid" @click="onSave") {{ 'actions.save' | i18n }}
</template>

<script>
import Vue from "vue";
import businessHoursDaySelector from "./business-hours-day-selector";
import { Modal } from "uiv";

export default {
  components: {
    Modal,
    businessHoursDaySelector,
  },
  props: {
    days: Array,
    stores: Array,
    config: Object,
    configs: Array,
  },
  data() {
    return {
      storeId: null,
      startDate: null,
      endDate: null,
      itemsByDay: null,
      open: false,
    };
  },
  computed: {
    isValid() {
      const config = this.getConfigFromModel();
      return config.start_date;
    },
    defaultItemsByDay() {
      if (this.startDate && !this.itemsByDay) {
        const activeConfig = this.findActiveConfig();
        if (activeConfig) {
          return _.groupBy(activeConfig.items, (item) => item.weekday);
        } else {
          return {};
        }
      }
      return null;
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    onSave() {
      const config = this.getConfigFromModel();
      this.$emit("save", config);
      this.open = false;
    },
    getConfigFromModel() {
      return {
        id: this.config.id,
        organisation_nk: this.storeId,
        start_date: this.startDate,
        end_date: this.endDate,
        items: _.flatten(_.values(this.itemsByDay || this.defaultItemsByDay || {})),
      };
    },
    getModelFromProps() {
      this.storeId = this.config.organisation_nk;
      this.startDate = this.config.start_date;
      this.endDate = this.config.end_date;
      this.itemsByDay = this.config.items ? _.groupBy(this.config.items, (item) => item.weekday) : null;
    },
    findActiveConfig() {
      const storeConfigs = _.sortBy(
        this.storeId ? this.configs.filter(({ organisation_nk }) => organisation_nk === this.storeId) : [],
        "start_date"
      ).reverse();

      const companyConfigs = _.sortBy(
        this.configs.filter(({ organisation_nk }) => !organisation_nk),
        "start_date"
      ).reverse();

      const configs = [...storeConfigs, ...companyConfigs].filter(({ start_date, end_date }) => {
        return start_date <= this.startDate && (!end_date || end_date >= this.startDate);
      });
      return _.first(configs);
    },
    onDefaultItemUpdated(day, config) {
      this.itemsByDay = {
        ...this.defaultItemsByDay,
        [day]: config,
      };
    },
  },
  created() {
    this.getModelFromProps();
  },
  watch: {
    config() {
      this.getModelFromProps();
    },
  },
};
</script>

<style lang="scss" scoped>
.scope-selector {
  margin-bottom: 5px;
}
.row {
  margin-bottom: 10px;
}
</style>
