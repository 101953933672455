<template lang="pug">
.newsletter-template-editor
  newsletter-definition-editor-view(
    :title="title"
    :save="save"
    :definition="template"
    :template="true",
    :allowed-section-types="allowedSectionTypes"
    )
</template>

<script lang="ts">
import NewsletterDefinitionEditorView from "../../newsletter-definition-editor/newsletter-definition-editor-view.vue";
import Vue from "vue";
import Component from "vue-class-component";
import Configuration from "../../newsletter-definition-editor/configuration";
import { makeApiInstance } from "../../api/instance";
import { Prop } from "vue-property-decorator";
import i18n from "../../i18n";
import newsletterDefinitionsApi from "../../api/admin-newsletter-definitions-api";

@Component({
  components: {
    NewsletterDefinitionEditorView,
  },
})
export default class NewsletterTemplateEditor extends Vue {
  @Prop()
  id: string;

  @Prop()
  templateId: string;

  @Prop()
  definitionId: string;

  template = null;

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/newsletter_templates/" });
  }

  get allowedSectionTypes() {
    return ["metric_comparison", "top", "metric_extremes", "time_series"];
  }

  get title() {
    if (this.id) {
      return i18n.t("newsletter_templates.edit_newsletter_template");
    } else {
      return i18n.t("newsletter_templates.new_newsletter_template");
    }
  }

  get companyId() {
    return window.zoinedContext.companyId;
  }

  async created() {
    if (this.id) {
      const template = await this.api.get(this.id).then((response) => response.data);
      template.configuration = Configuration.fromHash(template.configuration);
      this.template = template;
    } else if (this.templateId) {
      const template = await this.api.get(this.templateId).then((response) => response.data);
      template.configuration = Configuration.fromHash(template.configuration);
      delete template.id;
      template.title = `Copy of ${template.title}`;
      this.template = template;
    } else if (this.definitionId) {
      const definition = await newsletterDefinitionsApi.get({ id: this.definitionId, company_id: this.companyId });
      this.template = {
        title: definition.title,
        frequency: definition.frequency,
        translations: definition.translations,
        configuration: await Configuration.fromJson(definition.configuration),
      };
      this.template.configuration.sections = this.template.configuration.sections.filter((section) =>
        this.allowedSectionTypes.includes(section.type.id)
      );
    } else {
      this.template = {
        translations: {},
        configuration: new Configuration({ use_default_parameters: false }),
      };
    }
  }

  async save({ id, configuration, partners = [], ...newsletter_template }) {
    const url = id ? String(id) : "";
    const method = id ? "put" : "post";

    newsletter_template.configuration = configuration.toHash();
    newsletter_template.partner_ids = partners.map(({ id }) => id);

    await this.api.request({
      url,
      method,
      data: {
        newsletter_template,
      },
    });
    this.$router.back();
  }
}
</script>
