<template lang="pug">
pill-list(:title="title" :items="items" :available-items="menuItems" :radio="radio" :draggable="true" @update="onUpdateItems($event.items)")
</template>

<script lang="ts">
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PillList from "./pill-list.vue";
import FilterConfigurationItemMap, {
  filterConfigurationItemMapFromPillItemList,
} from "../model/filter-configuration-item-map";
import PillItem, { pillItemListFromFilterConfigurationItemMap } from "../interfaces/pill-item";
import i18n from "../i18n";
import Actions from "../store/actions";

@Component({ components: { PillList } })
export default class WidgetFilterSelector extends Vue {
  @Prop()
  name: string;

  @Prop()
  config: FilterConfigurationItemMap;

  @Prop({ default: false })
  radio: boolean;

  @Prop()
  availableItems: MenuItem[];

  get title(): string {
    return i18n.t("filter.config." + this.name);
  }

  get menuItems(): MenuItem[] {
    return this.availableItems || this.$store.getters.getParameters(this.paramKey);
  }

  get items(): PillItem[] {
    return pillItemListFromFilterConfigurationItemMap(this.config).map((item) => {
      if (!item.name) {
        const menuItem = this.menuItems?.find((menuItem) => menuItemKey(menuItem) === item.value);
        item.name = menuItem?.name || item.value;
      }
      return item;
    });
  }

  get paramKey() {
    if (["metrics", "metrics2", "metrics_x", "metrics_y", "metrics_z"].includes(this.name)) {
      return "metrics";
    } else if (
      [
        "grouping",
        "grouping_x",
        "grouping_y",
        "grouping_stack",
        "column_grouping",
        "top_grouping",
        "total_grouping",
        "dimensions",
      ].includes(this.name)
    ) {
      return "grouping";
    } else if (["sort", "sort_x", "sort_y", "sort_stack"].includes(this.name)) {
      return "sort";
    } else {
      return this.name;
    }
  }

  onUpdateItems(items: PillItem[]) {
    const config = filterConfigurationItemMapFromPillItemList(items);

    this.$emit("update", config);
  }

  fetchMenuItems() {
    this.$store.dispatch(Actions.fetchParameters(this.paramKey));
  }

  created() {
    if (!this.menuItems) {
      this.fetchMenuItems();
    }
  }
}
</script>
