<template lang="pug">
.flex-inline-row.align-items-center.flex-wrap.gap-lg
  .title(v-if="title") {{ title }}
  dropdown-select(button-style="primary" v-bind="$attrs" v-on="$listeners")
</template>

<script>
import DropdownSelect from "./dropdown-select.vue";

export default {
  props: {
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    DropdownSelect,
  },
};
</script>
