import ChartOptions from "../model/chart-options";
import FilterConfiguration from "../model/filter-configuration";
import { Base64 } from "js-base64";
import $ from "jquery";
import _ from "lodash";
import Company from "../model/company";

export interface OpenReportParams {
  report: string;
  grouping: string;
  metric?: string;
  limit?: number;
  sort?: string;
  proportions?: boolean;
}

export function openReport(filterConfiguration: FilterConfiguration, params: OpenReportParams, company: Company) {
  filterConfiguration = _.clone(filterConfiguration);
  const chartOptions: ChartOptions = {
    data_labels: true,
  };

  delete filterConfiguration.widgets.snippets;
  delete filterConfiguration.widgets.charts;

  let grouping = {};
  grouping[params.grouping] = { enabled: true };
  filterConfiguration.widgets.grouping = grouping;

  if (params.metric != null) {
    let metrics = {};
    metrics[params.metric] = { enabled: true };
    filterConfiguration.widgets.metrics = metrics;
  }

  if (params.limit != null) {
    filterConfiguration.raw_filters = {
      ...filterConfiguration.raw_filters,
      limit: params.limit,
    };
  }

  if (params.sort != null) {
    filterConfiguration.widgets.sort = {
      [params.sort]: { enabled: true, value: params.sort },
    };
  }

  if (params.proportions) {
    filterConfiguration.raw_filters.proportions = true;
  }

  let config = Base64.encode(
    JSON.stringify({
      filter_configuration: filterConfiguration,
      chart_options: chartOptions,
    })
  );

  // Customer-specific overrides.
  let report_name =
    params.report === "organization_adhoc" && ["cognimatics", "axis"].includes(company.partner_id)
      ? (() => {
          switch (params.metric) {
            case "cars_in":
            case "cars_out":
              return "parking_adhoc";
            case "avg_occupancy":
              return "occupancy_adhoc";
            case "tracked_males_count":
            case "tracked_females_count":
            case "percentage_males":
            case "percentage_females":
            case "avg_visitor_age":
              return "demographics_adhoc";
            default:
              if (/queue/.test(params.metric)) {
                return "queue_adhoc";
              } else {
                return "people_counting_adhoc";
              }
          }
        })()
      : params.report;

  let form = $("<form>")
    .attr("method", "POST")
    .attr("action", `/analytics/report/${report_name}`);

  let input = $("<input>")
    .attr("type", "hidden")
    .attr("name", "config")
    .val(config);

  form.append(input);
  form.appendTo("body");

  return form.trigger("submit");
}
