const Actions = {
  fetchParameters(param: string) {
    return `parameters/${param}/fetch`;
  },

  get fetchSystemDashboard() {
    return "reportContext/fetchSystemDashboard";
  },

  get fetchCustomDashboard() {
    return "reportContext/fetchCustomDashboard";
  },

  get fetchAnalyticsReport() {
    return "reportContext/fetchAnalyticsReport";
  },

  get fetchFiltersets() {
    return "filtersets/fetch";
  },

  get fetchPdfReports() {
    return "pdfReports/fetch";
  },

  get fetchExcelReports() {
    return "excelReports/fetch";
  },

  get fetchCustomPeriods() {
    return "customPeriods/fetch";
  },

  get fetchNavigation() {
    return "navigation/fetch";
  },

  get updateDetailChartMetricPreferences() {
    return "preferences/updateDetailChartMetricPreferences";
  },
};

export default Actions;
