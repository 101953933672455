import RootState from "../root-state";
import { Module } from "vuex";

export interface CurrentPageState {
  title?: string;
}

const currentPage: Module<CurrentPageState, RootState> = {
  namespaced: true,
  state: () => ({ title: undefined }),
  mutations: {
    setTitle: (state, title: string) => Object.assign(state, { title }),
    clearTitle: (state) => Object.assign(state, { title: undefined }),
  },
};

export default currentPage;
