<template lang="pug">
modal(v-model="open" :title="title" size="sm" :append-to-body="true")
  form.form(v-if="model" @submit.prevent="save")
    .form-group
      label.control-label {{"attributes.name" | i18n}}
      input.form-control(type="text", v-model="model.name")
  div(slot="footer")
    button.btn.btn-default(@click="hide") {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(@click="save") {{ 'actions.ok' | i18n }}
</template>

<script lang="ts">
import Category from "../model/category";
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";

@Component({
  components: {
    Modal,
  },
})
export default class EditCategoryModal extends Vue {
  open = false;
  model: Category = null;

  get title() {
    return this.model?.id ? "Edit folder" : "New folder";
  }

  show(category: Category) {
    this.model = { ...category };
    this.open = true;
  }

  hide() {
    this.open = false;
  }

  save() {
    this.$emit("save", this.model);
    this.open = false;
  }
}
</script>
