import _ from "lodash";
import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id, id }) => {
  let url = `/api/admin/companies/${company_id}/newsletter_subscriptions`;
  if (id) {
    url += `/${id}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: ({ company_id, id = null, ...params }) =>
    apiInstance
      .get(buildUrl({ company_id, id }), { params })
      .then(({ data, headers }) => (headers.total ? Object.assign(data, { total_count: headers.total }) : data)),
  delete: ({ company_id, id }) => apiInstance.delete(buildUrl({ company_id, id })).then(() => null),
  deliver: ({ company_id, id }) => apiInstance.post(buildUrl({ company_id, id }) + "/deliver").then(() => null),
};

export default api;
