import moment from "moment";

export const date = (value, format = "L") => {
  if (!value) {
    return "";
  }
  return moment(value).format(format);
};

export const dateTime = (value, format = "L, LT") => {
  if (!value) {
    return "";
  }
  return moment(value).format(format);
};
