<template>
  <div class="ai-assistant-panel flex-column">
    <div class="drag-handle mb-md" @click="toggleOpen">
      <div class="drag-handle-bar"></div>
    </div>
    <div class="content-container flex-1 min-h-0" :class="{ open }">
      <div class="content flex-column">
        <div
          class="flex-row align-items-center justify-content-center bg-surface-emphasis-primary-1 rounded p-md relative"
        >
          <button
            class="btn btn-sm btn-tertiary flex-row align-items-center gap-sm"
            :class="{ active: tab == 'chat' }"
            @click="tab = 'chat'"
          >
            <icon icon="fluent:wand-16-filled" />
            {{ t("actions.create") }}
          </button>
          <div class="fg-border-variant">|</div>
          <button
            class="btn btn-sm btn-tertiary flex-row align-items-center gap-sm"
            :class="{ active: tab == 'history' }"
            @click="tab = 'history'"
          >
            <icon icon="fluent:history-16-filled" />
            {{ t("ai_assistant.history") }}
          </button>
          <button class="close-button btn btn-icon btn-sm btn-primary hidden-xs hidden-sm" @click="toggleOpen">
            <icon icon="fluent:dismiss-16-filled" />
          </button>
        </div>
        <component :is="activeComponent" @create-message="onCreateMessage" />
      </div>
    </div>
    <form @submit="onSubmit">
      <input
        type="text"
        :placeholder="t('ai_assistant.ask_from_zed')"
        :disabled="isProcessing"
        v-model="messageInputValue"
        @focus="onInputFocus"
      />
    </form>
  </div>
</template>

<script setup lang="ts">
import { useCreateAiMessage, useCreateAiSession } from "@/ai-assistant/ai-assistant-api";
import AiMessage from "@/ai-assistant/ai-message";
import AiSession from "@/ai-assistant/ai-session";
import { computed, ref } from "vue";
import { Icon } from "@iconify/vue2";
import { useStore } from "@/composables/store";
import Mutations from "@/store/mutations";
import { useRoute, useRouter } from "vue-router/composables";
import { useI18n } from "@/composables/i18n";
import { useIsMobile } from "@/composables/is-mobile";
import FilterConfiguration from "@/model/filter-configuration";
import { availableComponents } from "@/custom-dashboard-editor/component-types";
import { filterToFlyover, flatFilterConfiguration } from "@/lib/filter-util";
import _ from "lodash";
import Component from "@/model/component";
import aiAssistantChat from "@/ai-assistant/ai-assistant-chat.vue";

const tab = ref("chat");

const activeComponent = computed(() => {
  switch (tab.value) {
    case "chat":
      return aiAssistantChat;
    case "history":
      return "div";
  }
});

const messageInputValue = ref("");

const session = ref<AiSession>(null);

const route = useRoute();

const router = useRouter();

const store = useStore();

const { t } = useI18n();

const createSessionMutation = useCreateAiSession();

const createMessageMutation = useCreateAiMessage();

const isMobile = useIsMobile();

const messageContext = computed(() => {
  const context = {};

  const lastComponent = _.last(store.state.aiAssistant.components) as Component;
  const lastMessage = _.last(store.state.aiAssistant.messages) as AiMessage;

  if (lastComponent?.filterConfiguration) {
    context["filterConfiguration"] = filterToFlyover(lastComponent.filterConfiguration);
  }

  if (lastMessage?.answer?.chart) {
    context["chart"] = lastMessage.answer.chart;
  }

  return context;
});

const onCreateMessage = ({ message, use = null }) => {
  createMessage(message, use);
};

const createMessage = (message: string, use = null) => {
  const context = messageContext.value;

  store.commit(Mutations.addAiMessage, { session_id: null, message });

  const _doCreateSession = () => {
    createMessageMutation.mutate(
      { session_id: session.value.session_id, message, context, use },
      {
        onSuccess: (aiMessage: AiMessage) => {
          messageInputValue.value = "";
          store.commit(Mutations.amendAiMessage, aiMessage);
          if (aiMessage.answer) {
            addComponent(aiMessage.answer);
          }
          if (route.name !== "zed_workspace") {
            router.push({ name: "zed_workspace" });
          }
          if (isMobile.value) {
            setTimeout(() => {
              if (open) {
                toggleOpen();
              }
            }, 2000);
          }
        },
      }
    );
  };

  if (!session.value) {
    createSessionMutation.mutate(null, {
      onSuccess: (data) => {
        session.value = data;
        _doCreateSession();
      },
    });
  } else {
    _doCreateSession();
  }
};

const isProcessing = computed(() => createSessionMutation.isPending.value || createMessageMutation.isPending.value);

const open = computed(() => store.state.aiAssistant.open);

const onSubmit = async (event: Event) => {
  event.preventDefault();

  if (messageInputValue.value.trim().length === 0) {
    return;
  }

  createMessage(messageInputValue.value);
};

const onInputFocus = () => {
  if (!open.value) {
    toggleOpen();
  }
};

const toggleOpen = () => {
  store.commit(Mutations.toggleAiAssistant);
};

const addComponent = ({ chart, filterConfiguration }: { chart: string; filterConfiguration: FilterConfiguration }) => {
  const componentType = getComponentType(chart);
  const component = getComponent(componentType, filterConfiguration);
  const components = [...store.state.aiAssistant.components, component];
  store.commit(Mutations.setAiComponents, components);
};

const getComponentType = (chart: string) => {
  switch (chart) {
    case "pie":
      return "pie";
    case "line":
      return "line";
    case "bar":
    case "column":
      return "dual_axis";
    case "table":
      return "cross_table";
    case "cross-table":
      return "cross_tab_custom";
  }
};

const getComponent = (componentType, filterConfiguration) => {
  const baseConfiguration = availableComponents[componentType];
  filterConfiguration = flatFilterConfiguration(filterConfiguration);

  return {
    ...baseConfiguration,
    filterConfiguration,
  };
};
</script>

<style lang="scss" scoped>
.ai-assistant-panel {
  // background-color: var(--color-surface-neutral-1);
  background-color: white;
  width: 100%;
  height: auto;
  max-height: 70vh;
  padding: 5px 15px 15px 15px;
  border-top-left-radius: var(--border-radius-md);
  border-top-right-radius: var(--border-radius-md);
  box-shadow: 0px -2px 20px 0px rgba(217, 217, 217, 0.5);
  font-size: 12px;

  .drag-handle {
    display: flex;
    justify-content: center;
    padding: 5px 0;

    .drag-handle-bar {
      width: 40px;
      height: 5px;
      border-radius: 5px;
      background-color: var(--color-border-variant-2);
    }

    @media (min-width: 992px) {
      display: none;
    }
  }

  .content-container {
    overflow: hidden;
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.25s ease;

    &.open {
      grid-template-rows: 1fr;
    }

    .content {
      overflow: hidden;
    }

    @media screen and (min-width: 992px) {
      display: flex;
      flex-direction: column;

      overflow: visible;

      .content {
        flex: 1;
        min-height: 0;
        overflow: visible;
        padding-bottom: 15px;
      }
    }
  }

  input {
    border: none;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
    max-width: unset;
    background: var(--color-surface-neutral-2);
    color: var(--color-text-variant);
    font-size: 14px;
    border-radius: var(--border-radius-sm);

    &:focus,
    &:not(:placeholder-shown) {
      background-color: transparent;
      border: 1px solid var(--color-border-emphasis);
    }
  }

  button.close-button {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(50%);
  }

  @media (min-width: 992px) {
    height: 100%;
    max-height: unset;
    padding: 15px;
    border-bottom-left-radius: var(--border-radius-md);
    border-bottom-right-radius: var(--border-radius-md);
  }
}
</style>
