import { makeApiInstance } from "./instance";

const apiInstance = makeApiInstance({ baseURL: "/api/v1/newsletter_subscriptions" });

const api = {
  getAll: () => apiInstance.get("").then((response) => response.data),
  create: (newsletter_subscription) =>
    apiInstance.post("", { newsletter_subscription }).then((response) => response.data),
  destroy: (newsletter_subscription) => apiInstance.delete(newsletter_subscription.id.toString()).then(() => null),
};

export default api;
