<template>
  <div class="ai-messages flex-1 fg-text-variant flex-column gap-xxl min-h-0 overflow-y-scroll scrollbar-hidden">
    <div>{{ t("ai_assistant.chat_info_text") }}</div>
    <ai-assistant-lottery
      v-if="aiMessages.length == 0"
      @create-message="emit('create-message', $event)"
    ></ai-assistant-lottery>
    <ai-message-view :ai-message="aiMessage" v-for="(aiMessage, index) in aiMessages" :key="index"></ai-message-view>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed } from "vue";
import AiMessageView from "@/ai-assistant/ai-message-view.vue";
import aiAssistantLottery from "@/ai-assistant/ai-assistant-lottery.vue";
import { useI18n } from "@/composables/i18n";

const emit = defineEmits(["create-message"]);

const { t } = useI18n();

const store = useStore();

const aiMessages = computed(() => store.state.aiAssistant.messages);
</script>

<style scoped>
.ai-messages {
  padding: 10px 15px;

  @media screen and (min-width: 992px) {
    padding: 10px 0;
  }
}
</style>
