<template lang="pug">
.choice-chip(:class="{ selected: selected }" @click="$emit('select')")
  slot
</template>

<script lang="ts">
export default {
  props: {
    selected: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.choice-chip {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 2px 5px;
  height: 22px;
  min-width: 40px;
  border-radius: 9999px;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  border: 1px solid var(--color-interactive-bg-emphasis-2);
  background-color: white;
  color: var(--color-interactive-bg-emphasis-2);

  &.selected {
    background-color: var(--color-interactive-bg-emphasis-2);
    color: white;
  }

  &::v-deep svg {
    width: 12px;
    height: 12px;
    background-color: white;
    border-radius: 9999px;
    overflow: hidden;
  }
}
</style>
