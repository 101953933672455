import { getDownloadUrl } from "../channel/file-download-channel";
import { v4 as uuidv4 } from "uuid";
import I18n from "../i18n";
import _ from "lodash";
import { Base64 } from "js-base64";
import { downloadFile } from "./download";
import toastr from "toastr";
import ChartOptions from "../model/chart-options";
import ReportComponent from "../model/report-component";
import FilterConfiguration from "../model/filter-configuration";
import { hideOverlay, showOverlay } from "../ui/overlay";
import { showDialog } from "../vue-app/utils";
import PdfExportDialog from "../export/pdf-export-dialog.vue";

interface ExportReportOpts {
  filterConfiguration?: FilterConfiguration;
  chartOptions?: ChartOptions;
  tableConfig?: any;
  components?: ReportComponent[];
  format: "pdf" | "xlsx";
}

interface ExportDashboardOpts {
  filterConfiguration: FilterConfiguration;
  format: "pdf";
}

export async function exportReport(
  reportName: string,
  { filterConfiguration, chartOptions, tableConfig, components, format }: ExportReportOpts
) {
  const download_id = uuidv4();
  const configObject = _.cloneDeep(
    _.extend(
      {},
      {
        filter_configuration: filterConfiguration,
        chart_options: chartOptions,
        table_config: tableConfig,
      }
    )
  );
  if (format === "xlsx" && configObject.filter_configuration.raw_filters) {
    delete configObject.filter_configuration.raw_filters.limit;
  }
  const time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const data: any = {
    download_id,
    time_zone,
    config: Base64.encode(JSON.stringify(configObject)),
  };
  if (components) {
    data.components = Base64.encode(JSON.stringify(components));
  }

  if (format === "pdf") {
    const settings = await showDialog(PdfExportDialog);
    if (!settings) {
      return;
    }
    Object.assign(data, settings);
  }

  showOverlay(I18n.t("processing_export_file"));
  try {
    // Listen for export finish
    const downloadUrl = await getDownloadUrl(download_id, {
      connected: () => {
        // Start export
        fetch("/analytics/export/" + reportName + "." + format, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      },
    });
    // Download file for user
    await downloadFile(downloadUrl);
  } catch (error) {
    console.log(error);
    (window.Rollbar || console).error("Export failed. Download id: " + download_id);
    toastr.error(I18n.t("errors.export_fail"), "", { timeOut: 0, extendedTimeOut: 1 });
  } finally {
    hideOverlay();
  }
}

export async function exportDashboard({ filterConfiguration, format }: ExportDashboardOpts) {
  const download_id = uuidv4();

  const data = {
    download_id,
    config: Base64.encode(JSON.stringify(filterConfiguration)),
  };

  if (format === "pdf") {
    const settings = await showDialog(PdfExportDialog);
    if (!settings) {
      return;
    }
    Object.assign(data, settings);
  }

  showOverlay(I18n.t("processing_export_file"));

  try {
    // Listen for export finish
    const url = await getDownloadUrl(download_id, {
      connected: () => {
        // Start export
        fetch(`/dashboard/export.${format}`, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
      },
    });

    // Download file for user
    await downloadFile(url);
  } catch (error) {
    (window.Rollbar || console).error("Export failed. Download id: " + download_id);
    toastr.error(I18n.t("errors.export_fail"), "", { timeOut: 0, extendedTimeOut: 1 });
  } finally {
    hideOverlay();
  }
}
