import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id, id }) => {
  let url = `/api/admin/companies/${company_id}/users`;
  if (id) {
    url += `/${id}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: ({ company_id, id = null, ...params }) =>
    apiInstance
      .get(buildUrl({ company_id, id }), { params })
      .then(({ data, headers }) => (headers.total ? Object.assign(data, { total_count: headers.total }) : data)),
  post: (params) => apiInstance.post(buildUrl(params), params).then((response) => response.data),
  put: (params) => apiInstance.put(buildUrl(params), params).then((response) => response.data),
  delete: (params) => apiInstance.delete(buildUrl(params), params).then((response) => response.data),
  invite: (params) => apiInstance.post(buildUrl(params) + "/invite", params).then((response) => response.data),
  resendInvite: (params) => apiInstance.post(buildUrl(params) + "/resend_invite").then((response) => response.data),
};

export default api;
