<template lang="pug">
.dashboard-components-grid-editor
  draggable.component-row(v-model='components', :options="{draggable:'.component-wrapper', handle: '.handle'}", @change="updateComponents(components)")
    .component-wrapper(v-for='(component, index) in components', :class="{half: component.span == 6, third: component.span == 4, quarter: component.span == 3}")
      zoined-report-component.component-item(
        :index="index",
        :snippet="component",
        :component="component",
        :filter-configuration="flatFilterConfiguration",
        :remove="removeComponent",
        :duplicate="duplicateComponent",
        :editor='true',
        :chart-options="effectiveChartOptions(component)",
        :showEditComponentModal="showAddComponentModal",
        :showFilterConfiguration="true",
        :custom="true",
        :dashboard="true",
        @componentUpdated="onComponentUpdated(component, $event)"
      )
    .add-component-button.half
      div.panel.panel-default(id="new-component-button") 
        .button(@click="showAddComponentModal()")
          .icon
            i.fa.fa-plus
          .title {{ 'dashboard_custom.report_editor.add_component' | i18n }}
        .button(@click="showEmbedReportModal()")
          .icon
            i.fa.fa-download 
          .title {{ 'dashboard_custom.report_editor.embed_report' | i18n }}
  add-component-modal(ref="addComponentModal", :addComponent="addComponent", :setComponent="setComponent", :options="options", :filter-configuration="flatFilterConfiguration", :shared="shared")
  embed-report-modal(ref="embedReportModal" @embedComponents="embedComponents")
</template>

<script lang="ts">
import _ from "lodash";
import { flatFilterConfiguration } from "../lib/filter-util";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import AddComponentModal from "./add-component-modal.vue";
import EmbedReportModal from "./embed-report-modal.vue";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import ReportConfig from "../model/report-config";
import FilterConfiguration from "../model/filter-configuration";

@Component({
  components: {
    draggable,
    AddComponentModal,
    EmbedReportModal,
    zoinedReportComponent,
  },
})
export default class DashboardComponentsGridEditor extends Vue {
  @Prop()
  filterConfiguration: FilterConfiguration;

  @Prop()
  config: ReportConfig;

  @Prop()
  chartOptions: any;

  @Prop()
  options: any;

  @Prop({ default: false })
  shared: boolean;

  @Ref("addComponentModal")
  addComponentModal: any;

  @Ref("embedReportModal")
  embedReportModal: any;

  components: any[] = null;

  get flatFilterConfiguration() {
    return flatFilterConfiguration(this.filterConfiguration);
  }

  effectiveChartOptions(component) {
    return {
      ...this.chartOptions,
      ...component.chartOptions,
    };
  }

  showAddComponentModal(index) {
    if (parseInt(index) > -1) {
      this.addComponentModal.showAddComponentModal(this.components, index);
    } else {
      this.addComponentModal.showAddComponentModal();
    }
  }

  showEmbedReportModal() {
    this.embedReportModal.show();
  }

  embedComponents(components) {
    this.updateComponents([...this.components, ...components]);
  }

  removeComponent(index) {
    this.components.splice(index, 1);
    this.updateComponents(this.components);
  }

  duplicateComponent(index) {
    const duplicate = _.cloneDeep(this.components[index]);
    this.components.splice(index + 1, 0, duplicate);
    this.updateComponents(this.components);
  }

  addComponent(component) {
    const components = [...this.components, component];
    this.updateComponents(components);
  }

  setComponent(component, index) {
    this.components.splice(index, 1, component);
    this.updateComponents(this.components);
  }

  onComponentUpdated(component, newComponent) {
    const index = this.config.components.indexOf(component);
    this.setComponent(newComponent, index);
  }

  created() {
    this.onConfigUpdated();
  }

  @Watch("config")
  onConfigUpdated() {
    this.components = [...this.config.components];
  }

  private updateComponents(components: any[]) {
    this.$emit("update", {
      ...this.config,
      components,
    });
  }
}
</script>

<style lang="scss" scoped>
.panel {
  border-style: dashed !important;
}

#new-component-button {
  height: 250px;
  display: flex;
  color: #555;

  .button {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      color: #81e4ff;
    }

    .icon {
      font-size: 120px;
      line-height: 1;
    }

    &:first-child {
      border-right: 1px dashed #ddd;
    }
  }
}

.component-row {
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));

  grid-column-gap: 30px;
  grid-row-gap: 30px;

  > * {
    grid-column-end: span 12;

    &.half {
      grid-column-end: span 6;
    }

    &.third {
      grid-column-end: span 4;
    }

    &.quarter {
      grid-column-end: span 3;
    }
  }
}
</style>
