import ApiError from "../api/api-error";
import toastr from "toastr";
import i18n from "../i18n";
import ErrorReportingService from "../core/error-reporting.service.js";

export const handleError = (err: any) => {
  if (err.response?.status === 401) {
    window.location.href = "/";
    return;
  }
  new ErrorReportingService().error(err);
  if (err instanceof ApiError) {
    toastr.error(i18n.t("errors.network_error"));
  }
};

export const withErrorHandling = async <T extends () => Promise<any>>(fn: T) => {
  try {
    return await fn();
  } catch (error) {
    handleError(error);
    throw error;
  }
};
