<template lang="pug">
modal.embed-report-modal(v-model="open", :title="title", :footer="!!components")
  .mh-100(v-if="!reportsByCategory")
    spinner
  .content(v-else)
    .mh-100(v-if="components")
      div(v-if="components.length == 0")
        | {{ "dashboard_custom.report_editor.no_embeddable_components_message" | i18n }}
      zoined-report-component(
        v-for="(component, index) in components",
        :key="index",
        :component="component",
        :filterConfiguration="{}",
        :show-filter-configuration="true",
        :chartOptions="{ custom: true }",
        :custom="true",
        :dashboard="true",
        :preview="true"
      )
    div(v-else)
      div(v-for="category in categories")
        .category(v-if="reportsByCategory[category]", @click="toggleCategory(category)")
          .category__title 
            span {{ category }}
            .pull-right
              i.fa(:class="openCategory == category ? 'fa-caret-up' : 'fa-caret-down'")
        .category__reports(v-if="openCategory == category")
          .report(v-for="report in reportsByCategory[category]", @click="selectReport(report)")
            .report__title {{ reportTitle(report) }}
  div(slot="footer", v-if="components")
    button.btn.btn-default(@click="open = false") {{ (components.length == 0 ? "actions.ok" : "actions.cancel") | i18n }}
    button.btn.btn-primary(v-if="components.length > 0", @click="embedComponents") {{ "actions.confirm" | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import I18n from "../i18n";
import _ from "lodash";
import { flatFilterConfiguration } from "../lib/filter-util";
import { buildComponent } from "./utils";
import zoinedReportComponent from "../analytics/zoined-report-component.vue";
import spinner from "../components/spinner.vue";
import MyReport from "../model/my-report";
import Category from "../model/category";
import { Watch } from "vue-property-decorator";
import { makeApiInstance } from "../api/instance";

const supportedComponentTypes = ["dual_axis", "cross_table", "cross_tab_custom"];

@Component({
  components: {
    Modal,
    zoinedReportComponent,
    spinner,
  },
})
export default class EmbedReportModal extends Vue {
  open = false;

  reports: MyReport[] = null;

  savedReports: MyReport[] = null;

  openCategory = null;

  components = null;

  get title() {
    return I18n.t("dashboard_custom.report_editor.embed_report");
  }

  get categories(): string[] {
    if (!this.reports) {
      return null;
    }
    const categories: Category[] = Object.values(
      this.reports.reduce((result, { report: { category } }) => ({ ...result, [category.id]: category }), {})
    );
    const categoryNames = _.sortBy(categories, "id").map((category) => this.categoryTitle(category));
    console.log(categoryNames);

    return [I18n.t("analytics.index.my_reports"), I18n.t("analytics.index.company_reports"), ...categoryNames];
  }

  get reportsByCategory(): { [x: string]: MyReport[] } {
    if (!this.reports || !this.savedReports) {
      return null;
    }

    let reportsByCategory = {
      ..._.groupBy(this.savedReports, (myReport) =>
        myReport.user_id ? I18n.t("analytics.index.my_reports") : I18n.t("analytics.index.company_reports")
      ),
      ..._.groupBy(this.reports, (myReport) => this.categoryTitle(myReport.report.category)),
    };

    reportsByCategory = _.mapValues(reportsByCategory, (reports) =>
      _.sortBy(reports, (report) => report.report.sort_order)
    );

    return reportsByCategory;
  }

  show() {
    this.open = true;
    this.components = null;

    if (this.categories) {
      this.openCategory = this.categories[0];
    }
  }

  toggleCategory(category) {
    this.openCategory = this.openCategory === category ? null : category;
  }

  reportTitle(myReport: MyReport) {
    return myReport.name || I18n.t(`report.${myReport.report.name}.title`);
  }

  categoryTitle(category) {
    return I18n.t(`report.category.${category.name}.name`);
  }

  selectReport(myReport) {
    const {
      filter_configuration,
      report: {
        config: { components },
      },
    } = myReport;

    const filterConfiguration = flatFilterConfiguration(filter_configuration);

    this.components = components
      .filter(({ name }) => supportedComponentTypes.indexOf(name) >= 0)
      .map((component) => buildComponent(component, { filterConfiguration }));
  }

  embedComponents() {
    this.$emit("embedComponents", this.components);
    this.open = false;
  }

  created() {
    this.fetchReports();
    this.fetchSavedReports();
  }

  fetchReports() {
    return makeApiInstance()
      .get(`/api/v1/reports/analytics_reports`)
      .then((response) => response.data)
      .then((reports) => (this.reports = reports));
  }

  fetchSavedReports() {
    return makeApiInstance()
      .get(`/api/v1/reports/saved_reports`)
      .then((response) => response.data)
      .then((reports) => (this.savedReports = reports));
  }

  @Watch("categories")
  setOpenCategory() {
    if (!this.openCategory) {
      this.openCategory = this.categories[0];
    }
  }
}
</script>

<style lang="scss" scoped>
.category {
  padding: 10px;

  &__title {
    .icon {
      display: inline-block;
      width: 15px;
    }
  }
  background-color: #eee;
  margin-bottom: 5px;
  border-radius: 5px;
}
.report {
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 5px;

  &:hover {
    background-color: #cceeff;
  }
}

.mh-100 {
  min-height: 100px;
}
</style>

<style lang="scss">
.embed-report-modal {
  .modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
  }
}
</style>
