<template lang="pug">
.time-period-dropdown
  .title(v-if="showTitle") {{ title }}
  dropdown
    template(v-slot:toggle="toggleProps")
      .btn.btn-sm.btn-primary(:class="btnClasses" @click="toggleProps.toggleDropdown()")
        | {{ label }} 
        span.caret
    template(slot="menu")
      .simple-spinner(v-if="!availableItems")
        .spinner
      div(v-if="availableItems")
        dropdown-item(@click="selectItem(item)", v-for="item in items" :key="menuItemKey(item)") {{ getLabel({ type: menuItemKey(item) }) }}
        .divider(v-if="isLimited")
        more-with-pro-menu
          .divider(v-if="restItems.length > 0")
          dropdown-submenu(v-if="restItems.length > 0" :title="'filters.date_selector.more_options' | i18n")
            dropdown-item(@click="selectItem(item)", v-for="item in restItems" :key="menuItemKey(item)") {{ getLabel({ type: menuItemKey(item) }) }}
          .divider(v-if="custom")
          dropdown-submenu(v-if="custom" :title="'filters.date_selector.custom' | i18n")
            dropdown-calendar(mode="range" @select-custom-range="selectCustomRange")
          .divider
          dropdown-submenu(:title="'filters.date_selector.rolling_yearly_period' | i18n")
            dropdown-submenu(:title="'filters.date_selector.until_yesterday' | i18n")
              .text-xs.px-md.pt-sm {{ 'filters.date_selector.pick_start_date' | i18n }}
              .divider
              dropdown-calendar(mode="single" :min-date="yearStartDate" :max-date="yearEndDate" @select-date="selectCustomUntilYesterday")
                template(v-slot:title="headerProps") {{ headerProps.monthLabel }}
          div(v-if="availableSeries.length > 0")
            .divider
            dropdown-submenu(:title="'filters.date_selector.time_series_type' | i18n")
              dropdown-item(@click="selectSeries(series)", v-for="series in availableSeries" :key="series.name") {{ series.name }}
</template>

<script lang="ts">
import moment from "moment";
import TranslationService from "../core/translation.service";
import i18n from "../i18n";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Ref } from "vue-property-decorator";
import MenuItem, { menuItemKey } from "../interfaces/menu-item";
import _ from "lodash";
import Dropdown from "./dropdown.vue";
import DropdownItem from "./dropdown-item.vue";
import DropdownSubmenu from "./dropdown-submenu.vue";
import DropdownCalendar from "./dropdown-calendar.vue";
import MoreWithProMenu from "./more-with-pro-menu.vue";

@Component({
  components: {
    Dropdown,
    DropdownItem,
    DropdownSubmenu,
    DropdownCalendar,
    MoreWithProMenu,
  },
})
export default class TimePeriodDropdown extends Vue {
  @Ref("dropdown")
  dropdown: Dropdown;

  @Prop()
  availableItems: MenuItem[];

  @Prop()
  availableSeriesTypes: string[];

  @Prop()
  default: any;

  @Prop()
  value: any;

  @Prop({ default: true })
  showTitle: boolean;

  @Prop({ default: true })
  custom: boolean;

  @Prop({ default: 5 })
  showItems: number;

  @Prop({ default: i18n.t("dashboard_custom.report_editor.time") })
  title: string;

  readonly isLimited = window.zoinedContext.limited;

  get btnClasses() {
    return {
      default: this.default && !this.value,
    };
  }

  get label() {
    if (this.value) {
      return this.getLabel(this.value);
    } else if (this.default) {
      return this.getLabel(this.default);
    } else {
      return "Select";
    }
  }

  get items() {
    return this.isLimited
      ? this.availableItems.filter((item) => !this.isLimited || item.enabled_for_limited)
      : _.take(this.availableItems, this.showItems);
  }

  get restItems() {
    return _.drop(this.availableItems, this.showItems);
  }

  get availableSeries() {
    return (this.availableSeriesTypes || []).map((type) => ({
      type,
      name: new TranslationService().seriesTitle(type),
    }));
  }

  get yearStartDate() {
    return moment()
      .subtract(1, "year")
      .startOf("year")
      .toDate();
  }

  get yearEndDate() {
    return moment()
      .subtract(1, "year")
      .endOf("year")
      .toDate();
  }

  menuItemKey(item) {
    return menuItemKey(item);
  }

  selectItem(item) {
    const current = this.value || this.default || {};
    this.$emit("update", { ...current, type: menuItemKey(item) });
  }

  selectSeries({ type }) {
    const current = this.value || this.default || {};
    this.$emit("update", { ...current, series: type });
  }

  getLabel(selection) {
    return new TranslationService().selectionTitle(selection, this.availableItems);
  }

  selectCustomUntilYesterday(date) {
    let start = moment(date);
    const selection = {
      type: "custom_until_yesterday",
      start_time: start.format("YYYY-MM-DD"),
    };
    this.$emit("update", selection);
  }

  selectCustomRange([d1, d2]) {
    let start = moment(d1);
    let end = moment(d2);
    [start, end] = start.isAfter(end) ? [end, start] : [start, end];

    const current = this.value || this.default || {};
    const selection = {
      ...current,
      type: "custom",
      start_time: start.format("YYYY-MM-DD"),
      end_time: end.format("YYYY-MM-DD"),
    };

    this.$emit("update", selection);
  }
}
</script>

<style lang="scss" scoped>
.time-period-dropdown {
  display: inline-block;

  .title {
    display: inline-block;
    margin-right: 15px;
  }

  .btn.default {
    opacity: 0.5;
  }

  .dropdown-submenu {
    position: relative;
    .dropdown-menu {
      top: 0;
      left: 100%;
      &.open {
        display: block;
      }
      @media (max-width: 767px) {
        position: absolute;
        left: 0;
      }
    }
  }

  .vc-container {
    border: 0;
  }
}
</style>
