<template lang="pug">
.filter-category-section(:class="{ open }")
  .flex-row.cursor-pointer.category-title-row(@click="open = !open")
    .flex-1.category-title {{ category }}
    i.fa.fa-sm(:class="open ? 'fa-chevron-up' : 'fa-chevron-down'")
  .contents(v-if="open")
    .p-md.mt-md.flex-row.align-items-center.flex-wrap.gap-md.column-gap-xxl
      filter-selector(v-for="filter in enabledFilters" :key="filter.id" :filter="filter" :config="filtersConfiguration.filters[filter.id]" @update="updateFilter(filter.id, $event)")
      .mt-md.flex-column.sm-flex-row.gap-md.align-items-flex-start.sm-align-items-center(v-if="isLimited && limitedFilters.length > 0" @mouseleave="showMore = false")
        .more-btn(@mouseenter="showMore = true", @click="showMore = !showMore")
          more-with-pro-text
        transition(name="fade" appear)
          .limited-filters.flex-row.align-items-center.flex-wrap.gap-md.column-gap-xxl(v-if="showMore")
            filter-selector(v-for="filter in limitedFilters" :key="filter.id" :filter="filter" :disabled="true")
            a.btn.btn-emphasis.btn-rect.btn-sm
              | {{ 'limited_version.get_pro' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import FilterSelector from "../components/filter-selector.vue";
import Filter from "main/model/filter";
import FiltersConfiguration from "main/model/filters-configuration";
import moreWithProText from "main/components/more-with-pro-text.vue";
import _ from "lodash";

@Component({
  components: {
    FilterSelector,
    moreWithProText,
  },
})
export default class FilterCategorySection extends Vue {
  @Prop()
  category: string;

  @Prop()
  filters: Filter[];

  @Prop()
  filtersConfiguration: FiltersConfiguration;

  open = false;
  showMore = false;

  readonly isLimited = window.zoinedContext.limited;

  get enabledFilters() {
    if (!this.isLimited) {
      return this.filters;
    }
    return this.filters.filter((filter) => !this.isLimited || filter.enabled_for_limited);
  }

  get limitedFilters() {
    if (!this.isLimited) {
      return [];
    }
    return this.filters.filter((filter) => !filter.enabled_for_limited);
  }

  get activeFilters() {
    return this.filters.filter((filter) => !_.isEmpty(this.filtersConfiguration.filters[filter.id]));
  }

  get activeSetId() {
    return this.filtersConfiguration.sets?.find(({ enabled }) => enabled)?.id;
  }

  get isCleared() {
    return _.isEmpty(this.filtersConfiguration.filters);
  }

  created() {
    this.open = this.activeFilters.length > 0;
  }

  updateFilter(filter: string, value: any) {
    this.$emit("update", { filter, value });
    const filtersConfiguration = {
      ...this.filtersConfiguration,
      filters: {
        ...this.filtersConfiguration.filters,
        [filter]: value,
      },
    };
    this.$emit("update", filtersConfiguration);
  }

  @Watch("activeSetId")
  onActiveSetChanged() {
    if (this.activeSetId) {
      this.open = this.activeFilters.length > 0;
    }
  }

  @Watch("isCleared")
  onFiltersCleared() {
    if (this.isCleared) {
      this.open = false;
    }
  }
}
</script>

<style lang="scss" scoped>
.filter-category-section {
  color: white;
  padding-bottom: 10px;
  border-bottom: 1px solid #84827d;

  .category-title-row {
    .category-title {
      font-size: 12px;
      text-transform: uppercase;
    }

    &:hover .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  &.open {
    .category-title {
      color: var(--color-text-emphasis-on-dark);
    }
  }

  .more-btn {
    display: inline-block;
    padding: 10px;
    background-color: #4d4d4d;
    border-radius: 4px;
    margin-bottom: 5px;
  }

  &::v-deep .filter-selector .pill-list {
    margin-bottom: 0;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s ease;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
}
</style>
