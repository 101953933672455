<template>
  <div class="report-action-button inline-block" @click="onClick">
    <icon-button size="medium" class="hidden-xs hidden-sm" :icon="icon" :disabled="disabled" />
    <button
      type="button"
      class="btn btn-tertiary btn-sm hidden visible-xs visible-sm"
      :disabled="disabled"
      @click="onClick"
    >
      <div class="flex-row align-items-center gap-sm">
        <icon :icon="icon" class="text-base" />
        <span v-if="text">{{ text }}</span>
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue2";
import iconButton from "@/components/icon-button.vue";

defineProps<{
  icon: string;
  disabled?: boolean;
  text?: string;
}>();

const emit = defineEmits(["click"]);

const onClick = (evt) => {
  emit("click", evt);
};
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.report-action-button {
  @media (min-width: $grid-float-breakpoint) {
    button.btn-icon {
      height: 35px;
      width: 35px;
    }
  }
}
</style>
