<template lang="pug">
.more-with-pro-menu(@mouseenter="limitedFeaturesHidden = false" @mouseleave="limitedFeaturesHidden = isLimited")
  .limited-features-toggle.flex-row.align-items-center.gap-md(v-if="isLimited")
    more-with-pro-text
    i.fa.fa-chevron-down(:class="{invisible: !limitedFeaturesHidden}")
  .limited-features(:class="{disabled: isLimited, invisible: limitedFeaturesHidden}")
    slot
  .get-pro(v-if="isLimited && !limitedFeaturesHidden")
    button.btn.btn-success.btn-block.btn-rect.flex-row.justify-content-center.align-items-center
      | {{ 'limited_version.get_pro' | i18n }}
      .ml-sm
        i.fa.fa-arrow-right.font-size-xs
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import MoreWithProText from "./more-with-pro-text.vue";

@Component({
  components: {
    MoreWithProText,
  },
})
export default class MoreWithProMenu extends Vue {
  readonly isLimited = window.zoinedContext.limited;
  limitedFeaturesHidden = this.isLimited;
}
</script>

<style lang="scss">
.limited-features-toggle {
  padding: 5px 15px;
  white-space: nowrap;
  .pro-text {
    font-weight: bold;
    color: var(--color-yellow);
  }
  i.fa {
    font-size: 10px;
  }
}

.limited-features {
  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  &.invisible {
    height: 0;
    overflow: hidden;
  }
}

.get-pro {
  padding: 10px 15px;
}
</style>
