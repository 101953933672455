import MyReport from "../model/my-report";
import ApiService from "./api-service";

export default class AnalyticsApiService extends ApiService {
  create(my_report: MyReport): Promise<MyReport> {
    return this._post("/api/v1/analytics", { data: { my_report } });
  }

  update(id, my_report: MyReport): Promise<MyReport> {
    return this._put(`/api/v1/analytics/${id}`, { data: { my_report } });
  }

  delete(id) {
    return this._delete(`/api/v1/analytics/${id}`);
  }
}
