<template lang="pug">
.sidenav-folder
  .sidenav-folder-title(v-if="name", @click="open = !open") {{ name }}
  .reports-container(v-if="open")
    slot(name="reports")
      .reports
        sidenav-report(:report="report", v-for="report in reports", :key="report.path")
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import SidenavReport from "./sidenav-report.vue";

@Component({
  components: { SidenavReport },
})
export default class SidenavCategory extends Vue {
  @Prop()
  name: string;

  @Prop()
  reports: any[];

  open = true;
}
</script>

<style lang="scss" scoped>
.sidenav-folder {
  margin-top: 10px;
  margin-bottom: 10px;
}

.sidenav-folder-title {
  margin-bottom: 5px;
  padding-left: 15px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  color: var(--color-sidenav-category-title);
}
</style>
