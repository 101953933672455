<template lang="pug">
dropdown(v-on="$listeners")
  template(v-slot:toggle="toggleProps")
    .submenu-title.flex-row.align-items-center.gap-md(@click="toggleProps.toggleDropdown()")
      .flex-1 {{ title }}
      i.fa.fa-chevron-right.fa-sm
  template(v-slot:menu)
    slot
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Dropdown from "./dropdown.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    Dropdown,
  },
})
export default class DropdownSubmenu extends Vue {
  @Prop()
  title: string;
}
</script>

<style lang="scss" scoped>
.submenu-title {
  cursor: pointer;
  padding: 5px 15px;
  white-space: nowrap;

  i.fa {
    font-size: 10px;
  }
}
.dropdown {
  display: block;
  &::v-deep {
    .dropdown-menu {
      left: 100%;
      top: 0;
    }
  }
}
</style>
