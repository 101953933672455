<template lang="pug">
.team-listing
  div
    .pull-right
      a.btn.btn-primary(@click="openTeamModal()") {{ 'setup.teams.add_team' | i18n }}

    .table-controls
      search-bar(v-model="search")

    div
      team-table(:teams="teams" @edit="openTeamModal" @goto-members="gotoMembers")
      .centered(v-if="total > perPage")
        uiv-pagination(v-model="page", :total-page="Math.ceil(total / perPage)")
  team-modal(ref="teamModal" @save="fetchTeams" @delete="fetchTeams")

</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import Team from "../model/team";
import adminTeamsApi from "../api/admin-teams-api";
import { Watch, Ref } from "vue-property-decorator";
import SearchBar from "../components/search-bar.vue";
import TeamTable from "./team-table.vue";
import TeamModal from "./team-modal.vue";

@Component({
  components: {
    SearchBar,
    TeamTable,
    TeamModal,
  },
})
export default class TeamListing extends Vue {
  @Ref("teamModal")
  teamModal: TeamModal;

  page = 1;
  perPage = 15;
  search = "";
  total = 0;
  teams: Team[] = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  get params() {
    const params = {
      page: this.page,
      per_page: this.perPage,
      company_id: this.companyId,
    };

    if (this.search.length > 0) {
      params["search"] = this.search;
    }

    return params;
  }

  async openTeamModal(team: Team = null) {
    if (team) {
      team = await adminTeamsApi.get({ id: team.id, company_id: this.companyId });
    }
    this.teamModal.show(team);
  }

  gotoMembers(team: Team) {
    this.$router.push(`teams/${team.id}/members`);
  }

  @Watch("params", { immediate: true })
  async fetchTeams() {
    this.teams = await adminTeamsApi.get(this.params);
    this.total = (this.teams as any).total_count;
  }

  @Watch("search")
  onSearchChange() {
    this.page = 1;
  }
}
</script>
