<template lang="pug">
pill-list(:items="partnerItems" :available-items="partnerMenuItems" :searchable="false" @update="onUpdate($event.items)")
</template>

<script lang="ts">
import { makeApiInstance } from "../api/instance";
import MenuItem from "../interfaces/menu-item";
import PillItem from "../interfaces/pill-item";
import Partner from "../model/partner";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import pillList from "../components/pill-list.vue";

@Component({
  components: { pillList },
})
export default class PartnerSelector extends Vue {
  @Prop()
  value: Partner[];

  partners: Partner[] = null;

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/partners/" });
  }

  get partnerItems(): PillItem[] {
    return this.value?.map((partner) => ({
      value: partner.id,
      name: partner.name,
    }));
  }

  get partnerMenuItems(): MenuItem[] {
    return this.partners?.map((partner) => ({
      key: partner.id,
      name: partner.name,
    }));
  }

  created() {
    this.api.get("").then((result) => (this.partners = result.data));
  }

  onUpdate(items: PillItem[]) {
    const partners: Partner[] = items.map((item) => ({
      id: item.value,
      name: item.name,
    }));
    this.$emit("input", partners);
  }
}
</script>
