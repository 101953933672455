<template lang="pug">
.pdf-attachment-section
  .option
    label {{ 'newsletter.definition.sections.pdf_attachment.report' | i18n }}
    select(:value="section.report", @input="updateReport")
      option(v-for="option in reportOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'pdf.choose_layout' | i18n }}
    select(:value="orientation", @input="updateOrientation")
      option(value="portrait") {{ 'pdf.orientation.portrait' | i18n }}
      option(value="landscape") {{ 'pdf.orientation.landscape' | i18n }}
</template>

<script lang="ts">
import Actions from "../../store/actions";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class PdfAttachmentSection extends Vue {
  @Prop()
  section: any;

  get reportOptions() {
    return this.$store.state.pdfReports.all;
  }

  get orientation() {
    return this.section.orientation || "portrait";
  }

  updateReport(event) {
    const report = Number.parseInt(event.target.value);
    this.$emit("update-section", { ...this.section, report });
  }

  updateOrientation(event) {
    const orientation = event.target.value;
    this.$emit("update-section", { ...this.section, orientation });
  }
}
</script>
