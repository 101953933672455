<template lang="pug">
spinner(v-if="loading")
.panel-snippet(v-else)
  form
    .panel-body
      h4 {{ displayName }}

      .row
        .col-xs-4(v-if="user.profile_picture_url")
          img.img-responsive(src="user.profile_picture_url", alt="displayName")

        div(:class="span")
          .row
            .col-sm-6
              label {{ "activerecord.attributes.user.email" | i18n }}
              p {{ user.email }}

            .col-sm-6
              label {{ "activerecord.attributes.user.language" | i18n }}
              p {{ user.native_language }}
        .col-md-12
          .row
            .col-md-12
              label {{ "profile.default_params" | i18n }}
              p {{ defaultValues }}

    .panel-footer
      a.btn.btn-primary.profile(v-b-modal="'password-modal'") {{ "profile.change_password" | i18n }}</a>
      a.btn.btn-primary.profile(v-b-modal="'email-modal'") {{ "profile.change_email" | i18n }}</a>
      a.btn.btn-primary.profile(v-b-modal="'edit-modal'")
        i.fa.fa-edit
        | {{ "profile.edit.title" | i18n }}

    editModal(v-bind="{ user }")
    changePasswordModal
    changeEmailModal
</template>

<script>
import bModal from "bootstrap-vue/es/components/modal/modal";
import bModalDirective from "bootstrap-vue/es/directives/modal/modal";

import spinner from "../components/spinner.vue";
import editModal from "./profile-edit-modal.vue";
import changePasswordModal from "./profile-password-modal.vue";
import changeEmailModal from "./profile-email-modal.vue";
import _ from "lodash";
import { menuItemKey } from "@/interfaces/menu-item";
import i18n from "../i18n";

export default {
  components: {
    spinner,
    editModal,
    changePasswordModal,
    changeEmailModal,
    "b-modal": bModal,
  },
  directives: {
    "b-modal": bModalDirective,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    role: Object,
  },
  data() {
    return {
      loading: false,
      error: null,
    };
  },
  computed: {
    displayName: function () {
      return this.user.first_name && this.user.last_name
        ? `${this.user.first_name} ${this.user.last_name}`
        : this.user.email;
    },
    span: function () {
      return this.user.profile_picture_url ? "col-xs-8" : "col-xs-12";
    },
    groupings() {
      return this.$store.getters.getParameters("grouping");
    },
    defaultValues() {
      const str = _.compact(
        _.map(this.role?.default_parameters, (config, key) => {
          const values = _.values(config)
            .filter(({ enabled }) => enabled)
            .map(({ name, value }) => name || value);

          if (values.length > 0) {
            const grouping = this.groupings.find((grouping) => menuItemKey(grouping) == key);
            return `${grouping?.name}: ${values.join(", ")}`;
          }
        })
      ).join(", ");

      return str.length === 0 ? i18n.t("newsletter.definition.default_parameter_not_set") : str;
    },
  },
};
</script>

<style scoped>
i.fa {
  margin-right: 4px;
}

.btn-primary.profile {
  margin-right: 4px;
}
</style>
