<template lang="pug">
  spinner(v-if="loading")
  div(v-else)
    b-alert(v-if="alert" variant="success" show dismissible) {{alertMsg}}
    .row
      .col-md-7
        profileBlock(v-bind="{user, role}")
      .col-md-5
        reports(v-bind:reports="reports")
</template>

<script>
import { fetchData } from "../lib/data/profile";

import spinner from "../components/spinner.vue";
import bAlert from "bootstrap-vue/es/components/alert/alert";

import profileBlock from "./profile-block";
import reports from "./profile-reports";

export default {
  components: {
    spinner,
    profileBlock,
    reports,
    "b-alert": bAlert,
  },
  data() {
    return {
      alert: null,
      alertMsg: null,
      user: {},
      role: {},
      reports: [],
      subscriptions: [],
      loading: false,
      company: 0,
    };
  },
  methods: {
    getAlert: function(msg, localize) {},
    fetchData: function() {
      fetchData("/profile.json")
        .then((res) => {
          this.user = res.user;
          this.role = res.role;
          this.reports = res.reports;
          this.subscriptions = res.subscriptions;
          this.company = res.company;
          this.loading = false;
        })
        .catch((err) => {
          this.error = err;
        });
    },
  },
  created: function() {
    this.$on("getAlert", function(msg) {
      this.alert = true;
      this.alertMsg = msg;
    });
    this.$on("fetchData", function(msg) {
      this.fetchData();
    });
    this.fetchData();
  },
};
</script>

<style scoped>
.btn-primary.save {
  float: right;
}
</style>
