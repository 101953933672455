<template lang="pug">
span.config-pill-list
  pill-list(:title="title", :searchable="searchable", :radio="radio", :draggable="draggable", :items="items", :defaults="defaultItems", :availableItems="availableItems", @update="onUpdate($event.items)")
</template>
<script>
import Vue from "vue";
import Component from "vue-class-component";
import { Emit } from "vue-property-decorator";
import pillList from "./pill-list";

@Component({
  components: {
    pillList
  },
  props: {
    config: Object,
    defaultConfig: Object,
    availableItems: Array,
    title: String,
    radio: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    }
  }
})
export default class FilterSelector extends Vue {
  get searchable() {
    return this.availableItems && this.availableItems.length > 10;
  }

  get items() {
    return (
      this.config &&
      _.map(this.config, ({ name, value, ...rest }, key) => ({
        ...rest,
        value: key,
        name: name || value
      }))
    );
  }

  get defaultItems() {
    return (
      this.defaultConfig &&
      _.map(this.defaultConfig, ({ name, value, ...rest }, key) => ({
        ...rest,
        value: key,
        name: name || value
      }))
    );
  }

  @Emit("update")
  onUpdate(items) {
    return items.reduce(
      (result, item) => ({
        ...result,
        [item.value]: item
      }),
      {}
    );
  }
}
</script>

<style lang="scss" scoped>
.config-pill-list {
  margin-right: 20px;
}
</style>
