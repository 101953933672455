<template lang="pug">
.default-parameter-selector(v-if="filters")
  label
    input(type="checkbox", :checked="value.use_default_parameters", @input="toggleUseDefaultParameters")
    | {{ title }}
  .default-parameter
    select(:value="value.default_parameter || 'store'" v-if="value.use_default_parameters" @input="updateDefaultParameter")
      option {{ 'actions.select_parameter' | i18n }}
      option(:value="filter.id" v-for="filter in filters") {{ filter.name }}
</template>

<script lang="ts">
import Actions from "../store/actions";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import Filter from "../model/filter";
import i18n from "../i18n";

@Component({})
export default class DefaultParameterSelector extends Vue {
  @Prop()
  value: any;

  @Prop({ default: i18n.t("activerecord.attributes.my_report.use_default_parameters") })
  title: string;

  get filters(): Filter[] {
    return this.$store.state.parameters.filters.all;
  }

  toggleUseDefaultParameters() {
    const { use_default_parameters } = this.value;

    this.$emit("input", { ...this.value, use_default_parameters: !use_default_parameters });
  }

  updateDefaultParameter(evt) {
    const default_parameter = evt.target.value;

    this.$emit("input", { ...this.value, default_parameter });
  }

  created() {
    this.setDefaultParameterUnlessSet();
  }

  @Watch("value")
  setDefaultParameterUnlessSet() {
    const { use_default_parameters, default_parameter } = this.value;

    if (use_default_parameters && !default_parameter) {
      this.$emit("input", { ...this.value, default_parameter: "store" });
    }
  }
}
</script>

<style scoped>
.default-parameter {
  padding-left: 20px;
  padding-top: 5px;
}
</style>
