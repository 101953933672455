<template lang="pug">
span.flyover-handle-list(v-if="filterHtml")
  span.filters(v-html="filterHtml")
  span.separator(v-if="endSeparator") &nbsp;|&nbsp;
</template>

<script lang="ts">
import FilterConfiguration from "../model/filter-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import ChartOptions from "../model/chart-options";
import _ from "lodash";
import TranslationService from "../core/translation.service";
import Actions from "../store/actions";
import i18n from "../i18n";
import ReportContext from "../model/report-context";
import { Prop } from "vue-property-decorator";

const translationService = new TranslationService();

@Component({})
export default class FlyoverHandleList extends Vue {
  @Prop({ default: true })
  endSeparator: boolean;
  
  get reportContext(): ReportContext {
    return this.$store.getters.getReportContext;
  }

  get config(): FilterConfiguration {
    return this.reportContext?.filter_configuration;
  }

  get chartOptions(): ChartOptions {
    return this.reportContext.chart_options;
  }

  get filtersets() {
    return this.$store.state.filtersets.all;
  }

  get filterHtml() {
    if (this.config) {
      let fText;

      const sets = (this.config.filters.sets || []).filter(({ enabled }) => enabled);
      if (this.chartOptions && this.chartOptions.show_filterset_name && sets.length > 0) {
        if (!this.filtersets) {
          return "";
        }
        const filterSetNames = sets.map((set) => {
          const filterSet = this.filtersets.find(({ id }) => id === set.id);
          return filterSet && filterSet.name;
        });
        fText = [filterSetNames];
      } else {
        fText = _.compact(
          _.map(this.config.filters.filters, (v, k) => {
            if (v != null) {
              let filterValues = Object.values(v);
              if (k == "hour") {
                filterValues = _.sortBy(filterValues, "value");
              }

              const vvv = _.compact(
                filterValues.map(function(vv) {
                  if (vv && vv.enabled) {
                    const name = vv.name || vv.value;
                    return vv.exclude ? "<span class='exclude'>" + name + "</span>" : name;
                  }
                  return null;
                })
              );

              if (vvv.length > 0) {
                if (vvv.length > 3) {
                  return _.take(vvv, 2).concat(_.last(vvv) + "...");
                }
                return vvv;
              }
            }
            return null;
          })
        );
      }
      if (!_.isEmpty(this.config.widgets.metric_range)) {
        const metricRangeLabels = Object.values(this.config.widgets.metric_range)
          .filter(({ enabled }) => enabled)
          .map((metricRange) => translationService.metricRangeTitle(metricRange));

        const separator = this.config.raw_filters?.rangefilter_union
          ? ` ${i18n.t("filter.config.or").toUpperCase()} `
          : ", ";

        fText.push([metricRangeLabels.join(separator)]);
      }

      return _.map(fText, (v) => v.join(", ")).join(" | ");
    } else {
      return null;
    }
  }

  created() {
    if (!this.filtersets) {
      this.$store.dispatch(Actions.fetchFiltersets);
    }
  }
}
</script>
