import _ from "lodash";
import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id, team_id, id }) => {
  let url = `/api/admin/companies/${company_id}/teams/${team_id}/members`;
  if (id) {
    url += `/${id}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: ({ company_id, team_id, id = null, ...params }) =>
    apiInstance
      .get(buildUrl({ company_id, team_id, id }), { params })
      .then(({ data, headers }) =>
        Object.assign(data, _.isNumber(headers.total) ? { total_count: headers.total } : {})
      ),
  post: (params) => apiInstance.post(buildUrl(params), params).then((response) => response.data),
  delete: (params) => apiInstance.delete(buildUrl(params)).then((response) => response.data),
};

export default api;
