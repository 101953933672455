const numberWithSeparator = function(x, sep) {
  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep);
  return parts.join(".");
};

export const formatNumber = function(val, precision) {
  if (val>=1000000) {
    return `${numberWithSeparator(new Number(val/1000000).toFixed(2), '\u00a0')}M`;
  } else {
    return `${numberWithSeparator(new Number(val).toFixed(precision), '\u00a0')}`;
  }
};
