import { makeApiInstance } from "./instance";

const buildUrl = ({ company_id, id = null }) => {
  let url = `/api/admin/companies/${company_id}/notifications`;
  if (id) {
    url = `${url}/${id}`;
  }
  return url;
};

const apiInstance = makeApiInstance();

const api = {
  get: ({ company_id, ...params }) => apiInstance.get(buildUrl({ company_id }), { params }).then(({ data }) => data),
  delete: ({ company_id, id }) => apiInstance.delete(buildUrl({ company_id, id })),
};

export default api;
