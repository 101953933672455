<template lang="pug">
uiv-modal(:title="title" v-model="open" :append-to-body="true" @hide="onHide")
  spinner(v-if="!loaded")
  .manage-reports-modal(v-else)
    p 
      | Organize your reports into folders. You can order your reports and folders
      | and move reports between folders by dragging them.
    category-view(:category="defaultCategory" :reports="myReportsByFolder[defaultCategory.id]" :is-default="true" :key="defaultCategory.id" @reports-updated="updated=true")
    hr.my-md
    draggable(:list="myReportFolders" ghost-class="category-ghost" @change="onOrderChange")
      category-view(:category="myReportFolder" :reports="myReportsByFolder[myReportFolder.id]" @edit="editFolder(myReportFolder)" @delete="deleteFolder(myReportFolder)" @reports-updated="updated=true" v-for="myReportFolder in myReportFolders" :key="myReportFolder.id")
  edit-category-modal(ref="editCategoryModal" @save="onSave")
  div(slot="footer")
    .flex-row.justify-content-space-between
      button.btn.btn-default.btn-outline.flex-row.align-items-center(@click="addFolder")
        svg-icon.mr-sm(name="folder-add")
        | Add folder
      button.btn.btn-primary(@click="open = false") {{ 'actions.close' | i18n }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Ref } from "vue-property-decorator";
import EditCategoryModal from "./edit-category-modal.vue";
import MyReportFolder from "../model/my-report-folder";
import _ from "lodash";
import CategoryView from "./category-view.vue";
import draggable from "vuedraggable";
import Spinner from "../components/spinner.vue";
import MyReportFoldersApiService from "../api/my-report-folders-api-service";
import MyReportsApiService from "../api/my-reports-api-service";
import MyReport from "../model/my-report";
import Actions from "../store/actions";
import SvgIcon from "../components/svg-icon.vue";

@Component({
  components: {
    EditCategoryModal,
    CategoryView,
    draggable,
    Spinner,
    SvgIcon,
  },
})
export default class ManageReportsPage extends Vue {
  @Ref("editCategoryModal")
  editCategoryModal: EditCategoryModal;

  @Prop()
  navigationItem: any;

  myReportFolders: MyReportFolder[] = null;

  myReportsByFolder: Record<number, MyReport[]> = null;

  open = false;

  updated = false;

  get loaded() {
    return this.myReportFolders && this.myReportsByFolder;
  }

  get myReportFoldersService() {
    return new MyReportFoldersApiService();
  }

  get myReportsService() {
    return new MyReportsApiService();
  }

  get title() {
    return this.navigationItem.name;
  }

  get defaultCategory() {
    return {
      id: 0,
      name: this.title,
    };
  }

  get apiParams() {
    const params: any = {};
    if (this.navigationItem.type === "partner_analytics") {
      params.partner_id = this.navigationItem.partner_id;
    } else {
      params.company_id = window.zoinedContext.companyId;
      if (this.navigationItem.type === "my_analytics") {
        params.user_id = window.zoinedContext.userId;
      }
      if (this.navigationItem.type === "team_analytics") {
        params.team_id = this.navigationItem.team_id;
      }
    }
    return params;
  }

  show() {
    this.open = true;
    this.updated = false;
    this.myReportFolders = this.myReportsByFolder = null;

    return Promise.all([this.fetchMyReportFolders(), this.fetchMyReports()]).then(([myReportFolders, myReports]) => {
      this.myReportFolders = myReportFolders;
      this.myReportsByFolder = _.groupBy(myReports, ({ my_report_folder_id }) => {
        const hasFolder = my_report_folder_id && myReportFolders.some(({ id }) => id === my_report_folder_id);
        return hasFolder ? my_report_folder_id : this.defaultCategory.id;
      });
    });
  }

  onHide() {
    if (this.updated) {
      this.updateNavigation();
    }
  }

  addFolder() {
    this.editCategoryModal.show({} as MyReportFolder);
  }

  editFolder(myReportFolder: MyReportFolder) {
    this.editCategoryModal.show(myReportFolder);
  }

  async deleteFolder(myReportFolder: MyReportFolder) {
    await this.myReportFoldersService.delete(myReportFolder);
    this.myReportFolders = this.myReportFolders.filter((it) => it.id !== myReportFolder.id);
    this.updated = true;
  }

  async onSave(myReportFolder: MyReportFolder) {
    if (!myReportFolder.id) {
      const sort_order = (_.last(this.myReportFolders)?.sort_order || 0) + 1;
      myReportFolder = await this.myReportFoldersService.create({ ...myReportFolder, sort_order, ...this.apiParams });
      this.myReportFolders = [...this.myReportFolders, myReportFolder];
    } else {
      myReportFolder = await this.myReportFoldersService.update(myReportFolder);
      this.myReportFolders = this.myReportFolders.map((it) => (it.id === myReportFolder.id ? myReportFolder : it));
    }
    this.updated = true;
  }

  async onOrderChange({ moved }) {
    if (moved) {
      await Promise.all(
        this.myReportFolders.map((myReportFolder, index) =>
          this.myReportFoldersService.update({ ...myReportFolder, sort_order: index + 1 })
        )
      );
      this.updated = true;
    }
  }

  updateNavigation() {
    if (this.$store.state.navigation.state !== "loading") {
      return this.$store.dispatch(Actions.fetchNavigation);
    }
  }

  fetchMyReportFolders() {
    return this.myReportFoldersService.getAll(this.apiParams);
  }

  fetchMyReports() {
    return this.myReportsService.getAll(this.apiParams);
  }
}
</script>

<style lang="scss">
.category-ghost {
  opacity: 0.5;
}
</style>
