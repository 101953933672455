
export default class PortalContext {
  static global() {
    return new PortalContext(window.chart_token, I18n.locale);
  }
  constructor(token, locale, baseUrl) {
    this._token = token;
    this._locale = locale;
    this._baseUrl = baseUrl ? new URL(baseUrl) : null;
  }

  get baseUrl() { return (this._baseUrl ? this._baseUrl.toString() : null); }
  get host() { return (this._baseUrl ? this._baseUrl.hostname : null) }
  get token() { return this._token; }
  get locale() { return this._locale; }
  get csrfToken() { return document.querySelector("meta[name='csrf-token']").getAttribute("content"); }
  get csrfParam() { return document.querySelector("meta[name='csrf-param']").getAttribute("content"); }
}
