<template>
  <ui-toggle-switch :value="value" :title="t('filter.config.round_values')" @input="toggle" />
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import UiToggleSwitch from "@/ui/ui-toggle-switch.vue";

const props = defineProps<{
  value?: boolean;
}>();

const emit = defineEmits(["input"]);

const { t } = useI18n();

const toggle = () => {
  emit("input", !props.value);
};
</script>
