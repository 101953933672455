<template>
  <ui-button-multi-toggle :value="value" :options="options" :button-class="buttonClass" @input="update" />
</template>

<script lang="ts" setup>
import UiButtonMultiToggle, { UiButtonMultiToggleOption } from "@/ui/ui-button-multi-toggle.vue";
import { useI18n } from "@/composables/i18n";

const props = withDefaults(
  defineProps<{
    value?: boolean;
    trueTitle?: string;
    falseTitle?: string;
    buttonClass?: string;
  }>(),
  {
    trueTitle: () => useI18n().t("options.yes"),
    falseTitle: () => useI18n().t("options.no"),
  }
);

const emit = defineEmits(["input"]);

const options: UiButtonMultiToggleOption[] = [
  { value: true, label: props.trueTitle },
  { value: false, label: props.falseTitle },
];

const update = (value) => {
  emit("input", value);
};
</script>
