<template lang="pug">
select(:value="value" @change="onChange")
  option(:value="null") {{'newsletter.definition.sending_time_default' |i18n}}
  option(:value="item.id" v-for="item in sendingTimeOptions") {{ item.name }}
</template>

<script lang="ts">
import _ from "lodash";
import moment from "moment";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class TimeSelect extends Vue {
  @Prop()
  value: string;

  get sendingTimeOptions() {
    return _.map(_.range(0, 96), function(v) {
      const m = moment([2000, 1, 1]).add(15 * v, "minutes");
      const id = m.format("HH:mm");
      return {
        id,
        name: m.format("LT"),
      };
    });
  }

  onChange(evt) {
    this.$emit("input", evt.target.value);
  }
}
</script>
