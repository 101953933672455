<template lang="pug">
.inline-block
  button.btn.btn-sm.btn-primary(:disabled="isSetActive || !hasEnabledFilters", @click="openSaveModal") {{ 'filters.filterset.save_filters' | i18n }}
  uiv-modal(
    v-model="saveModalOpen",
    :append-to-body="true",
    :title="'filters.actions.save_as' | i18n",
    :cancel-text="(requireConfirmation ? 'actions.edit' : 'actions.cancel') | i18n",
    :cancel-type="requireConfirmation ? 'primary' : 'default'",
    :ok-text="(requireConfirmation ? 'actions.confirm' : 'actions.save') | i18n",
    :ok-type="requireConfirmation ? 'danger' : 'primary'",
    :before-close="beforeClose"
    @hide="onHide"
    )
    form
      spinner(v-if="saving")
      .form(:class="{disabled: saving}")
        .form-group
          input.form-control(type='text' v-model='filterset.name' required=true :placeholder="'filters.filterset.name_placeholder' | i18n" autofocus=true style="max-width:100%;")
        .form-group(v-if="isAdmin")
          .checkbox
            label(for="shared")
              input(type="checkbox", name="shared", v-model="filterset.shared")
              | {{ 'filters.filterset.shared' | i18n }}
      div(v-if="requireConfirmation") {{ 'filters.filterset.override_filter_name' | i18n }}
      div(v-else-if="saveAsOwn") {{ 'filters.filterset.cannot_save_shared' | i18n }}
</template>

<script lang="ts">
import _ from "lodash";
import FiltersConfiguration from "main/model/filters-configuration";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import spinner from "../components/spinner.vue";

@Component({
  components: {
    spinner,
  },
})
export default class SaveFiltersButton extends Vue {
  @Prop({
    default: (): FiltersConfiguration => ({
      v: 2,
      sets: [],
      filters: {},
    }),
  })
  config: FiltersConfiguration;

  saveModalOpen = false;
  saving = false;
  requireConfirmation = false;
  confirmed = false;
  filterset: { name: string; shared: boolean } = {
    name: "",
    shared: false,
  };
  lastSetId: number = null;
  saveAsOwn = false;

  readonly isAdmin = !!window.zoinedContext?.isAdmin;

  get filtersets() {
    return this.$store.state.filtersets.all;
  }

  get effectiveConfig(): FiltersConfiguration {
    if (this.config) {
      const sets = (this.config.sets || []).filter(({ id }) => _.find(this.filtersets, (set) => set.id == id));
      return {
        ...this.config,
        sets,
      };
    } else {
      return null;
    }
  }

  get isSetActive() {
    return !!this.enabledSetId;
  }

  get enabledSetId() {
    return _.find(this.effectiveConfig.sets, ({ enabled }) => enabled)?.id;
  }

  get hasEnabledFilters() {
    return !_.isEmpty(this.effectiveConfig.filters);
  }

  get lastSet() {
    return this.lastSetId && _.find(this.filtersets, (set) => set.id == this.lastSetId);
  }

  openSaveModal() {
    this.saveModalOpen = true;
    this.filterset = {
      name: this.lastSet?.name || "",
      shared: !!this.lastSet?.shared,
    };
    if (!this.isAdmin) {
      this.filterset.shared = false;
    }
  }

  onHide() {
    this.saving = this.requireConfirmation = this.saveAsOwn = this.confirmed = false;
  }

  beforeClose(msg) {
    if (msg == "ok") {
      if (this.requireConfirmation) {
        this.confirmed = true;
      }
      this.save();
      return false;
    } else if (msg == "cancel" && this.requireConfirmation) {
      this.requireConfirmation = false;
      return false;
    } else {
      return true;
    }
  }

  save() {
    if (this.saving || _.isEmpty(this.filterset.name)) {
      return;
    }

    const config = {
      v: 2,
      filters: this.config.filters,
    };

    if (this.lastSet?.name === this.filterset.name) {
      if (this.lastSet.shared && !this.isAdmin) {
        this.saveAsOwn = true;
        this.lastSetId = null;
      } else {
        return this.updateFilterset(this.lastSet, config);
      }
    } else {
      const existingSet = _.find(this.filtersets, (set) => {
        return set.name == this.filterset.name && (this.isAdmin || !set.shared);
      });

      if (existingSet) {
        if (this.confirmed) {
          return this.updateFilterset(existingSet, config);
        } else {
          this.requireConfirmation = true;
        }
      } else {
        return this.createFilterset(config);
      }
    }
  }

  createFilterset(config) {
    this.saving = true;

    const filterset = {
      ...this.filterset,
      config,
    };

    this.$store.dispatch("filtersets/add", filterset).then((filterset) => {
      this.saveModalOpen = false;

      // Add set to config
      const sets = [...this.config.sets, { id: filterset.id, enabled: true }];
      const config = {
        ...this.config,
        sets,
      };

      this.$emit("update", config);
    });
  }

  updateFilterset(existingSet, config) {
    this.saving = true;

    const filterset = {
      ...existingSet,
      ...this.filterset,
      config,
    };

    this.$store.dispatch("filtersets/update", [filterset.id, filterset]).then((filterset) => {
      this.saveModalOpen = false;

      const sets = this.config.sets.map(({ id }) => ({
        id,
        enabled: id === filterset.id,
      }));

      if (!sets.find(({ id }) => id == filterset.id)) {
        sets.push({ id: filterset.id, enabled: true });
      }

      const config = {
        ...this.config,
        sets,
      };
      this.$emit("update", config);
    });
  }

  @Watch("config.sets", { immediate: true })
  watchSets(sets) {
    const enabledSetId = sets?.find(({ enabled }) => enabled)?.id;
    if (enabledSetId) {
      this.lastSetId = enabledSetId;
    }
  }
}
</script>
