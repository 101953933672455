import _ from "lodash";
import PortalContext from "./portal_context";

const context = new PortalContext();

export const fetchData = (path) => {
  return fetch(path, {
    credentials: "same-origin",
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};

export const updateProfile = (data) => {
  return fetch("/profile/", {
    credentials: "same-origin",
    method: "PATCH",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};

export const changePassword = (data) => {
  return fetch("/profile/save_password", {
    credentials: "same-origin",
    method: "PATCH",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        console.log(err);
        const message = _.isArray(err.error) ? err.error.join("<br>") : err.error;
        throw new Error(message);
      });
    }
  });
};

export const changeEmail = (data) => {
  return fetch("/profile/save_email", {
    credentials: "same-origin",
    method: "PATCH",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ user: data }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};

export const switchLanguage = (lang) => {
  return fetch(`/profile/switch_language/${lang}`, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      location.reload();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};

export const unsubscribe = (id, company_id) => {
  return fetch(`/profile/${company_id}/newsletter_subscriptions/${id}`, {
    credentials: "same-origin",
    method: "DELETE",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};

export const updateSubscriptions = (ids) => {
  return fetch(`/profile/newsletter_subscriptions`, {
    credentials: "same-origin",
    method: "POST",
    headers: {
      "X-CSRF-Token": context.csrfToken,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify({ newsletter_definition_ids: ids }),
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      return response.json().then((err) => {
        throw new Error(err.error);
      });
    }
  });
};
