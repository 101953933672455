import axios from "axios";
import _ from "lodash";

export const refreshSession = _.throttle(
  () => {
    axios
      .get("/refresh_session")
      .then((response) => response.data)
      .then(({ token }) => {
        if (token) {
          window.chart_token = token;
        }
      })
      .catch(() => {
        console.log("Error refreshing session.");
      });
  },
  60 * 1000,
  { leading: true, trailing: false }
);
