import { makeApiInstance } from "@/api/instance";
import { useExportReport } from "@/composables/export-report";
import UserCompanyRole from "@/model/user-company-role";
import { useQuery } from "@tanstack/vue-query";
import { Ref } from "vue";

export type UseUserCompanyRolesParams = {
  search: string;
  sort: string;
  page: number;
  per_page: number;
};

export const useUserCompanyRoles = (params: Ref<UseUserCompanyRolesParams>) => {
  return useQuery<{ data: UserCompanyRole[]; total: number }>({
    queryKey: ["partnerUserCompanyRoles", params],
    queryFn: () =>
      makeApiInstance()
        .get("/api/partner_admin/user_company_roles", { params: params.value })
        .then((res) => {
          return {
            data: res.data,
            total: Number.parseInt(res.headers["total"]),
          };
        }),
  });
};

export const useExportUserCompanyRoles = () => useExportReport("/api/partner_admin/user_company_roles/export");
