import mixpanel from "mixpanel-browser";
import $ from "jquery";

const token = window.zoinedContext.mixpanel_token;

if (token) {
  mixpanel.init(token);

  var metaInfo = $("meta[name='zoined']");
  if (metaInfo && metaInfo.data("user")) {
    // Only register/identify logged-in (non-anonymous) users.
    if (metaInfo.data("user")) {
      // Register global attributes for tracks
      mixpanel.register({
        User: metaInfo.data("user"),
        Company: metaInfo.data("company"),
        Partner: metaInfo.data("partner"),
      });

      // Identify user and set/update 'people' info
      mixpanel.identify(metaInfo.data("user"));
      mixpanel.people.set({
        $email: metaInfo.data("user"),
        $name: metaInfo.data("name"),
        Company: metaInfo.data("company"),
        Partner: metaInfo.data("partner"),
      });
    }
  }
}

export const sendMixpanelEvent = (event: string, data: any = null) => {
  if (token) {
    mixpanel.track(event, data);
  }
};
