import ApiService from "./api-service";
import MyReportFolder from "../model/my-report-folder";

export default class MyReportFoldersApiService extends ApiService {
  getAll(params = null): Promise<MyReportFolder[]> {
    return this._get("/api/v1/my_report_folders", { params });
  }

  create(my_report_folder: MyReportFolder): Promise<MyReportFolder> {
    return this._post("/api/v1/my_report_folders", { data: { my_report_folder } });
  }

  update({ id, ...my_report_folder }: MyReportFolder): Promise<MyReportFolder> {
    return this._put(`/api/v1/my_report_folders/${id}`, { data: { my_report_folder } });
  }

  delete({ id }: MyReportFolder) {
    return this._delete(`/api/v1/my_report_folders/${id}`);
  }
}
