<template>
  <button type="button" class="icon-button btn btn-icon" :class="classes" v-bind="$attrs" v-on="$listeners">
    <icon class="text-base" :icon="icon" />
  </button>
</template>

<script setup lang="ts">
import { Icon } from "@iconify/vue2";
import { computed } from "vue";

const props = defineProps<{
  icon: string;
  size: "small" | "medium";
}>();

const classes = computed(() => {
  return {
    "btn-xs": props.size === "small",
    "btn-primary": props.size === "small",
    "btn-dark": props.size === "medium",
  };
});
</script>

<style lang="scss">
.dark-bg {
  .icon-button.btn-xs.btn-primary {
    &,
    &:focus {
      background-color: var(--color-interactive-fill-neutral-high-contrast);
      border-color: var(--color-interactive-fill-neutral-high-contrast);
      color: var(--color-text-primary-inverse);
    }

    &:hover {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-on-dark);
        border-color: var(--color-interactive-fill-primary-on-dark);
        color: var(--color-button-primary-hover-text);
      }
    }

    &:active,
    &.active {
      &,
      &:focus {
        background-color: var(--color-interactive-fill-primary-variant-on-dark);
        border-color: var(--color-interactive-fill-primary-variant-on-dark);
        color: var(--color-button-primary-active-text);
      }
    }

    &:disabled,
    &.disabled {
      &,
      &:hover,
      &:focus {
        background-color: var(--color-button-primary-disabled-background);
        border-color: var(--color-button-primary-disabled-background);
        color: var(--color-button-primary-disabled-text);
        opacity: 1;
      }
    }
  }
}
</style>
