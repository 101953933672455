<template lang="pug">
.company-admin
  router-view
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class CompanyAdmin extends Vue {
  get navigationItems() {
    const category = this.$store.state.navigation?.data.settings?.find(
      (category) => category.id === "company_settings"
    );
    const folders = category?.folders || [];
    const reports = folders.map((folder) => folder.reports).flat();
    return reports;
  }
}
</script>
