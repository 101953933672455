import SectionType from "../section-type";

class PdfAttachment extends SectionType {
  constructor() {
    super("pdf_attachment");

    this.validators = [my_report_validator];
  }
}

export const my_report_validator = function(section, errors) {
  if (section.report == null) {
    errors.report || (errors.report = []);
    return errors.report.push(I18n.t("newsletter.definition.errors.must_choose_report"));
  }
};

export default PdfAttachment;
