<template lang="pug">
uiv-modal.newsletter-definition-from-template-modal(v-model="open", :append-to-body="true", :title="'newsletter.definition.select_newsletter_template' | i18n", :footer="false")
  .loading-indicator(v-if="!newsletterTemplates")
    spinner
  .content(v-else)
    .item(v-for="template in newsletterTemplates" @click="createFromTemplate(template)") {{ template.title }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import spinner from "../components/spinner.vue";
import { Prop } from "vue-property-decorator";

@Component({
  components: {
    spinner,
  },
})
export default class NewsletterDefinitionFromTemplateModal extends Vue {
  @Prop()
  newsletterTemplates: any[];

  open = false;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  show() {
    this.open = true;
  }

  createFromTemplate(template) {
    this.open = false;
    this.$emit("new-from-template", template.id);
  }
}
</script>

<style lang="scss" scoped>
.newsletter-definition-from-template-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
