import consumer from "./consumer";

export function getDownloadUrl(download_id, args: any = {}) {
  return new Promise((resolve, rejected) => {
    const { connected } = args;
    const subscription = consumer.subscriptions.create(
      {
        channel: "FileDownloadChannel",
        download_id
      },
      {
        connected,
        rejected,
        received(data) {
          if (data.success) {
            resolve(data.url);
          } else {
            rejected();
          }
          subscription.unsubscribe();
        }
      }
    );
  });
}
