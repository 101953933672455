import { i18n, locale } from "./i18n";
import { date, dateTime } from "./date";

export default {
  install(Vue) {
    Vue.filter("i18n", i18n);
    Vue.filter("date", date);
    Vue.filter("dateTime", dateTime);
    Vue.mixin({
      methods: {
        current_locale: locale,
      },
    });
  },
};
