<template>
  <div class="ai-assistant-component-view" ref="el">
    <save-report-dialog ref="saveReportDialog" :config="saveReportConfig"></save-report-dialog>
    <zoined-report-row
      :components="[component]"
      :custom="true"
      :dashboard="true"
      :saveable="true"
      @save="saveReportDialog.show()"
      @component-updated="updateComponent"
      @excel-export-component="excelExport"
    ></zoined-report-row>
  </div>
</template>

<script setup lang="ts">
import ZoinedReportRow from "@/analytics/zoined-report-row.vue";
import { useStore } from "@/composables/store";
import { exportReport } from "@/lib/export";
import { filterToFlyover } from "@/lib/filter-util";
import Component from "@/model/component";
import Mutations from "@/store/mutations";
import _ from "lodash";
import { computed, onMounted, ref } from "vue";
import SaveReportDialog from "@/save-report/save-report-dialog.vue";
import ReportConfig from "@/model/report-config";

const props = defineProps<{
  component: Component;
}>();

const store = useStore();

const emit = defineEmits(["component-updated", "update-overrides"]);

const el = ref<HTMLElement | null>(null);

const saveReportDialog = ref<typeof SaveReportDialog>(null);

const saveReportConfig = computed<ReportConfig>(() => {
  return {
    components: [props.component],
  } as ReportConfig;
});

const updateComponent = ({ updatedComponent }: { updatedComponent: Component }) => {
  const components = store.state.aiAssistant.components.map((component: Component) => {
    if (component === props.component) {
      return updatedComponent;
    }
    return component;
  });

  store.commit(Mutations.setAiComponents, components);
};

const excelExport = () => {
  const c = _.cloneDeep(props.component);
  exportReport(c.name, {
    filterConfiguration: filterToFlyover(c.filterConfiguration),
    components: [c],
    format: "xlsx",
  });
};

onMounted(() => {
  setTimeout(() => {
    el.value.scrollIntoView({ behavior: "smooth", block: "start" });
  }, 300);
});
</script>

<style scoped lang="scss">
$grid-float-breakpoint: 992px;

.ai-assistant-component-view {
  &:last-child {
    min-height: calc(100vh - var(--header-height) - var(--sidenav-iconbar-width) - 30px);

    @media screen and (min-width: $grid-float-breakpoint) {
      min-height: calc(100vh - var(--header-height) - 30px);
    }
  }
}
</style>
