<template lang="pug">
.newsletter-section-insights(v-if="groupings && groupings.length > 0")
  .current-configuration(v-if="configuration")
    b {{ 'filters.compare_to' | i18n }}:
    span {{ 'filters.benchmark.' + configuration.comparison | i18n }}.
    b {{ 'newsletter.definition.insights.dimensions_title' | i18n }}:
    span {{ dimensionsText }}
  .buttons(v-if="!configuration")
    button.btn.btn-primary(key="applyButton", :disabled="disabled", @click="apply") {{ 'actions.apply' | i18n }}
    i.enable-insights-info(v-if="disabled") &nbsp; {{ 'newsletter.definition.insights.enable_insights_info' | i18n }}
  .buttons(v-else)
    button.btn.btn-primary(@click="edit") {{ 'actions.edit' | i18n }} 
    button.btn.btn-default(v-if="configuration", @click="clearInsights") {{ 'actions.delete' | i18n }}
  newsletter-section-insights-modal(ref="newsletterSectionInsightsModal" :metric="metric", :groupings="groupings", :configuration="configuration", :options="options", @update="$emit('change', $event)")
</template>
<script>
import newsletterSectionInsightsModal from "./newsletter-section-insights-modal";
import GroupingsApiService from "../api/groupings-api-service";

export default {
  components: {
    newsletterSectionInsightsModal: newsletterSectionInsightsModal,
  },
  props: {
    metric: String,
    configuration: Object,
    options: Object,
  },
  data() {
    return {
      groupings: null,
    };
  },
  computed: {
    defaultConfiguration() {
      // Filter default dimensions by available groupings
      let dimensions = ["store", "category", "subcategory", "supplier"].filter(
        (dimension) => !!this.groupings.find(({ key }) => key == dimension)
      );

      // Take just first few groupings if none matched
      if (dimensions.length == 0) {
        dimensions = _.take(this.groupings, 4).map(({ key }) => key);
      }

      return {
        dimensions,
        comparison: "prev_year_corresponding",
        insights_limit: 1,
        sub_insights_limit: 1,
      };
    },
    dimensionsText() {
      return this.configuration.dimensions.map((dimension) => I18n.t(`filter.config.${dimension}`)).join(", ");
    },
    disabled() {
      return !window.zoinedContext.features.insights;
    },
  },
  methods: {
    apply() {
      this.$emit("change", { ...this.defaultConfiguration });
    },
    clearInsights() {
      this.$emit("change", null);
    },
    loadGroupings() {
      new GroupingsApiService().getMetricGroupings(this.metric).then((groupings) => (this.groupings = groupings));
    },
    edit() {
      this.$refs.newsletterSectionInsightsModal.show();
    },
  },
  watch: {
    metric(m) {
      this.loadGroupings();
    },
  },
  created() {
    if (this.metric) {
      this.loadGroupings();
    }
  },
};
</script>

<style lang="scss" scoped>
.newsletter-section-insights {
  margin-left: 200px;
}
.current-configuration > * {
  margin-right: 0.5em;
}
.buttons {
  margin-top: 0.5em;
}
.buttons > * {
  margin-right: 0.5em;
}
</style>
