<template>
  <div v-if="filterConfiguration && allFilterItems.length > 0" class="component-filters-container">
    <div class="component-filters">
      <div class="items-container scrollbar-hidden">
        <div class="items">
          <component-filters-item
            v-for="filterItem in allFilterItems"
            :items="filterItem.items"
            :type="filterItem.type"
            :separator="filterItem.separator"
            :key="filterItem.items.join()"
          />
        </div>
      </div>
      <div v-if="specialItemsText" class="special-items-container">{{ specialItemsText }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import Actions from "@/store/actions";
import { computed } from "vue";
import ComponentFiltersItem, { ComponenFiltersItemProps } from "@/analytics/component-filters-item.vue";
import { removeSpecialAndExtraFiltersFromFilterConfiguration } from "@/lib/filter-util";
import _ from "lodash";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import { useI18n } from "@/composables/i18n";
import TranslationService from "@/core/translation.service";

const props = defineProps<{
  filterConfiguration: FlatFilterConfiguration;
  chartOptions: any;
}>();

const store = useStore();

const { t } = useI18n();

const filtersets = computed(() => store.state.filtersets.all);

const fetchFiltersets = () => {
  store.dispatch(Actions.fetchFiltersets);
};

const allFilterItems = computed<ComponenFiltersItemProps[]>(() => {
  return filterSetItems.value || [...filterItems.value, ...metricRangeItems.value];
});

const filterSetItems = computed<ComponenFiltersItemProps[]>(() => {
  if (props.chartOptions?.show_filterset_name) {
    const sets = _.filter(props.filterConfiguration.sets, ({ enabled }) => enabled);

    if (sets && sets.length > 0) {
      if (!filtersets.value) {
        fetchFiltersets();
        return [];
      } else {
        return [
          {
            items: sets.map((set) => filtersets.value.find(({ id }) => id === set.id)?.name),
            type: "include",
          },
        ];
      }
    }
  }
  return null;
});

const filterItems = computed<ComponenFiltersItemProps[]>(() => {
  const filters = removeSpecialAndExtraFiltersFromFilterConfiguration(props.filterConfiguration);

  return _.reduce(
    filters,
    (acc, filterConfigMap) => {
      const filterConfigs = _.map(filterConfigMap, (filterConfig, key) => ({ ...filterConfig, key }));
      const enabledConfigs = _.filter(filterConfigs, ({ enabled }) => enabled);
      const includeItems = _.filter(enabledConfigs, ({ exclude }) => !exclude).map(
        ({ value, name, key }) => name || value || key
      );
      const excludeItems = _.filter(enabledConfigs, ({ exclude }) => exclude).map(
        ({ value, name, key }) => name || value || key
      );
      if (includeItems.length > 0) {
        acc.push({ items: includeItems, type: "include" });
      }
      if (excludeItems.length > 0) {
        acc.push({ items: excludeItems, type: "exclude" });
      }
      return acc;
    },
    []
  );
});

const metricRangeItems = computed<ComponenFiltersItemProps[]>(() => {
  if (props.filterConfiguration.metric_range) {
    const separator = props.filterConfiguration.rangefilter_union ? ` ${t("filter.config.or").toUpperCase()} ` : ", ";

    const labels = _.filter(props.filterConfiguration.metric_range, ({ enabled }) => enabled).map((metricRange) =>
      new TranslationService().metricRangeTitle(metricRange)
    );

    return [{ items: labels, type: "range", separator }];
  } else {
    return [];
  }
});

const specialItemsText = computed<string>(() => {
  const likeForLike = _.findKey(props.filterConfiguration.like_for_like, ({ enabled }) => enabled);
  const items = [];
  if (likeForLike) {
    items.push(t(`filters.like_for_like.${likeForLike}`));
  }
  if (props.filterConfiguration.limit_to_business_hours) {
    items.push(t("filter.config.limit_to_business_hours"));
  }
  return items.join(", ");
});
</script>

<style lang="scss" scoped>
$grid-float-breakpoint: 992px;

.component-filters {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (min-width: $grid-float-breakpoint) {
    flex-direction: row;
    align-items: flex-start;
  }

  .items-container {
    flex-grow: 1;
    overflow-x: scroll;

    .items {
      width: max-content;
      display: flex;
      flex-wrap: nowrap;
      gap: 10px;
    }

    @media (min-width: 768px) {
      overflow-x: auto;

      .items {
        width: 100%;
        flex-wrap: wrap;
      }
    }
  }

  .special-items-container {
    flex-shrink: 0;
  }
}
</style>
