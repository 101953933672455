<template lang="pug">
  b-modal(id="email-modal",
    ref="emailModal",
    no-fade=true,
    lazy
    :ok-title='"actions.save" | i18n',
    :cancel-title='"actions.cancel" | i18n',
    :title='"profile.change_email" | i18n',
    title-tag="h4",
    @ok="handleOk",
    ok-variant="primary save",
    @show="show")

    b-alert(variant="danger" v-if="error" show dismissible) {{error}}
    spinner(v-if="loading")
    .row(v-else)
      form.form-horizontal
        .form-group
          label.col-md-4.control-label(for="email") {{"activerecord.attributes.user.email" | i18n}}
          .col-md-8
            input(type="email", v-model="email")

        .form-group
          label.col-md-4.control-label(for="current_password") {{"activerecord.attributes.user.current_password" | i18n}}
          .col-md-8
            input(type="password", v-model="current_password")

</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import bAlert from 'bootstrap-vue/es/components/alert/alert';
import { changeEmail } from '../lib/data/profile';

import spinner from '../components/spinner.vue';

export default {
	components: {
    spinner,
    'b-modal': bModal,
    'b-alert': bAlert,

  },
	directives: {
		'b-modal': bModalDirective
	},
	data(){
		return {
      loading: false,
      current_password: "",
      email: "",
      error: null
		}
  },
  methods: {
    handleOk: function(e) {
      e.preventDefault();
      this.handleSubmit()
    },
    handleSubmit: function(){
      let data = {
        email: this.email,
        current_password: this.current_password,
      }

      this.loading = true;

      changeEmail(data)
        .then((res) => {
          this.$root.$emit('getAlert', res.success)
          this.$root.$emit('fetchData')
          this.$refs.emailModal.hide()
          this.email = ""
          this.current_password = ""
          this.error = null
          this.loading = false
        }).catch(error => {
            this.error = error.message;
            this.email = "";
            this.current_password = "";
            this.loading = false
        })
    },
    show: function() {
      this.loading = false
    }
  },
  mounted: function() {
    this.loading = true
  }
};
</script>
