<template lang="pug">
.zoined-spinner
	.spin-area
		.cog.cog-1
			.wheel
				.fa.fa-cog
		.cog.cog-2
			.wheel
				.fa.fa-cog
		.cog.cog-3
			.wheel
				.fa.fa-cog
</template>
<script>

export default {

};
</script>
