<template lang="pug">
.update-times-container.container(v-if="updateTimes")
  .row
    .col-xs-12
      flyover-handle.handle(:title="title")
        table.updates
          tr
            th {{ "update_times.data_set" | i18n }}
            th {{ "update_times.last_updated" | i18n }}
          tr(v-for="update in updates")
            td {{ update.name }}
            td {{ update.last_updated }}
</template>
<script>
import UpdateTimesApi from "../api/update-times-api-service";
import flyoverHandle from "./flyover-handle";
import moment from "moment";

export default {
  components: {
    flyoverHandle,
  },
  data() {
    return {
      updateTimes: null,
    };
  },
  computed: {
    title() {
      return I18n.t("update_times.last_updated_at_date", {
        date: this.formatDate(this.updateTimes.last_updated),
      });
    },
    updates() {
      return this.updateTimes.entities.map(({ entity_name, last_updated }) => ({
        name: entity_name,
        last_updated: this.formatDate(last_updated),
      }));
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("L, LT");
    },
  },
  mounted() {
    new UpdateTimesApi()
      .getUpdateTimes()
      .then((times) => (this.updateTimes = times))
      .catch(() => (this.updateTimes = null));
  },
};
</script>
<style lang="scss" scoped>
.update-times-container {
  height: 0px;
  .col-xs-12 {
    min-height: 0;
  }
  .handle {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 25;
  }
  table.updates {
    margin-top: 5px;

    th,
    td {
      &:not(:last-child) {
        padding-right: 10px;
      }
    }
  }
}
</style>