import ApiService from "./api-service";
import MyReport from "../model/my-report";

export default class MyReportsApiService extends ApiService {
  getAll(params = null): Promise<MyReport[]> {
    return this._get("/api/v1/my_reports", { params });
  }

  update({ id, ...my_report }: MyReport): Promise<MyReport> {
    return this._put(`/api/v1/my_reports/${id}`, { data: { my_report } });
  }

  delete({ id }: MyReport): Promise<MyReport> {
    return this._delete(`/api/v1/my_reports/${id}`);
  }
}
