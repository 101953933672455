import { useI18n } from "@/composables/i18n";
import { v4 as uuidv4 } from "uuid";
import { useToastr } from "@/composables/toastr";
import { getDownloadUrl } from "@/channel/file-download-channel";
import { downloadFile } from "@/lib/download";
import { useOverlay } from "@/composables/overlay";
import { makeApiInstance } from "@/api/instance";

export const useExportReport = (url: string) => {
  const { t } = useI18n();
  const toastr = useToastr();
  const overlay = useOverlay(t("processing_export_file"));

  const perform = async () => {
    const download_id = uuidv4();

    overlay.show();
    try {
      // Listen for export finish
      const downloadUrl = await getDownloadUrl(download_id, {
        connected: () => {
          // Start export
          const data: any = {
            download_id,
          };
          makeApiInstance().post(url, data, {
            headers: {
              "Content-Type": "application/json",
            },
          });
        },
      });

      // Download file for user
      await downloadFile(downloadUrl);
    } catch (error) {
      (window.Rollbar || console).error("Export failed. Download id: " + download_id);
      toastr.error(t("errors.export_fail"), { timeOut: 0 });
    } finally {
      overlay.hide();
    }
  };

  return {
    perform,
  };
};
