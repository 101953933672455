<template lang="pug">
.dashboard-templates-list
  .pull-right
    dropdown
      template(v-slot:toggle="toggleProps")
        .btn-group
          button.btn.btn-primary(type="primary" @click="newDashboardTemplate()") {{ 'dashboard_templates.new_dashboard_template' | i18n }}
          button.btn.btn-primary(type="primary" @click="toggleProps.toggleDropdown()")
            span.caret
      template(v-slot:menu)
        dropdown-item(@click="newDashboardTemplate()") {{ 'dashboard_templates.empty_template' | i18n }}
        dropdown-item(@click="newTemplateFromDashboard()") {{ 'dashboard_templates.from_dashboard' | i18n }}
  .filters
    span.badge(v-if="partnerFilter")
      | {{ partnerFilter.name }} 
      i.fa.fa-times(@click="partnerFilter = null")
  table.table.table-hover
    thead
      tr
        th {{ 'attributes.name' | i18n }}
        th {{ 'attributes.visibility' | i18n }}
        th
    tbody
      tr(v-for="template in filteredTemplates")
        td {{ template.name }}
        td
          a.site(v-for="site in visibleSites(template)" @click.stop="setFilter(site)") {{ site.name }}
        td.actions
          .action
            a(@click="editTemplate(template)") {{ 'actions.edit' | i18n }}
          .action
            a(@click.stop="duplicateTemplate(template)") {{ 'actions.duplicate' | i18n }}
          .action
            confirm-button(@confirm="deleteTemplate(template)")
              a {{ 'actions.delete' | i18n }}
  dashboard-template-from-dashboard-modal(ref="dashboardTemplateFromDashboardModal" @select="onSelectDashboard")
</template>

<script lang="ts">
import { makeApiInstance } from "../../api/instance";
import AnalyticsTemplate from "../../model/analytics-template";
import DashboardTemplateFromDashboardModal from "./dashboard-template-from-dashboard-modal.vue";
import Vue from "vue";
import Component from "vue-class-component";
import { Ref } from "vue-property-decorator";
import dropdown from "../../components/dropdown.vue";
import Partner from "../../model/partner";
import confirmButton from "../../components/confirm-button.vue";
import DropdownItem from "../../components/dropdown-item.vue";

@Component({
  components: {
    DashboardTemplateFromDashboardModal,
    dropdown,
    confirmButton,
    DropdownItem,
  },
})
export default class DashboardTemplatesList extends Vue {
  @Ref("dashboardTemplateFromDashboardModal")
  dashboardTemplateFromDashboardModal: DashboardTemplateFromDashboardModal;

  templates: AnalyticsTemplate[] = null;

  partnerFilter: Partner = null;

  get api() {
    return makeApiInstance({ baseURL: "/api/zoined_admin/analytics_templates/" });
  }

  get filteredTemplates(): AnalyticsTemplate[] {
    if (!this.partnerFilter) {
      return this.templates;
    }

    return this.templates.filter((template) =>
      this.partnerFilter.id === "zoined"
        ? template.visible_in_zoined
        : template.partners.some(({ id }) => id === this.partnerFilter.id)
    );
  }
  setFilter(filter: Partner) {
    this.partnerFilter = filter;
  }

  visibleSites(template: AnalyticsTemplate): Partner[] {
    const sites = [];
    if (template.visible_in_zoined) {
      sites.push({ id: "zoined", name: "Zoined" });
    }
    template.partners.forEach(({ id, name }) => sites.push({ id, name }));
    return sites;
  }

  newDashboardTemplate() {
    this.$router.push({ name: "new_dashboard_template" });
  }

  newTemplateFromDashboard() {
    this.dashboardTemplateFromDashboardModal.show();
  }

  onSelectDashboard(id) {
    this.$router.push({ name: "new_dashboard_template", query: { dashboardId: id } });
  }

  editTemplate(template: AnalyticsTemplate) {
    this.$router.push({ name: "edit_dashboard_template", params: { id: template.id.toString() } });
  }

  duplicateTemplate(template: AnalyticsTemplate) {
    this.$router.push({ name: "new_dashboard_template", query: { templateId: template.id.toString() } });
  }

  async deleteTemplate(template: AnalyticsTemplate) {
    await this.api.delete(template.id.toString());
    this.templates = this.templates.filter((it) => it !== template);
  }

  created() {
    this.fetchDashboardTemplates();
  }

  async fetchDashboardTemplates() {
    const result = await this.api.get("", { params: { analytics_type: "dashboard" } });
    this.templates = result.data;
  }
}
</script>

<style lang="scss" scoped>
a.site {
  cursor: pointer;
  margin-right: 5px;
}
.actions {
  .action {
    display: inline-block;
    margin: 0 10px;
    a {
      cursor: pointer;
    }
    a:not(:hover) {
      color: gray;
    }
  }
}
</style>
