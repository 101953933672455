import * as i18n from "./vue/filters/i18n";
import numeral from "numeral";
import "numeral/locales";

const locales = [
  "bg",
  "chs",
  "cs",
  "da-dk",
  "de-ch",
  "de",
  "en-au",
  "en-gb",
  "en-za",
  "es-es",
  "es",
  "et",
  "fi",
  "fr-ca",
  "fr-ch",
  "fr",
  "hu",
  "it",
  "ja",
  "lv",
  "nl-be",
  "nl-nl",
  "no",
  "pl",
  "pt-br",
  "pt-pt",
  "ru-ua",
  "ru",
  "sk",
  "sl",
  "th",
  "tr",
  "uk-ua",
  "vi",
];

export const formatDashboxCurrency = (value, precision, currency = "€", locale = null) => {
  locale = locale || i18n.locale;
  locales.includes(locale) ? numeral.locale(locale) : numeral.locale("en");

  numeral.localeData().abbreviations.million = " M";
  numeral.localeData().abbreviations.billion = " B";
  numeral.localeData().delimiters.thousands = " ";

  let format = "";

  if (precision == 1) {
    format = ".0";
  } else if (precision == 2) {
    format = ".00";
  }

  if (Math.abs(value) >= 1000000000) {
    return numeral(value * 1000).format("0.00a");
  } else if (Math.abs(value) >= 1000000) {
    return numeral(value).format("0.00a");
  } else if (Math.abs(value) >= 100000 && precision >= 1) {
    return numeral(value).format(`0,0`);
  } else if (Math.abs(value) >= 10000 && precision >= 2) {
    return numeral(value).format(`0,0`);
  } else {
    return numeral(value).format(`0,0${format}`);
  }
};

export const formatDashboxPercentageChange = (value, precision = 0, locale = null, plusSign = true) => {
  locale = locale || i18n.locale;
  locales.includes(locale) ? numeral.locale(locale) : numeral.locale("en");
  let positive;
  value > 0 ? (positive = true) : null;

  if (Math.abs(value) > 10000) {
    return (positive && plusSign ? "+" : "") + numeral(value / 100).format("0.0a %");
  } else {
    return (positive && plusSign ? "+" : "") + numeral(value / 100).format("0.0 %");
  }
};

export const formatDashboxCurrencyChange = (value, precision, currency = "€", locale = null) => {
  locale = locale || i18n.locale;
  locales.includes(locale) ? numeral.locale(locale) : numeral.locale("en");
  let format = "";

  if (precision == 1) {
    format = ".0";
  } else if (precision == 2) {
    format = ".00";
  }

  let positive;
  value > 0 ? (positive = true) : null;

  if (Math.abs(value) >= 1000000000) {
    return (positive ? "+" : "") + numeral(value * 1000).format("0.00a");
  } else if (Math.abs(value) >= 1000000) {
    return (positive ? "+" : "") + numeral(value).format("0.00a");
  } else {
    return (positive ? "+" : "") + numeral(value).format(`0,0${format}`);
  }
};

const formatCurrencyChange = (value, precision, currency = "€", locale = null) => {};
