<template>
  <div class="ai-assistant-placeholder" :class="{ open }">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "@/composables/store";
import { computed } from "vue";

const store = useStore();

const open = computed(() => store.state.aiAssistant.open);
</script>

<style scoped>
.ai-assistant-placeholder {
  display: none;

  @media print {
    display: none;
  }

  @media screen and (min-width: 992px) {
    display: block;
    width: 0px;
    height: 100%;
    flex-shrink: 0;
    transition: all 0.3s;

    &.open {
      width: var(--ai-assistant-width);
    }
  }
}
</style>
