<template lang="pug">
.update-times-dropdown(v-if="updateTimes")
  uiv-dropdown
    navbar-dropdown-button(:title="'update_times.last_updated' | i18n", :subtitle="lastUpdatedDate", icon="clock")
    template(slot="dropdown")
      table.updates
        tr
          th {{ "update_times.data_set" | i18n }}
          th {{ "update_times.last_updated" | i18n }}
        tr(v-for="update in updates")
          td {{ update.name }}
          td {{ update.last_updated }}
</template>

<script lang="ts">
import UpdateTimesApi from "../api/update-times-api-service";
import NavbarDropdownButton from "./navbar-dropdown-button.vue";
import moment from "moment";

export default {
  components: { NavbarDropdownButton },
  data() {
    return {
      updateTimes: null,
    };
  },
  computed: {
    lastUpdatedDate() {
      return this.formatDate(this.updateTimes.last_updated);
    },
    updates() {
      return this.updateTimes.entities.map(({ entity_name, last_updated }) => ({
        name: entity_name,
        last_updated: this.formatDate(last_updated),
      }));
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("L, LT");
    },
  },
  mounted() {
    new UpdateTimesApi()
      .getUpdateTimes()
      .then((times) => (this.updateTimes = times))
      .catch(() => (this.updateTimes = null));
  },
};
</script>
<style lang="scss" scoped>
table.updates {
  width: 360px;
  margin: 0 10px;

  th,
  td {
    &:not(:last-child) {
      padding-right: 10px;
    }
  }
}
</style>
