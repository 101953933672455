<template lang="pug">
.top-section
  .option
    label {{ 'filter.config.grouping' | i18n }}
    select(:value="section.grouping", @input="updateGrouping")
      option(v-for="option in groupingOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'filter.config.metrics' | i18n }}
    select(:value="section.metric", @input="updateMetric")
      option(v-for="option in metricsOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'filter.config.sort' | i18n }}
    select(:value="section.sort", @input="updateSort")
      option(v-for="option in sortOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.entries_to_show' | i18n }}
    select(:value="section.limit", @input="updateLimit")
      option(v-for="option in limitOptions" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.chart_type.description' | i18n }}
    select(:value="section.displayType", @input="updateDisplayType")
      option(v-for="option in displayTypes" :value="option.id") {{ option.name }}
  .option
    label {{ 'newsletter.definition.sections.common.time_period' | i18n }}
    timePeriodDropdown(:show-title="false", :custom="false", :available-items="timePeriods", :available-series-types="seriesTypes", :value="selection", @update="updateSelection")
  .option(v-if="openingHoursDefined")
    label {{ 'filter.config.limit_to_business_hours' | i18n }}
    businessHoursSelector(:title="false" :value="section.limit_to_business_hours" @update="updateLimitToBusinessHours")
  .option(v-if="section.displayType=='bar'")
    label {{  'newsletter.definition.sections.common.comparison' | i18n }}
    comparisonPillList(:choices="comparisons", :custom="false", :title="null", :togglable="false", :comparisons="section.comparisons", @update="updateComparisons")
  .option(v-if="lflEnabled && section.comparisons.length == 1 && isTimeComparison(section.comparisons[0].type)")
    label {{ 'filter.config.like_for_like' | i18n }}
    likeForLikeSelector(:config="section.like_for_like", :title="null", @update="updateLikeForLike")
  .option(v-if="section.displayType=='bar' && section.comparisons.length == 1")
    label
    .checkbox
      label
        input(type="checkbox" :checked="section.pairwiseDiff" @input="updatePairwiseDiff")
        | {{ 'newsletter.definition.sections.common.pairwise_diff' | i18n }}
  .option(v-if="insightsVisible && section.metric")
    label {{ 'newsletter.definition.insights.title' | i18n }}
    newsletterSectionInsights(:metric="section.metric", :configuration="section.insights", @change="updateInsights")
  .option(v-if="newsletterType == 'alert'")
    label {{  'newsletter.definition.alert.alert_conditions' | i18n }}
    div(style="margin-left: 200px;")
      newsletterSectionAlertConditions(:config="section.alert", :default-metric="section.metric" @update="updateAlert")
</template>

<script lang="ts">
import { timeComparisonTypes } from "../../filters/time-period";
import MenuItem from "../../interfaces/menu-item";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import _ from "lodash";
import timePeriodDropdown from "../../components/time-period-dropdown.vue";
import businessHoursSelector from "../../components/business-hours-selector.vue";
import comparisonPillList from "../../components/comparison-pill-list.vue";
import likeForLikeSelector from "../../components/like-for-like-selector.vue";
import newsletterSectionInsights from "../newsletter-section-insights.vue";
import newsletterSectionAlertConditions from "../newsletter-section-alert-conditions.vue";
import comparisonSelector from "../../components/comparison-selector.vue";
import { displayTypes, limitOptions, sortOptions } from "../constants";

@Component({
  components: {
    timePeriodDropdown,
    businessHoursSelector,
    comparisonPillList,
    likeForLikeSelector,
    newsletterSectionInsights,
    newsletterSectionAlertConditions,
    comparisonSelector,
  },
})
export default class TopSection extends Vue {
  @Prop()
  section: any;

  @Prop()
  newsletterType: string;

  @Prop()
  timePeriods: MenuItem[];

  @Prop()
  seriesTypes: string[];

  @Prop()
  openingHoursDefined: boolean;

  @Prop()
  lflEnabled: boolean;

  @Prop()
  insightsVisible: boolean;

  get selection() {
    return (
      this.section.time_period && {
        type: this.section.time_period,
        series: this.section.time_series_type,
      }
    );
  }

  get metricsOptions(): MenuItem[] {
    return this.$store.state.parameters.metrics.all;
  }

  get groupingOptions(): MenuItem[] {
    return this.$store.state.parameters.grouping.all;
  }

  get comparisons() {
    const allowedComparisonTypes = [
      "prev_year_corresponding",
      "2_years_back_corresponding",
      "3_years_back_corresponding",
      "prev_year_corresponding_same_weekday_ending",
      "prev_year_same_days",
      "prev_year_full_month",
      "previous_corresponding_period",
      "previous_year_lfl",
      "2_years_back_lfl",
      "3_years_back_lfl",
      "avg_chain",
      "avg_store",
      "best_store",
      "avg_salesperson",
      "best_salesperson",
      "budget",
      "budget_full_month",
    ];
    return this.$store.state.parameters.comparisons.all?.filter(({ type }) => allowedComparisonTypes.includes(type));
  }

  get limitOptions() {
    return limitOptions;
  }

  get sortOptions() {
    return sortOptions;
  }

  get displayTypes() {
    return displayTypes(["bar", "pie"]);
  }

  updateGrouping(event) {
    const grouping = event.target.value;
    this.updateSection({ ...this.section, grouping });
  }

  updateMetric(event) {
    const metric = event.target.value;
    this.updateSection({ ...this.section, metric, insights: null });
  }

  updateSort(event) {
    const sort = event.target.value;
    this.updateSection({ ...this.section, sort });
  }

  updateLimit(event) {
    const limit = Number.parseInt(event.target.value);
    this.updateSection({ ...this.section, limit });
  }

  updateDisplayType(event) {
    const displayType = event.target.value;
    this.updateSection({ ...this.section, displayType });
  }

  updatePairwiseDiff(event) {
    const pairwiseDiff = event.target.checked;
    this.updateSection({ ...this.section, pairwiseDiff });
  }

  updateSelection({ type, series }) {
    this.updateSection({ ...this.section, time_period: type, time_series_type: series });
  }

  updateLimitToBusinessHours(limit_to_business_hours) {
    this.updateSection({ ...this.section, limit_to_business_hours });
  }

  updateComparisons(comparisons) {
    this.updateSection({ ...this.section, comparisons });
  }

  updateLikeForLike(like_for_like) {
    this.updateSection({ ...this.section, like_for_like });
  }

  updateInsights(insights) {
    this.updateSection({ ...this.section, insights });
  }

  updateAlert(alert) {
    this.updateSection({ ...this.section, alert });
  }

  updateSection(section) {
    this.$emit("update-section", section);
  }

  isTimeComparison(type) {
    return _.includes(timeComparisonTypes, type);
  }
}
</script>
