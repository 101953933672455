"use strict";

import TimePeriod, { timeComparisonTypes } from "../filters/time-period";
import * as _ from "lodash";
import moment from "moment-timezone";
import FilterConfiguration from "../model/filter-configuration";
import FiltersConfiguration from "../model/filters-configuration";
import { filterConfigurationItemMapToFilters } from "../model/filter-configuration-item-map";
import FlatFilterConfiguration from "@/model/flat-filter-configuration";
import Filters from "@/model/filters";

declare const I18n: any;

export const DASHBOARD_FILTERS = ["snippets", "charts"];
export const WIDGET_FILTERS = [
  ...DASHBOARD_FILTERS,
  "grouping",
  "grouping_x",
  "grouping_y",
  "grouping_stack",
  "column_grouping",
  "top_grouping",
  "total_grouping",
  "metrics",
  "metrics2",
  "metrics_x",
  "metrics_y",
  "metrics_z",
  "sort",
  "sort_x",
  "sort_y",
  "sort_stack",
  "inventory_count",
  "dimensions",
];
export const TIME_FILTERS = [
  "date",
  "compare_date",
  "selection",
  "comparison",
  "no_of_past_weeks",
  "sale_date",
  "comparisons",
  "limit_to_business_hours",
];
export const RAW_FILTERS = [
  "proportions",
  "show_percentual_change",
  "show_absolute_change",
  "show_index",
  "limit",
  "limit_y",
  "detail_chart_limit",
  "variant",
  "proportions_set",
  "zerofill",
  "round_values",
  "rangefilter_union",
  "trend",
  "show_values",
  "span",
  "metric_type",
];

// "filters" that should not be passed to the backend
export const NON_FILTERS = ["span"];

export const EXTRA_FILTERS = ["metric_range", "like_for_like", "selected_items"];
export const SPECIAL_FILTERS = WIDGET_FILTERS.concat(TIME_FILTERS)
  .concat(RAW_FILTERS)
  .concat(EXTRA_FILTERS)
  .concat(["sets", "basket_filters"]);

export const VALIDATION_ERROR = "VALIDATION_ERROR";
export const VALIDATION_WARNING = "VALIDATION_WARNING";

export const removeSpecialAndExtraFiltersFromFilterConfiguration = (
  filterConfiguration: FlatFilterConfiguration
): Filters => _.omit(filterConfiguration, SPECIAL_FILTERS);

export const componentFilters = (reportFilterConfiguration, component) => {
  const filterConfiguration = refreshFilterTimes({
    ...reportFilterConfiguration,
    ...component.filterConfiguration,
  });

  const filter = {
    ...configToActive(filterConfiguration),
    ...component.drilldownFilters,
    ...component.filter,
  };

  if (component.dynamic_time_range) {
    filter.grouping = selectGroupingFromTimeRange(filter.start_time, filter.end_time);
  }
  if (component.extend_comparison) {
    // Fill in comparison dates, if missing.
    const selection = filterConfiguration.selection;
    const comparisons_with_dates = filter.comparisons.map((c) =>
      c.end_time
        ? c
        : {
            start_time: selection.start_time,
            end_time: selection.end_time,
            ...c,
          }
    );
    // Extend comparison series, based on selection length.
    const f = extendComparisonSeries(comparisons_with_dates, selection);
    // Update grouping and linking in filter.
    filter.grouping = f.grouping;
    filter.comparisons = f.comparisons;
    filter.compare_unlinked = f.compare_unlinked;
  }
  if (component.pagination) {
    if (filter.limit == null) {
      let { grouping } = filter;
      if (grouping instanceof Array) {
        grouping = grouping[0];
      }
      filter.limit = (() => {
        switch (grouping) {
          case "hour":
            return 24;
          case "day":
            return 50;
          case "month":
            return 12;
          case "year":
          case "quarter":
            return 10;
          default:
            return 10;
        }
      })();
    }
  }

  return filter;
};

export const validateFilters = function(config) {
  const errors: { message: string; level: string }[] = [];
  const flattenedConfig = _.merge({}, config.time, config.widgets, config.filters.filters);
  const { comparisons, like_for_like } = configToActive(flattenedConfig);

  if (like_for_like) {
    if (comparisons.length !== 1 || !_.includes(timeComparisonTypes, comparisons[0].type)) {
      errors.push({
        message: I18n.t("filters.errors.incompatible_comparisons_for_like_for_like"),
        level: VALIDATION_ERROR,
      });
    }
  }

  return errors;
};

function isValidBudget(label, metric, groupings, budgetConfigs) {
  const budgetConfig = (budgetConfigs && budgetConfigs[label]) || {};
  const budgetGroupings = budgetConfig[metric];
  if (!budgetGroupings) {
    return false;
  }
  const availableGroupings = ["year", "month", "week", "day"].concat(budgetGroupings);
  return _.difference(groupings, availableGroupings).length === 0;
}

export function extendComparisonSeries(comparisons, timeSelection) {
  // Extend comparison time ranges
  comparisons = comparisons || [];
  comparisons = comparisons.map((comparison) => {
    comparison = _.cloneDeep(comparison);
    if (comparison.end_time) {
      const endTime = moment(comparison.end_time);
      switch (timeSelection.type) {
        case "4_weeks":
          endTime.add(8, "weeks");
          break;
        case "12_months":
          endTime.add(6, "months");
          break;
        case "rolling_3_months":
          endTime.add(9, "months");
          break;
      }
      comparison.end_time = endTime.format("YYYY-MM-DD");
    }
    return comparison;
  });
  // Calculate grouping from longest time range
  const ranges = comparisons.filter((range) => range.start_time && range.end_time);
  const longestRange = ranges.reduce(pickLongestRange, timeSelection);
  const grouping = selectGroupingFromTimeRange(longestRange.start_time, longestRange.end_time);

  return {
    comparisons,
    grouping,
    compare_unlinked: true,
  };
}

function pickLongestRange(r1, r2) {
  const d1 = moment(r1.end_time).diff(r1.start_time, "days");
  const d2 = moment(r2.end_time).diff(r2.start_time, "days");
  return d1 > d2 ? r1 : r2;
}

export function configToActive(config, currentTime = new Date()): any {
  const result: any = {};
  _.each(config, (v, k) => {
    if (!v && v !== false) {
      return;
    }
    if (NON_FILTERS.includes(k)) {
      return;
    }
    if (k === "selection") {
      result.start_time = v.start_time;
      result.end_time = v.end_time;
      if (v.series) {
        result.series_type = v.series;
      }
      if (v.type === "today_until_last_full_hour") {
        let currentMoment = moment(currentTime);
        const zoinedContext = (window as any).zoinedContext;
        const timeZone = zoinedContext && zoinedContext.companyTimeZone;
        if (timeZone) {
          currentMoment = currentMoment.tz(timeZone);
        }
        const hour = currentMoment.hour();
        result.hour = _.range(0, hour).map((hour) => ("0" + hour).slice(-2));
      }
      if (v.type === "last_full_hour") {
        let currentMoment = moment(currentTime);
        const zoinedContext = (window as any).zoinedContext;
        const timeZone = zoinedContext && zoinedContext.companyTimeZone;
        if (timeZone) {
          currentMoment = currentMoment.tz(timeZone);
        }
        const hour = currentMoment.hour();
        if (hour > 0) {
          result.hour = [("0" + (hour - 1)).slice(-2)];
        }
      }
    } else if (k === "comparison") {
      if (v.start_time && v.end_time) {
        result.compare_start_time = v.start_time;
        result.compare_end_time = v.end_time;
      } else {
        result.benchmark = [v.type];
      }
    } else if (k === "comparisons") {
      result[k] = _.map(
        _.filter(v, (c) => c.enabled),
        (c) => _.omit(c, "enabled")
      );
    } else if (k === "limit_to_business_hours") {
      result[k] = !!v;
    } else if (k === "metric_range") {
      result[k] = _.map(
        _.filter(v, (c) => c.enabled),
        (c) => _.omit(c, "enabled")
      );
    } else if (_.includes(RAW_FILTERS, k)) {
      result[k] = v;
    } else if (k === "basket_filters") {
      result[k] = Object.keys(v).reduce((basketFilters, key) => {
        return {
          ...basketFilters,
          [key]: filterConfigurationItemMapToFilters(v[key]),
        };
      }, {});
    } else {
      result[k] = filterConfigurationItemMapToFilters(v);
    }
  });
  return result;
}

export function filterToFlyover(filters): FilterConfiguration {
  if (filters && filters.time) {
    const config: any = {
      time: _.cloneDeep(filters.time),
      widgets: _.cloneDeep(filters.widgets),
      filters: _.cloneDeep(filters.filters),
    };
    if (filters.raw_filters) {
      config.raw_filters = _.cloneDeep(filters.raw_filters);
    }
    if (filters.basket_filters) {
      config.basket_filters = _.cloneDeep(filters.basket_filters);
    }
    return config;
  } else {
    const config: any = {
      time: _.pick(filters, TIME_FILTERS),
      widgets: _.pick(filters, [...WIDGET_FILTERS, ...EXTRA_FILTERS]),
      filters: normalizeConfig(_.omit(filters, SPECIAL_FILTERS)),
    };
    const raw_filters = _.pick(filters, RAW_FILTERS);
    if (!_.isEmpty(raw_filters)) {
      config.raw_filters = raw_filters;
    }
    const basket_filters = filters?.basket_filters;
    if (!_.isEmpty(basket_filters)) {
      config.basket_filters = basket_filters;
    }

    return config;
  }
}

export function refreshFilterTimes(time) {
  const result = _.cloneDeep(time);

  if (time.selection) {
    result.selection = refreshSelection(time.selection);

    if (time.comparisons) {
      result.comparisons = refreshComparisons(time.selection, time.comparisons);
    }
  }

  return result;
}

export function selectGroupingFromTimeRange(startTime, endTime) {
  const s = moment(startTime);
  const e = moment(endTime + " 23:59:59");
  if (e.diff(s, "hours") <= 24) {
    return "hour";
  } else if (e.diff(s, "days") <= 31) {
    return "day";
  } else if (e.diff(s, "weeks") <= 12) {
    return "week";
  } else if (e.diff(s, "months") <= 24) {
    return "month";
  } else {
    return "year";
  }
}

export function selectFilterableGroupingFromTimeRange(startTime, endTime) {
  const s = moment(startTime);
  const e = moment(endTime + " 23:59:59");
  if (e.diff(s, "days") < 7) {
    return "dayperiod";
  } else {
    return "weekday";
  }
}

function convertOneGroupingToBusinessCalendar(grouping) {
  switch (grouping) {
    case "year":
      return "custom_year";
    case "week":
      return "custom_week";
    case "month":
      return "custom_period";
    default:
      return grouping;
  }
}

export function convertGroupingToBusinessCalendar(groupings) {
  if (_.isArray(groupings)) {
    return groupings.map(convertOneGroupingToBusinessCalendar);
  } else {
    return convertOneGroupingToBusinessCalendar(groupings);
  }
}

function refreshSelection(selection) {
  let tp = new TimePeriod({ selection }).getFilterModel();

  return tp.selection;
}

function refreshComparisons(selection, comparisons) {
  return comparisons
    .filter((comparison) => comparison.type || comparison.text)
    .map((comparison) => refreshComparison(selection, comparison));
}

function refreshComparison(selection, comparison) {
  let tp = new TimePeriod({ selection, comparison }).getFilterModel();

  return _.extend({}, comparison, tp.comparison);
}

function upgradeFromV1(config) {
  const config2 = _.omit(config, ["parameters"]);
  if (_.isArray(_.values(config2)[0])) {
    return {
      v: 2,
      sets: [],
      filters: _.reduce(
        config2,
        (acc, v, k) => {
          acc[k] = _.reduce(
            v,
            (f, id, index) => {
              let exclude = false;
              if (id.indexOf("exclude:") === 0) {
                id = id.substring(8);
                exclude = true;
              }
              f[id] = { enabled: true, exclude, order: index + 1 };
              return f;
            },
            {}
          );
          return acc;
        },
        {}
      ),
    };
  } else {
    return { v: 2, sets: [], filters: _.cloneDeep(config2) };
  }
}

// Validate and potentially upgrade legacy config into new filter configuration
// format
export function normalizeConfig(config): FiltersConfiguration {
  if (config.v !== 2) {
    config = upgradeFromV1(config);
  } else {
    config = _.cloneDeep(config);
  }
  if (!config.filters) {
    config.filters = {};
  }
  _.map(config.filters, (v, k) => {
    if (v === null) {
      delete config.filters[k];
    }
  });

  return config;
}

export function convertToNewFilterConfiguration(f, filterSets = null) {
  f = filterToFlyover(f);
  f.filters = normalizeConfig(f.filters);
  if (filterSets) {
    f.filters = resolveFilterConfiguration(f, filterSets);
  }

  return f;
}

export function resolveFilterConfiguration(f, filterSets) {
  const set = _.find(f.sets, ({ enabled }) => enabled);
  if (set) {
    const setConfig = _.find(filterSets, ({ id }) => id === set.id);
    if (setConfig) {
      return {
        ...setConfig.config,
        sets: f.sets,
      };
    }
  }
  return f;
}

export function flatFilterConfiguration(config: FilterConfiguration) {
  const { time, widgets, filters, raw_filters, basket_filters } = config;
  return Object.assign({}, time, widgets, filters.filters, raw_filters, basket_filters ? { basket_filters } : {});
}

export function mergeFilterConfigurations(...filterConfigurations: FilterConfiguration[]) {
  return filterConfigurations.reduce(
    (acc: any, config) => {
      if (config.time) {
        acc.time = { ...acc.time, ...config.time };
      }
      if (config.widgets) {
        acc.widgets = { ...acc.widgets, ...config.widgets };
      }
      if (config.filters) {
        if (config.filters.filters) {
          acc.filters.filters = { ...acc.filters.filters, ...config.filters.filters };
        }
        if (config.filters.sets) {
          acc.filters.sets = [...acc.filters.sets, ...config.filters.sets];
        }
      }
      if (config.raw_filters) {
        acc.raw_filters = { ...acc.raw_filters, ...config.raw_filters };
      }
      if (config.basket_filters) {
        acc.basket_filters = acc.basket_filters || {};
        acc.basket_filters = { ...acc.basket_filters, ...config.basket_filters };
      }
      return acc;
    },
    { time: {}, widgets: {}, filters: { v: 2, filters: {}, sets: [] }, raw_filters: {} }
  );
}
