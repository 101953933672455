<template lang="pug">
modal.newsletter-template-from-newsletter-modal(v-model="open", :append-to-body="true", :title="'newsletter_templates.select_newsletter' | i18n", :footer="false")
  .loading-indicator(v-if="!newsletterDefinitions")
    spinner
  .content(v-else)
    .item(v-for="newsletterDefinition in newsletterDefinitions" @click="createFromNewsletterDefinition(newsletterDefinition)") {{ newsletterDefinition.title }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Modal } from "uiv";
import spinner from "../../components/spinner.vue";
import newsletterDefinitionsApi from "../../api/admin-newsletter-definitions-api";

@Component({
  components: {
    Modal,
    spinner,
  },
})
export default class NewsletterTemplateFromNewsletterModal extends Vue {
  open = false;

  newsletterDefinitions = null;

  get companyId() {
    return window.zoinedContext.companyId;
  }

  show() {
    this.open = true;
    if (!this.newsletterDefinitions) {
      this.fetchNewsletterDefinitions();
    }
  }

  createFromNewsletterDefinition(newsletterDefinition) {
    this.open = false;
    this.$emit("select", newsletterDefinition.id);
  }

  async fetchNewsletterDefinitions() {
    this.newsletterDefinitions = await newsletterDefinitionsApi.get({ company_id: this.companyId });
  }
}
</script>

<style lang="scss" scoped>
.newsletter-template-from-newsletter-modal {
  .loading-indicator {
    min-height: 100px;
  }

  .item {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 5px;

    &:hover {
      cursor: pointer;
      background-color: #cceeff;
    }
  }
}
</style>
