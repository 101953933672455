<template lang="pug">
  b-modal(id="edit-modal",
    ref="editModal",
    no-fade=true,
    lazy
    :ok-title='"actions.save" | i18n',
    :cancel-title='"actions.cancel" | i18n',
    :title='"profile.edit.title" | i18n',
    title-tag="h4",
    ok-variant="primary save"
    @ok="handleOk",
    @show="show")

    spinner(v-if="loading")
    .row(v-else)
      form.form-horizontal
        .form-group
          label.col-md-4.control-label(for="user_first_name") {{"activerecord.attributes.user.first_name" | i18n}}
          .col-md-8
            input.string.required(type="text", v-model="first_name")

        .form-group
          label.col-md-4.control-label(for="user_last_name") {{"activerecord.attributes.user.last_name" | i18n}}
          .col-md-8
            input.string.required(type="text", v-model="last_name")

        .form-group
          label.col-md-4.control-label(for="language") {{"activerecord.attributes.user.language" | i18n}}
          .col-md-8
            select(v-model="selected")
              option(v-for="language in languages" v-bind:value="language.locale") {{language.native}}

</template>

<script>
import bModal from 'bootstrap-vue/es/components/modal/modal'
import bModalDirective from 'bootstrap-vue/es/directives/modal/modal'
import { fetchData, updateProfile, switchLanguage } from '../lib/data/profile';

import spinner from '../components/spinner.vue';

export default {
	components: {
    spinner,
    'b-modal': bModal

  },
	directives: {
		'b-modal': bModalDirective
	},
  props: {
    user: {
      type: Object,
      required: true
    },
  },
	data(){
		return {
      fetchIndex: 0,
      loading: false,
      languages: null,
      error: null,
      selected: null,
      first_name: null,
      last_name: null
		}
  },
  methods: {
		fetchLanguages: function() {
      this.loading = true;

			fetchData('/profile/languages', this.fetchIndex).then((res) => {
				this.languages = res
				this.loading = false;
			}).catch(err => {
				this.error = err
			})
		},
    handleOk: function(e) {
      e.preventDefault()
      if ((!!this.first_name.length && !!this.last_name.length) == false)  {
        this.error="Error"
      } else {
        this.handleSubmit()
      }
    },
    handleSubmit: function(){
      let data = {
        first_name: this.first_name,
        last_name: this.last_name,
        language: this.selected,
      }

     this.loading = true;

      updateProfile(data, this.fetchIndex)
        .then((res) => {
          this.loading = false;
          this.fetchIndex = res.index;
          this.$root.$emit('getAlert', res.alert)
          this.$root.$emit('fetchData')
          this.$refs.editModal.hide()
          this.user.language != res.language ? switchLanguage(res.language) : null
        }).catch(err => {
          this.error = err
        })
    },
    show: function() {
      this.fetchLanguages()
      this.selected = this.user.language
      this.first_name = this.user.first_name
      this.last_name = this.user.last_name
    }
	}
};
</script>
