<template lang="pug">
.navbar-title(v-uiv-tooltip.bottom="title") 
  span.hidden-print {{ title }}
  span.visible-print {{ printTitle }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";

@Component({})
export default class NavbarTitle extends Vue {
  get title() {
    return this.$route.meta?.title || this.$store.getters.pageTitle;
  }

  get printTitle() {
    return [this.title, window.zoinedContext.company?.name].filter(Boolean).join(" - ");
  }
}
</script>

<style lang="scss" scoped>
.navbar-title {
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (min-width: 768px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media print {
    font-size: 14pt;
    font-weight: 400;
  }
}
</style>
