<template>
  <div class="users-list relative">
    <div class="flex-row mb-md">
      <div class="flex-1">
        <search-bar v-model="search" />
      </div>
      <div>
        <button class="btn btn-primary" @click="exportUsers()">Export users</button>
      </div>
    </div>
    <spinner v-if="isFetching && !isFetched" />
    <admin-table :columns="columns" :items="userCompanyRoles" :sort="sort" @update-sort="sort = $event">
      <template #cell(company)="{ item }">
        {{ companyName(item) }}
      </template>
    </admin-table>
    <div v-if="totalPages > 1" class="centered">
      <uiv-pagination v-model="page" :total-page="totalPages"></uiv-pagination>
    </div>
  </div>
</template>

<script setup lang="ts">
import { AdminTableColumn } from "@/admin/admin-table.vue";
import { useI18n } from "@/composables/i18n";
import { computed, Ref, ref } from "vue";
import Spinner from "@/components/spinner.vue";
import AdminTable from "@/admin/admin-table.vue";
import SearchBar from "@/components/search-bar.vue";
import UserCompanyRole from "@/model/user-company-role";
import {
  useExportUserCompanyRoles,
  useUserCompanyRoles,
  UseUserCompanyRolesParams,
} from "@/partner-admin/users/user-company-roles-api";

const { t } = useI18n();

const search = ref("");

const sort = ref("last_name");

const page = ref(1);

const perPage = 15;

const totalPages = computed(() => (data.value ? Math.ceil(data.value.total / perPage) : 0));

const columns: AdminTableColumn[] = [
  { label: "First name", key: "first_name", sortable: true },
  { label: "Last name", key: "last_name", sortable: true },
  { label: "Email", key: "email", sortable: true },
  { label: "Role", key: "role" },
  { label: "Company", key: "company" },
];

const params: Ref<UseUserCompanyRolesParams> = computed(() => ({
  page: page.value,
  per_page: perPage,
  sort: sort.value,
  search: search.value,
}));

const { isFetched, isFetching, data } = useUserCompanyRoles(params);

const userCompanyRoles = computed(() => data.value?.data || []);

const companyName = (role: UserCompanyRole) => role.company.name;

const exportUserCompanyRoles = useExportUserCompanyRoles();

const exportUsers = () => exportUserCompanyRoles.perform();
</script>

<style scoped></style>
