<template lang="pug">
pill-list(:title="'activerecord.models.team.other' | i18n", :typeahead="true", :items="items" :available-items="menuItems", @update="onUpdate($event)", @search="fetchTeams($event)")
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import PillItem from "../interfaces/pill-item";
import MenuItem from "../interfaces/menu-item";
import pillList from "./pill-list.vue";
import TeamApiService from "../api/teams-api-service";

// interface Team {
//   id: string;
//   name: string;
// }

@Component({
  props: {
    teams: {
      type: Array,
      default: [],
    },
  },
  components: {
    pillList,
  },
})
export default class UserRolePillList extends Vue {
  menuTeams = [];

  get items() {
    return this.teams.map(({ id, name }) => ({
      value: id,
      name,
    }));
  }

  get menuItems() {
    return this.menuTeams.map(({ id, name }) => ({
      key: id,
      name,
    }));
  }

  fetchTeams(search) {
    this.menuTeams = [];
    new TeamApiService().getTeams({ search }).then((teams) => (this.menuTeams = teams));
  }

  onUpdate({ items }) {
    const teams = items.map(({ value, name }) => ({
      id: value,
      name,
    }));
    this.$emit("update", teams);
  }
}
</script>
