import _ from "lodash";
import FilterConfigurationItemMap from "../model/filter-configuration-item-map";

interface PillItem {
  value: string;
  name: string;
  exclude?: boolean;
  enabled?: boolean;
  wildcard?: boolean;
}

const pillItemListFromFilterConfigurationItemMap = (itemMap: FilterConfigurationItemMap): PillItem[] => {
  return _.chain(itemMap)
    .toPairs()
    .sortBy(([, item]) => item.order || 99999)
    .map(([key, { name, ...rest }]) => {
      delete rest.order;
      const item: PillItem = {
        name,
        ...rest,
        value: key,
      };
      return item;
    })
    .value();
};

export { pillItemListFromFilterConfigurationItemMap };

export default PillItem;
