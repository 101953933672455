import _ from "lodash";
import { normalizeConfig } from "../lib/filter-util";
import { normalizeComparisonType } from "../filters/time-period";
import SectionTypes from "./section-types";

class Configuration {
  global = null;
  sections: any[] = null;

  constructor(global = {}, sections = []) {
    this.global = global || {};
    this.global.filters = this.global.filters || {
      v: 2,
      sets: [],
      filters: {},
    };
    this.sections = sections || [];
  }

  toHash() {
    const json: any = {};
    json.sections = this.sections.map((s) => {
      return s.type.toJson(s);
    });
    json.global = _.cloneDeep(this.global);
    return json;
  }

  toJson() {
    return JSON.stringify(this.toHash());
  }

  valid(definition) {
    return _.every(this.sections, function(s) {
      return s.type.valid(s, definition);
    });
  }

  static fromJson(jsonString) {
    const json = JSON.parse(jsonString) || {};

    return Promise.resolve(this.fromHash(json));
  }

  static fromHash(hash) {
    const config = _.cloneDeep(hash);
    const sections = hash.sections || [];
    sections.forEach((section) => {
      if (section.comparisons) {
        section.comparisons = section.comparisons.map(normalizeComparison);
      }
      if (section.comparison) {
        section.comparison = normalizeComparison(section.comparison);
      }
      if (section.filters) {
        section.filters = normalizeConfig(section.filters);
      }
    });
    const types = sections.map((s) => SectionTypes.get(s.type));
    const valid_sections = _.map(_.zip(sections, types), ([section, type]: [any, any]) => {
      const s = { ...type.defaults, ...section };
      s.type = type;
      if (s.type != null) {
        s.type.valid(s);
        return s;
      } else {
        return null;
      }
    });
    config.global = config.global || {};
    config.global.filters = normalizeConfig(config.global.filters || {});

    return new Configuration(config.global, _.compact(valid_sections));
  }
}

function normalizeComparison(comparison) {
  if (_.isString(comparison)) {
    comparison = { type: comparison };
  }
  const { type, ...rest } = comparison;
  return {
    ...rest,
    type: normalizeComparisonType(type),
  };
}

export default Configuration;
