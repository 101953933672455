import _ from "lodash";
import { makeApiInstance } from "../api/instance";
import RootState from "../store/root-state";
import { Module } from "vuex";

export const STATE_IDLE = "idle";
export const STATE_LOADING = "loading";
export const STATE_FAILED = "failed";

type State = typeof STATE_IDLE | typeof STATE_LOADING | typeof STATE_FAILED;

export interface ApiSliceState<T> {
  all: T[];
  state: State;
}

type UrlConfig = {
  url: string;
  params?: any;
};

type Opts<T> = {
  // eslint-disable-next-line no-unused-vars
  transformItem?: (item: T) => any;
};

export function createApiSlice<T>(
  name: string,
  urlOrConfig: string | UrlConfig,
  opts: Opts<T> = {}
): Module<ApiSliceState<T>, RootState> {
  const { transformItem = (item) => item } = opts;

  const { url, params } = _.isString(urlOrConfig)
    ? {
        url: urlOrConfig,
        params: null,
      }
    : urlOrConfig;

  const mutationNames = apiSliceMutationNames(name);
  const actionNames = apiSliceActionNames(name);

  return {
    state: () => ({
      all: null,
      state: STATE_IDLE,
    }),
    mutations: {
      // fetch
      [mutationNames.fetchRequest]: (state) => (state.state = STATE_LOADING),
      [mutationNames.fetchSuccess]: (state, all) =>
        Object.assign(state, {
          all,
          state: STATE_IDLE,
        }),
      [mutationNames.fetchFailure]: (state) => (state.state = STATE_FAILED),

      // add
      [mutationNames.addRequest]: () => {},
      [mutationNames.addSuccess]: (state, item) => {
        Object.assign(state, {
          all: [...state.all, item],
        });
      },
      [mutationNames.addFailure]: () => {},

      // update
      [mutationNames.updateRequest]: () => {},
      [mutationNames.updateSuccess]: (state, [id, item]) => {
        Object.assign(state, {
          all: state.all.map((each) => ((each as any).id === id ? item : each)),
        });
      },
      [mutationNames.updateFailure]: () => {},

      // delete
      [mutationNames.deleteRequest]: () => {},
      [mutationNames.deleteSuccess]: (state, id) => {
        Object.assign(state, {
          all: state.all.filter((each) => (each as any).id !== id),
        });
      },
      [mutationNames.deleteFailure]: () => {},
    },
    actions: {
      [actionNames.fetch]: ({ commit, state }) => {
        if (state.state !== STATE_LOADING) {
          commit(mutationNames.fetchRequest);
          return makeApiInstance()
            .get(url, { params })
            .then((response) => commit(mutationNames.fetchSuccess, response.data))
            .catch((error) => {
              commit(mutationNames.fetchFailure);
              throw error;
            });
        } else {
          return Promise.resolve();
        }
      },
      [actionNames.add]: ({ commit }, item: T) => {
        commit(mutationNames.addRequest);
        return makeApiInstance()
          .post(url, transformItem(item), { params })
          .then((response) => {
            const item = response.data;
            commit(mutationNames.addSuccess, item);
            return item;
          })
          .catch((error) => {
            commit(mutationNames.addFailure);
            throw error;
          });
      },
      [actionNames.update]: ({ commit }, [id, item]) => {
        commit(mutationNames.updateRequest);
        return makeApiInstance()
          .put(url + "/" + id, transformItem(item), { params })
          .then((response) => {
            const item = response.data;
            commit(mutationNames.updateSuccess, [id, item]);
            return item;
          })
          .catch((error) => {
            commit(mutationNames.updateFailure);
            throw error;
          });
      },
      [actionNames.delete]: ({ commit }, id) => {
        commit(mutationNames.deleteRequest);
        return makeApiInstance()
          .delete(url + "/" + id, { params })
          .then(() => commit(mutationNames.deleteSuccess, id))
          .catch((error) => {
            commit(mutationNames.deleteFailure, id);
            throw error;
          });
      },
    },
  };
}

export const apiSliceMutationNames = (name: string) => ({
  fetchRequest: `${name}/fetchRequest`,
  fetchSuccess: `${name}/fetchSuccess`,
  fetchFailure: `${name}/fetchFailure`,
  addRequest: `${name}/addRequest`,
  addSuccess: `${name}/addSuccess`,
  addFailure: `${name}/addFailure`,
  updateRequest: `${name}/updateRequest`,
  updateSuccess: `${name}/updateSuccess`,
  updateFailure: `${name}/updateFailure`,
  deleteRequest: `${name}/deleteRequest`,
  deleteSuccess: `${name}/deleteSuccess`,
  deleteFailure: `${name}/deleteFailure`,
});

export const apiSliceActionNames = (name: string) => ({
  fetch: `${name}/fetch`,
  add: `${name}/add`,
  update: `${name}/update`,
  delete: `${name}/delete`,
});
