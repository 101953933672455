<template lang="pug">
.report-attachment-section
  .option
    label {{ 'newsletter.definition.sections.report_attachment.report' | i18n }}
    select(:value="section.report", @input="updateReport")
      option(v-for="option in reportOptions" :value="option.id") {{ option.name }}
</template>

<script lang="ts">
import Actions from "../../store/actions";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ReportAttachmentSection extends Vue {
  @Prop()
  section: any;

  get reportOptions() {
    return this.$store.state.excelReports.all;
  }

  updateReport(event) {
    const report = Number.parseInt(event.target.value);
    this.$emit("update-section", { ...this.section, report });
  }
}
</script>
