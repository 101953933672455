import Filter from "../../model/filter";
import Metric from "../..//model/metric";
import { createApiSlice } from "../../store/api-slice";
import RootState from "../root-state";
import { Module } from "vuex";

export type ParametersState = {};

const currency = window.zoinedContext?.currency?.currency;

const parameters: Module<ParametersState, RootState> = {
  namespaced: true,
  modules: {
    filters: createApiSlice<Filter>("filters", "/api/v1/parameters/available_filters"),
    grouping: createApiSlice("grouping", "/api/v1/parameters/available_groupings"),
    metrics: createApiSlice<Metric>("metrics", {
      url: "/api/v1/parameters/metrics",
      params: {
        ...(currency ? { currency } : {}),
      },
    }),
    snippets: createApiSlice("snippets", "/api/v1/parameters/snippets"),
    charts: createApiSlice("charts", "/api/v1/parameters/charts"),
    sort: createApiSlice("sort", "/api/v1/parameters/sort"),
    timePeriods: createApiSlice("timePeriods", "/api/v1/parameters/available_time_periods"),
    comparisons: createApiSlice("comparisons", "/api/v1/parameters/available_comparisons"),
    locales: createApiSlice("locales", "/api/v1/parameters/available_locales"),
  },
};

export default parameters;
