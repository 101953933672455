import { makeApiInstance } from "./instance";

export default class GroupingsApiService {
  getMetricGroupings(metric) {
    return makeApiInstance()
      .get(`/api/v1/groupings/${metric}`)
      .then((response) => response.data)
      .then((groupings) => groupings[0]);
  }
}
