import { makeApiInstance } from "./instance";

export default class DashboardsService {
  getCompanyDashboards(userId = null) {
    let url = "/api/v1/reports/company_dashboards";
    if (userId) {
      url += `?user_id=${userId}`;
    }
    return makeApiInstance()
      .get(url)
      .then((response) => response.data);
  }
}
