<template lang="pug">
select(
  v-model='model',
  @change="onModelChange"
)
  option(:value="item.key" v-for="item in items") {{ item.name }}
</template>

<script lang="ts">
import Comparison from "../model/comparison";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { comparisonToMenuKey, menuKeyToComparison } from "../lib/menu-helper";
import MenuItem from "../interfaces/menu-item";
import _ from "lodash";

@Component({})
export default class ComparisonSelector extends Vue {
  @Prop()
  value: Comparison;

  @Prop()
  choices: any[];

  model: string = null;

  get items(): MenuItem[] {
    return (
      this.choices?.map((comparison) => {
        return {
          key: comparisonToMenuKey(comparison),
          name: comparison.name,
        };
      }) || []
    );
  }

  onModelChange() {
    this.$emit("input", menuKeyToComparison(this.model));
  }

  created() {
    this.updateModel(this.value);
  }

  @Watch("value")
  updateModel(value) {
    if (!value || _.isEmpty(value)) {
      this.model = null;
    } else {
      value = _.isString(value) ? { type: value } : value;
      this.model = comparisonToMenuKey(value);
    }
  }
}
</script>
