<template lang="pug">
dropdown(ref="dropdown", tag="span", class="inline-block", v-model="open", :not-close-elements="notCloseElements")
  slot(name="default")
  div.item-menu-dropdown(slot="dropdown")
    item-menu(
      :items="items", 
      :selected-items="selectedItems", 
      :typeahead="typeahead", 
      :searchable="searchable",
      :deleteable="deleteable",
      :search-query="searchQuery",
      :wildcard="wildcard",
      @select="onSelect($event)",
      @deselect="onDeselect($event)",
      @search="onSearch($event)",
      @delete="$emit('delete',$event)"
    )
      template(slot="footer" v-if="$slots['footer']")
        slot(name="footer")
</template>

<script lang="ts">
import Vue from "vue";
import { Dropdown } from "uiv";
import Component from "vue-class-component";
import { Prop, Ref, Watch } from "vue-property-decorator";
import itemMenu from "./item-menu.vue";
import MenuItem from "../interfaces/menu-item";
import PillItem from "@/interfaces/pill-item";

@Component({
  components: {
    Dropdown,
    itemMenu,
  },
})
export default class ItemMenuDropdown extends Vue {
  @Ref("dropdown")
  dropdown: Dropdown;

  @Prop()
  items: MenuItem[];

  @Prop()
  selectedItems: PillItem[];

  @Prop({ default: false })
  typeahead: boolean;

  @Prop({ default: true })
  searchable: boolean;

  @Prop({ default: false })
  multiSelect: boolean;

  @Prop({ default: false })
  deleteable: boolean;

  @Prop()
  menuOpen: boolean;

  @Prop({ default: false })
  wildcard: boolean;

  open = false;
  notCloseElements = [];
  searchQuery = "";

  onSelect(item) {
    if (!this.multiSelect) {
      this.open = false;
    }
    this.$emit("select", item);
  }

  onDeselect(item) {
    if (!this.multiSelect) {
      this.open = false;
    }
    this.$emit("deselect", item);
  }

  onSearch(q) {
    this.searchQuery = q;
    this.$emit("search", this.searchQuery);
  }

  mounted() {
    this.notCloseElements = [this.dropdown.$el];
  }

  @Watch("open")
  onOpenChange() {
    if (this.open) {
      this.onSearch("");
    }
    this.$emit("update:menu-open", this.open);
  }

  @Watch("menuOpen")
  onMenuOpenChange() {
    if (this.menuOpen === true || this.menuOpen === false) {
      this.open = this.menuOpen;
    }
  }
}
</script>

<style lang="scss" scoped>
.item-menu-dropdown {
  min-width: 300px;
}
</style>
