<template lang="pug">
.category-view
  .category-title.flex-row(@click="open = !open")
    .category-icon
      i.fa(:class="open ? 'fa-folder-open' : 'fa-folder'")
    .flex-1
      b 
        | {{ category.name }} 
        i(v-if="isDefault") (default folder) 
    .actions
      i.fa.fa-pencil(v-if="$listeners.edit" @click.stop="$emit('edit')")
      confirm-button(v-if="$listeners.delete && this.model.length == 0" @confirm="$emit('delete')")
        i.fa.fa-trash
  .category-reports
    draggable(:list="model" group="reports" ghost-class="report-ghost" @change="onOrderChange")
      .report(v-if="open" v-for="report in model") {{ report.name }}
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import draggable from "vuedraggable";
import ConfirmButton from "../components/confirm-button.vue";
import MyReport from "../model/my-report";
import MyReportFolder from "../model/my-report-folder";
import MyReportsApiService from "../api/my-reports-api-service";
import _ from "lodash";

@Component({
  components: {
    draggable,
    ConfirmButton,
  },
})
export default class CategoryView extends Vue {
  @Prop()
  category: MyReportFolder;

  @Prop()
  isDefault: boolean;

  @Prop()
  editable: boolean;

  @Prop()
  reports: MyReport[];

  model: MyReport[] = null;

  open = true;

  get myReportsService() {
    return new MyReportsApiService();
  }

  created() {
    this.model = [...(this.reports || [])];
  }

  async onOrderChange() {
    const updatedMyReports = [];

    this.model = this.model.map((report, index) => {
      const updated = {
        ...report,
        sort_order: index + 1,
        my_report_folder_id: this.category?.id || null,
      };
      if (!_.isEqual(report, updated)) {
        updatedMyReports.push(updated);
      }
      return updated;
    });

    await Promise.all(updatedMyReports.map((myReport) => this.myReportsService.update(myReport)));

    this.$emit("reports-updated", this.model);
  }
}
</script>

<style lang="scss" scoped>
.category-view {
  cursor: pointer;
  .category-title {
    padding: 5px;
    .category-icon {
      color: var(--color-yellow);
      width: 20px;
    }
  }
  .actions {
    i.fa:not(:last-child) {
      margin-right: 5px;
    }
  }
  .category-reports {
    padding-left: 25px;
  }

  .report {
    padding: 2px 0;
  }
}
</style>

<style lang="scss">
.report-ghost {
  opacity: 0.5;
  background-color: #ccc;
}
</style>
