<template lang="pug">
span.more-with-pro-text
  | {{ 'limited_version.more_options_with' | i18n }} 
  pro-text
</template>

<script lang="ts">
import ProText from "./pro-text.vue";

export default {
  name: "MoreWithProText",
  components: {
    ProText,
  },
};
</script>
