import _ from "lodash";
import moment from "moment";

const firstDayOfWeek = window.zoinedContext.firstDayOfWeek;

type ChartState = {
  chart: string;
  selection: string;
  comparison: string;
  metric: string;
  grouping: string;
};

const randomChart = (availableGroupings: string[]): ChartState => {
  const selection = randomSelection();
  const comparison = randomComparison();
  const metric = randomMetric();
  const grouping = randomGrouping(selection, availableGroupings);
  const chart = "column";

  return { chart, selection, comparison, metric, grouping };
};

const randomSelection = () => {
  const options = ["yesterday"];
  const dayOfWeek = moment().day();
  const dayOfMonth = moment().date();
  const dayOfYear = moment().dayOfYear();
  if (dayOfWeek >= firstDayOfWeek && dayOfWeek < firstDayOfWeek + 3) {
    options.push("last_week");
  }
  if (dayOfMonth < 5) {
    options.push("last_month");
  }
  if (dayOfYear > 1) {
    options.push("current_year");
  }
  if (dayOfMonth > 1) {
    options.push("current_month");
  }
  if (dayOfWeek > firstDayOfWeek) {
    options.push("current_week");
  }
  return options[_.random(options.length - 1)];
};

const randomComparison = () => {
  const options = [null, "previous_corresponding_period", "prev_year_corresponding"];
  return options[_.random(options.length - 1)];
};

const randomMetric = () => {
  const options = ["sales", "sales_pcs", "basket_cost", "basket_size", "avg_unit_revenue", "order_count"];
  return options[_.random(options.length - 1)];
};

const randomGrouping = (selection: string, availableGroupings: string[]) => {
  const timeGroupingsBySelection = {
    yesterday: "hour",
    last_week: "day",
    last_month: "day",
    current_year: "month",
    current_month: "day",
    current_week: "day",
  };
  const organizationGrouping = ["store", "sales_person", "cash_register"].find((grouping) =>
    availableGroupings.includes(grouping)
  );
  const categoryGrouping = ["category", "subcategory"].find((grouping) => availableGroupings.includes(grouping));
  const options = [timeGroupingsBySelection[selection], organizationGrouping, categoryGrouping, "product"].filter(
    (grouping) => grouping && availableGroupings.includes(grouping)
  );
  return options[_.random(options.length - 1)];
};

export { ChartState, randomChart };
