import { makeApiInstance } from "./instance";

const apiInstance = makeApiInstance({ baseURL: "/api/v1/newsletter_templates" });

const api = {
  get: (id) => apiInstance.get(id).then((response) => response.data),
  getDefinition: (id) => apiInstance.get(`${id}/definition`).then((response) => response.data),
  getAll: () => apiInstance.get("").then((response) => response.data),
};

export default api;
