<template lang="pug">
uiv-modal(v-model="open" :title='"newsletter.definition.insights.title" | i18n')
  .content
    spinner(v-if="!groupings")
    div(v-else)
      h4 {{ 'filters.compare_to' | i18n }}
      pill-select(:radio="true", :items="comparisonItems", @change="onComparisonItemsChanged")
      h4 {{ 'newsletter.definition.insights.dimensions_title' | i18n }}
      p {{ 'newsletter.definition.insights.dimensions_description' | i18n }}
      pill-select(:items="items", @change="onItemsChanged")
      h4 {{ 'newsletter.definition.insights.number_of_insights' | i18n }}
      select(v-model="numberOfInsights", :disabled="options.numberOfInsights")
        option(v-for="num in [1,2,3,4,5]" v-bind:value="num") {{ num }}
      h4 {{ 'newsletter.definition.insights.number_of_sub_insights' | i18n }}
      select(v-model="numberOfSubInsights")
        option(v-for="num in [1,2,3,4,5]" v-bind:value="num") {{ num }}
  div(slot="footer")
    button.btn.btn-default(@click='cancel()') {{ 'actions.cancel' | i18n }}
    button.btn.btn-primary(@click='save()') {{ 'actions.save' | i18n }}
</template>

<script>
import spinner from "../components/spinner";
import pillSelect from "../components/pill-select";

const defaultDimensions = ["store", "category", "subcategory", "supplier"];

const comparisonTypes = ["prev_year_corresponding", "previous_corresponding_period"];

export default {
  components: {
    spinner: spinner,
    pillSelect: pillSelect,
  },
  props: {
    metric: String,
    groupings: {
      type: Array,
      required: false,
    },
    configuration: Object,
    options: {
      type: Object,
      default: () => ({
        // If not null then number of insights is fixed (non configurable)
        numberOfInsights: null,
      }),
    },
  },
  data() {
    return {
      enabledDimensions: [],
      comparison: null,
      numberOfInsights: 1,
      numberOfSubInsights: 1,
      open: false,
    };
  },
  computed: {
    // Pill list items
    items() {
      return (
        this.groupings &&
        this.groupings.map(({ key, name }) => ({
          key,
          label: name,
          enabled: this.enabledDimensions.includes(key),
        }))
      );
    },

    comparisonItems() {
      return comparisonTypes.map((key) => ({
        key,
        label: I18n.t(`filters.benchmark.${key}`),
        enabled: key === this.comparison,
      }));
    },
  },
  methods: {
    show() {
      const configuration = this.configuration || {};

      // Set up enabled dimensions when modal is opened (keep state for modal lifetime..)
      this.enabledDimensions = configuration.dimensions || defaultDimensions;

      this.comparison = configuration.comparison || comparisonTypes[0];
      this.numberOfInsights = configuration.insights_limit || this.options.numberOfInsights || 1;
      this.numberOfSubInsights = configuration.sub_insights_limit || 1;

      this.open = true;
    },
    onItemsChanged(items) {
      // Update enabled dimensions
      this.enabledDimensions = items.filter(({ enabled }) => enabled).map(({ key }) => key);
    },
    onComparisonItemsChanged(items) {
      const enabledItem = items.find(({ enabled }) => enabled);
      this.comparison = enabledItem ? enabledItem.key : null;
    },
    save() {
      if (this.groupings) {
        const configuration = Object.assign({}, this.configuration || {});
        // Set up dimensions from enabled dimensions, filtering from allowed dimensions for this metric
        configuration.dimensions = this.enabledDimensions.filter(
          (dimension) => !!this.groupings.find(({ key }) => key == dimension)
        );
        configuration.comparison = this.comparison;
        configuration.insights_limit = this.numberOfInsights;
        configuration.sub_insights_limit = this.numberOfSubInsights;

        this.$emit("update", configuration);
        this.open = false;
      }
    },
    cancel() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// ^ lang was 'postcss'
</style>
