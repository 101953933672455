import SectionType from "../section-type";
import { avg_store_validator, cumulative_grouping_validator, time_series_legacy_validator } from "../validators";

class TimeSeries extends SectionType {
  constructor() {
    const opts = {
      defaults: {
        metric: "sales",
        comparison: { type: "no_comparison" },
        displayType: "bar",
        benchmarkForward: false,
        showDatalabels: false,
        cumulative: false,
        top_grouping: "",
        sort: "-metric",
        limit: 3,
      },
    };

    super("time_series", opts);
    this.validators = [time_series_legacy_validator, avg_store_validator, cumulative_grouping_validator];
  }

  exportHook(section) {
    if (!section.comparison || section.comparison.type !== "avg_store") {
      delete section.comparison_target;
    }
    return section;
  }
}

export default TimeSeries;
