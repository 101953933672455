<template>
  <div class="report-owner-selector">
    <div class="form-group">
      <div>
        <label>{{ t(reportTypeLabel) }}</label>
      </div>
      <div class="btn-group btn-multiple">
        <div class="btn btn-primary" :class="{ active: userReport }" @click="selectUserReport()">
          {{ t(userReportLabel) }}
        </div>
        <div
          class="btn btn-primary"
          v-if="isSelectTeamVisible"
          :class="{ active: teamReport }"
          @click="selectTeamReport()"
        >
          {{ t(teamReportLabel) }}
        </div>
        <div
          class="btn btn-primary"
          v-if="isSelectCompanyVisible"
          :class="{ active: companyReport }"
          @click="selectCompanyReport()"
        >
          {{ t(companyReportLabel) }}
        </div>
        <div
          class="btn btn-primary"
          v-if="isSelectPartnerVisible"
          :class="{ active: partnerReport }"
          @click="selectPartnerReport()"
        >
          {{ t(partnerReportLabel) }}
        </div>
      </div>
    </div>
    <div class="form-group" v-if="teamReport">
      <label>{{ t("newsletter.definition.team") }}</label>
      <select @change="onTeamChange">
        <option :value="team.id" :selected="myReport.team_id == team.id" v-for="team in teams">{{ team.name }}</option>
      </select>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "@/composables/i18n";
import TeamsApiService from "../api/teams-api-service";
import MyReport from "../model/my-report";
import { computed, onMounted, reactive, ref } from "vue";

const props = defineProps<{ myReport: MyReport }>();

const emit = defineEmits(["update-my-report"]);

const { t } = useI18n();

const teams = ref([]);

const isSelectTeamVisible = computed(() => {
  return teams.value.length > 0;
});

const isSelectCompanyVisible = computed(() => {
  return isAdmin;
});

const isSelectPartnerVisible = computed(() => {
  return (isPartnerAdmin || isZoinedAdmin) && partnerId;
});

const isAdmin = window.zoinedContext.isAdmin;

const isPartnerAdmin = window.zoinedContext.isPartnerAdmin;

const isZoinedAdmin = window.zoinedContext.isZoinedAdmin;

const companyId = window.zoinedContext.company.id;

const partnerId = window.zoinedContext.company.partner_id;

const userReport = computed(() => {
  return !!props.myReport.user_id;
});

const teamReport = computed(() => {
  return !!props.myReport.team_id;
});

const partnerReport = computed(() => {
  return !!props.myReport.partner_id;
});

const companyReport = computed(() => {
  return !userReport.value && !teamReport.value && !partnerReport.value;
});

const reportTypeLabel = computed(() => {
  return props.myReport.dashboard ? "dashboard_custom.dashboard_type" : "custom_report.report_type";
});

const userReportLabel = computed(() => {
  return props.myReport.dashboard ? "dashboard_custom.user_dashboard" : "custom_report.user_report";
});

const companyReportLabel = computed(() => {
  return props.myReport.dashboard ? "dashboard_custom.company_dashboard" : "custom_report.company_report";
});

const teamReportLabel = computed(() => {
  return props.myReport.dashboard ? "dashboard_custom.team_dashboard" : "custom_report.team_report";
});

const partnerReportLabel = computed(() => {
  return props.myReport.dashboard ? "dashboard_custom.partner_dashboard" : "custom_report.partner_report";
});

const selectUserReport = () => {
  emit("update-my-report", {
    ...props.myReport,
    user_id: window.zoinedContext.userId,
    team_id: null,
    partner_id: null,
    company_id: companyId,
  });
};

const selectCompanyReport = () => {
  emit("update-my-report", {
    ...props.myReport,
    user_id: null,
    partner_id: null,
    team_id: null,
    company_id: companyId,
  });
};

const selectPartnerReport = () => {
  if (!partnerId) {
    return;
  }
  emit("update-my-report", {
    ...props.myReport,
    partner_id: partnerId,
    company_id: null,
    user_id: null,
    team_id: null,
  });
};

const selectTeamReport = (team_id = null) => {
  team_id = team_id || teams.value[0]?.id;
  if (!team_id) {
    return;
  }
  emit("update-my-report", {
    ...props.myReport,
    user_id: null,
    partner_id: null,
    team_id,
    company_id: companyId,
  });
};

const onTeamChange = (event) => selectTeamReport(event.target.value);

onMounted(() => {
  if (isAdmin) {
    new TeamsApiService().getTeams().then((result) => (teams.value = result));
  } else {
    // teams as admin
    teams.value = window.zoinedContext.current_role.team_memberships
      .filter(({ role }) => role === "admin")
      .map(({ team_id, name }) => ({ id: team_id, name }));
  }
});
</script>
